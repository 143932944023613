<template>
    <cookie-law v-if="show" :theme="theme" transitionName="fade">
        <template v-slot="props">
            <div class="Cookie--modal">
                <div class="Cookie--modal--content">
                    <div class="Cookie__content">
                        {{ $t('footer.cookies.label') }}
                    </div>
                    <div class="Cookie__buttons">
                        <button class="button button-3d" @click="props.accept">{{ $t('footer.cookies.accept') }}</button>
                        <button v-if="showMoreInfos" class="button button-border button-rounded m-0" @click="showCookiesPage()">{{ $t('footer.cookies.more-info') }}</button>
                    </div>
                </div>
            </div>
        </template>
    </cookie-law>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes } from '@fwk-client/store/types';

//  @ts-ignore
const CookieLaw = () => import(/* webpackChunkName: 'vue-cookie-law' */'vue-cookie-law');

@Component({
  components: { 
    CookieLaw
  }
})
export default class Cookies extends Vue {

    show:boolean = false;
    theme:string = "vigneron-bottom";
    showMoreInfos:boolean = true;

    created() {
        this.checkTheme();
        this.checkShowMoreInfos();
    }

    mounted() {
        if(this.$store.state.server && this.$store.state.server.configuration &&
                this.$store.state.server.configuration.fwk) {
      
            // We only show the cookie banner when we are in maintenance or coming soon
            if(!this.$store.state.server.configuration.fwk.isComingSoon && 
                    !this.$store.state.server.configuration.fwk.isMaintenance) {
                this.show = true;
            }
        }
        else {
            this.show = true;
        }
    }

    showCookiesPage() {
        var languageCode = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
        // We redirect to the cart page
        this.$router.push({
            name : 'cookies',
            params : {
                lang : languageCode
            }
		}).catch(err => {});
    }

    checkTheme() {
        if(this.$route.name && this.$route.name == "cookies") {
            this.theme = "vigneron-bottom"
        }
        else {
            this.theme = "vigneron"
        }
    }

    checkShowMoreInfos() {
        if(this.$route.name && this.$route.name == "cookies") {
            this.showMoreInfos = false;
        }
        else {
            this.showMoreInfos = true
        }
    }

    @Watch('$route')
    onRouteChange(to:any, from:any) {
        this.checkTheme();
        this.checkShowMoreInfos();
    }
  
}
</script>