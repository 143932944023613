
import { Module, Store } from '@fwk-node-modules/vuex';
import { Component, Position, RouteConfig, Route } from '@fwk-node-modules/vue-router/types/router';
import Vue from '@fwk-node-modules/vue';

import { routerTypes as types } from '@fwk-client/store/types';
import { utils as RouterUtils } from '@fwk-client/router/utils';
import * as api from '@fwk-client/utils/api';

export function createRouterStore () {

  // We need to store components outside of the store as with composition api, components props are updated directly.
  const availableLayouts:{[layoutName:string]:any} = {}; // contains a map of available layouts

  const routerStore:Module<any, any> = {
    namespaced: true,
    state: {
      routes: [], // contains the list of route config without component attribute.
      routeIndexFromShortName : {}, // contains the corresponding index of route in routes from short name.
      currentComponents: [], // contains a list of component objects to watch for async data
      asyncData: {}, // contains async data loaded associated to current components.
      fragments: {}, // contains fragments content loaded via Async component.
      previousRoutesScroll: [], // Contains the scroll position of all previous routes
      layoutGetter: 'router/' + types.getters.DEFAULT_LAYOUT_GETTER,
      previousRoute: undefined, // We store the previous route before redirect so that we can catch SSR redirection
      redirectData: undefined // Contains any data added before redirection.
    },
    getters: {
      /**
       *  GET_ROUTES
       *  This getter provide the list of routes config.
       *  !!! It does not contains the component attribute !!!
       */
      [types.getters.GET_ROUTES]: (state, getters, rootState, rootGetters) => {
        return [...state.routes];
      },
      /**
       *  GET_ROUTE_INDEXES_FROM_SHORTNAME
       *  This getter provide map from route short name to find index in routes
       *  !!! It does not contains the component attribute !!!
       */
       [types.getters.GET_ROUTE_INDEXES_FROM_SHORTNAME]: (state, getters, rootState, rootGetters) => {
        return {...state.routeIndexFromShortName};
      },
      /**
       *  GET_ROUTE_FROM_SHORTNAME
       *  This getter provide the route config corresponding to a short name.
       *  !!! It does not contains the component attribute !!!
       */
       [types.getters.GET_ROUTE_FROM_SHORTNAME]: (state, getters, rootState, rootGetters) => (shortName:string) => {
        var routes = getters[types.getters.GET_ROUTES];
        return routes[state.routeIndexFromShortName[shortName]];
      },
      /**
       *  GET_ASYNC_DATA_BY_COMPONENT
       *  This getter provide the async data for a component.
       *  In case of localized component, it takes the data for current language.
       */
      [types.getters.GET_ASYNC_DATA_BY_COMPONENT]: (state, getters, rootState, rootGetters) => (component:Component) => {
        // We must have a localizedDataName static variable defined for the component
        var asyncDataName = (component as any).asyncDataName;
        if(typeof asyncDataName == "function") {
          asyncDataName = (asyncDataName as Function).call(null, rootState.route);
        }
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - GET ASYNC DATA BY COMPONENT GETTER - " + asyncDataName);
        }
        var asyncData = state.asyncData[asyncDataName]; // This trigger refresh on getter using asyncData - issue is that it trigger getter for all components as reactivity is not working on the object child property.
        if(asyncData && asyncData.isLocalized) {
          var currentLanguage = rootState.languages.currentLanguageCode; // This trigger refresh of the getter with language code is updated.
          return asyncData[currentLanguage];
        }
        return asyncData;
      },
      /**
       *  GET_FRAGMENT_BY_PATH
       *  This getter provide the async data for a component.
       *  In case of localized component, it takes the data for current language.
       */
      [types.getters.GET_FRAGMENT_BY_PATH]: (state, getters, rootState, rootGetters) => (path:string) => {
        var languageCode = rootState.languages.currentLanguageCode;
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - GET FRAGMENT BY PATH - " + path + " (language:"+languageCode+")");
        }
        var fragment = state.fragments[languageCode][path];
        return fragment;
      },
      /**
       *  GET_PREVIOUS_ROUTE_SCROLL
       *  This getter provide the scroll position of the previous route by index.
       *  0 is the last stored route (stored during router.beforeEach)
       */
      [types.getters.GET_PREVIOUS_ROUTE_SCROLL]: (state) => (index:number) => {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - GET PREVIOUS ROUTE SCROLL - " + index);
        }
        var position = null;
        if(state.previousRoutesScroll.length > index) {
          position = state.previousRoutesScroll[index];
        };
        return position;
      },
      /**
       * DEFAULT_LAYOUT_GETTER
       * This action is triggered to compute the layout based on target route.
       */
       [types.getters.DEFAULT_LAYOUT_GETTER]: (state, getters) => (route:Route) => {
        if(route.meta && route.meta.layout && route.meta.layout != "") {
          return route.meta.layout;
        }
        return null;
      },
      /**
       * GET_LAYOUT_NAME_FROM_ROUTE
       * This action get the layout based on the layout computer in store.
       * @param state - current meta state
       * @param getters - current meta getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
       [types.getters.GET_LAYOUT_NAME_FROM_ROUTE]: (state, getters, rootState, rootGetters) => (route:Route) => {
        // We compute the layout from the associated action
        var layoutName = rootGetters[state.layoutGetter](route);
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - GET_LAYOUT_NAME_FROM_ROUTE - Layout name: " + layoutName);
        }
        return layoutName;
      },
      /**
       * GET_LAYOUT
       * This action get the layout based on the layout name.
       * @param state - current meta state
       * @param getters - current meta getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
      [types.getters.GET_LAYOUT]: (state, getters, rootState, rootGetters) => (layoutName:string) => {
        return availableLayouts[layoutName]
      },
      /**
       *  GET_PREVIOUS_ROUTE
       *  This getter provide the previous route.
       */
      [types.getters.GET_PREVIOUS_ROUTE]: (state, getters, rootState, rootGetters) => {
        return state.previousRoute;
      },
      /**
       *  GET_REDIRECT_DATA
       *  This getter provide the redirect data.
       */
       [types.getters.GET_REDIRECT_DATA]: (state, getters, rootState, rootGetters) => {
        return state.redirectData;
      },
    },
    mutations: {
      /**
       *  SET_ROUTES
       *  This mutation store the generated routes used by the router.
       */
      [types.mutations.SET_ROUTES] (state, routes:RouteConfig[]) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - SET_ROUTES MUTATION")
        }
        state.routes = routes;  
        // We compute the map to get the route config from shortName
        var routeIndexFromShortName:{[shortName:string]:number} = {};
        for(var index = 0 ; index < routes.length ; index++) {
          var shortName = RouterUtils.getRouteShortNameFromRoute(routes[index] as Route);
          routeIndexFromShortName[shortName] = index;
        }
        state.routeIndexFromShortName = routeIndexFromShortName;
      },
      /**
       *  ADD_PREVIOUS_ROUTE_SCROLL
       *  This mutation store the previous routes scroll position.
       */
      [types.mutations.ADD_PREVIOUS_ROUTE_SCROLL] (state, position:any) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - SET_PREVIOUS_PAGE_SCROLL MUTATION")
        }
        state.previousRoutesScroll.unshift(position);
      },
      /**
       *  SET_ASYNC_DATA
       *  This mutation update store with async data for component name.
       */
      [types.mutations.SET_ASYNC_DATA] (state, {data, dataName}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - SET_ASYNC_DATA MUTATION")
        }
        // We update the store
        if(state.asyncData[dataName]) {
          state.asyncData[dataName] = data;
        }
        else {
          // We add the dataName property to the asynData
          Vue.set(state.asyncData, dataName, data);
        }
      },
      /**
       *  SET_FRAGMENTS
       *  This mutation update store with fragments loaded for a languageCode.
       */
      [types.mutations.SET_FRAGMENTS] (state, {loadedFragments, languageCode}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - SET_FRAGMENTS MUTATION")
        }
        // We update the fragments
        updateFragmentsInStore(state, {loadedFragments, languageCode});
      },
      /**
       *  SET_COMPONENTS
       *  This mutation update store with list of components matching current route.
       */
      [types.mutations.SET_COMPONENTS] (state, {listComponents}) {
        // We update the store
        Vue.set(state, 'currentComponents', [
          ...listComponents.filter((component:Component) => { 
            // We only keep components for which we have associated async data
            return component != undefined && (component as any).asyncDataName != undefined; 
          })
        ]);
      },
      /**
       *  ADD_LAYOUT
       *  This mutation update store to add layout component.
       */
      [types.mutations.ADD_LAYOUT] (state, {layoutName, layoutComponent}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - ADD_LAYOUT MUTATION - " + layoutName)
        }
        // We update the store
        Vue.set(availableLayouts, layoutName, layoutComponent);
      },
      /**
       *  SET_LAYOUT_GETTER
       *  This mutation update store to update layout getter.
       */
      [types.mutations.SET_LAYOUT_GETTER] (state, payload) {
        // We set the layout getter in the store
        state.layoutGetter = payload.action;
      },
      /**
       *  SET_PREVIOUS_ROUTE
       *  This setter set the previous route.
       */
       [types.mutations.SET_PREVIOUS_ROUTE] (state, previousRoute) {
        // We update the store
        state.previousRoute = {...previousRoute};
      },
      /**
       *  ADD_REDIRECT_DATA
       *  This mutation add redirect data.
       */
      [types.mutations.ADD_REDIRECT_DATA] (state, redirectData) {
        // We update the store
        state.redirectData = {
          ...state.redirectData,
          ...redirectData
        };
      },
      /**
       *  CLEAR_REDIRECT_DATA
       *  This mutation clear redirect data.
       */
      [types.mutations.CLEAR_REDIRECT_DATA] (state) {
        // We update the store
        state.redirectData = undefined;
      },
    },
    actions: {
      /**
       *  CHECK_ASYNC_DATA_ALL_FOR_ROUTE
       *  This method check if async data is loaded for all current components in the route provided.
       *  This methods is called to refresh async data in case of route is updated after list of components are updated.
       */
      [types.actions.CHECK_ASYNC_DATA_ALL_FOR_ROUTE](payload: any, {route}:{route:Route}) {
        // We get all properties from payload parameter.
        var { commit, state, rootState, getters, dispatch } = payload;
        // We need to get data for all components that are currently loaded
        return Promise.all(
          state.currentComponents.map((component:Component) => {
            return dispatch(types.actions.CHECK_ASYNC_DATA_COMPONENT, {component, route});
          })
        )
      },
      /**
       *  CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE
       *  This method check if async data is loaded for all current components in the languageCode provided.
       *  This methods is called to refresh async data in case of language is updated.
       */
      [types.actions.CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE](payload: any, {languageCode}) {
        // We get all properties from payload parameter.
        var { commit, state, rootState, getters, dispatch } = payload;
        // We get the current route
        var route = rootState.route;
        // We need to get data for all components that are currently loaded
        return Promise.all(
          state.currentComponents.map((component:Component) => {
            return dispatch(types.actions.CHECK_ASYNC_DATA_COMPONENT, {component, languageCode, route});
          })
        )
      },
      /**
       *  CHECK_ASYNC_DATA_COMPONENT
       *  This method check if async data is loaded for the component in parameter.
       *  This method is called in case we change route to load associated components data.
       */
      [types.actions.CHECK_ASYNC_DATA_COMPONENT](payload: any, {component, languageCode, route}:{component:Component, languageCode:string, route:Route}):Promise<void> {
        // We get all properties from payload parameter.
        var { commit, state, rootState, getters, dispatch } = payload;
        // We must have a localizedDataName static variable defined for the component
        var asyncDataName = (component as any).asyncDataName;
        if (!asyncDataName) {
          return Promise.resolve();
        }
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - CHECK ASYNC DATA - We check if we need asyncData for: " + (component as any).name);
        }
        if(typeof asyncDataName == "function") {
          asyncDataName = (asyncDataName as Function).call(null, route);
        }
        var isLocalized = (component as any).isAsyncDataLocalized;
        if(!languageCode) {
          languageCode = rootState.languages.currentLanguageCode;
        }
        var asyncData = state.asyncData[asyncDataName];
        if(!asyncData || (isLocalized && !asyncData[languageCode])) {
          if(process.env.CONSOLE == "LOG") {
            if(!isLocalized) {
              console.log("STORE - ROUTER - CHECK ASYNC DATA - We need to load asyncData: " + asyncDataName);
            }
            else {
              console.log("STORE - ROUTER - CHECK ASYNC DATA - We need to load asyncData: " + asyncDataName + " in language: " + languageCode);
            }
          }
          
          return loadAsyncData(asyncDataName,isLocalized ? languageCode : undefined).then((data:any) => {
            // We build the data to be updated
            var updatedData = {
              ...state.asyncData[asyncDataName]
            }
            if(isLocalized) {
              updatedData[languageCode] = data;
              updatedData.isLocalized = true;
            }
            else {
              updatedData = data;
            }
            // We store the data.
            commit(types.mutations.SET_ASYNC_DATA, {
              data: updatedData,
              dataName: asyncDataName
            });
            return Promise.resolve();
          });
        }
        return Promise.resolve();
      },
      /**
       *  CHECK_LAYOUT
       *  This method check if layout in parameter is available.
       */
      [types.actions.CHECK_LAYOUT](payload: any, {layoutName}) {
        // We get all properties from payload parameter.
        var { commit, state, rootState, getters, dispatch } = payload;

        if(layoutName && !availableLayouts[layoutName]) {
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - ROUTER - CHECK LAYOUT - We need to load layout: " + layoutName);
          }
          var componentName = layoutName.charAt(0).toUpperCase() + layoutName.slice(1);
          return import(/* webpackChunkName: "layout-[request]" */ `../components/layouts/${componentName}.vue`)
          .then(
            (m) => {
              var layoutComponent = m.default;
              // We store the data.
              commit(types.mutations.ADD_LAYOUT, {
                layoutName,
                layoutComponent
              });
            }
          )
          .catch((err:string) => {
            return Promise.reject(err);
          })
        }
        else {
          return Promise.resolve();
        }
      },
      /**
       * CHECK_ROUTE_FRAGMENTS
       * This action is triggered when we want to check if route fragments are available.
       * @param context - the action context (state, commit)
       * @param payload - the payload for this action (route, app)
       * @returns Promise object once route bundle is loaded.
       */
      [types.actions.CHECK_ROUTE_FRAGMENTS] ({state, commit}, {languageCode, route}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - ROUTER - CHECK_ROUTE_FRAGMENT: "+route.path);
        }
        var fragments = RouterUtils.getFragmentsFromRoute(route);

        var fragmentsPromises:any[] = [];

        if(fragments) {
          // We check that we need to load
          var missingFragments = getMissingFragments(fragments, state.fragments, languageCode);
          if(missingFragments.length > 0) {
            if(process.env.CONSOLE == "LOG") {
              console.log("STORE - ROUTER - CHECK_ROUTE_FRAGMENT - Missing fragments: "+missingFragments.join(", "));
            }
            // In case there is no route specific bundle in translations
            fragmentsPromises.push(loadRouteFragmentsAsync(languageCode, missingFragments).then(data => {
              commit(types.mutations.SET_FRAGMENTS, data);
            }))
          }
        }
        if(fragmentsPromises.length > 0) {
          return Promise.all(fragmentsPromises);
        }
        return Promise.resolve();
      }
    }
  }

  return routerStore;
};

function getMissingFragments(fragments:string[], loadedFragments:any, languageCode:string):string[] {
  // We keep the fragments which are not available in store
  return fragments.filter((fragment:string) => { return loadedFragments[languageCode] == undefined || loadedFragments[languageCode][fragment] == undefined; })
}

function loadRouteFragmentsAsync(languageCode:string, fragments:string[]):Promise<any> {
  if(process.env.CONSOLE == "LOG") {
    console.log("ROUTER - LOAD ROUTE FRAGMENT (" + languageCode + ", " + fragments.join(", ") + ")");
  }
  try {
    var promises = [];
    for(var fragment of fragments) {
      // We need to use a closure to pass the routeBundle parameter to keep its value within the result.
      (function(fragment) {
        var promise = loadFragment(fragment, languageCode);
        promises.push(promise
          .then(content => {
            // We return the translations with routeBundle to notify that it corresponds to specific route
            return {
              "languageCode" : languageCode,
              "content" : content, 
              "fragment" : fragment
            };
          }
        ));  
      })(fragment);
    }
    return Promise.all(promises).then(results => {
      var loadedFragments:any = {}
      for(var result of results) {
        loadedFragments[result.fragment] = result.content;
      }
      if(process.env.CONSOLE == "LOG") {
        console.log("ROUTER - LOAD ROUTE FRAGMENT (" + languageCode + ", " + fragments!.join(", ") + ") - DONE");
      }
      return {
        "languageCode" : languageCode,
        "loadedFragments" : loadedFragments
      }
    });
  }
  catch(e) {
    if(process.env.CONSOLE == "LOG") {
      console.log("ROUTER - LOAD ROUTE FRAGMENT (" + languageCode + ", " + fragments!.join(", ") + ") - ERROR");
      console.log(e)
    }
    return Promise.reject(e);
  }
}

/**
 * updateFragmentsInStore
 * This method update the store with retrieved fragment from route.
 * @param state - the router store state.
 * @param payload - languageCode, loadedFragments
 */
function updateFragmentsInStore(state:any, payload:any) {
  var {languageCode, loadedFragments} = payload;
  if(loadedFragments !== undefined) {
    
    // merge the fragments
    if(state.fragments[languageCode]) {
      state.fragments[languageCode] = {
        ...state.fragments[languageCode],
        ...loadedFragments
      };
    }
    else {
      Vue.set(state.fragments, languageCode, {...loadedFragments});
    }
  }
}

export function loadFragment(path:string, languageCode:string):Promise<string> {
  return api.getStaticFromPublic('/fragments/'+path+'/'+languageCode+'.html')
      .then((response:any) => {
        if(response != null && response != "") { 
          return Promise.resolve(response);
        }
        else {
          return Promise.resolve("");
        }
      })
      .catch((error:any) => {
        return Promise.resolve("");
      })
}

export function loadAsyncData(name:string, languageCode?:string):Promise<any> {
  return api.getStaticFromPublic('/data/'+name+(languageCode ? '-'+languageCode : '')+'.json')
      .then((response:any) => {
        if(response != null && response != "") { 
          return Promise.resolve(response);
        }
        else {
          return Promise.resolve("");
        }
      })
      .catch((error:any) => {
        return Promise.resolve("");
      })
}