import Vue from '@fwk-node-modules/vue';
import { loadStripe, Stripe, StripeElementsOptions, StripeCardNumberElement, StripeCardExpiryElement, StripeCardCvcElement } from '@stripe/stripe-js';
import { types as cartTypes } from '@fwk-client/modules/shop/stores/cart';
import { types as shopTypes } from '@fwk-client/modules/shop/stores/shop';

export interface StripeCardElements {
    cardNumber:StripeCardNumberElement,
    cardExpiry:StripeCardExpiryElement,
    cardCvc:StripeCardCvcElement
}

export class Payment {

    protected $vm:Vue;
    protected type:"shop"|"hospitality";

    private shopID?:string;
    private stripe?:Stripe;
    private cardElements:Map<string,StripeCardElements>;

    constructor(vm:Vue) {
        this.$vm = vm;
        this.cardElements = new Map();
        this.type = "shop";
    }

    getPaymentTitle(methodOfPayment:any) {
        var title = "";
        if(methodOfPayment.title) {
            title = this.$vm.getLocalizedText(methodOfPayment.title);
        }
        else {
            switch(methodOfPayment.type) {
                case "CARD":{
                    title = this.$vm.$t(this.type + '.payments.card.title') as string;
                    break;
                }
            }
        }

        return title;
    }


    getStripeInstance():Promise<Stripe> {
        if(!this.stripe || this.$vm.$shop_shop._id != this.shopID) {
            // we need to get the shop stripe information.
            var cards = this.$vm.$store.getters['shop/shop/' + shopTypes.getters.GET_SHOP_METHOD_OF_PAYMENTS_CARD];
            var publishableKey = cards[0].publishableKey;
            var options = {
                stripeAccount : cards[0].accountID ? cards[0].accountID : undefined
            }
            return loadStripe(publishableKey, options)
                .then((stripe:Stripe|null) => {
                    if(stripe) {
                        this.stripe = stripe;
                        this.shopID = this.$vm.$shop_shop._id;
                        return Promise.resolve(this.stripe);
                    }
                    return Promise.reject("Unable to load stripe");
                })
                .catch((error) => {
                    console.log("PAYMENT - LOAD STRIPE ERROR");
                    console.log(error);
                    console.log("publishableKey: "+publishableKey);
                    console.log("stripeAccount: "+options.stripeAccount);

                    if(this.$vm.$gtag) {
                        this.$vm.$gtag.exception({
                            description : error,
                            fatal:false
                        })
                    }
                    

                    return Promise.reject(error);
                })
        }
        else {
            return Promise.resolve(this.stripe);
        }
    }

    createStripeCardElements(id:string, locale:StripeElementsOptions["locale"]) {
        return this.getStripeInstance()
        .then((stripe:Stripe) => {
            var elements = stripe.elements({
                locale: locale
              });
            var cardNumber = elements.create('cardNumber', {
                showIcon:true,
                iconStyle:"solid"
              });
            var cardExpiry = elements.create('cardExpiry');
            var cardCvc = elements.create('cardCvc');
            var cardElements:StripeCardElements = {
                cardNumber,
                cardExpiry,
                cardCvc
            }
            this.cardElements.set(id,cardElements);
            return cardElements;
        })
    }

    getStripeCardElements(id:string):StripeCardElements|undefined {
        return this.cardElements.get(id);
    }

    confirmStripeCardPayment(idCardElements:string, stripeClientSecret:string) {
        return this.getStripeInstance()
        .then((stripe:Stripe) => {
            var order = this.$vm.$shop_order;
            var elements = this.cardElements.get(idCardElements);
            if(!elements) {
                return Promise.reject("There is no stripe card element for id: "+idCardElements);
            }
            return stripe.confirmCardPayment(stripeClientSecret, {
              payment_method: {
                card: elements.cardNumber,
                billing_details: {
                  //name: 'Jenny Rosen',
                  address: {
                      line1: order.contact.address.addressLines[0],
                      line2: order.contact.address.addressLines[1] ? order.contact.address.addressLines[1] : undefined,
                      postal_code: order.contact.address.postalCode,
                      city: order.contact.address.city,
                      country: order.contact.address.countryCode
                  }
                }
              }
            })
        })
        .then((result) => {
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              console.log(result.error.message);

              if(this.$vm.$gtag) {
                this.$vm.$gtag.exception({
                    description : result.error.message,
                    fatal:false
                })
              }

              return Promise.reject(result.error.message);
            } else {
              // The payment has been processed!
              if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
                return Promise.resolve();
              }

              if(this.$vm.$gtag) {
                this.$vm.$gtag.exception({
                    description : "Shop - Payment - confirmStripeCardPayment - The payment is not confirmed",
                    fatal:false
                })
              }

              return Promise.reject("The payment is not confirmed");
            }
        })
        .catch((error) => {
            
            if(this.$vm.$gtag) {
                this.$vm.$gtag.exception({
                    description : error,
                    fatal:false
                })
            }

            return Promise.reject(error);
        })
    }

    updateStoreAfterConfirmation() {
        // We check that we have a cart with order id and an order associated in the store.
        var cart = this.$vm.$shop_cart;
        var order = this.$vm.$shop_order;

        if(cart && order && cart.order_id && cart.order_id == order._id) {
            // We remove clean the cart
            this.$vm.$store.commit('shop/cart/' + cartTypes.mutations.SET_CART,null);
        }
    }

}
