import { Module, Store } from '@fwk-node-modules/vuex';
import { createCartStore, types as cartTypes } from './cart';
import { createBookingStore, types as bookingTypes } from './booking';
import { createAgencyStore, types as agencyTypes } from './agency';

export const types = {
  getters : {
    IS_CART_LOADING : 'IS_CART_LOADING',
    IS_CART_PRODUCTS_LOADING : 'IS_CART_PRODUCTS_LOADING',
    GET_SELECTED_METHOD_OF_PAYMENT_INDEX : 'GET_SELECTED_METHOD_OF_PAYMENT_INDEX',
  },
  mutations : {
    START_CART_LOADING : 'START_CART_LOADING',
    STOP_CART_LOADING : 'STOP_CART_LOADING',
    START_CART_PRODUCTS_LOADING : 'START_CART_PRODUCTS_LOADING',
    STOP_CART_PRODUCTS_LOADING : 'STOP_CART_PRODUCTS_LOADING',
    START_AGENCY_APARTMENTS_LOADING : 'START_AGENCY_APARTMENTS_LOADING',
    STOP_AGENCY_APARTMENTS_LOADING : 'STOP_AGENCY_APARTMENTS_LOADING',
    SET_SELECTED_METHOD_OF_PAYMENT_INDEX : 'SET_SELECTED_METHOD_OF_PAYMENT_INDEX',
  },
  actions : {
  }
}

export const statePath:string = 'hospitality/';

export function createStore () {

  const cart = createCartStore();
  const booking = createBookingStore();
  const agency = createAgencyStore();

  const store:Module<any, any> = {
    namespaced: true,
    modules: {
      cart,
      booking,
      agency
    },
    state: {
      isCartLoading : false, // Set to true when backend action to load the cart
      isCartProductsLoading : false, // Set to true when backend action updating the cart products
      isAgencyApartmentsLoading : false, // Set to true when backend action loading the apartments associated to agency
      selectedMethodOfPaymentIndex : null, // number or null, it is the index of method of payment selected (agency.methodOfPayments)
    },
    getters: {  
      [types.getters.IS_CART_LOADING](state) { return state.isCartLoading; },
      [types.getters.IS_CART_PRODUCTS_LOADING](state) { return state.isCartProductsLoading; },  
      [types.getters.GET_SELECTED_METHOD_OF_PAYMENT_INDEX](state) { return state.selectedMethodOfPaymentIndex; },  
    },
    mutations: {
      [types.mutations.START_CART_LOADING] (state) { state.isCartLoading = true; },
      [types.mutations.STOP_CART_LOADING] (state) { state.isCartLoading = false; },
      [types.mutations.START_CART_PRODUCTS_LOADING] (state) { state.isCartProductsLoading = true; },
      [types.mutations.STOP_CART_PRODUCTS_LOADING] (state) { state.isCartProductsLoading = false; },
      [types.mutations.START_AGENCY_APARTMENTS_LOADING] (state) { state.isAgencyApartmentsLoading = true; },
      [types.mutations.STOP_AGENCY_APARTMENTS_LOADING] (state) { state.isAgencyApartmentsLoading = false; },
      [types.mutations.SET_SELECTED_METHOD_OF_PAYMENT_INDEX] (state, index) { state.selectedMethodOfPaymentIndex = index; },
    },
    actions: {
      
    }
  }
  return store;
};

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;

/**
 * updateStoreFromBackend
 * This method update the store based on data returned in backend responses.
 * @param response - the backend response
 * @param store - the store instance
 */
export function updateStoreFromBackend(response:any, store:Store<any>) {
  if(response.cart !== undefined) {
    // We update the cart in store
    store.commit('hospitality/cart/' + cartTypes.mutations.SET_CART,response.cart);
    store.commit('hospitality/cart/' + cartTypes.mutations.SET_CART_RETRIEVED, true);
  }
  if(response.booking !== undefined) {
    // We update the order in store
    store.commit('hospitality/booking/' + bookingTypes.mutations.SET_BOOKING,response.booking);
  }
  if(response.agency !== undefined && response.agency !== null) {
    // We update list of feed
    store.commit('hospitality/agency' + agencyTypes.mutations.SET_AGENCY,response.agency);
  }
}