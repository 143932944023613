import { Module } from '@fwk-node-modules/vuex';
import { Route } from '@fwk-node-modules/vue-router';
import { utils as RouterUtils } from '@fwk-client/router/utils';
import { metaTypes } from '@fwk-client/store/types';
import { gettersPath as siteGettersPath, types as siteTypes } from '@fwk-client/modules/cms/stores/site';
import { gettersPath as vigneronOnlineGettersPath, types as vigneronOnlineTypes } from './vigneron-online';
import { computeDefaultFaviconMetas, getRouteMetasFromShortName } from '@fwk-client/store/meta';
import { computeRoutesInMenu, filterScope, filterAuthRoles, getDefaultShopMenuItems, isShopRoute, menu as vigneronOnlineMenu } from '../utils/menu';
import { populateRouteSocialMediasMeta, updateRouteMetasFromPagesMeta, getThemeVariablesFromRoute } from '../utils/metas';
import { RouteMeta } from '@fwk-client/types/meta';

export const types = {
  getters : {
    GET_ROUTES_IN_MENU : 'GET_ROUTES_IN_MENU',
    GET_VIGNERON_ONLINE_MENU : 'GET_VIGNERON_ONLINE_MENU',
    GET_IS_ROUTE_ACTIVE : 'GET_IS_ROUTE_ACTIVE',
    LAYOUT_GETTER : 'LAYOUT_GETTER'
  },
  mutations : {},
  actions : {
    METAS_COMPUTER : 'METAS_COMPUTER',
    THEME_STYLE_COMPUTER : 'THEME_STYLE_COMPUTER'
  }
}

const privateTypes = {
  getters : {
    GET_SITE_MENU : 'GET_SITE_MENU',
    GET_SHOP_CONTENT_MENU : 'GET_SHOP_CONTENT_MENU'
  },
  mutations : {
  }
}

export const statePath:string = 'application/';

export function createApplicationStore () {

  const applicationStore:Module<any, any> = {
    namespaced: true,
    state: {
    },
    getters : {
      /**
       * GET_VIGNERON_ONLINE_MENU
       * This getter get the menu items associated to the vigneron-online application.
       * @param state - current state
       * @param getters - current getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
       [types.getters.GET_VIGNERON_ONLINE_MENU]: (state, getters, rootState, rootGetters) => (app:Vue, scope?:string):any[] => {
        var routesInMenu = vigneronOnlineMenu;
        routesInMenu = computeRoutesInMenu(routesInMenu, app.$store, app.$i18n);
        routesInMenu = filterScope(routesInMenu, scope);
        return routesInMenu;
      },
      /**
       * GET_SITE_MENU
       * This getter get the menu items associated to the site.
       * @param state - current state
       * @param getters - current getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
      [privateTypes.getters.GET_SITE_MENU]: (state, getters, rootState, rootGetters) => (app:Vue, scope?:string):any[] => {
        var siteRoutesInMenu = rootGetters[siteGettersPath[siteTypes.getters.GET_SITE_MENU]];
        if(siteRoutesInMenu != null) {
          siteRoutesInMenu = computeRoutesInMenu(siteRoutesInMenu, app.$store, app.$i18n);
          siteRoutesInMenu = filterAuthRoles(siteRoutesInMenu, app.$store);
          siteRoutesInMenu = filterScope(siteRoutesInMenu, scope);
        }
        return siteRoutesInMenu;
      },
      /**
       * GET_SHOP_CONTENT_MENU
       * This getter get the menu items associated to the shop content.
       * @param state - current state
       * @param getters - current getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
       [privateTypes.getters.GET_SHOP_CONTENT_MENU]: (state, getters, rootState, rootGetters) => (app:Vue, scope?:string):any[] => {
        // We get the menu from shop content
        var shopContentMenu = rootGetters[vigneronOnlineGettersPath[vigneronOnlineTypes.getters.GET_SHOP_CONTENT_MENU]];
        if(!shopContentMenu) {
          shopContentMenu = getDefaultShopMenuItems();
        }
        shopContentMenu = computeRoutesInMenu(shopContentMenu, app.$store, app.$i18n);
        shopContentMenu = filterScope(shopContentMenu, scope);
        
        return shopContentMenu;
      },
      /**
       * GET_ROUTES_IN_MENU
       * This getter get the routes to be displayed in top menu for all pages (site, vigneron-online shop pages, vigneron-online site).
       * @param state - current state
       * @param getters - current getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
      [types.getters.GET_ROUTES_IN_MENU]: (state, getters, rootState, rootGetters) => (app:Vue, scope?:string):any[] => {
        // By default we return the vigneron-online routes
        var routesInMenu = getters[types.getters.GET_VIGNERON_ONLINE_MENU](app, scope);

        // Do we have a site
        if(app.$cms_site && !app.$vigneronOnline_site) {
          routesInMenu = getters[privateTypes.getters.GET_SITE_MENU](app, scope);
        }
        // We are on vigneron-online on a shop route
        else if(isShopRoute(rootState.route)) {
          routesInMenu = getters[privateTypes.getters.GET_SHOP_CONTENT_MENU](app, scope);
        }
        
        return routesInMenu;
      },
      /**
       * GET_IS_ROUTE_ACTIVE
       * This getter is triggered to know if route is active based on the link target route and current route.
       */
      [types.getters.GET_IS_ROUTE_ACTIVE]: (state, getters, rootState, rootGetters) => (routeMenu:any) => {
        const routeShortName = RouterUtils.getRouteShortNameFromRoute(rootState.route);
        // We check if we are on checkout page to have shop-products page considered as active
        if(["shop-checkout"].indexOf(routeShortName) > -1 && routeMenu.shortName == "shop-products") {
          return true;
        }
        // We check if one of the sub route is active
        if(routeMenu.subRoutes && routeMenu.subRoutes.map((subRoute:any) => subRoute.shortName).indexOf(routeShortName) > -1) {
          return true;
        }
        return routeShortName == routeMenu.shortName;
      },
      /**
       * LAYOUT_GETTER
       * This getter is triggered to get the layout based on target route.
       * Layout can be defined:
       *  - At route level in meta (route.meta.layout)
       *  - Provided by site in layout ruleset (site.layout.name)
       *  In case we are on vigneron-online website
       *  - Default layout is Page
       *  - On shop dedicated page (with shopPath in params), layout is Shop
       */
      [types.getters.LAYOUT_GETTER]: (state, getters, rootState, rootGetters) => (route:Route) => {
        var site = rootGetters[siteGettersPath[siteTypes.getters.GET_SITE]];
        if(site) {
          // We have a site so we first check if we have a layout provided from layout ruleset
          var siteLayoutName = rootGetters[siteGettersPath[siteTypes.getters.GET_SITE_LAYOUT_NAME]];
          var metaLayout = route.meta!.layout;
          if(metaLayout) {
            siteLayoutName = metaLayout
          }
          return siteLayoutName;
        }
        return null;
      }
    },
    mutations: {
      
    },
    actions: {
      /**
       * METAS_COMPUTER
       * This getter get the meta for current route and language.
       * @param state - current meta state
       * @param getters - current meta getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
      [types.actions.METAS_COMPUTER] ({state, commit, rootState}, {app}) {

        // The computer is based on the current route and language
        var route:Route = rootState.route;
        var routeShortName = RouterUtils.getRouteShortNameFromRoute(route);
        const languageCode = rootState.languages.currentLanguageCode;

        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - APPLICATION - METAS_COMPUTER - " + route.path + " - " + languageCode)
        }

        // We compute the meta from the store (localized resources)
        var routeMetas:RouteMeta = getRouteMetasFromShortName(rootState, routeShortName, languageCode);
        
        // We update the metas from pages meta associated to shopContent or site
        updateRouteMetasFromPagesMeta(routeMetas, route, app);
        
        // We populate the social medias meta
        populateRouteSocialMediasMeta(routeMetas, route, app);

        // We update the computed metas
        commit('meta/' + metaTypes.mutations.SET_ROUTE_METAS, {routeMetas}, {root: true})

        // We compute the favicon metas
        var faviconPath = undefined;
        if(app.$cms_site && app.$cms_site.theme && app.$cms_site.theme.faviconPath) {
          // We compute the path which can be relative to public or static paths
          faviconPath = app.$cms.utils.getComputedPictureURL(app.$cms_site.theme.faviconPath);
        }
        var faviconMetas = computeDefaultFaviconMetas(faviconPath);
        // We update the computed favicon mets
        commit('meta/' + metaTypes.mutations.SET_FAVICON_METAS, {faviconMetas}, {root: true});

        // We compute the styles metas
        if(app.$cms_site && app.$cms_site.theme && app.$cms_site.theme.stylePaths) {
          // We update the computed styles mets
          commit('meta/' + metaTypes.mutations.SET_STYLES_META, {
            key: 'site',
            styles: app.$cms_site.theme.stylePaths.map((path:string) => {
              return app.$cms.utils.getComputedPictureURL(path);
            })
          }, {root: true});
        }
      },
      /**
       * THEME_STYLE_COMPUTER
       * This action compute the style to apply shop theme.
       * @param state - current meta state
       * @param getters - current meta getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
       [types.actions.THEME_STYLE_COMPUTER] ({state, commit, rootState, rootGetters}, {app}) {

        // The computer is based on the current route and language
        var route:Route = rootState.route;

        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - APPLICATION - THEME_STYLE_COMPUTER - " + route.path)
        }

        var style = {
          key : "shopStyle",
          style : ""
        }

        var variables = [
          {
            key: 'primaryColor',
            css: '--primary-color',
            default: '#b880ff'
          },
          {
            key: 'secondaryColor',
            css: '--secondary-color',
            default: '#C89CFF'
          },
          {
            key: 'headerLinksColor',
            css: '--header-links-color',
            default: '#444'
          },
          {
            key: 'headerLinksHoverColor',
            css: '--header-links-hover-color',
            default: '#C89CFF'
          },
          {
            key: 'headerLinksActiveColor',
            css: '--header-links-active-color',
            default: '#C89CFF'
          },
          {
            key: 'headerDarkLinksColor',
            css: '--header-dark-links-color',
            default: '#F7F7F7'
          },
          {
            key: 'headerDarkLinksHoverColor',
            css: '--header-dark-links-hover-color',
            default: '#C89CFF'
          },
          {
            key: 'headerDarkLinksActiveColor',
            css: '--header-dark-links-active-color',
            default: '#C89CFF'
          },
          {
            key: 'headerBackgroundColor',
            css: '--header-background-color',
            default: '#FFFFFF'
          },
          {
            key: 'headerDarkBackgroundColor',
            css: '--header-dark-background-color',
            default: '#333333'
          },
          {
            key: 'headerBorderColor',
            css: '--header-border-color',
            default: '#EEE'
          },
          {
            key: 'bodyTextColor',
            css: '--body-text-color',
            default: '#444'
          },
          {
            key: 'bodyBackgroundColor',
            css: '--body-background-color',
            default: 'inherit'
          },
          {
            key: 'contentLinksColor',
            css: '--content-links-color',
            default: '#444'
          },
          {
            key: 'contentLinksHoverColor',
            css: '--content-links-hover-color',
            default: '#222'
          },
          {
            key: 'contentBackgroundColor',
            css: '--content-background-color',
            default: '#FFF'
          },
          {
            key: 'footerBorderColor',
            css: '--footer-border-color',
            default: '#999'
          },
          {
            key: 'footerTextColor',
            css: '--footer-text-color',
            default: '#555'
          },
          {
            key: 'footerLinksColor',
            css: '--footer-links-color',
            default: '#333'
          },
          {
            key: 'footerLinksHoverColor',
            css: '--footer-links-hover-color',
            default: '#555'
          },
          {
            key: 'footerLinksActiveColor',
            css: '--footer-links-active-color',
            default: '#555'
          },
          {
            key: 'footerBackgroundColor',
            css: '--footer-background-color',
            default: '#DDD'
          }
        ]

        // If we are on a shop route of a vigneron-online site
        var themeVariables:any = getThemeVariablesFromRoute(route, app);
        
        // We check if we have updated theme and generate variables
        if(Object.keys(themeVariables).length > 0) {
          style.style = ":root { ";
          for(var variable of variables) {
            if(themeVariables[variable.key]) { style.style += variable.css+": " + themeVariables[variable.key] + ";"; }
          }
          style.style += " }";
        }

        // We update the computed style
        commit('meta/' + metaTypes.mutations.SET_STYLE, style, {root: true})
      }
    }
  }
  return applicationStore;
};

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;