import { FormatDateType, formatDate } from "@igotweb-node-api-utils/formatter";

export enum CHECKOUT_STEPS {
    REVIEW = 1,
    METHOD_OF_DELIVERY = 2,
    METHOD_OF_PAYMENT = 3
}

export function viewProductDetails(product:any, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var shop = $vm.$shop_shop;
        var shopName = getShopNameFromProduct(product, shop);

        var eventName = 'view_item';
        var eventContent = {
            "items": [
                getItemFromProduct(product, shop)
            ],
            "affiliation" : shopName
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("SHOP - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function addProductInCart(product:any, quantity:number, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var shop = $vm.$shop_shop;
        var shopName = getShopNameFromProduct(product, shop);

        var eventName = 'add_to_cart';
        var eventContent = {
            "items": [
                getItemFromProduct(product, shop, quantity)
            ],
            "affiliation" : shopName
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("SHOP - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function removeProductInCart(product:any, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var productDetails = $vm.$shop_cartProductDetails(product);
        var currentQuantity = productDetails != undefined ? productDetails.quantity : 0;

        var shop = $vm.$shop_shop;
        var shopName = getShopNameFromProduct(product, shop);

        var eventName = 'remove_from_cart';
        var eventContent = {
            "items": [
              getItemFromProduct(product, shop, currentQuantity)
            ],
            "affiliation" : shopName
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("SHOP - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function updateProductInCart(product:any, quantity:number, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var productDetails = $vm.$shop_cartProductDetails(product);
        var currentQuantity = productDetails != undefined ? productDetails.quantity : 0;

        var shop = $vm.$shop_shop;
        var shopName = getShopNameFromProduct(product, shop);

        var eventName = 'add_to_cart'
        var eventQuantity = quantity - currentQuantity;
        if(quantity < currentQuantity) {
            eventName = 'remove_from_cart';
            eventQuantity = currentQuantity - quantity;
        }
        var eventContent = {
            "items": [
                getItemFromProduct(product, shop, eventQuantity)
            ],
            "affiliation" : shopName
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("SHOP - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

function addActivityEvent(activity:any, activityDetails:any, shop:any, shopName:string, $vm:Vue) {
    var eventName = 'add_to_cart';
    var eventContent = {
        "items": [
            getItemFromActivity(activity, activityDetails.numberOfPersons, activityDetails.startDate, shop)
        ],
        "affiliation" : shopName
    }
    if(process.env.CONSOLE == "LOG") {
        console.log("SHOP - GTAG - " + eventName);
        console.log(eventContent);
    }
    if($vm.$gtag) {
        $vm.$gtag.event(eventName, eventContent);
    }
}

export function addActivityInCart(product:any, numberOfPersons:number, startDate:Date, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var shop = $vm.$shop_shop;
        var shopName = getShopNameFromProduct(product, shop);

        addActivityEvent(product, {numberOfPersons:numberOfPersons, startDate:startDate}, shop, shopName, $vm)
    }
}

function removeActivityEvent(activity:any, activityDetails:any, shop:any, shopName:string, $vm:Vue) {
    var eventName = 'remove_from_cart';
    var eventContent = {
        "items": [
            getItemFromActivity(activity, activityDetails.numberOfPersons, activityDetails.startDate, shop)
        ],
        "affiliation" : shopName
    }
    if(process.env.CONSOLE == "LOG") {
        console.log("SHOP - GTAG - " + eventName);
        console.log(eventContent);
    }
    if($vm.$gtag) {
        $vm.$gtag.event(eventName, eventContent);
    }
}

export function removeActivityInCart(activity:any, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var activityDetails = $vm.$shop_cartProductDetails(activity);

        var shop = $vm.$shop_shop;
        var shopName = getShopNameFromProduct(activity, shop);

        removeActivityEvent(activity, activityDetails, shop, shopName, $vm);
    }
}

export function updateActivityInCart(product:any, numberOfPersons:number, startDate:Date, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var currentDetails = $vm.$shop_cartProductDetails(product);

        var shop = $vm.$shop_shop;
        var shopName = getShopNameFromProduct(product, shop);

        // We check if we have updated date
        var isUpdatedDate = startDate.getTime() != currentDetails.startDate.getTime();

        if(isUpdatedDate) {
            removeActivityEvent(product, currentDetails, shop, shopName, $vm);
            addActivityEvent(product, {numberOfPersons:numberOfPersons, startDate:startDate}, shop, shopName, $vm);
        }
        else {
            if(numberOfPersons < currentDetails.numberOfPersons) {
                removeActivityEvent(product, {
                    numberOfPersons: currentDetails.numberOfPersons - numberOfPersons,
                    startDate: currentDetails.startDate
                }, 
                shop, shopName, $vm);
            }
            else {
                addActivityEvent(product, {
                    numberOfPersons: numberOfPersons - currentDetails.numberOfPersons,
                    startDate: currentDetails.startDate
                }, 
                shop, shopName, $vm);
            }
        }
    }
}

export function addPromoCodeInCart(promoCode:string, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var shop = $vm.$shop_shop;
        var shopName = shop ? shop.name : "undefined shop";

        var eventName = 'Add Promo Code';
        var eventContent = {
            'event_category': 'Promo Codes',
            'event_label' : promoCode,
            "affiliation" : shopName
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("SHOP - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

function getShopNameFromProduct(product:any, shop?:any) {
    return shop ? shop.name : product.shop_id;
}

export function getItemFromProduct(product:any, shop?:any, quantity?:number) {

    var shopName = getShopNameFromProduct(product, shop);

    let productPrice = product.price ? product.price.amount : undefined;

    var item:Gtag.Item = {
        "item_id": product._id,
        "item_name": product.title + (product.subtitle ? " - "+product.subtitle : ""),
        //"list_name": "Search Results",
        "item_brand": shopName,
        //"category": "Apparel/T-Shirts",
        // "variant": "Black",
        // "list_position": 1,
        "quantity": quantity ? quantity : 1,
        "price": productPrice
      }

    return item;
}

export function getItemFromActivity(product:any, numberOfPersons:number, startDate:Date, shop?:any) {

    var shopName = getShopNameFromProduct(product, shop);

    let productPrice = product.pricePerPerson ? product.pricePerPerson.amount*numberOfPersons : undefined;
    let formattedStartDate = formatDate(startDate, "fr", {type: FormatDateType.SHORT})

    var item:Gtag.Item = {
        "item_id": product._id,
        "item_name": product.title + (product.subtitle ? " - "+product.subtitle : ""),
        //"list_name": "Search Results",
        "item_brand": shopName,
        //"category": "Apparel/T-Shirts",
        // "variant": "Black",
        "item_variant": "Start date: "+formattedStartDate,
        // "list_position": 1,
        "quantity": numberOfPersons,
        "price": productPrice
      }

    return item;
}

export function startCheckout($vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var shop = $vm.$shop_shop;
        var shopName = shop ? shop.name : "undefined shop";

        var cartProducts = $vm.$shop_cartProducts;

        if(!cartProducts || cartProducts.length == 0) {
            return;
        }

        var cartProductsAmount = $vm.$shop_cartProductsAmount;

        var eventName = 'begin_checkout'
        var eventContent = {
            "currency": cartProductsAmount.currencyCode,
            "value": cartProductsAmount.amount,
            "items": [...cartProducts].map((cartProduct:any) => {
                if(cartProduct.type == 'ACTIVITY') {
                    return getItemFromActivity(cartProduct.activity, cartProduct.numberOfPersons, cartProduct.startDate, shop);
                }
                return getItemFromProduct(cartProduct.product, shop, cartProduct.quantity)
            })
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("SHOP - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function selectTypeOfDelivery(type:string|null, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var shop = $vm.$shop_shop;
        var shopName = shop.name;

        var deliveryTitle = "No method";
        if(type != null) {
            deliveryTitle = type;
        }

        var cartProductsAmount = $vm.$shop_cartProductsAmount;

        var eventName = 'checkout_progress'
        var eventContent = {
            "checkout_step": CHECKOUT_STEPS.METHOD_OF_DELIVERY,
            "checkout_option": deliveryTitle,
            "value": cartProductsAmount.amount,
            "currency" : cartProductsAmount.currencyCode
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("SHOP - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function selectMethodOfPayment(index:number|null, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var shop = $vm.$shop_shop;
        var shopName = shop.name;

        var paymentTitle = "No method";
        if(index != null) {
            var methodOfPayment = $vm.$shop_methodOfPayments[index]
            paymentTitle = $vm.$shop.payment.getPaymentTitle(methodOfPayment);
        }

        var cartProductsAmount = $vm.$shop_cartProductsAmount;

        var eventName = 'checkout_progress'
        var eventContent = {
            "checkout_step": CHECKOUT_STEPS.METHOD_OF_PAYMENT,
            "checkout_option": paymentTitle,
            "value": cartProductsAmount.amount,
            "currency" : cartProductsAmount.currencyCode
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("SHOP - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function confirmTransaction(order:any, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var shop = $vm.$shop_shop;
        var shopName = shop.name;

        var transactionID = (order.transaction && order.transaction.reference) ? order.transaction.reference : order._id;

        var eventName = 'purchase'
        var eventContent = {
            "transaction_id" : transactionID,
            "affiliation" : shopName,
            "value": order.totalAmount.amount,
            "currency" : order.totalAmount.currencyCode,
            "shipping" : order.deliveryAmount.amount,
            "items": [...order.products].map((cartProduct:any) => {
                if(cartProduct.type == 'ACTIVITY') {
                    return getItemFromActivity(cartProduct.activity, cartProduct.numberOfPersons, cartProduct.startDate, shop);
                }
                return getItemFromProduct(cartProduct.product, shop, cartProduct.quantity)
            })
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("SHOP - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}
