<template>
    <validation-observer v-slot="{ invalid }" slim>
        <form method="post" name="contact-form" class="contact m-0 row" @submit="onContactFormSubmit">
            
            <slot name="description">
                <p>{{ $t("contact.description") }}</P>
            </slot>

            <TextField 
                v-bind:value.sync="form.email" 
                id="email" 
                fieldType="email"
                :label="$t('contact.email')" 
                :placeholder="$t('contact.email_placeholder')" 
                :inputClass="{'form-control':true, 'sm-form-control':true, 'required':true}"
                validationMode="aggressive"
                :required="true"
            />

            <TextField 
                v-bind:value.sync="form.message" 
                id="message" 
                :label="$t('contact.message')" 
                :inputClass="{'form-control':true, 'sm-form-control':true, 'required':true}"
                validationMode="aggressive"
                :textarea="true"
                :required="true"
            />

            <div class="col-12 m-0">
                <button :disabled="invalid" class="button button-3d m-0 ladda-button" data-style="zoom-in" type="submit" value="submit">{{ $t("contact.button") }}</button>
            </div>
        </form>
    </validation-observer>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';


@Component({
  components: { 
    TextField
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class Contact extends Vue {

    @Prop({
        type: Number,
        required: false,
    }) readonly contactIndex?: number;

    laddaSubmit:Ladda.LaddaButton|null = null;

    form = {
        email : "",
        message : ""
    }

    mounted() {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=contact-form] button.ladda-button' );
        this.laddaSubmit = Ladda.create(button!);

        this.$recaptchaLoaded()
        .then(() => {
            this.$recaptchaInstance.showBadge();
        });
    }

    beforeDestroy() {
        if(this.$recaptchaInstance) {
            this.$recaptchaInstance.hideBadge();
        }
    }

    onContactFormSubmit(evt:Event) {
        evt.preventDefault();

        var options:api.ApiVueOptions =  {
            app: this
        }

        this.laddaSubmit!.start();

        this.$recaptchaLoaded()
        .then(() => {
            return this.$recaptcha('contact_form');
        })
        .then((token: string) => {
            var input = {
                ...this.form,
                contactIndex : this.contactIndex,
                recaptcha_token:token
            }
            
            if(this.$gtag) {
                this.$gtag.event('Submit', {
                    'event_category': 'Contact Form'
                })
            }

            return api.postAPI('/api/contact/message', input, options);
        })
        .then((response:any) => {
            if(response.sent) {
                this.$emit('contact-form-sent');
            }
            this.laddaSubmit!.stop();
        })
        .catch((error:any) => {
            console.log(error);
            this.laddaSubmit!.stop();
        });
    }
}
</script>