import { Module } from '@fwk-node-modules/vuex';
import Vue from '@fwk-node-modules/vue';
import { Route } from '@fwk-node-modules/vue-router';
import { types as siteTypes, gettersPath as siteGettersPath} from './site';
import { utils as RouterUtils } from '@fwk-client/router/utils';
import * as api from '@fwk-client/utils/api';

export const types = {
  getters : {
    GET_PAGE_CONTENT : 'GET_PAGE_CONTENT'
  },
  mutations : {
    ADD_PAGE_CONTENT : 'ADD_PAGE_CONTENT'
  },
  actions : {
    CHECK_PAGE_CONTENT : 'CHECK_PAGE_CONTENT'
  }
}

export const statePath:string = 'cms/pagesContent/';

export function createStore () {

  const pagesContentStore:Module<any, any> = {
    namespaced: true,
    state: {
      pagesContent: {}, // contains a map of route name => page content
    },
    getters : {
      [types.getters.GET_PAGE_CONTENT]: (state) => (pageName:string) => {
        return state.pagesContent[pageName];
      }
    },
    mutations: {
      /**
       *  ADD_CONTENT
       *  This mutation update store to add component.
       */
       [types.mutations.ADD_PAGE_CONTENT] (state, {pageName, content}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - CMS - ADD_PAGE_CONTENT MUTATION - " + pageName)
        }
        // We update the store
        Vue.set(state.pagesContent, pageName, content);
      }
    },
    actions: {
      /**
       *  CHECK_PAGE_CONTENT
       *  This method check if content for the routeName in parameter is available.
       */
      [types.actions.CHECK_PAGE_CONTENT](payload: any, to:Route) {
        // We get all properties from payload parameter.
        var { commit, state, rootState, getters, dispatch, rootGetters } = payload;

        // We check that the page content is loaded
        var pageName = RouterUtils.getRouteShortNameFromRoute(to);

        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - CMS - CHECK PAGE CONTENT: " + pageName);
        }
        
        if(pageName && !state.pagesContent[pageName]) {
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - CMS - CHECK PAGE CONTENT - We need to load page content: " + pageName);
          }
          var site = rootGetters[siteGettersPath[siteTypes.getters.GET_SITE]];
          var jsonFilePath = pageName + ".json";
          if(to.meta && to.meta.hasPageContent && typeof to.meta.hasPageContent == "string") {
            jsonFilePath = to.meta.hasPageContent + ".json"
          }
          var promise = api.getStaticFromStatics(`/sites/${site.code}/pages-content/${jsonFilePath}`, rootState)
          return promise
          .then(response => {
            if(process.env.CONSOLE == "LOG") {
              console.log("STORE - CMS - CHECK PAGE CONTENT (" + pageName + ") - DONE");
            }
            // We store the data.
            commit(types.mutations.ADD_PAGE_CONTENT, {
              pageName,
              content: response
            });
          })
          .catch(error => {
            if(process.env.CONSOLE == "LOG") {
              console.log("STORE - CMS - CHECK PAGE CONTENT (" + pageName + ") - ERROR");
              console.log(error);
            }
            // We do not fail the process if there is no content associated
            return Promise.resolve();
          });
        }
        else {
          return Promise.resolve();
        }
      }
    }
    
  }
  return pagesContentStore;
};

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;