import './canvas.polyfills';
import * as header from './canvas.header';
import * as slider from './canvas.slider';
import { checkViewport } from './canvas.responsive';
import { elements, updateAllElements } from './canvas.elements';
import { Store } from '@fwk-node-modules/vuex';

/*-------------------------------------------------------------------------------*/
/*	UPDATE PROCESS: Compare functions.js file between versions to adapt methods	 */
/*-------------------------------------------------------------------------------*/

const documentOnReady = {

	init: function(){
		updateAllElements();

		var {$slider, $sliderElement} = elements;

		//SEMICOLON.initialize.init();
		header.init();
		if( $slider || $sliderElement ) { slider.init(); }
		//if( $portfolio.length > 0 ) { SEMICOLON.portfolio.init(); }
		//SEMICOLON.widget.init();
		documentOnReady.windowscroll();
		//SEMICOLON.customization.onReady();
	},

	windowscroll: function(){

		var {$header, headerOffset, $headerWrap, headerWrapOffset, $window} = elements;

		if( $header.length > 0 ) {
			headerOffset = $header.offset().top;
			$headerWrap.addClass('position-absolute');
			headerWrapOffset = $headerWrap.offset().top;
			$headerWrap.removeClass('position-absolute');
		}

		let headerDefinedOffset = $header.getAttribute('data-sticky-offset');
		if( typeof headerDefinedOffset !== 'undefined' ) {
			if( headerDefinedOffset == 'full' ) {
				headerWrapOffset = $window.height();
				let headerOffsetNegative = $header.attr('data-sticky-offset-negative');
				if( typeof headerOffsetNegative !== 'undefined' ) {
					headerWrapOffset = headerWrapOffset - headerOffsetNegative - 1;
				}
			} else {
				headerWrapOffset = Number(headerDefinedOffset);
			}
		} else {
			if( headerWrapOffset === 'undefined' ) {
				headerWrapOffset = headerOffset;
			}
		}

		/*
		let pageMenuWrap	= $pagemenu.find('#page-menu-wrap'),
			offset			= $headerWrap.outerHeight(),
			head			= document.head || document.getElementsByTagName('head')[0],
			style			= document.createElement('style'),
			css;

		if( $pagemenu.length > 0 ) {
			$pageMenuClone.css({ 'height': $pagemenu.find('#page-menu-wrap').outerHeight() });
			setTimeout( function(){
				if( $header.length > 0 && !$header.hasClass('no-sticky') ) {
					if( $body.hasClass('device-xl') || $body.hasClass('device-lg') || mobileSticky == 'true' ) {
						pageMenuOffset = $pagemenu.offset().top - $headerWrap.outerHeight();
						head.appendChild(style);
						css = '#page-menu.sticky-page-menu:not(.dots-menu) #page-menu-wrap { top: '+ offset +'px; }';

						style.type = 'text/css';
						style.appendChild(document.createTextNode(css));
					} else {
						pageMenuOffset = $pagemenu.offset().top;
					}
				} else {
					pageMenuOffset = $pagemenu.offset().top;
				}
			}, 1000);
		}
		*/

		header.stickyMenu( headerWrapOffset );
		//SEMICOLON.header.stickyPageMenu( pageMenuOffset );

		window.addEventListener( 'scroll', function(){

			//SEMICOLON.initialize.goToTopScroll();
			//document.querySelector('body.open-header.close-header-on-scroll').removeClass("side-header-open");
			header.stickyMenu( headerWrapOffset );
			//SEMICOLON.header.stickyPageMenu( pageMenuOffset );
			header.logo();

		}, { passive: true });

		// window.addEventListener( 'DOMContentLoaded', onScrollSliderParallax, false );

		/*
		$window.scrollEnd( function(){
			let headerHeight = $headerWrap.outerHeight();
			
			if( $pagemenu.length > 0 && $header.length > 0 && !$header.hasClass('no-sticky') ) {
				if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
					$pagemenu.filter('.sticky-page-menu:not(.dots-menu,.no-sticky)').find( $pageMenuWrap ).css({ 'top': headerHeight +'px' });
				}
			}
		}, 500 );

		*/

	}

};

const documentOnLoad = {

	init: function(){
		slider.swiperSliderMenu(true);
		slider.revolutionSliderMenu(true);
		// SEMICOLON.initialize.stickFooterOnSmall();
		// SEMICOLON.widget.gridInit();
		/*
		let isoCheckInt = setInterval( function(){
			if( 'pluginIsotopeReady' in scwEvents ) {
				SEMICOLON.widget.filterInit();
				SEMICOLON.widget.masonryThumbs();
				clearInterval( isoCheckInt );
			}
		}, 1000 );
		*/
		//SEMICOLON.widget.parallax();
		//SEMICOLON.widget.loadFlexSlider();
		//SEMICOLON.widget.html5Video();
		//SEMICOLON.widget.ticker();
		header.responsiveMenuClass();
		//SEMICOLON.initialize.modal();
		//SEMICOLON.customization.onLoad();

	}

};

const documentOnResize = {

	init: function(){

		header.menufunctions();
		//SEMICOLON.header.fullWidthMenu();
		header.stickyMenu();
		header.logo();
		// SEMICOLON.initialize.dataResponsiveHeights();
		// SEMICOLON.initialize.stickFooterOnSmall();
		slider.sliderDimensions();
		// SEMICOLON.slider.sliderParallax();
		// SEMICOLON.widget.html5Video();
		// SEMICOLON.widget.masonryThumbs();
		// SEMICOLON.initialize.dataResponsiveClasses();
		// SEMICOLON.customization.onResize();

		var {windowWidth, $window} = elements;

		windowWidth = window.document.documentElement.clientWidth;

		// $(window).trigger( 'scwWindowResize' );

	}

};

var resizeTimer:any;

const onResize = function() {
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function() {
		var currentWindowWidth = window.document.documentElement.clientWidth;
		if ( currentWindowWidth !== elements.windowWidth ) {
			documentOnResize.init();
		}
	}, 250);
}

export const layoutCreated = function(store:Store<any>) {
	if(process.env.CONSOLE == "LOG") { console.log("CANVAS - LAYOUT CREATED"); }

	if(typeof window !== 'undefined') {
		// We add listener for viewport size
		checkViewport(store);
	}
}

export const layoutDestroyed = function() {
	if(process.env.CONSOLE == "LOG") { console.log("CANVAS - LAYOUT DESTROYED"); }

	if(typeof window !== 'undefined') {
		// We remove the dom event listeners
		window.removeEventListener('resize', onResize);
	}
}

export const layoutMounted = function() {
	if(process.env.CONSOLE == "LOG") { console.log("CANVAS - LAYOUT MOUNTED"); }
	// We add the dom event listeners
	window.addEventListener('resize', onResize);
}

export const layoutOnRouteChange = function() {
	if(process.env.CONSOLE == "LOG") { console.log("CANVAS - LAYOUT ON ROUTE CHANGE"); }
	// We call the on ready / init method
	documentOnReady.init();
	// We call the on load / init method
	documentOnLoad.init();
}