import { containerUtils } from '@fwk-client/modules/shop/stores';
import { Module } from '@fwk-node-modules/vuex';


export const types = {
  getters : {
    // CONTAINER COMMON GETTERS
    GET_CART : 'GET_CART',
    GET_CART_RETRIEVED : 'GET_CART_RETRIEVED',
    GET_CART_PRODUCTS : 'GET_CART_PRODUCTS',
    GET_CART_PRODUCT_QUANTITY : 'GET_CART_PRODUCT_QUANTITY',
    GET_CART_PRODUCTS_AMOUNT : 'GET_CART_PRODUCTS_AMOUNT',
  },
  mutations : {
    // CONTAINER COMMON SETTERS
    SET_CART : 'SET_CART',
    SET_CART_RETRIEVED : 'SET_CART_RETRIEVED',
  },
  actions : {}
}

export function createCartStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      cart : null, // We store the shopping cart
      cartRetrieved : false, // We update it to true when the cart has been retrieved from backend
    },
    getters: {
      [types.getters.GET_CART](state) { return state.cart; },
      [types.getters.GET_CART_RETRIEVED](state) { return state.cartRetrieved; },
      [types.getters.GET_CART_PRODUCTS](state) {
        if(state.cart && state.cart.products) {
          return state.cart.products;
        }
        return [];
      },
      [types.getters.GET_CART_PRODUCTS_AMOUNT](state) {
        return containerUtils.getProductsAmount(state.cart);
      },
      [types.getters.GET_CART_PRODUCT_QUANTITY](state) {
        return function(product:any) {
          if(state.cart && state.cart.products && product && product._id) {
            var index = state.cart.products.map((containerProduct:any) => {
              return containerProduct.product._id;
            }).indexOf(product._id);
            if(index > -1) {
              return state.cart.products[index].quantity;
            }
          }
          return 0; 
        }
      },
      
    },
    mutations: {
      [types.mutations.SET_CART] (state, cart) { state.cart = cart; },
      [types.mutations.SET_CART_RETRIEVED] (state, cartRetrieved) { state.cartRetrieved = cartRetrieved; },
    },
    actions: {}
  }
  return store;
};

export const getters = {
  $hospitality_cart : {
    get: function (this:Vue) {
      return this.$store.getters['hospitality/cart/'+types.getters.GET_CART];
    }
  },
  $hospitality_cartProductQuantity : {
    get: function (this:Vue) {
      return (product:any) => {
        return this.$store.getters['hospitality/cart/'+types.getters.GET_CART_PRODUCT_QUANTITY](product);
      }
    },
  },
  $hospitality_cartProducts : {
    get: function (this:Vue) {
      return this.$store.getters['hospitality/cart/'+types.getters.GET_CART_PRODUCTS];
    },
  },
  $hospitality_cartProductsAmount : {
    get: function (this:Vue) {
      return this.$store.getters['hospitality/cart/'+types.getters.GET_CART_PRODUCTS_AMOUNT];
    }
  }
}