import Vue from '@fwk-node-modules/vue';
import * as api from '@fwk-client/utils/api';
import * as gtag from '../utils/gtag';
import { types as shopTypes } from '@fwk-client/modules/shop/stores/shop';

export interface AvailabilitiesOptions {
    startDate?:Date,
    endDate?:Date
}

export class Activity {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    public getActivitiesFromCategoryID(categoryID:any) {
        return this.$vm.$store.getters['shop/shop/'+shopTypes.getters.GET_SHOP_ACTIVITIES].filter((product:any) => {
            // In case we want to display all products
            if(categoryID == null) { return true; }
            // In case a specific category is selected
            return product.category_id == categoryID
        });
    }

    public getActivitiesFromIDs(productIDs:string[]) {
        return this.$vm.$store.getters['shop/shop/'+shopTypes.getters.GET_SHOP_ACTIVITIES].filter((product:any) => {
            // We keep products from list of ids
            return productIDs.indexOf(product._id) > -1
        });
    }

    /**
     * getAvailabilities
     * This method get the apartment availabilities from backend
     */
    getAvailabilities(activity:any, availOptions?:AvailabilitiesOptions):Promise<void> {        
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        var input = {
            startDate: (availOptions && availOptions.startDate) ? availOptions.startDate : undefined,
            endDate: (availOptions && availOptions.endDate) ? availOptions.endDate : undefined,
        }

        return api.postAPI('/api/shop/shop/'+activity.shop_id+'/products/'+activity._id+'/availabilities', input, options).then((response:any) => {
            return response.availabilities;
        })
    }

}