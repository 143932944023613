/**
 * getPath
 * This method get the value at a given path of an object.
 * https://youmightnotneed.com/lodash
 * @param obj 
 * @param path 
 * @param defValue 
 * @returns 
 */
export function getPath(obj:any, path:string|string[], defValue?:any) {
    // If path is not defined or it has false value
    if (!path) return undefined;
    // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
    // if the format of the path is wrong
    if(!pathArray) return undefined;
    // Find value
    const result = pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj)
    // If found value is undefined return default value; otherwise return the value
    return result === undefined ? defValue : result
}

/**
 * setPath
 * This method set the value at a given path of an object.
 * @param obj 
 * @param path 
 * @param value 
 * @returns 
 */
 export function setPath(obj:any, path:string|string[], value:any) {
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
    // if the format of the path is wrong
    if(!pathArray) return;
    // Update object with value
    pathArray.reduce((acc, key, i) => {
      if (acc[key] === undefined) acc[key] = {}
      if (i === pathArray.length - 1) acc[key] = value
      return acc[key]
    }, obj)
}

/**
 * mergeObjects
 * @param objects - list of objects to merge
 * @returns 
 */
export function mergeObjects<T>(...objects: T[]) {
  var mergedObject: T = {
    ...objects[0]
  }
  for(var index = 1 ; index < objects.length ; index++) {
    if(objects[index]) {
      for(const [key, value] of Object.entries(objects[index] as Object)) {
        if((mergedObject as any)[key] !== undefined && typeof (mergedObject as any)[key] == "object" && !Array.isArray((mergedObject as any)[key])) {
          // We merge two objects
          (mergedObject as any)[key] = mergeObjects({...(mergedObject as any)[key]}, value)
        }
        else if((mergedObject as any)[key] !== undefined) {
          // We merge two primitive types
          (mergedObject as any)[key] = value;
        }
        else if(Array.isArray(value)) {
          (mergedObject as any)[key] = [...value];
        }
        else if(typeof value == "object") {
          (mergedObject as any)[key] = {...value};
        }
        else {
          (mergedObject as any)[key] = value;
        }
      }
    }
  }
  return mergedObject;
}