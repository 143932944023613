import { RouteConfig } from '@fwk-node-modules/vue-router';
import { utils } from '@fwk-client/router/utils';
import { HospitalityData } from '@fwk-client/modules/hospitality/helpers/hospitality';
import { References } from '@fwk-client/store/languages';
import { roles } from '@igotweb/core-api/src/roles';

export const routes:any[] = [
  { 
    name: 'hospitality-apartments',
    path: '/apartments',
    meta: {
      languageBundle: ['hospitality','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      hospitality: {
        data: [HospitalityData.CART]
      }
    }
  },
  {
    name: 'hospitality-apartment',
    path: '/apartments/:apartmentCode',
    meta: {
      languageBundle: ['hospitality','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      hospitality: {
        apartmentCodeParam : "apartmentCode",
        data: [HospitalityData.CART]
      }
    }
  },
  { 
    name: 'hospitality-checkout',
    path: '/checkout',
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['hospitality','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      languageReferences: [References.COUNTRIES],
      components: {
        pageTitle : {
          "path" : "canvas/PageTitle",
          "props" : {
            "options" : {
              "mini" : true,
            },
            "labels": {
              "title" : "key:hospitality.checkout.title"
            }
          }
        },
        content : [
          "canvas/hospitality/Checkout"
        ]
      },
      hospitality: {
        data: [HospitalityData.CART]
      }
    } 
  },
  { 
    name: 'hospitality-confirmation',
    path: '/confirmation',
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['hospitality','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      languageReferences: [References.COUNTRIES],
      components: {
        pageTitle : {
          "path" : "canvas/PageTitle",
          "props" : {
            "options" : {
              "mini" : true,
            },
            "labels": {
              "title" : "key:hospitality.booking.header.confirmation"
            }
          }
        },
        content : [
          {
            "path" : "canvas/shop/Retrieve",
            "props" : {
              "isConfirmation" : true,
              "options" : {
                "type" : "hospitality",
              },
            }
          }
        ]
      },
      hospitality: {
        isConfirmation : true
      }
    } 
  },
  { 
    name: 'hospitality-booking',
    path: '/booking/:bookingId?',
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['hospitality','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      languageReferences: [References.COUNTRIES],
      components: {
        header: {
          "props": {
            "options": {
              "style": "square-mini",
              "topCartOptions": {
                "hideWhenEmpty":true,
                "type":"hospitality"
              }
            }
          }
        },
        pageTitle : {
          "path" : "canvas/PageTitle",
          "props" : {
            "options" : {
              "mini" : true,
            },
            "labels": {
              "title" : "key:hospitality.booking.header.retrieve"
            }
          }
        },
        content : [
          {
            "path" : "canvas/shop/Retrieve",
            "props" : {
              "options" : {
                "type" : "hospitality",
              },
            }
          }
        ]
      },
      hospitality: {
        data: [HospitalityData.CART]
      }
    } 
  },
  { 
    name: 'hospitality-user-bookings',
    path: '/my-bookings',
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      authRoles: [roles.user], // User must be logged in to access this route.
      languageBundle: ['hospitality','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      languageReferences: [References.COUNTRIES],
      components: {
        header: {
          "props": {
            "options": {
              "style": "square-mini",
              "topCartOptions": {
                "hideWhenEmpty":true,
                "type":"hospitality"
              }
            }
          }
        },
        pageTitle : {
          "path" : "canvas/PageTitle",
          "props" : {
            "options" : {
              "mini" : true,
            },
            "labels": {
              "title" : "key:hospitality.booking.header.user-bookings"
            }
          }
        },
        content : [
          {
            "path" : "canvas/shop/UserOrders",
            "props" : {
              "options" : {
                "type" : "hospitality",
              },
            }
          }
        ]
      },
      hospitality: {
        data: [HospitalityData.CART]
      }
    } 
  }
]

export const routesFromShortName:{[routeShortName:string]:RouteConfig} = utils.computeRoutesFromShortName(routes);