import Vue from '@fwk-node-modules/vue';
import VueGtag from 'vue-gtag';

import App from '@fwk-client/App.vue';
import { createStore, populateStore } from '@fwk-client/store';
import { createRouter, populateRoutes } from '@fwk-client/router';
import { createI18n } from '@fwk-client/languages';
import { languagesTypes } from '@fwk-client/store/types';
import { getPath } from "@igotweb-node-api-utils/misc";
import '@fwk-client/utils/class-component-hooks';

import { getLocalizedText, LocalizedTextOptions as LocalizedTextOptionsGeneric, getTextFromLocalizedContent } from '@igotweb-node-api-utils/formatter';

import { hooks } from './hooks';
import { getComputedMediaURL } from './utils/media';

export interface LocalizedTextOptions extends LocalizedTextOptionsGeneric {
  filters?:string[]
}

/**
 * createApp
 * This method creates the Vue application
 * @param acceptedLanguages - the list of languages accepted by the user. 
 *                            On Web without SSR: We take the browser preferred languages
 *                            On Web with SSR: We rely on the information in initial state.
 *                            On SSR side, we take the i18next detected languages.
 * @param availableLanguages - the list of languages available and supported.
 *                            On Web without SSR: 
 *                            On Web with SSR: We rely on the information in initial state.
 *                            On SSR side, we take the framework configuration.
 * @param initialState - the initial state available from SSR.
 */
export function createApp (acceptedLanguages:string[], availableLanguages:string[], initialState:any) {

  // We call the onCreateAppEnter hooks if defined.
  if(hooks.onCreateAppEnter) {
    hooks.onCreateAppEnter(Vue, initialState);
  }

  // We get the store instance
  const store = createStore();
  // We populate the store
  populateStore(store, acceptedLanguages, availableLanguages, initialState);

  // We create the i18n instance
  // The language store is needed to access initial language code and translations
  const i18n = createI18n(store);
  // We get the router instance
  // The language store is needed to get available languages to create localized routes.
  const router = createRouter(store);
  // We populate the routes
  populateRoutes(router, store);

  // We add google analytics
  // The server store is needed to get the Google Analytics Configuration.
  // It is only available on web side after SSR rendering (as populated by initial state).
  var gtagConfig = getPath(store.state, "server.configuration.fwk.analytics.gtag");
  if(gtagConfig) {
    // We initialize google tag ($gtag) if configuration is available.
    var gtagConfig = store.state.server.configuration.fwk.analytics.gtag;
    Vue.use(VueGtag, {
      config: { 
        id: gtagConfig.id,
        params: {
          send_page_view: false
        }
      }
    });
  }

  // We call the hook with all dependencies
  if(hooks.onVueDependenciesCreated) {
    hooks.onVueDependenciesCreated(store, router, i18n, initialState);
  }

  /**
   * We add our filters
   */
  Vue.filter('breakLines', function (value:string) {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br/>');
  })

  Vue.filter('staticPath', function(value:string, staticsDomain?:string) {
    // We replace all static-path attributes by src with statics domain
    var updatedValue = value.replace(/static-path=\"([^\"]*)\"/g, (substring: string, ...args: any[]) => {
      // We get the static path
      var path = args[0];
      // We replace with src attribute
      if(staticsDomain && staticsDomain != "") {
        var url = "src=\"" + staticsDomain + path + "\"";
        return url;
      }
      else if(store.state.server && store.state.server.staticsDomain) {
        var url = "src=\"" + store.state.server.staticsDomain + path + "\"";
        return url;
      }
      // if no statics domain, we keep the initial attribute
      return substring;
    });
    return updatedValue;
  })

  const app:Vue = new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
    beforeCreate () {
      // beforeCreate is called on client and server code
    },
    mounted () {
      // mounted is only called on client code.
      // You'll need this for renderAfterDocumentEvent (cf. PrerenderSPAPlugin in webpack config).
      document.dispatchEvent(new Event('render-event'))
    }
  });

  /**
   * We add our helpers
   * options.params must be an array of string and within localised resrouce we should have {0}, {1}, {2}... to replace the params
   */
  Vue.prototype.getLocalizedText = function(localizedText:any, options?:LocalizedTextOptions) {
    // We check if we get a key for i18n
    if(typeof localizedText == 'string' && localizedText.startsWith("key:")) {
      localizedText = i18n.t(localizedText.substring(4), options ? options.params : undefined);
    }
    else {
      // We get the current languageCode
      var currentLanguageCode = store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      localizedText = getLocalizedText(localizedText, currentLanguageCode, options);
    }
    if(options && options.filters) {
      for(var filter of options.filters) {
        localizedText = Vue.filter(filter)(localizedText);
      }
    }
    return localizedText;
  }

  Vue.prototype.getTextFromLocalizedContent = function(localizedContent:any, options?:LocalizedTextOptionsGeneric) {
    // We get the current languageCode
    var currentLanguageCode = store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
    return getTextFromLocalizedContent(localizedContent, currentLanguageCode, options);
  }

  Vue.prototype.getStaticURLFromPath = function(path:string) {
    var url = path;
    // In case we have a relative path, we append the statics domain
    var pat = /^https?:\/\//i;
    if(!pat.test(path) && store.state.server && store.state.server.staticsDomain) {
      // We update the path
      url = store.state.server.staticsDomain + path
    }
    return url;
  }

  Vue.prototype.getComputedMediaURL = function(mediaURL:string) {
    var staticsDomain = (store.state.server && store.state.server.staticsDomain) ? store.state.server.staticsDomain : undefined;
    return getComputedMediaURL(mediaURL, staticsDomain);
  }
  

  return { app, router, store, i18n }
}
