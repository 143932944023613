import Vue from '@fwk-node-modules/vue';
import * as api from '@fwk-client/utils/api';
import { formatDateForInput } from '@igotweb-node-api-utils/formatter';
import { types, updateStoreFromBackend } from '@fwk-client/modules/hospitality/stores';
import { types as cartTypes } from '@fwk-client/modules/shop/stores/cart';
import { Price } from '@fwk-client/modules/shop/vue';
import * as gtag from '../utils/gtag';

export class Cart {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    /**
     * refreshCart
     * This method refresh the cart from backend.
     */
    refreshCart():Promise<void> {
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('hospitality/' + types.mutations.START_CART_LOADING);

        return api.getAPI('/api/hospitality/cart', options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('hospitality/' + types.mutations.STOP_CART_LOADING);
        })
    }

    /**
     * addRental
     * This method add a rental to cart
     */
    addRental(apartment:any, startDate:Date, endDate:Date, price:number):Promise<any> {
        var input = {
            "apartmentID" : apartment._id,
            "startDate" : formatDateForInput(startDate),
            "endDate" : formatDateForInput(endDate)
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('hospitality/' + types.mutations.START_CART_PRODUCTS_LOADING);
        
        return api.postAPI('/api/hospitality/cart/rental/add', input, options).then((response:any) => {

            // We send analytics
            gtag.addRentalInCart(apartment, startDate, endDate, price, this.$vm);

            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('hospitality/' + types.mutations.STOP_CART_PRODUCTS_LOADING);
            return Promise.resolve(response);
        });
    }

    /**
     * removeRental
     * This method removes a rental from the cart
     */
    removeRental(rental:any) {
        var apartment = rental.apartment;
        var input = {
            "apartmentID" : apartment._id
        }

        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('hospitality/' + types.mutations.START_CART_PRODUCTS_LOADING);
        
        return api.postAPI('/api/hospitality/cart/rental/remove', input, options).then((response:any) => {

            // We send analytics
            gtag.removeRentalInCart(rental, this.$vm);

            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('hospitality/' + types.mutations.STOP_CART_PRODUCTS_LOADING);
            return Promise.resolve(response);
        });
    }

    public startCheckout() {
        // We send analytics
        gtag.startCheckout(this.$vm);
    }

    public selectMethodOfPayment(index:number|null) {

        // We send analytics
        gtag.selectMethodOfPayment(index, this.$vm);
        
        this.$vm.$store.commit('hospitality/' + types.mutations.SET_SELECTED_METHOD_OF_PAYMENT_INDEX, index);
    }

    public getTotalPrice():Price|null {
        var totalPrice = {
            ...this.$vm.$hospitality_cartProductsAmount
        }
        // In case we have promo codes applied, we need to use the reduced products amount to calculate the total amount
        /*
        if(this.$vm.$hospitality_cartPromoCodes && this.$vm.$hospitality_cartPromoCodes.length > 0) {
            totalPrice = {
                ...this.$vm.$hospitality_cart.promoCodesReduction.reducedProductsAmount
            }
        }
        */
       /*
        var deliveryType = this.$vm.$store.getters['shop/' + types.getters.GET_SELECTED_METHOD_OF_DELIVERY_TYPE];
        if(deliveryType != null) {
            var deliveryPrice = this.$vm.$hospitality_cartPriceForDeliveryType(deliveryType);
            if(deliveryPrice && deliveryPrice.price) {
                totalPrice.amount += deliveryPrice.price.amount;
                totalPrice.nonDiscountedAmount +=  deliveryPrice.price.nonDiscountedAmount;
            }
            return totalPrice;
        }
        */
        return totalPrice;
    }

    /**
     * checkout
     * This method checkout the cart.
     */
    checkout() {
        var cart = this.$vm.$store.getters['hospitality/cart/' + cartTypes.getters.GET_CART];
        var input = {
            "agencyID" : cart.agency._id,
            "methodOfPaymentIndex" : this.$vm.$store.getters['hospitality/' + types.getters.GET_SELECTED_METHOD_OF_PAYMENT_INDEX],
        }

        var options:api.ApiVueOptions =  {
            app: this.$vm
        }
        
        return api.postAPI('/api/hospitality/cart/checkout', input, options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);

            if(response.booking) {
                var booking = response.booking;
                // We send analytics
                gtag.confirmBooking(booking, this.$vm);
                // The payment is succedded
                return Promise.resolve();
            }
            return Promise.reject();
            
        })
    }

}
