import Vue from '@fwk-node-modules/vue';
import VueRouter from '@fwk-node-modules/vue-router';
import VueMeta from '@fwk-node-modules/vue-meta';
import { sync } from '@fwk-node-modules/vuex-router-sync';
import { Store } from '@fwk-node-modules/vuex';
import { Component, RouteConfig, Route } from '@fwk-node-modules/vue-router/types/router';

import { utils , PAGE_NOT_FOUND} from '@fwk-client/router/utils';
import { routerTypes, languagesTypes, metaTypes, authenticationTypes, languagesGettersPath, authenticationGettersPath, routerGettersPath } from '@fwk-client/store/types';
import { getRoutes } from '@fwk-client/router/routes';
import { hooks } from '../hooks';
import { RouterSSRRedirectionError } from '@fwk-utils/router';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';

/**
 * createRouter
 * This method create a new router instance without routes.
 * Routes are populated with the populateRoutes method
 */
export function createRouter (store:Store<any>) {

  // We move it here so that other modules (cms, shop...) are available when beforeEach is called for the first route
  // on client side (it is done during the new Vue instance in beforeCreate hook)
  Vue.use(VueRouter);
  Vue.use(VueMeta, {
    ssrAppId : "1" // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
  });

  if(process.env.CONSOLE == "LOG") {
    console.log("ROUTER - WE CREATE THE ROUTER");
  }

  const router:VueRouter = new VueRouter({
    scrollBehavior : function(to, next, savedPosition) {
      // We use specific implementation for scroll behavior
      return utils.scrollBehavior(to, next, savedPosition, router);
    },
    mode: 'history',
    linkActiveClass: 'current'
  });

  if(hooks.onRouterCreated) {
    hooks.onRouterCreated(router);
  }

  

  /**
   * RedirectionCheck beforeEach router implementation
   * It is used to do all checks before entering the requested route if redirection is needed.
   *    - Redirection on server side for SSR logic
   *    - Maintenance / Comming soon redirections
   *    - Authentication redirection
   *    - Localization redirection
   */
  const redirectionCheck = function(to:Route, from:Route, next:any) {
    // We enter the redirection checks
    if(process.env.CONSOLE == "LOG") {
      console.log("ROUTER - REDIRECTION CHECKS (From: "+from.fullPath+", To: " + to.fullPath + ")");
    }

    // We get the previous route if any
    var previousRoute = router.app.$store.getters['router/' + routerTypes.getters.GET_PREVIOUS_ROUTE];

    // We clear the redirect data if no previous route and not in initial navigation
    if(!previousRoute && from !== VueRouter.START_LOCATION) {
      // We cleanup the router store for redirect data
      router.app.$store.commit('router/' + routerTypes.mutations.CLEAR_REDIRECT_DATA);
    }

    // We computed everything on server side, if there was a redirection, we trigger it in browser
    if(process.env.VUE_ENV == "server" && previousRoute && to.fullPath != previousRoute.fullPath) {
      if(process.env.CONSOLE == "LOG") {
        console.log("ROUTER - REDIRECTION CHECKS (To: " + to.fullPath + ", From: "+from.fullPath+", previous: "+previousRoute.fullPath+") - SSR REDIRECT");
      }
      // We check if we have redirection data in store
      var redirectData = router.app.$store.getters['router/' + routerTypes.getters.GET_REDIRECT_DATA];
      return next(new RouterSSRRedirectionError(to.fullPath, redirectData));
    }

    // We update the requested language
    var requestedLanguage = to.params.lang;
    router.app.$store.commit('languages/' + languagesTypes.mutations.SET_REQUESTED_LANGUAGE, {languageCode: requestedLanguage});

    var isRequestedLanguageInPath = requestedLanguage != null && requestedLanguage!= "";
    var currentLanguage = router.app.$store.getters[languagesGettersPath[languagesTypes.getters.GET_CURRENT_LANGUAGE]];

    var routeShortName = utils.getRouteShortNameFromRoute(to);

    /**
     * ComingSoon check and Maintenance check
     */
    if(router.app.$store.state.server && router.app.$store.state.server.configuration &&
        router.app.$store.state.server.configuration.fwk) {
      var redirectPath = "";
      if(router.app.$store.state.server.configuration.fwk.isMaintenance) {
        redirectPath = "/maintenance";
      }
      else if(router.app.$store.state.server.configuration.fwk.isComingSoon) {
        redirectPath = "/coming-soon";
      }
      else if(routeShortName == "coming-soon" || routeShortName == "maintenance") {
        // In case we target these pages without having them activated, we redirect back to home page
        redirectPath = "/";
      }

      if(redirectPath != "") {
        // We check if we add localization in path.
        if(isRequestedLanguageInPath) { redirectPath = "/" + requestedLanguage + redirectPath; }
        else if(currentLanguage) { redirectPath = "/" + currentLanguage + redirectPath; }
        
        if(to.fullPath != redirectPath && (!previousRoute || previousRoute.fullPath != redirectPath)) {
          if(process.env.CONSOLE == "LOG") {
            console.log("ROUTER - REDIRECTION CHECKS (To: " + to.fullPath + ") - MAINTENANCE / COMING SOON: " + redirectPath);
          }
          
          // We redirect to the maintenance or coming soon page
          router.app.$store.commit('router/' + routerTypes.mutations.SET_PREVIOUS_ROUTE, to);
          return next(redirectPath);
        }
      }          
    }

    /**
     * Authentication check
     * We check if the user is allowed to display this page
     */
    if(to.meta && to.meta.authRoles) {
      var redirectPath = "";
      if(to.meta.authRoles.length == 1 && to.meta.authRoles[0] == "public" && !router.app.$store.state.authentication.isLoggedIn) {
        // We do nothing as we are on public page
      }
      else if(!router.app.$store.state.authentication.isLoggedIn) {
        redirectPath = "/login?redirect=" + to.fullPath;
      }
      else if(router.app.$store.getters[authenticationGettersPath[authenticationTypes.getters.HAS_USER_ROLE]](apiRoles.superadmin)) {
        // We do nothing as user is superadmin
      }
      else if(!router.app.$store.getters[authenticationGettersPath[authenticationTypes.getters.HAS_USER_ROLES]](to.meta.authRoles)) {
        // The user does not have enough access rights to target this page
        redirectPath = "/access-restricted";
      }
      if(redirectPath != "") {
        // We check if we add localization in path.
        if(isRequestedLanguageInPath) { redirectPath = "/" + requestedLanguage + redirectPath; }
        else if(currentLanguage) { redirectPath = "/" + currentLanguage + redirectPath; }
        
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - REDIRECTION CHECKS (To: " + to.fullPath + ") - AUTHENTICATION CHECK FAILED REDIRECT TO: " + redirectPath);
        }
        // We redirect to the login page
        router.app.$store.commit('router/' + routerTypes.mutations.SET_PREVIOUS_ROUTE, to);
        return next(redirectPath);
      }
    }

    /**
     * Language check
     * We check the language to be used for the next route to be displayed.
     */
    
    // We check if language is valid and available in the store
    // We update the language if not the current one
    if(!isRequestedLanguageInPath) {
      // There is no language in the URL so We need to redirect to the localized version if any
      var redirectPath = "/" + currentLanguage + to.path;
      var redirectRoute = router.resolve(redirectPath).route;
      // We redirect if the route exists
      if(!redirectRoute.meta![PAGE_NOT_FOUND] && redirectRoute.matched.length > 0) {
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - REDIRECTION CHECKS (To: " + to.fullPath + ") - LANGUAGE REDIRECT TO: " + redirectPath);
        }
        router.app.$store.commit('router/' + routerTypes.mutations.SET_PREVIOUS_ROUTE, to);
        return next(redirectPath);
      }
    }

    // We continue to next router if no redirection
    if(process.env.CONSOLE == "LOG") {
      console.log("ROUTER - REDIRECTION CHECKS (To: " + to.fullPath + ") - NEXT");
    }
    next();
  }

  router.beforeEach(redirectionCheck);

  if(hooks.onRouterAfterRedirectionCheck) {
    hooks.onRouterAfterRedirectionCheck(router);
  }
      

  /**
   * TargetRouteDataChecks beforeEach router implementation
   * It is used to do all data checks needed before we enter the target route.
   *    - Store the scroll position of the previous route
   *    - Check the layout needed
   *    - Localization data for target language
   *    - Fragments content
   */
  const targetRouteDataChecks = function(to:Route, from:Route, next:any) {
    // We enter the data checks
    if(process.env.CONSOLE == "LOG") {
      console.log("ROUTER - DATA CHECKS (From: "+from.fullPath+", To: " + to.fullPath + ")");
    }

    var promises = [];

    // We check if language is updated
    var isLanguageUpdated = getIsLanguageUpdated(to, from, router.app.$store);

    /**
     * Store scroll position
     */
    if(process.env.VUE_ENV == "client" && window) {
      var fromRouteShortName = utils.getRouteShortNameFromRoute(from);
      var position = {
        x:0,
        y:window.pageYOffset
      }
      router.app.$store.commit('router/' + routerTypes.mutations.ADD_PREVIOUS_ROUTE_SCROLL, {
        routeShortName : fromRouteShortName,
        position : position
      });

      // We register global event for trigger scroll
      utils.registerTriggerScrollEvent(router.app);
    }
    

    /**
     * Layout check
     * before each route, we check that the approriate layout is loaded if needed.
     */
    // We check the Layout to be displayed for next route
    var layoutName = router.app.$store.getters[routerGettersPath[routerTypes.getters.GET_LAYOUT_NAME_FROM_ROUTE]](to);
    if(layoutName) {
      var params = {
        layoutName : layoutName
      }
      promises.push(router.app.$store.dispatch('router/' + routerTypes.actions.CHECK_LAYOUT, params));
    }

    var requestedLanguage = router.app.$store.getters[languagesGettersPath[languagesTypes.getters.GET_REQUESTED_LANGUAGE]];
    if(isLanguageUpdated) {
      // We call the UPDATE_LANGUAGE action of the language store
      var updateLanguagePromise = router.app.$store.dispatch('languages/' + languagesTypes.actions.UPDATE_LANGUAGE, {
        languageCode: requestedLanguage,
        app: router.app,
        route: to
      });
      // We add the update language in the list of promises to be processed.
      promises.push(updateLanguagePromise);
    }
    else {
      /**
       * Localization check
       * before each route, we check that all needed translation is loaded for the target route and language
       * This is needed only if we are not updating the language as well as route bundle is loaded when language is updated.
       */
      promises.push(router.app.$store.dispatch('languages/' + languagesTypes.actions.CHECK_ROUTE_LANGUAGE, {
        app: router.app,
        route: to
      }));
      promises.push(router.app.$store.dispatch('languages/' + languagesTypes.actions.CHECK_BUNDLE_LANGUAGE, {
        app: router.app
      }));
      
      /**
       * Fragments check
       * before each route, we check that all needed fragments are loaded for the target route and language
       * This is needed only if we are not updating the language as well as fragments are loaded when language is updated.
       */
      promises.push(router.app.$store.dispatch('router/' + routerTypes.actions.CHECK_ROUTE_FRAGMENTS, {
        languageCode: requestedLanguage,
        route: to
      }));
    }

    Promise.all(promises)
      .then(() => {
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - DATA CHECKS (To: " + to.fullPath + ") - NEXT");
        }
        next();
      })
      .catch((error) => {
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - DATA CHECKS (To: " + to.fullPath + ") - ERROR: "+error);
        }
        next(error);
      });
  };

  router.beforeEach(targetRouteDataChecks);

  if(hooks.onRouterAfterTargetRouteDataChecks) {
    hooks.onRouterAfterTargetRouteDataChecks(router);
  }

  /**
   * Asynchronous data
   * Before resolving route, we get the asynchronous data if needed.
   */
  router.beforeResolve((to, from, next) => {
    if(process.env.CONSOLE == "LOG") {
      console.log("ROUTER - BEFORE RESOLVE (To: " + to.fullPath + ")");
    }

    // We compute the body classes for the new route or language
    router.app.$store.dispatch('meta/' + metaTypes.actions.UPDATE_ROUTE_BODY_CLASSES, {
      app:router.app,
      route: to
    })
    
    const matched:Component[] = router.getMatchedComponents(to)
    const prevMatched = router.getMatchedComponents(from)

    // we only care about non-previously-rendered components,
    // so we compare them until the two matched lists differ
    let diffed = false
    const newComponents = matched.filter((c, i) => {
      return (diffed || (diffed = (prevMatched[i] !== c))) && c != undefined
    })

    // We update the store with new components
    if (newComponents.length > 0) {
      router.app.$store.commit('router/' + routerTypes.mutations.SET_COMPONENTS, {
        listComponents: newComponents
      });
    }

    // We check async data on the all components for the new route
    router.app.$store.dispatch('router/' + routerTypes.actions.CHECK_ASYNC_DATA_ALL_FOR_ROUTE, {
      route: to
    }).then(() => {
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - BEFORE RESOLVE (To: " + to.fullPath + ") - NEXT");
        }
        next();
      })
      .catch(next);
  });

  // Syncronize the router and the store of VueX
  sync(store, router);

  router.afterEach((to, from) => {
    if(process.env.CONSOLE == "LOG") {
      console.log("ROUTER - AFER EACH (To: " + to.fullPath + ")");
    }

    // We compute the metas for the new route or language
    router.app.$store.dispatch('meta/' + metaTypes.actions.COMPUTE_METAS, {
      app:router.app
    })
    // We compute the style for the new route or language
    router.app.$store.dispatch('meta/' + metaTypes.actions.COMPUTE_STYLE, {
      app:router.app
    })

    // We push analytics event for page view.
    if(process.env.VUE_ENV == "client") {
      var shortName = to.name ? to.name : utils.getRouteShortNameFromRoute(to);
      var eventContent = {
        page_title: shortName,
        page_path: to.fullPath,
        page_location: window.location.href
      }
      if(process.env.CONSOLE == "LOG") {
        console.log("ROUTER - AFTER EACH - GTAG - PAGE VIEW");
        console.log(eventContent);
      }
      if(router.app.$gtag) {
        router.app.$gtag.pageview(eventContent)
      }
    }
  });

  if(hooks.onRouterAfterEachAdded) {
    hooks.onRouterAfterEachAdded(router);
  }

  if(process.env.CONSOLE == "LOG") {
    console.log("ROUTER - ROUTER IS CREATED");
  }
  
  return router;

}

export function getIsLanguageUpdated(to:Route, from:Route, store:Store<any>) {
  var isLanguageUpdated = false;
  // The requested language in URL
  var requestedLanguage = store.getters[languagesGettersPath[languagesTypes.getters.GET_REQUESTED_LANGUAGE]];
  // Current language is set by default if previous route has no requested language.
  var currentLanguage = store.getters[languagesGettersPath[languagesTypes.getters.GET_CURRENT_LANGUAGE]];

  var isPreviousRequestedLanguageUpdated = from.params.lang && from.params.lang != requestedLanguage;
  var isDefaultLanguageUpdated = from.params.lang == undefined && currentLanguage != requestedLanguage;
  var isRequestedLanguageInPath = requestedLanguage != null && requestedLanguage!= "";
  
  if(isRequestedLanguageInPath && (isPreviousRequestedLanguageUpdated || isDefaultLanguageUpdated)) {
    if(process.env.CONSOLE == "LOG") {
      console.log("ROUTER - GET IS LANGUAGE UPDATED (To: " + to.fullPath + ") - UPDATE LANGUAGE TO: " + requestedLanguage);
    }   
    isLanguageUpdated = true;
  }
  return isLanguageUpdated;
}

export function populateRoutes(router:VueRouter, store:Store<any>) {
  if(process.env.CONSOLE == "LOG") {
    console.log("ROUTER - WE POPULATE THE ROUTES");
  }
  // We get the routes
  var routes:RouteConfig[] = getRoutes(store);
  // We store them in the store to avoid need to regenerate them.
  store.commit('router/' + routerTypes.mutations.SET_ROUTES, routes);
  // We add them to the router
  for(var route of routes) {
    router.addRoute(route);
  }
}