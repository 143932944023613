import { getPath } from "@igotweb-node-api-utils/misc";

export function init(app:Vue) {
    var hotjarID:any = getPath(app.$store.state, "server.configuration.fwk.analytics.hotjar.id");
    if(hotjarID) {

        (function(h:any,o:any,t:any,j:any,a?:any,r?:any) {
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:parseInt(hotjarID),hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }
}