import { Route } from '@fwk-node-modules/vue-router';
import { Store } from '@fwk-node-modules/vuex';
import VueI18n from "@fwk-node-modules/vue-i18n";
import { LOCALIZED_PATH_REGEX } from '@fwk-client/router/utils';
import { routerTypes, routerGettersPath, authenticationTypes, authenticationGettersPath } from '@fwk-client/store/types';
import { types as vigneronOnlineTypes, gettersPath as vigneronOnlineGettersPath } from '../store/vigneron-online'

export const menu = [
  {
    "shortName" : "home",
    "label" : "key:menu.home",
    "scope" : ["primary", "footer"]
  },
  {
    "shortName" : "nos-services",
    "label" : "key:menu.nos-services",
    "scope" : ["primary", "footer"],
    "subRoutes" : [
      {
        "shortName" : "creation-site-internet",
        "label" : "key:menu.creation-site-internet"
      },
      {
        "shortName" : "boutique-en-ligne",
        "label" : "key:menu.boutique-en-ligne"
      },
      {
        "shortName" : "strategie-marketing",
        "label" : "key:menu.strategie-marketing"
      }
    ]
  },
  {
    "shortName" : "nos-actualites",
    "label" : "key:menu.nos-actualites",
    "scope" : ["primary", "footer"]
  },
  {
    "shortName" : "order",
    "label" : "key:menu.order",
    "scope" : ["primary", "footer"]
  },
  {
    "shortName" : "nos-vignerons",
    "label" : "key:menu.nos-vignerons",
    "scope" : ["footer"]
  }
]

export function isShopRoute(route:Route) {
  return route.params && route.params.shopPath && route.params.shopPath != "";
}

export function isShopRouteName(routeShortName:string) {
  return routeShortName.indexOf("shop-") == 0
}

export function isBlogPostRoute(route:Route) {
  return route.meta && route.meta.blog && route.meta.blog.postPathParam;
}

export function getDefaultShopMenuItems() {
    var routes = [
      {
        "shortName" : 'shop-products',
        "scope" : ["primary", "footer"]
      },
      {
        "shortName": 'shop-order',
        "scope" : ["primary", "footer"]
      },
      {
        "shortName": 'shop-information',
        "scope" : ["primary", "footer"]
      }
    ]
    return routes;
}
  
/**
 * computeSiteMenu
 * This method enrich menu item targetting internal defined routes
 * @param menu 
 * @param rootGetters 
 * @returns 
 */
function computeMenu(menu: any, store: Store<any>, i18n: VueI18n) {
    return menu.map((menuItem:any) => {
        var item:any = {};
        // We check if we target internal route
        if(menuItem.shortName) {
          var routeConfig = store.getters[routerGettersPath[routerTypes.getters.GET_ROUTE_FROM_SHORTNAME]](menuItem.shortName);
          if(!routeConfig) { item = undefined; }
          else {
            item = {
              path: computePathFromRouteConfig(menuItem.shortName, routeConfig, store.getters),
              label: menuItem.label ? menuItem.label : computeDefaultLabelFromRouteConfig(menuItem.shortName, routeConfig, i18n),
              ...menuItem
            }
          }
        }
        else {
            // We have an external menu item
            item = menuItem;
        }
        if(item && menuItem.subRoutes) {
          // We compute the sub routes
          item.subRoutes = computeMenu(menuItem.subRoutes, store, i18n);
        }
        return item;
    }).filter((menuItem:any) => {
        // We filter undefined route in case we did not found the shop route from short name.
        return menuItem !== undefined;
    })
}

function computePathFromRouteConfig(shortName:string, routeConfig:any, rootGetters:any) {
  var path = routeConfig.path;
  
  // We remove the localized parameter
  path = path.replace(LOCALIZED_PATH_REGEX, "");

  // We remove the order ID parameter
  var orderIdParamRegExp = /\/:orderId(\?)?/g;
  path = path.replace(orderIdParamRegExp,"");

  // We compute the shop path if we are in shop Content
  if(isShopRouteName(routeConfig.name)) {
    var shopContentPath = rootGetters[vigneronOnlineGettersPath[vigneronOnlineTypes.getters.GET_SHOP_CONTENT_PATH]]
    var shopParamRegExp = /:shopPath(\?)?/g;
    path = path.replace(shopParamRegExp,shopContentPath);
  }

  return path;
}

function computeDefaultLabelFromRouteConfig(shortName:string, routeConfig:any, i18n: VueI18n) {
  var label:any = undefined;
  if(isShopRouteName(shortName) && i18n.te("menu.shop."+shortName)) {
    label = "key:menu.shop."+shortName;
  }
  else if(i18n.te("menu."+shortName)) {
    label = "key:menu."+shortName;
  }
  return label;
}

export function filterScope(menu:any[], scope?:string) {
  if(scope) {
    // We only keep routes having the scope
    menu = menu.filter((menuItem) => {
      return menuItem.scope && menuItem.scope.indexOf(scope) > -1;
    })
  }
  return menu;
}

export function filterAuthRoles(menu:any[], store: Store<any>) {
  return menu.filter((menuItem:any) => {
    // We check if we target internal route
    if(menuItem.shortName) {
      var routeConfig = store.getters[routerGettersPath[routerTypes.getters.GET_ROUTE_FROM_SHORTNAME]](menuItem.shortName);
      if(!routeConfig) { return false; }
      else if(!routeConfig.meta) { 
        // We have a public route
        return true; 
      }
      else {
        return isMenuAllowed(store, routeConfig.meta.authRoles);
      }
    }
    else {
      // We have an external links
      return isMenuAllowed(store, menuItem.authRoles);
    }
  })
}

function isMenuAllowed(store: Store<any>, authRoles?:string[]) {
  if(!authRoles) { 
    // We have a public route
    return true; 
  }
  else if(authRoles.length == 1 && authRoles[0] == "public") {
    var isUserLogged = store.getters[authenticationGettersPath[authenticationTypes.getters.IS_LOGGED_IN]];
    // We have a public only route, we hide it from menu when user is logged in
    return !isUserLogged;
  }
  else if(!store.getters[authenticationGettersPath[authenticationTypes.getters.HAS_USER_ROLES]](authRoles)) {
    // We check if authRoles is associated to the route
    return false;
  }
  return true;
}

export function computeRoutesInMenu(menu:any, store: Store<any>, i18n: VueI18n) {
  var routesInMenu = [];
  if(menu) {
    routesInMenu = computeMenu(menu, store, i18n); 
  }
  
  return routesInMenu;
}