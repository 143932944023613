import Vue from '@fwk-node-modules/vue';
import VueI18n from '@fwk-node-modules/vue-i18n';
import { Store } from '@fwk-node-modules/vuex';
import { languagesTypes } from '@fwk-client/store/types';
import * as api from '@fwk-client/utils/api';
import { References } from '@fwk-client/store/languages.js';

Vue.use(VueI18n);

/**
 * createI18n
 * This method create the i18n instance
 * @param languageCode - the initial language code to be set.
 */
export function createI18n (store:Store<any>) {

  // We initialize localication based on the values in the language store
  let languageCode = store.state.languages.currentLanguageCode;

  // We are able to populate translations when coming from SSR.
  let translations = store.state.languages.translations;
  let references = store.state.languages.references;
  
  var vuei18n = new VueI18n({
      locale: languageCode, // set locale
      messages: {
        [languageCode] : {
          ...translations[languageCode],
          ...references[languageCode]
        }
      },
      preserveDirectiveContent: true
    });

  vuei18n.locale = languageCode;

  return vuei18n;
}

/**
 * loadLanguageBundleAsync
 * This method is called to load the language bundle.
 * @param languageCode 
 * @param vm 
 */
export function loadLanguageBundleAsync(languageCode:string):Promise<any> {
  if(process.env.CONSOLE == "LOG") {
    console.log("LANGUAGES - LOAD LANGUAGE BUNDLE (" + languageCode + ")");
  }
  try {
    // We get the common json file
    var promise = api.getStaticFromPublic(`/languages/${languageCode}/common.json`)
    return promise
    .then(msgs => {
      if(process.env.CONSOLE == "LOG") {
        console.log("LANGUAGES - LOAD LANGUAGE BUNDLE (" + languageCode + ") - DONE");
      }
      var meta = filterMetasFromTranslations(msgs);
      return {
        "languageCode" : languageCode,
        "translations" : msgs, // We need to copy the value to make sure the result from import is not updated.
        "meta" : meta,
        "bundles" : [languagesTypes.COMMON_BUNDLE] // we set the generic common bundle
      };
    });
  }
  catch(e) {
    if(process.env.CONSOLE == "LOG") {
      console.log("LANGUAGES - LOAD LANGUAGE BUNDLE (" + languageCode + ") - ERROR");
      console.log(e)
    }
    return Promise.reject(e);
  }
}

export function loadRouteLanguageBundlesAsync(languageCode:string, routeBundles:string[]):Promise<any> {
    if(process.env.CONSOLE == "LOG") {
      console.log("LANGUAGES - LOAD ROUTE LANGUAGE BUNDLE (" + languageCode + ", " + routeBundles.join(", ") + ")");
    }
    try {
      var promises = [];
      for(var routeBundle of routeBundles) {
        // We need to use a closure to pass the routeBundle parameter to keep its value within the result.
        (function(bundleName) {
          //var promise = import(/* webpackChunkName: "lang-[request]" */ `../languages/${languageCode}/${bundleName}.json`);
          //var promise = import(/* webpackIgnore: true */ `../languages/${languageCode}/${bundleName}.json`);
          var promise = api.getStaticFromPublic(`/languages/${languageCode}/${bundleName}.json`)
          promises.push(promise
            .then(msgs => {
              var meta = filterMetasFromTranslations(msgs);
              // We return the translations with routeBundle to notify that it corresponds to specific route
              return {
                "languageCode" : languageCode,
                "translations" : {
                  [bundleName] : msgs // We need to copy the value to make sure the result from import is not updated. We also add prefix to translations with route bundle
                },
                "meta" : meta,
                "bundle" : bundleName
              } as any;
            }
          ).catch(error => {
            console.log("LANGUAGES - LOAD ROUTE LANGUAGE BUNDLE (" + languageCode + ", " + bundleName + ") - ERROR")
            console.log(error);
            return {}
          }));  
        })(routeBundle);
      }
      return Promise.all(promises).then(results => {
        var resultRouteBundles = []
        var translations = {};
        var meta = {};
        for(var result of results) {
          resultRouteBundles.push(result.bundle);
          translations = {...result.translations, ...translations};
          meta = {...result.meta, ...meta};
        }
        if(process.env.CONSOLE == "LOG") {
          console.log("LANGUAGES - LOAD ROUTE LANGUAGE BUNDLE (" + languageCode + ", " + routeBundles!.join(", ") + ") - DONE");
        }
        return {
          "languageCode" : languageCode,
          "translations" : translations,
          "meta" : meta,
          "bundles" : resultRouteBundles
        }
      });
    }
    catch(e) {
      if(process.env.CONSOLE == "LOG") {
        console.log("LANGUAGES - LOAD ROUTE LANGUAGE BUNDLE (" + languageCode + ", " + routeBundles!.join(", ") + ") - ERROR");
        console.log(e)
      }
      return Promise.reject(e);
    }
}

function filterMetasFromTranslations(translations:any) {
  if(translations && translations.meta) {
    var meta = translations.meta;
    delete translations.meta;
    return meta;
  }
  return undefined;
}

export function loadReferences(languageCode:string, routeReferences:References[], app:Vue):Promise<any> {
  if(process.env.CONSOLE == "LOG") {
    console.log("LANGUAGES - LOAD REFERENCES (" + languageCode + ")");
  }
  var options:api.ApiVueOptions =  {
    app: app,
    languageCode: languageCode // We force the language code as the new language is not yet set in store.
  }
  var input = {
    references: routeReferences
  }
  return api.postAPI('/api/utils/references', input, options).then((response:any) => {
    if(response.references) {
      return Promise.resolve(response.references);
    }
    else {
      return Promise.resolve({});
    }
  })
  .catch(e => {
    if(process.env.CONSOLE == "LOG") {
      console.log("LANGUAGES - LOAD REFERENCES (" + languageCode + ") - ERROR");
      console.log(e)
    }
    return Promise.reject(e);
  })
}

export function templateReplace(label:string, parameters:any[], vm:Vue) {
  return vm.$i18n.formatter.interpolate(label, parameters, "")!.join('');
}