import Vue from '@fwk-node-modules/vue';
import { Module, Store } from '@fwk-node-modules/vuex';
import * as api from '@fwk-client/utils/api';
import { gettersPath as siteGettersPath, types as siteTypes } from '@fwk-client/modules/cms/stores/site';

export const types = {
  getters : {
    GET_SHOP_CONTENT_LAYOUT : 'GET_SHOP_CONTENT_LAYOUT',
    GET_SHOP_CONTENT_MENU : 'GET_SHOP_CONTENT_MENU',
    GET_SHOP_CONTENT_ROUTES : 'GET_SHOP_CONTENT_ROUTES',
    GET_SHOP_CONTENT_PATH : 'GET_SHOP_CONTENT_PATH',
    GET_SHOP_CONTENT_LOGO : 'GET_SHOP_CONTENT_LOGO',
    GET_SHOP_CONTENT_BANNER : 'GET_SHOP_CONTENT_BANNER',
    GET_SHOP_CONTENT_THEME : 'GET_SHOP_CONTENT_THEME',
    GET_SHOP_CONTENT_PAGES_META : 'GET_SHOP_CONTENT_PAGES_META',
    GET_SHOP_CONTENT_SHOP : 'GET_SHOP_CONTENT_SHOP',
    GET_SHOPS : 'GET_SHOPS',
    GET_VIGNERON_ONLINE_SITE : 'GET_VIGNERON_ONLINE_SITE',
  },
  mutations : {
    SET_SHOP_CONTENT : 'SET_SHOP_CONTENT'
  },
  actions : {
    UPDATE_LIST_SHOPS : 'UPDATE_LIST_SHOPS'
  }
};

const privateTypes = {
  mutations : {
    SET_LIST_SHOPS : 'SET_LIST_SHOPS'
  },
  actions : {
    
  }
}

export const statePath:string = 'vigneron-online/';

export function createVigneronOnlineStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      shopContent : null, // We store the shop content
      shops : [] // The list of available & activated shops (with shop content or site information)
    },
    getters: {
      [types.getters.GET_SHOP_CONTENT_LAYOUT](state, getters) { 
        var shopContent = state.shopContent;
        if(shopContent && shopContent.theme && shopContent.theme.layout) {
          return shopContent.theme.layout.name;
        }
        return null;
      },
      [types.getters.GET_SHOP_CONTENT_MENU](state, getters) { 
        var shopContent = state.shopContent;
        if(shopContent && shopContent.theme && shopContent.theme.layout) {
          return shopContent.theme.layout.menu;
        }
        return null;
      },
      [types.getters.GET_SHOP_CONTENT_ROUTES](state, getters) {
        var shopContent = state.shopContent;
        if(shopContent && shopContent.theme && shopContent.theme.layout) {
          return shopContent.theme.layout.routes;
        }
        return null;
      },
      [types.getters.GET_SHOP_CONTENT_PATH](state, getters) {
        return state.shopContent ? state.shopContent.path : undefined;
      },

      [types.getters.GET_SHOP_CONTENT_LOGO](state, getters) {
        return state.shopContent ? state.shopContent.logo : undefined;
      },
      [types.getters.GET_SHOP_CONTENT_BANNER](state, getters) {
        return state.shopContent ? state.shopContent.banner : undefined;
      },
      [types.getters.GET_SHOP_CONTENT_THEME](state, getters) {
        return state.shopContent ? state.shopContent.theme : undefined;
      },
      [types.getters.GET_SHOP_CONTENT_PAGES_META](state, getters) {
        return state.shopContent && state.shopContent.content ? state.shopContent.content.pagesMeta : undefined;
      },
      [types.getters.GET_SHOP_CONTENT_SHOP](state, getters) {
        return state.shopContent ? state.shopContent.shop : undefined;
      },
      [types.getters.GET_SHOPS](state) { return state.shops; },
      [types.getters.GET_VIGNERON_ONLINE_SITE](state, getter, rootState, rootGetters) { 
        var site = rootGetters[siteGettersPath[siteTypes.getters.GET_SITE]];
        if(site && site.code == "vigneron-online") {
          return site;
        }
        return null;
      }
    },
    mutations: {
      [types.mutations.SET_SHOP_CONTENT] (state, shopContent) {
        // We update the store
        state.shopContent = shopContent;
      },
      [privateTypes.mutations.SET_LIST_SHOPS] (state, shops) { state.shops = shops; }
    },
    actions: {
      /**
       * UPDATE_LIST_SHOPS
       * This action update the list of shops in store.
       * @param app - The Vue instance
       */
       [types.actions.UPDATE_LIST_SHOPS] ({state, commit, getters, rootGetters}, {app}):Promise<void> {
        var options:api.ApiVueOptions =  {
          app: app
        }
      
        return api.getAPI('/api/vigneron-online/shop/list', options).then((response:any) => {
          if(response.shops) {
            // We update the list of shops
            commit(privateTypes.mutations.SET_LIST_SHOPS, response.shops);
          }
          return Promise.resolve();
        });
      }
    }
  }
  return store;
};

export const getters = {
  $shopContent_path : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_CONTENT_PATH]];
    }
  },
  $shopContent_logo : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_CONTENT_LOGO]];
    }
  },
  $shopContent_banner : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_CONTENT_BANNER]];
    }
  },
  $shopContent_theme : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_CONTENT_THEME]];
    }
  },
  $shopContent_pagesMeta : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_CONTENT_PAGES_META]];
    }
  },
  $shopContent_shop : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_CONTENT_SHOP]];
    }
  },
  $vigneronOnline_shops : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOPS]];
    }
  },
  $vigneronOnline_site : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_VIGNERON_ONLINE_SITE]];
    }
  }
}

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;


/**
 * updateStoreFromBackend
 * This method update the store based on data returned in backend responses.
 * @param response - the backend response
 * @param store - the store instance
 */
 export function updateStoreFromBackend(response:any, store:Store<any>) {
  if(response.shopContent) {  
    // We update the cart in store
    store.commit(statePath + types.mutations.SET_SHOP_CONTENT,response.shopContent);
  }
}