export function showPostContent(post:any, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {

        var eventName = 'Click';
        var eventContent = {
            "event_category": 'Post link',
            "event_label" : post.title
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("BLOG - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}