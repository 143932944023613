import Vue from '@fwk-node-modules/vue';
import * as api from '@fwk-client/utils/api';
import * as gtag from '../utils/gtag';
import { updateStoreFromBackend, types } from '../stores';
import { gettersPath as agencyGettersPath, types as agencyTypes } from '../stores/agency';

export interface AvailabilitiesOptions {
    startDate?:Date,
    endDate?:Date
}

export class Apartment {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    } 

    public onApartmentContentClick(apartment:any) {
        // We send analytics
        gtag.showApartmentContent(apartment, this.$vm);
    }

    /**
     * getApartment
     * This method get the apartment from backend
     */
    getApartment(apartmentCode:string):Promise<void> {        
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        return api.getAPI('/api/hospitality/apartment/'+apartmentCode, options).then((response:any) => {
            if(response.apartment !== undefined && response.apartment !== null) {
                // We update the apartment
                this.$vm.$store.dispatch('hospitality/agency/' + agencyTypes.actions.UPDATE_APARTMENT,{
                    app:this.$vm,
                    apartment:response.apartment
                });
            }
        })
    }

    /**
     * getAvailabilities
     * This method get the apartment availabilities from backend
     */
    getAvailabilities(apartmentCode:string, availOptions?:AvailabilitiesOptions):Promise<void> {        
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        var input = {
            startDate: (availOptions && availOptions.startDate) ? availOptions.startDate : undefined,
            endDate: (availOptions && availOptions.endDate) ? availOptions.endDate : undefined,
        }

        return api.postAPI('/api/hospitality/apartment/'+apartmentCode+'/availabilities', input, options).then((response:any) => {
            return response.availabilities;
        })
    }

}
