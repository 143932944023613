<template>
  <div v-if="isCContentInDOM" ref="offcanvas" :class="contentClass">
    <slot></slot>
  </div>
</template>

<style>

.offcanvas.openedOnCreate {
  transition:none;
}

</style>

<script lang="ts">
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber, CmsBoolean } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, Ref, ref, computed, nextTick, watch, toRefs, reactive, onBeforeUnmount } from '@fwk-node-modules/vue'
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

// @ts-ignore
import { Offcanvas as BSOffcanvas } from 'bootstrap';

/** @cmsOptions */
export interface OffcanvasOptions {
    /** @cmsType CmsEnum */
    position?:CmsEnum<"left"|"top"|"right"|"bottom">,
}

/** @cmsLabels */
export interface OffcanvasLabels {
    
}

/** @cmsSlots */
export interface OffcanvasSlots {
    
}

export default defineComponent({
  props: {
    value: Boolean as PropType<boolean>,
    options: Object as PropType<OffcanvasOptions>,
    labels: {
      type: Object as PropType<OffcanvasLabels>,
      default: () => { return {} }
    },
    components: Object as PropType<OffcanvasSlots>
  },
  components : {
  },
  setup(props, context) {

    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    const cms = useCmsModule();

    const { options, value } = toRefs(props);

    const computedOptions:Ref<OffcanvasOptions> = computed(() => {
      return {
        position: "left",
        ...options.value,
      }
    });

    const computedLabels:OffcanvasLabels = {
        ...props.labels
    }

    const offcanvas:Ref<HTMLElement|null> = ref(null);

    const isCContentInDOM:Ref<boolean> = ref(false);
    const isOpenedOnCreate:Ref<boolean> = ref(false);
    if(props.value) {
      isOpenedOnCreate.value = true;
    }

    const contentClass = computed(() => {
      var cssClass = "";
      switch(computedOptions.value.position) {
        case "left" :
          cssClass += "offcanvas offcanvas-start"
          break;
        case "top" :
          cssClass += "offcanvas offcanvas-top"
          break;
        case "right" :
          cssClass += "offcanvas offcanvas-end"
          break;
        case "bottom" :
          cssClass += "offcanvas offcanvas-bottom"
          break;
      }

      return cssClass;
    })

    const show = () => {
      // We add the collapse in the DOM
      isCContentInDOM.value = true;
      // We wait for DOM to be rendered
      nextTick(() => {
        // We add the class if opened on create
        if(isOpenedOnCreate.value) {
          offcanvas.value!.classList.add("openedOnCreate")
        }
        // We create the instance
        var instance = BSOffcanvas.getOrCreateInstance(offcanvas.value);
        // We attach the hidden event
        offcanvas.value!.addEventListener('hidden.bs.offcanvas', (event:Event) => {
          // We destroy the instance
          instance.dispose();
          // We remove the modal from the DOM
          isCContentInDOM.value = false;
          // We update the v-model
          context.emit('input', false);
        });

        offcanvas.value!.addEventListener('shown.bs.offcanvas', (event:Event) => {
          console.log("Offcanvas - we disable isOpenedOnCreate")
          // We update the flag which disable animation on create when already opened.
          isOpenedOnCreate.value = false;
          offcanvas.value!.classList.remove("openedOnCreate")
        })

        // We show the component
        instance.show();
        // We update the v-model
        context.emit('input', true);
      })
    }

    const hide = () => {
      // We get the instance
      var instance = BSOffcanvas.getOrCreateInstance(offcanvas.value);
      instance.hide();
    }

    if(value.value) {
      show();
    }

    onBeforeUnmount(() => {
      if(isCContentInDOM.value) {
        hide();
      }
    })

    watch(
      value,
      (val:any, oldVal:any) => {
        if(value.value) {
          show();
        }
        else {
          hide();
        }
      },
      { deep: true }
    )

    return {
      offcanvas,
      isCContentInDOM,
      contentClass
    }
  }
})
</script>