import { Module } from '@fwk-node-modules/vuex';
import { containerUtils } from './index';


export const types = {
  getters : {
    // CONTAINER COMMON GETTERS
    GET_ORDER : 'GET_ORDER',
    GET_ORDER_PRODUCTS : 'GET_ORDER_PRODUCTS',
    GET_ORDER_PRODUCT_QUANTITY : 'GET_ORDER_PRODUCT_QUANTITY',
    GET_ORDER_PRODUCTS_AMOUNT : 'GET_ORDER_PRODUCTS_AMOUNT',
    GET_ORDER_PROMOCODES : 'GET_ORDER_PROMOCODES',
    GET_ORDER_PROMOCODES_REDUCTION : 'GET_ORDER_PROMOCODES_REDUCTION',
    // ORDER SPECIFIC GETTERS
    GET_ORDER_DELIVERY_AMOUNT : 'GET_ORDER_DELIVERY_AMOUNT',
    GET_ORDER_TOTAL_AMOUNT : 'GET_ORDER_TOTAL_AMOUNT'
  },
  mutations : {
    // CONTAINER COMMON SETTERS
    SET_ORDER : 'SET_ORDER',
  },
  actions : {}
}

export const statePath:string = 'shop/order/';

export function createOrderStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      order : null, // We store the order information
    },
    getters: {
      /**
       *  CONTAINER COMMON GETTERS
       */ 
      [types.getters.GET_ORDER](state) { return state.order; },
      [types.getters.GET_ORDER_PRODUCTS](state) {
        if(state.order && state.order.products) {
          return state.order.products;
        }
        return [];
      },
      [types.getters.GET_ORDER_PRODUCTS_AMOUNT](state) {
        return containerUtils.getProductsAmount(state.order);
      },
      [types.getters.GET_ORDER_PRODUCT_QUANTITY](state) {
        return function(product:any) {
          if(state.order && state.order.products) {
            var index = state.order.products.map((containerProduct:any) => {
              return containerProduct.product._id;
            }).indexOf(product._id);
            if(index > -1) {
              return state.order.products[index].quantity;
            }
          }
          return 0; 
        }
      },
      [types.getters.GET_ORDER_PROMOCODES](state) {
        return containerUtils.getPromoCodes(state.order);
      },
      [types.getters.GET_ORDER_PROMOCODES_REDUCTION](state) {
        return containerUtils.getPromoCodesReduction(state.order);
      },
      [types.getters.GET_ORDER_DELIVERY_AMOUNT](state) {
        return utils.getOrderDeliveryAmount(state.order);
      },
      [types.getters.GET_ORDER_TOTAL_AMOUNT](state) {
        return utils.getOrderTotalAmount(state.order);
      },
    },
    mutations: {
      [types.mutations.SET_ORDER] (state, order) { state.order = order; },
    },
    actions: {}
  }
  return store;
};

export const utils = {
  getOrderDeliveryAmount(order:any){
    if(order && order.deliveryAmount) {
      return order.deliveryAmount;
    }
    return null;
  },
  getOrderTotalAmount(order:any){
    if(order && order.totalAmount) {
      return order.totalAmount;
    }
    return null;
  }
}

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;

export const getters = {
  $shop_order : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_ORDER]];
    }
  },
  $shop_orderProducts : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_ORDER_PRODUCTS]];
    },
  },
  $shop_orderPromoCodes : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_ORDER_PROMOCODES]];
    },
  },
  $shop_orderProductsAmount : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_ORDER_PRODUCTS_AMOUNT]];
    }
  },
  $shop_orderDeliveryAmount : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_ORDER_DELIVERY_AMOUNT]];
    }
  },
  $shop_orderPromoCodesReduction : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_ORDER_PROMOCODES_REDUCTION]];
    }
  },
  $shop_orderTotalAmount : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_ORDER_TOTAL_AMOUNT]];
    }
  },
}