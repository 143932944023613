import Vue from '@fwk-node-modules/vue';
import * as gtag from '../utils/gtag';
import { types as shopTypes } from '@fwk-client/modules/shop/stores/shop';

export class Product {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    public getThumbnailsURLs(product:any, defaultThumbnail:string) {
        var urls = [];
        if(product.thumbnails && product.thumbnails.length > 0) {
            for(var thumbnail of product.thumbnails) {
                urls.push(this.$vm.getStaticURLFromPath(thumbnail));
            }
        }
        else {
            urls.push(defaultThumbnail);
        }
        return urls;
    }

    public getPicturesURLs(product:any, defaultPicture:string) {
        var urls = [];
        if(product.pictures && product.pictures.length > 0) {
            for(var picture of product.pictures) {
                urls.push(this.$vm.getStaticURLFromPath(picture));
            }
        }
        else {
            urls.push(defaultPicture);
        }
        return urls;
    }

    public getProductTitle(product:any, shopName?:string) {
        var alt = product.title;
        if(product.subtitle) {
            alt += " - " + product.subtitle
        }
        if(product.year) {
            alt += " ("+product.year+")";
        }
        if(shopName) {
            alt += " - " + shopName;
        }
        return alt;
    }

    public viewProductDetails(product:any) {
        // We send analytics
        gtag.viewProductDetails(product, this.$vm);
    }

    public getProductsFromCategoryID(categoryID:any) {
        return this.$vm.$store.getters['shop/shop/'+shopTypes.getters.GET_SHOP_PRODUCTS].filter((product:any) => {
            // In case we want to display all products
            if(categoryID == null) { return true; }
            // In case a specific category is selected
            return product.category_id == categoryID
        });
    }

    public getProductsFromIDs(productIDs:string[]) {
        return this.$vm.$store.getters['shop/shop/'+shopTypes.getters.GET_SHOP_PRODUCTS].filter((product:any) => {
            // We keep products from list of ids
            return productIDs.indexOf(product._id) > -1
        });
    }

    public getRelatedProducts(product:any) {
        var relatedProducts = [];
        // We check if we have related products defined for the product
        if(product.relatedProducts_ids && product.relatedProducts_ids.length > 0) {
            // We have related products
            relatedProducts = this.getProductsFromIDs(product.relatedProducts_ids);
        }
        else if(product.relatedActivities_ids && product.relatedActivities_ids.length > 0) {
            // We have related activities
            relatedProducts = this.$vm.$shop.activity.getActivitiesFromIDs(product.relatedActivities_ids);
        }
        else {
            // We take the product from the same category
            var categoryID = product.category_id ? product.category_id : null;
            relatedProducts = (product.type == 'ACTIVITY') ? this.$vm.$shop.activity.getActivitiesFromCategoryID(categoryID) : this.getProductsFromCategoryID(categoryID);
        }
        return relatedProducts.filter((currentProduct:any) => {
            return currentProduct._id != product._id;
        });
    }

}