import { Module } from '@fwk-node-modules/vuex';
import * as api from '@fwk-client/utils/api';
import { authenticationTypes as types } from '@fwk-client/store/types';

const privateTypes = {
  mutations : {
    AUTH_ERROR : 'AUTH_ERROR',
    AUTH_LOGOUT : 'AUTH_LOGOUT'
  }
}

/**
 * This is a generic authentication store which can be used by any application.
 * To use it, the store must be registered in the hooks.ts file of the application.
 *   var authenticationStore = createAuthenticationStore();
 *   store.registerModule("authentication", authenticationStore);
 */
export function createAuthenticationStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      isLoggedIn : false,
      user : null
    },
    getters: {
      /**
       * HAS_USER_ROLE
       * This getter check if role is available for user
       */
      [types.getters.HAS_USER_ROLE]: (state) => (role:string):boolean =>  {
        return state.user && state.user.roles && state.user.roles.indexOf(role) > -1;
      },
      /**
       * HAS_USER_ROLES
       * This getter check if all roles are available for user
       */
       [types.getters.HAS_USER_ROLES]: (state, getters) => (roles:string[]):boolean =>  {
        for(var role of roles) {
          if(!getters[types.getters.HAS_USER_ROLE](role)) {
            return false;
          }
        }
        return true;
      },
      /**
       * IS_LOGGED_IN
       */
       [types.getters.IS_LOGGED_IN]: (state) =>  {
        return state.isLoggedIn;
      },
      /**
       * GET_USER
       */
       [types.getters.GET_USER]: (state) =>  {
        return state.user;
      }
    },
    mutations: {
      [types.mutations.AUTH_SUCCESS] (state, user) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - AUTHENTICATION - AUTH_SUCCESS");
        }
        // We update the store
        state.isLoggedIn = true;
        state.user = user;
      },
      [types.mutations.UPDATE_USER] (state, user) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - AUTHENTICATION - UPDATE_USER");
        }
        // We update the store
        state.user = user;
      },
      [privateTypes.mutations.AUTH_ERROR] (state) {
        // We update the store
        state.isLoggedIn = false;
        state.user = null;
      },
      [privateTypes.mutations.AUTH_LOGOUT] (state) {
        // We update the store
        state.isLoggedIn = false;
        state.user = null;
      }
    },
    actions: {
      [types.actions.AUTH_LOGOUT]({commit}, {app}):Promise<any> {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - AUTHENTICATION - AUTH_LOGOUT");
        }
        var options:api.ApiVueOptions =  {
          app: app
        }
        return api.getAPI('/api/auth/logout',options).then((response:any) => {
          commit(privateTypes.mutations.AUTH_LOGOUT);
          return Promise.resolve();
        })
      }
    }
  }
  return store;
};