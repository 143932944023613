import { roles } from '@igotweb/core-api/src/roles';
import { RouteConfig } from '@fwk-node-modules/vue-router';
import { utils } from '@fwk-client/router/utils';

export const routes:any[] = [
  { 
    name: 'login',
    path: '/login',
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      authRoles: ["public"],
      languageBundle: ['authentication','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      components: {
        pageTitle : {
          "path" : "canvas/PageTitle",
          "props" : {
            "labels": {
              "title" : "key:authentication.login.title"
            },
            "options" : {
              "mini" : true,
            }
          }
        },
        content : [
          "canvas/authentication/Login"
        ]
      }
    }
  },
  {
    name: 'register',
    path: '/register',
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      authRoles: ["public"],
      languageBundle: ['authentication','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      components: {
        pageTitle : {
          "path" : "canvas/PageTitle",
          "props" : {
            "options" : {
              "mini" : true,
            },
            "labels": {
              "title" : "key:authentication.register.title"
            }
          }
        },
        content : [
          "canvas/authentication/Register"
        ]
      }
    }
  },
  {
    name: 'registered',
    path: '/registered',
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      authRoles: ["public"],
      languageBundle: ['authentication','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      components: {
        pageTitle : {
          "path" : "canvas/PageTitle",
          "props" : {
            "options" : {
              "mini" : true,
            },
            "labels": {
              "title" : "key:authentication.registered.title"
            }
          }
        },
        content : [
          {
            "path" : "canvas/authentication/Registered"
          }
        ]
      }
    }
  },
  { 
    name: 'profile',
    path: '/profile',
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      authRoles: [roles.user],
      languageBundle: ['authentication','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      components: {
        pageTitle : {
          "path" : "canvas/PageTitle",
          "props" : {
            "options" : {
              "mini" : true,
            },
            "labels": {
              "title" : "key:authentication.profile.title"
            }
          }
        },
        content : [
          "canvas/authentication/Profile"
        ]
      }
    }
  },
  {
    name: 'access-restricted',
    path: '/access-restricted',
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['authentication','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      components: {
        content : [
          "canvas/authentication/AccessRestricted"
        ]
      }
    }
  }
]

export const routesFromShortName:{[routeShortName:string]:RouteConfig} = utils.computeRoutesFromShortName(routes);