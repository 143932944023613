<template>
  <div id="#wrapper" :class="'layout page-layout '+pageShortName+'-page'">
    <CmsComponent v-if="components.header" :key="pageShortName + '-header'" :slider="components.slider" :component="components.header"></CmsComponent>
    <transition @after-leave="afterLeave">
      <router-view></router-view>
    </transition>
    <Footer></Footer>
    <SideCart></SideCart>
    <ContactModal></ContactModal>
    <Cookies></Cookies>
  </div>
</template>

<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { utils as RouterUtils } from '@fwk-client/router/utils';
import Footer from '../panels/layouts/page/Footer.vue';
import SideCart from '../cms/canvas/shop/SideCart.vue';
import CmsCanvasLayout from '../mixins/CmsCanvasLayout.vue';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

import ContactModal from '../cms/canvas/ContactModal.vue';
import Cookies from '../panels/Cookies.vue';

@Component({
  components: { Footer , SideCart, ContactModal, Cookies }
})
export default class Page extends mixins(CmsCanvasLayout, GenericPage) {

  get pageShortName() {
    return RouterUtils.getRouteShortNameFromRoute(this.$route);
  }

  get components() {
    return this.$cms.components.getComponentsFromRoute(this.$route);
	}
  
  constructor() {
    super();
  }

  mounted() {
    // We trigger the scroll when the layout is mounted coming from an other layout.
    // This event is catched by the generic triggerScroll event listener until the scrollBehavior method is called.
    if(process.env.CONSOLE == "LOG") {
      console.log("PAGE LAYOUT - MOUNTED - WE TRIGGER SCROLL");
    }
    this.$root.$emit('triggerScroll');
  }

  /*
   *  Transition afterLeave event
   *  We emit triggerScroll event to be catched by Router.scrollBehavior when there is a transition to a new route in the same Layout
   */
  afterLeave () {
    if(process.env.CONSOLE == "LOG") {
      console.log("PAGE LAYOUT - AFTER LEAVE - WE TRIGGER SCROLL");
    }
    this.$root.$emit('triggerScroll');
  }

}
</script>