<template>
    <Offcanvas v-model="isSideCartDisplayed" :options="{position:'right'}">
        <div class="offcanvas-header">
            <h4 class="offcanvas-title">{{ $cms.utils.getComputedLabel(computedLabels.title) }}</h4>
            <button type="button" aria-label="Close" class="btn-close" @click="onCloseCartClick()"></button>
        </div>
        <div v-if="cartProducts.length > 0 || cartActivities.length > 0" class="offcanvas-body top-cart-items">
            <div v-if="showBackToListLink">
                <a href="javascript:void(0)" @click="onBackToListClick">
                    {{ $cms.utils.getComputedLabel(computedLabels.backToList) }}
                </a>
            </div>
            <template v-if="computedOptions.type == 'shop'">
                <TopCartProduct v-for="(cartProduct, index) in cartProducts" :key="index + 'top-cart-product'" :cartProduct="cartProduct"></TopCartProduct>
                <TopCartActivity v-for="(cartActivity, index) in cartActivities" :key="index + 'top-cart-activity'" :cartActivity="cartActivity"></TopCartActivity>
            </template>
            <template v-if="computedOptions.type == 'hospitality'">
                <TopCartRental v-for="(cartProduct, index) in cartProducts" :key="index + 'top-cart-rental'" :cartProduct="cartProduct"></TopCartRental>
            </template>
        </div>
        <div v-else class="offcanvas-body top-cart-items empty">
            {{ $cms.utils.getComputedLabel(computedLabels.cartEmpty) }}
        </div>
        <div class="top-cart-action">
            <span v-if="cartProducts.length > 0 || cartActivities.length > 0" class="top-checkout-price">{{ formatPriceAmount(subTotal) }}</span>
            <button :disabled="cartProducts.length == 0 && cartActivities.length == 0" class="button button-3d button-small m-0 fright" @click="onCheckoutClick">{{ $cms.utils.getComputedLabel(computedLabels.checkout) }}</button>
        </div>
    </Offcanvas>
</template>

<style>

.top-cart-items.empty {
    text-align: center;
}

</style>

<script lang="ts">
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber, CmsBoolean } from '@fwk-client/modules/cms/types';
import { defineComponent, PropType, Ref, ref, computed } from '@fwk-node-modules/vue'
import { getApp, useCmsModule, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { useCart } from '@root/src/client/composables/useCart'
import Offcanvas from '@fwk-client/components/panels/bootstrap/Offcanvas.vue';

/** @cmsOptions */
export interface SideCartOptions {
    /** @cmsType CmsEnum */
    type?:CmsEnum<"shop"|"hospitality">,
}

/** @cmsLabels */
export interface SideCartLabels {
    /** @cmsType CmsLabel */
    cartEmpty:CmsLabel
    /** @cmsType CmsLabel */
    title:CmsLabel
    /** @cmsType CmsLabel */
    backToList:CmsLabel
    /** @cmsType CmsLabel */
    checkout:CmsLabel
}

export const defaultLabels:SideCartLabels = {
    cartEmpty : {
        fr : "Votre panier est vide",
        de : "Ihr Warenkorb ist leer",
        en : "Your cart is empty"
    },
    title : {
        fr : "Votre panier",
        de : "Ihr Warenkorb",
        en : "Your cart"
    },
    backToList : {
        fr : "Retour à la boutique",
        de : "Kehre zur Shop zurück",
        en : "Continue shopping"
    },
    checkout : {
        fr : "Commander",
        de : "Befehl",
        en : "Checkout",
    }
}

/** @cmsSlots */
export interface SideCartSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<SideCartOptions>,
        labels: {
          type: Object as PropType<SideCartLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<SideCartSlots>
    },
    components : {
        TopCartProduct : () => import(/* webpackChunkName: "panels-top-cart-product" */ '@root/src/client/components/panels/shop/cart/TopCartProduct.vue'),
        TopCartActivity : () => import(/* webpackChunkName: "panels-top-cart-activity" */ '@root/src/client/components/panels/shop/cart/TopCartActivity.vue'),
        TopCartRental : () => import(/* webpackChunkName: "panels-top-cart-rental" */ '@root/src/client/components/panels/hospitality/cart/TopCartRental.vue'),
        Offcanvas
    },
    setup(props, context) {

        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const cms = useCmsModule();

        const computedOptions:SideCartOptions = {
            type: "shop",
            ...props.options,
        };

        const { onBackToListClick, onCheckoutClick, cartProducts, cartActivities, subTotal, isSideCartDisplayed, showBackToListLink } = useCart({
            type: computedOptions.type!
        }, context);

        const computedLabels:SideCartLabels = {
            ...defaultLabels,
            ...props.labels
        }

        const currentLanguageCode = computed(() => {
			return $store.state.languages.currentLanguageCode;
		})

        const onCloseCartClick = () => {
            app.$shop.cart.setSideCartDisplayed(false);
        }

        

        return {
            isSideCartDisplayed,
            currentLanguageCode,
            showBackToListLink,
            onCloseCartClick,
            onBackToListClick,
            onCheckoutClick,
            computedLabels,
            computedOptions,
            cartProducts,
            cartActivities,
            subTotal
        }
    }
})

</script>