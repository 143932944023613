import {swiperSliderMenu, revolutionSliderMenu} from './canvas.slider';
import { getAttribute } from './canvas.utils';
import { elements } from './canvas.elements';

/*-------------------------------------------------------------------------------*/
/*	UPDATE PROCESS: Compare functions.js file between versions to adapt methods	 */
/*-------------------------------------------------------------------------------*/

/**
 * This method add class to menu child element when they are out of screen displayed to be aligned on the left and not on the right.
 * This method is to be called when DOM of menu is updated.
 * @param elements 
 * @param subMenuEl 
 */
function menuInvert( subMenuEl?:HTMLElement|null ) {

	var {windowWidth, $body} = elements;

	let submenus:HTMLElement[] = subMenuEl ? [subMenuEl] : Array.from(document.querySelectorAll( '.mega-menu-content, .sub-menu-container, .top-links-section' ));

	submenus.forEach((submenu:HTMLElement) => {
		Array.from(submenu.children).forEach((element:Element) => { (element as HTMLElement).css({ 'display': 'block' }) })
		submenu.css({ 'display': 'block' });
	})
	
	submenus.forEach((submenu:HTMLElement) => {
		let viewportOffset = submenu.getBoundingClientRect();
		if( $body.hasClass('rtl') ) {
			if( viewportOffset.left < 0 ) {
				submenu.classList.add('menu-pos-invert');
			}
		}
		if((viewportOffset.left + viewportOffset.width) - windowWidth > 0) {
			submenu.classList.add('menu-pos-invert');
		}
	});

	submenus.forEach((submenu:HTMLElement) => {
		Array.from(submenu.children).forEach((element:Element) => { (element as HTMLElement).css({ 'display': '' }) })
		submenu.css({ 'display': '' });
	})

};

function includeOffset() {
	var {$headerInc, $header} = elements;
	
	if( !$headerInc ) {
		return;
	}

	let headerInc = $header.outerHeight();
	if( $header.hasClass('floating-header') || $headerInc.hasClass('include-topbar') ) {
		headerInc = headerInc + $header.offset().top;
	}
	$headerInc.css({ 'margin-top': -headerInc });
	//SEMICOLON.slider.sliderParallax();
}

export function menufunctions() {

	var {$body, primaryMenu, initHeaderHeight, $headerWrapClone, $headerInc} = elements;

	var menuItemSubs = Array.from(document.querySelectorAll('.menu-item')).filter((element:Element) => { return element.querySelector('.sub-menu-container') != null });
	var menuItemSubsLinks = [];
	for(var menuItemSub of menuItemSubs) {
		menuItemSubsLinks.push(Array.from(menuItemSub.children).filter((element:Element) => { return element.matches('.menu-link') }));
	}
	var submenusT = '.mega-menu-content, .sub-menu-container';
	var submenus = document.querySelector( submenusT );
	var menuItemT = '.menu-item';
	var subMenuT = '.sub-menu';
	var menuSpeed = getAttribute(primaryMenu,'data-trigger-speed',200);
	var subMenuTriggerT = '.sub-menu-trigger';
	
	var menuItemTrigger = [];
	for(var menuItemSub of menuItemSubs) {
		menuItemTrigger.push(Array.from(menuItemSub.children).filter((element:Element) => { return element.matches(subMenuTriggerT) }));
	}

	if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
		setTimeout( function(){
			if( $headerWrapClone ) {
				$headerWrapClone.css({ 'height': initHeaderHeight });
			}
			includeOffset();
		}, 1000);
		primaryMenu.find( submenusT ).forEach((element:HTMLElement) => {
			element.css({ 'display': '' })
		});
	} else if($headerInc) {
		$headerInc.css({ 'margin-top': '' });
	}

	/*

	if( ( $body.hasClass('overlay-menu') && primaryMenu.hasClass('on-click') ) && ( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) ) {
		menuItemSubsLinks.off( 'click' ).on( 'click', function(e){
			let triggerEl = $(this);
			triggerEl.parents( subMenuT ).siblings().find( submenus ).stop( true, true ).slideUp( menuSpeed );
			triggerEl.parent( menuItemT ).children( submenusT ).stop( true, true ).slideToggle( menuSpeed );
			e.preventDefault();
		});
	} else if( ( $body.hasClass('side-header') && primaryMenu.hasClass('on-click') ) || ( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) ) {
		menuItemTrigger.removeClass('icon-rotate-90');
		$( menuItemT ).find( submenus ).filter(':not(:animated)').stop( true, true ).slideUp( menuSpeed , function(){
			$body.toggleClass("primary-menu-open", false);
		});

		menuItemTrigger = menuItemTrigger.add( menuItemSubsLinks.filter('[href^="#"]') );

		menuItemTrigger.off( 'click' ).on( 'click', function(e){
			let triggerEl = $(this);
			triggerEl.parents( subMenuT ).siblings().find( subMenuTriggerT ).removeClass('icon-rotate-90');
			triggerEl.parents( subMenuT ).siblings().find( submenus ).filter(':not(:animated)').stop( true, true ).slideUp( menuSpeed );
			triggerEl.parent( menuItemT ).children( submenusT ).filter(':not(:animated)').stop( true, true ).slideToggle( menuSpeed );

			let subMenuTriggerEl = triggerEl.parent( menuItemT ).children( subMenuTriggerT );

			if( !subMenuTriggerEl.hasClass( 'icon-rotate-90' ) ) {
				subMenuTriggerEl.addClass('icon-rotate-90');
			} else {
				subMenuTriggerEl.removeClass('icon-rotate-90');
			}

			e.preventDefault();
		});
	} else if( ( $body.hasClass('overlay-menu') || $body.hasClass('side-header') ) && ( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) ) {
		primaryMenu.find( submenus ).stop( true, true ).slideUp( menuSpeed );
		$( menuItemT ).hover( function(e){
			$(this).children( submenusT ).stop( true, true ).slideDown( menuSpeed );
		}, function(){
			$(this).children( submenusT ).stop( true, true ).slideUp( menuSpeed );
		});
	} else {
		if( primaryMenu.hasClass('on-click') ) {
			menuItemSubsLinks.off( 'click' ).on( 'click', function(e){
				let triggerEl = $(this);
				triggerEl.parents( subMenuT ).siblings().find( submenus ).removeClass('d-block');
				triggerEl.parent( menuItemT ).children( submenusT ).toggleClass('d-block');
				triggerEl.parent( menuItemT ).siblings().removeClass('current');
				triggerEl.parent( menuItemT ).toggleClass('current');
				e.preventDefault();
			});
		}
	}

	*/

	/*

	if( $('.top-links').hasClass('on-click') || ( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) ) {
		$('.top-links-item:has(.top-links-sub-menu,.top-links-section) > a').on( 'click', function(e){
			$(this).parents('li').siblings().find('.top-links-sub-menu,.top-links-section').removeClass('d-block');
			$(this).parent('li').children('.top-links-sub-menu,.top-links-section').toggleClass('d-block');
			e.preventDefault();
		});
	}

	*/

	menuInvert( document.querySelector('.top-links-section') as HTMLElement );

	/*

	$('#primary-menu-trigger').off( 'click' ).on( 'click', function() {
		if( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) {
			if( primaryMenu.find('.mobile-primary-menu').length > 0 ) {
				$( '.primary-menu:not(.mobile-menu-off-canvas) .mobile-primary-menu' ).stop( true, true ).slideToggle( menuSpeed );
				$( '.primary-menu.mobile-menu-off-canvas .mobile-primary-menu' ).toggleClass('d-block');
			} else {
				$( '.primary-menu:not(.mobile-menu-off-canvas) .menu-container' ).stop( true, true ).slideToggle( menuSpeed );
				$( '.primary-menu.mobile-menu-off-canvas .menu-container' ).toggleClass('d-block');
			}
		}
		$body.toggleClass("primary-menu-open");
		return false;
	});

	*/

	var menuContainer:HTMLElement|null = document.querySelector( '.menu-container:not(.mobile-primary-menu)' );
	if(menuContainer) { menuContainer.css({ 'display': '' }); }
	if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
		primaryMenu.find('.mobile-primary-menu').forEach((element:HTMLElement) => {
			element.removeClass('d-block')
		});
	}
}

/**
 * initialize
 * This method adds missing DOM elements (header-wrap-clone, page-menu-wrap-clone)
 * It calls menuInvert
 * @param elements 
 */
function initialize() {

	var {initHeaderHeight, $headerWrap, $headerWrapClone, $pagemenu, $pageMenuClone} = elements;

	initHeaderHeight = $headerWrap.outerHeight();

	if( $headerWrap ) {
		if( !document.querySelector('.header-wrap-clone') ) {
			$headerWrap.after('<div class="header-wrap-clone"></div>');
		}
		$headerWrapClone = document.querySelector('.header-wrap-clone');
	}

	if( $pagemenu ) {
		$pagemenu.querySelector('#page-menu-wrap').after('<div class="page-menu-wrap-clone"></div>');
		$pageMenuClone = document.querySelector('.page-menu-wrap-clone');
	}

	// We need to get menu-item which have sub-menu-container in their children
	/*
	let menuItemSubs = Array.from(document.querySelectorAll( '.menu-item')).filter((element:Element) => { return element.querySelector('.sub-menu-container)') != null });

	menuItemSubs.forEach((menuItemSub:Element) => { (menuItemSub as HTMLElement).addClass('sub-menu'); }); // , .primary-menu.with-arrows > .menu-container > .menu-item:has(.sub-menu-container) > .menu-link > div:not(:has(.icon-angle-down))
	document.querySelectorAll( '.top-links-item:has(.top-links-sub-menu,.top-links-section) > a:not(:has(.icon-angle-down)), .menu-item:not(.mega-menu-title):has(.sub-menu-container) > .menu-link > div:not(:has(.icon-angle-down)), .page-menu-item:has(.page-menu-sub-menu) > a > div:not(:has(.icon-angle-down))' ).forEach((item:Element) => { (item as HTMLElement).append( '<i class="icon-angle-down"></i>' ) });
	document.querySelectorAll( '.menu-item:not(.mega-menu-title):has(.sub-menu-container):not(:has(.sub-menu-trigger))' ).forEach((item:Element) => { (item as HTMLElement).append( '<button class="sub-menu-trigger icon-chevron-right"></button>' ); });
	*/
	menuInvert();

};

export function logo() {

	var {defaultLogo, retinaLogo, $headerWrap, $body, $header, defaultDarkLogo, retinaDarkLogo, 
			defaultLogoImg, retinaLogoImg, defaultStickyLogo, retinaStickyLogo, defaultMobileLogo, 
			retinaMobileLogo} = elements;

	// We do nothing if not logo provided
	if(!defaultLogo) { return; }

	let sLogo = defaultLogo.querySelector('img'),
		rLogo = retinaLogo.querySelector('img');
	if( ( $header.hasClass('dark') || $body.hasClass('dark') ) && !$headerWrap.hasClass('not-dark') ) {
		if( defaultDarkLogo && ( sLogo.attr('src') != defaultDarkLogo ) ){
			sLogo.attr('src', defaultDarkLogo);
		}

		if( retinaDarkLogo && ( rLogo.attr('src') != retinaDarkLogo ) ){
			rLogo.attr('src', retinaDarkLogo);
		}
	} else {
		if( defaultLogoImg && ( sLogo.attr('src') != defaultLogoImg ) ){
			sLogo.attr('src', defaultLogoImg);
		}

		if( retinaLogoImg && ( rLogo.attr('src') != retinaLogoImg ) ){
			rLogo.attr('src', retinaLogoImg);
		}
	}

	if( $header.hasClass('sticky-header') ) {
		if( defaultStickyLogo && ( sLogo.attr('src') != defaultStickyLogo ) ){
			sLogo.attr('src', defaultStickyLogo);
		}

		if( retinaStickyLogo && ( rLogo.attr('src') != retinaStickyLogo ) ){
			rLogo.attr('src', retinaStickyLogo);
		}
	}

	if( $body.hasClass('device-md') || $body.hasClass('device-sm') || $body.hasClass('device-xs') ) {
		if( defaultMobileLogo && ( sLogo.attr('src') != defaultMobileLogo ) ){
			sLogo.attr('src', defaultMobileLogo);
		}

		if( retinaMobileLogo && ( rLogo.attr('src') != retinaMobileLogo ) ){
			rLogo.attr('src', retinaMobileLogo);
		}
	}
}

export function stickyMenuClass() {

	var {stickyMenuClasses, $header, $headerWrap} = elements;

	let newClassesArray = '';

	if( stickyMenuClasses ) {
		newClassesArray = stickyMenuClasses.split(/ +/);
	}

	let noOfNewClasses = newClassesArray.length;

	if( noOfNewClasses > 0 ) {
		let i = 0;
		for( i=0; i<noOfNewClasses; i++ ) {
			if( newClassesArray[i] == 'not-dark' ) {
				$header.removeClass('dark');
				if($headerWrap.matches(':not(.not-dark)')) { $headerWrap.addClass('not-dark'); }
			} else if( newClassesArray[i] == 'dark' ) {
				$headerWrap.removeClass('not-dark force-not-dark');
				if( !$header.hasClass( newClassesArray[i] ) ) {
					$header.addClass( newClassesArray[i] );
				}
			} else if( !$header.hasClass( newClassesArray[i] ) ) {
				$header.addClass( newClassesArray[i] );
			}
		}
	}
}

function menuItemsSpacing( spacing = '' ) {
	
	var {$body, primaryMenu, primaryMenuMainItems} = elements;

	let items:HTMLElement[] = primaryMenuMainItems;
	if( !$body.hasClass('side-header') && !$body.hasClass('overlay-menu') ) {
		if( primaryMenu.hasClass('menu-spacing-margin') ) {
			if( spacing == '' ) {
				items.forEach((item:HTMLElement) => { item.css({ 'margin-top': '', 'margin-bottom': '' }) });
			} else {
				items.forEach((item:HTMLElement) => { item.css({ 'margin-top': Number( spacing ), 'margin-bottom': Number( spacing ) }) });
			}
		} else {
			if( spacing == '' ) {
				items.forEach((item:HTMLElement) => { item.css({ 'padding-top': '', 'padding-bottom': '' }) });
			} else {
				items.forEach((item:HTMLElement) => { item.css({ 'padding-top': Number( spacing ), 'padding-bottom': Number( spacing ) }) });
			}
		}
	}
}

export function responsiveMenuClass() {

	var {$body,$header, $headerWrap,  responsiveMenuClasses} = elements;

	if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ){
		return;
	}

	let newClassesArray = '';

	if( responsiveMenuClasses ) {
		newClassesArray = responsiveMenuClasses.split(/ +/);
	}

	let noOfNewClasses = newClassesArray.length;

	if( noOfNewClasses > 0 ) {
		let i = 0;
		for( i=0; i<noOfNewClasses; i++ ) {
			if( newClassesArray[i] == 'not-dark' ) {
				$header.removeClass('dark');
				$headerWrap.addClass('not-dark');
			} else if( newClassesArray[i] == 'dark' ) {
				$headerWrap.removeClass('not-dark force-not-dark');
				if( !$header.hasClass( newClassesArray[i] ) ) {
					$header.addClass( newClassesArray[i] );
				}
			} else if( !$header.hasClass( newClassesArray[i] ) ) {
				$header.addClass( newClassesArray[i] );
			}
		}
	}

	logo();
}

function removeStickyness(){
	var {$body, $header, oldHeaderClasses, $headerWrap, oldHeaderWrapClasses, $headerWrapClone , responsiveMenuClasses} = elements;

	if( $header.hasClass('sticky-header') ){
		$header.removeClass('sticky-header');
		$header.removeClass();
		$header.addClass(oldHeaderClasses);
		$headerWrap.removeClass()
		$headerWrap.addClass(oldHeaderWrapClasses);
		if( !$headerWrap.hasClass('force-not-dark') ) { $headerWrap.removeClass('not-dark'); }
		swiperSliderMenu();
		revolutionSliderMenu();
	}
	if( ( $body.hasClass('device-sm') || $body.hasClass('device-xs') || $body.hasClass('device-md') ) && ( responsiveMenuClasses === 'undefined' ) ) {
		$header.removeClass();
		$header.addClass(oldHeaderClasses);
		$headerWrap.removeClass()
		$headerWrap.addClass(oldHeaderWrapClasses);
		if( !$headerWrap.hasClass('force-not-dark') ) { $headerWrap.removeClass('not-dark'); }
	}
}

export function stickyMenu( headerOffset:number = 0 ){

	var {$window, $body, $header, stickyShrink, stickyShrinkOffset, headerSizeCustom, logo, stickyLogoH, defLogoH, mobileSticky, mobileLogoH, stickyMenuP , defMenuP} = elements;
	var windowScrT	= $window.scrollTop();

	if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
		if( windowScrT > headerOffset ) {

			if( !$body.hasClass('side-header') ) {
				if($header.matches(':not(.no-sticky)')) { $header.addClass('sticky-header') };
				// if( !$headerWrap.hasClass('force-not-dark') ) { $headerWrap.removeClass('not-dark'); }
				stickyMenuClass();

				if( stickyShrink == 'true' && !$header.hasClass('no-sticky') ) {
					if( ( windowScrT - headerOffset ) > Number( stickyShrinkOffset ) ) {
						$header.addClass('sticky-header-shrink');
						if( headerSizeCustom ){
							if(logo) {
								(logo.find('img') as HTMLElement[]).forEach((element:HTMLElement) => element.css({ 'height': Number( stickyLogoH ) }));
							}
							menuItemsSpacing( stickyMenuP );
						}
					} else {
						$header.removeClass('sticky-header-shrink');
						if( headerSizeCustom ){
							if(logo) {
								(logo.find('img') as HTMLElement[]).forEach((element:HTMLElement) => element.css({ 'height': Number( defLogoH ) }));
							}
							menuItemsSpacing( defMenuP );
						}
					}
				}
			}

		} else {
			removeStickyness();
			if( headerSizeCustom ){
				if(logo) {
					(logo.find('img') as HTMLElement[]).forEach((element:HTMLElement) => element.css({ 'height': Number( defLogoH ) }));
				}
				menuItemsSpacing( defMenuP );
			}
		}
	}

	if( $body.hasClass('device-xs') || $body.hasClass('device-sm') || $body.hasClass('device-md') ) {
		if( mobileSticky == 'true' ) {
			if( windowScrT > headerOffset ) {
				if($header.matches(':not(.no-sticky)')) { $header.addClass('sticky-header') };
				stickyMenuClass();
			} else {
				removeStickyness();
				responsiveMenuClass();
			}
		} else {
			removeStickyness();
		}
		if( headerSizeCustom ) {
			if(logo) {
				(logo.find('img') as HTMLElement[]).forEach((element:HTMLElement) => element.css({ 'height': Number( mobileLogoH ) }));
			}
			menuItemsSpacing( '' );
		}
	}
}

export function init() {
	initialize();
	menufunctions();
	// SEMICOLON.header.fullWidthMenu();
	stickyMenu();
	// SEMICOLON.header.stickyPageMenu();
	// SEMICOLON.header.sideHeader();
	// SEMICOLON.header.sidePanel();
	// SEMICOLON.header.onePageScroll();
	logo();
	// SEMICOLON.header.topsearch();
	// SEMICOLON.header.topcart();
	// SEMICOLON.header.miscFunctions();
}
