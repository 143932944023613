import Vue from '@fwk-node-modules/vue';
import * as api from '@fwk-client/utils/api';
import { types, updateStoreFromBackend } from '@fwk-client/modules/blog/stores';

export class Feed {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    /**
     * getFeed
     * This method get the feed from backend
     */
    getFeed(feedName:string):Promise<void> {
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        return api.getAPI('/api/blog/feed/'+feedName, options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);
        })
    }

}
