export const languagesStatePath:string = 'languages/';

export const languagesTypes = {
    actions : {
        SET_DEFAULT_LANGUAGE : 'SET_DEFAULT_LANGUAGE',
        UPDATE_LANGUAGE : 'UPDATE_LANGUAGE',
        CHECK_ROUTE_LANGUAGE: 'CHECK_ROUTE_LANGUAGE',
        CHECK_BUNDLE_LANGUAGE: 'CHECK_BUNDLE_LANGUAGE'
    },
    getters : {
        GET_AVAILABLE_LANGUAGES : 'GET_AVAILABLE_LANGUAGES',
        GET_CURRENT_LANGUAGE : 'GET_CURRENT_LANGUAGE',
        GET_REQUESTED_LANGUAGE : 'GET_REQUESTED_LANGUAGE',
        IS_AVAILABLE_LANGUAGE : 'IS_AVAILABLE_LANGUAGE'
    },
    mutations : {
        SET_REQUESTED_LANGUAGE : 'SET_REQUESTED_LANGUAGE',
        ADD_BEFORE_SET_LANGUAGE_ACTION : 'ADD_BEFORE_SET_LANGUAGE_ACTION',
        ADD_AFTER_SET_LANGUAGE_ACTION : 'ADD_AFTER_SET_LANGUAGE_ACTION',
        SET_AVAILABLE_LANGUAGE : 'SET_AVAILABLE_LANGUAGE'
    },
    COMMON_BUNDLE : 'COMMON_BUNDLE'
}

var languagesPaths: {[x:string]:string} = {};
for(var key of Object.keys(languagesTypes.getters)) {
    languagesPaths[key] = languagesStatePath + key;
}
export const languagesGettersPath = languagesPaths;

export interface Meta {
    title?:string,
    description?:string
}

export const metaStatePath:string = 'meta/';

export const metaTypes = {
    getters : {
        GET_APPLICATION_METAS : 'GET_APPLICATION_METAS',
        GET_ROUTE_METAS : 'GET_ROUTE_METAS',
        GET_FAVICON_METAS : 'GET_FAVICON_METAS',
        GET_STYLES_METAS : 'GET_STYLES_METAS',
        GET_BODY_CLASSES : 'GET_BODY_CLASSES',
        GET_STYLE : 'GET_STYLE',
        GET_PAGE_META_INFO : 'GET_PAGE_META_INFO'
    },
    mutations : {
        ADD_BODY_CLASS : "ADD_BODY_CLASS",
        REMOVE_BODY_CLASS : "REMOVE_BODY_CLASS",
        TOGGLE_BODY_CLASS : "TOGGLE_BODY_CLASS",
        SET_BODY_CLASSES : "SET_BODY_CLASSES",
        SET_BODY_CLASSES_FROM_ROUTE : "SET_BODY_CLASSES_FROM_ROUTE",
        UPDATE_METAS_FROM_TRANSLATIONS : "UPDATE_METAS_FROM_TRANSLATIONS",
        SET_METAS_COMPUTER : "SET_METAS_COMPUTER",
        SET_APPLICATION_METAS : 'SET_APPLICATION_METAS',
        SET_ROUTE_METAS : "SET_ROUTE_METAS",
        SET_FAVICON_METAS : "SET_FAVICON_METAS",
        ADD_STYLE_COMPUTER : "ADD_STYLE_COMPUTER",
        SET_STYLE : "SET_STYLE",
        SET_STYLES_META : "SET_STYLES_META",
        SET_PAGE_META_INFO : "SET_PAGE_META_INFO"
    },
    actions : {
        DEFAULT_METAS_COMPUTER : "DEFAULT_METAS_COMPUTER",
        COMPUTE_METAS : "COMPUTE_METAS",
        COMPUTE_STYLE : "COMPUTE_STYLE",
        UPDATE_ROUTE_BODY_CLASSES : "UPDATE_ROUTE_BODY_CLASSES"
    }
}

var metaPaths: {[x:string]:string} = {};
for(var key of Object.keys(metaTypes.getters)) {
    metaPaths[key] = metaStatePath + key;
}
export const metaGettersPath = metaPaths;


// WARNING: Same as MessageTypes in Message in igotweb-node-api
export enum MessageTypes {
    ERROR = "ERROR",
    WARNING = "WARNING",
	SUCCESS = "SUCCESS",
	MESSAGE = "MESSAGE"
}
// WARNING: Same as Message in igotweb-node-api
export interface Message {
    key:number,
    formattedMessage:string,
    number:number,
    secondNumber?:number,
    type: MessageTypes
}

export const messagesStatePath:string = 'messages/';

export const messagesTypes = {
    actions : {
        
    },
    getters : {
        GET_MESSAGES : 'GET_MESSAGES'
    },
    mutations : {
        ADD_MESSAGES : 'ADD_MESSAGES',
        ADD_GENERIC_MESSAGE : 'ADD_GENERIC_MESSAGE',
        CLEAR_MESSAGES : 'CLEAR_MESSAGES'
    }
}

var messagesPaths: {[x:string]:string} = {};
for(var key of Object.keys(messagesTypes.getters)) {
    messagesPaths[key] = messagesStatePath + key;
}
export const messagesGettersPath = messagesPaths;


export const routerStatePath:string = 'router/';

export const routerTypes = {
    mutations : {
        SET_ROUTES : 'SET_ROUTES',
        ADD_PREVIOUS_ROUTE_SCROLL : 'ADD_PREVIOUS_ROUTE_SCROLL',
        SET_ASYNC_DATA : 'SET_ASYNC_DATA',
        SET_COMPONENTS : 'SET_COMPONENTS',
        ADD_LAYOUT : 'ADD_LAYOUT',
        SET_FRAGMENTS : 'SET_FRAGMENTS',
        SET_LAYOUT_GETTER : 'SET_LAYOUT_GETTER',
        SET_PREVIOUS_ROUTE : 'SET_PREVIOUS_ROUTE',
        ADD_REDIRECT_DATA : 'ADD_REDIRECT_DATA',
        CLEAR_REDIRECT_DATA : 'CLEAR_REDIRECT_DATA'
    },
    getters : {
        GET_ASYNC_DATA_BY_COMPONENT : 'GET_ASYNC_DATA_BY_COMPONENT',
        GET_FRAGMENT_BY_PATH : 'GET_FRAGMENT_BY_PATH',
        GET_ROUTES : 'GET_ROUTES',
        GET_ROUTE_INDEXES_FROM_SHORTNAME : 'GET_ROUTE_INDEXES_FROM_SHORTNAME',
        GET_ROUTE_FROM_SHORTNAME : 'GET_ROUTE_FROM_SHORTNAME',
        GET_PREVIOUS_ROUTE_SCROLL : 'GET_PREVIOUS_ROUTE_SCROLL',
        DEFAULT_LAYOUT_GETTER : 'DEFAULT_LAYOUT_GETTER',
        GET_LAYOUT_NAME_FROM_ROUTE : 'GET_LAYOUT_NAME_FROM_ROUTE',
        GET_LAYOUT : 'GET_LAYOUT',
        GET_PREVIOUS_ROUTE : 'GET_PREVIOUS_ROUTE',
        GET_REDIRECT_DATA : 'GET_REDIRECT_DATA'
    },
    actions : {
        CHECK_ASYNC_DATA_COMPONENT : 'CHECK_ASYNC_DATA_COMPONENT',
        CHECK_ASYNC_DATA_ALL_FOR_ROUTE : 'CHECK_ASYNC_DATA_ALL_FOR_ROUTE',
        CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE : 'CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE',
        CHECK_LAYOUT : 'CHECK_LAYOUT',
        CHECK_ROUTE_FRAGMENTS : 'CHECK_ROUTE_FRAGMENTS'
    }
}

var routerPaths: {[x:string]:string} = {};
for(var key of Object.keys(routerTypes.getters)) {
    routerPaths[key] = routerStatePath + key;
}
export const routerGettersPath = routerPaths;


export const authenticationStatePath:string = 'authentication/';

export const authenticationTypes = {
    actions : {
        AUTH_LOGOUT : "AUTH_LOGOUT",
    },
    getters : {
        HAS_USER_ROLES : 'HAS_USER_ROLES',
        HAS_USER_ROLE : 'HAS_USER_ROLE',
        IS_LOGGED_IN : 'IS_LOGGED_IN',
        GET_USER : 'GET_USER'
    },
    mutations : {
        AUTH_SUCCESS : "AUTH_SUCCESS",
        UPDATE_USER : "UPDATE_USER"
    }
};

var authenticationPaths: {[x:string]:string} = {};
for(var key of Object.keys(authenticationTypes.getters)) {
    authenticationPaths[key] = authenticationStatePath + key;
}
export const authenticationGettersPath = authenticationPaths;