import __Vue from '@fwk-node-modules/vue';
import { Store } from 'vuex';
import { getters as siteGetters, types as siteTypes } from './stores/site';
import { createStore } from './stores/index';
import { Components } from './helpers/components';
import { Utils } from './helpers/utils';
import CmsComponent from './components/CmsComponent.vue';


export let Vue:typeof __Vue // bind on install
let installed:boolean = false;


const isDef = (v:any) => v !== undefined

function init(this: __Vue) {

  const options = this.$options

  // We inject the $shop features
  if(options.parent && options.parent.$cms) {
    this.$cms = options.parent.$cms;
  }
  else if(!this.$cms) {
    if(process.env.CONSOLE == "LOG") {
      console.log("CMS PLUGIN - WE REGISTER $CMS FEATURES TO APP INSTANCE");
    }
    // We create the $blog instance for the parent component
    this.$cms = {
      components : new Components(this),
      utils : new Utils(this)
    }
  }
}

/**
 * registerStore
 * This method has to be called to register the CMS PLUGIN store
 * @param store 
 */
function registerStore(store:Store<any>) {
  if(process.env.CONSOLE == "LOG") {
    console.log("CMS PLUGIN - WE REGISTER THE CMS STORE");
  }
  // We create the cms store
  var cmsStore = createStore();
  // We register the cms module in the store
  store.registerModule("cms", cmsStore, { preserveState: store.state.cms !== undefined });
}

/**
 * populateStore
 * This method has to be called to populate store based on server state
 * @param store 
 */
function populateStore(store:Store<any>) {
  // We check if we have site provided by server to populate the store
  if(store.state.server && store.state.server.site) {
    if(process.env.CONSOLE == "LOG") {
      console.log("CMS PLUGIN - WE COPY THE SITE FROM SERVER");
    }
    store.commit('cms/site/'+siteTypes.mutations.SET_SITE, store.state.server.site)
  }
}

/**
 * install
 * This method install the plugin.
 * The code is based on vue-router (https://github.com/vuejs/vue-router/blob/dev/src/install.js)
 * @param Vue 
 * @param options 
 */
function install(_Vue: typeof __Vue, options:any): void {

  if (Vue && _Vue === Vue && installed) {
    if(process.env.CONSOLE == "LOG") {
      console.log('CMS PLUGIN - ALREADY INSTALLED - Vue.use(CmsPlugin) should be called only once.')
    }
    return
  }
  
  Vue = _Vue
  installed = true;
  
  if(process.env.CONSOLE == "LOG") {
    console.log("CMS PLUGIN - WE INSTALL THE PLUGIN");
  }

  Vue.mixin({ 
    beforeCreate: init,

    methods : {
    },

    // We add some computed properties
    computed : {
      ...siteGetters
    }
  });

  Vue.component('CmsComponent', CmsComponent)

  Vue.$cms = {
    router : {
      beforeEach : Components.routerBeforeEach,
      afterEach : Components.routerAfterEach,
    }
  }  

}

export default {
  install,
  registerStore,
  populateStore
}