<template>
  <validation-provider ref="validator" :name="id" :mode="validationMode" :skipIfEmpty="skipIfEmpty" :rules="computedValidationRules" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label :for="id" :class="{...labelCSSClass}">{{ inputLabel }} <small v-if="required">*</small></label>
      <div :class="{...fieldCSSClass}">
        <textarea v-if="textarea" :id="id" :type="fieldType" :placeholder="inputPlaceholder" :class="{...inputCSSClass, 'required':required, ...classes}" v-model="input" :required="required" :disabled="disabled"></textarea>
        <input v-else-if="fieldType == 'file'" :size="size" :id="id" :type="fieldType" :placeholder="inputPlaceholder" :class="{...inputCSSClass, 'required':required, ...classes}" :required="required" :disabled="disabled" @change="filesChange($event.target.files)" ref="file" />
        <input v-else :id="id" :type="fieldType" :size="size" :step="inputStep" :placeholder="inputPlaceholder" :class="{...inputCSSClass, 'required':required, ...classes}" v-model="input" :required="required" :disabled="disabled"/>
        <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { extend } from "vee-validate";
import GenericInput from '../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class TextField extends mixins<GenericInput<string>>(GenericInput) {

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly textarea!: boolean

  @Prop({
    type: Number,
    required: false,
    default: undefined
  }) readonly size: number|undefined

  @Prop({
    type: Array,
    required: false,
    default: () => []
  }) readonly files!: any[]

  @Prop({
    type: Boolean,
    required: false
  }) readonly disabled!: boolean

  @Prop({
    type: String,
    required: false,
    default: "text"
  }) readonly fieldType!: "text"|"email"|"number"|"password"|"file"

  @Prop({
    type: Number,
    required: false,
    default: 1
  }) readonly step!: number

  get inputStep() {
    if(this.fieldType != "number") {
      return undefined;
    }
    return this.step;
  }
  
  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  get inputPlaceholder() {
    return (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  get skipIfEmpty() {
    if(this.fieldType == "file") {
      return false;
    }
    return true;
  }

  get computedValidationRules() {
    var validationRules:any = {};
    if(this.validationRules) {
      validationRules = {
        ...this.validationRules
      }
    }
    if(this.fieldType == "file") {
      validationRules["fileValidation"] = true;
    }
    else if(this.required) {
      validationRules["required"] = true;
    }
    return validationRules;
  }

  created() {
    if(this.fieldType == "file") {
      this.addFileValidation();
    }
  }

  addFileValidation() {
    var componentInstance = this;
    extend('fileValidation',{
      validate(value, params):Promise<boolean|string> {
        var fieldValue = (componentInstance.$refs.file as HTMLInputElement).value;
        if(componentInstance.required && fieldValue == "") {
          return Promise.resolve(false);
        }
        return Promise.resolve(true);
      }
    });
  }

  mounted() {
    if(this.input != "" && this.$refs.validator) {
      // @ts-ignore
      this.$refs.validator.validate();
    }
    if(this.fieldType == "file") {
      // @ts-ignore
      this.$refs.validator.reset();
    }
  }

  validate() {
    // @ts-ignore
    return this.$refs.validator.validate();
  }

  filesChange(fileList:FileList) {
    var listFiles:any[] = [];
    if(fileList && fileList.length) {
      listFiles = Array.from(fileList);
    }

    // @ts-ignore
    this.$refs.validator.validate(listFiles);
    
    this.$emit('update:files', listFiles);
  };

  @Watch('files', { deep: true })
  onFilesChange(val: any, oldVal: any) {
    if(val.length == 0 && this.fieldType == "file" && this.$refs.file) {
      (this.$refs.file as HTMLInputElement).value = "";
      // @ts-ignore
      this.$refs.validator.reset();
    }
  }

  @Watch('input', { deep: true })
  onInputChange(val: any, oldVal: any) {
    if(this.fieldType == "number") {
      var value = Number(val);
      if(value != NaN) {
        this.$emit('update:value', value);    
        return;
      }
    }
    else {
      this.$emit('update:value', val);
    }
  }

  @Watch('value')
  onValueChange(val: any, oldVal: any) {
    this.input = val;
    // @ts-ignore
    this.$refs.validator.validate(val);
  }
  
}
</script>