import Vue from '@fwk-node-modules/vue';
import * as api from '@fwk-client/utils/api';
import { updateStoreFromBackend } from '../stores';

export class Booking {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }


    /**
     * retrieveBooking
     * This method retrieves a booking
     */
    retrieveBooking(bookingID:string, email?:string):Promise<any> {
        var input = {
            "email" : email
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }
        
        return api.postAPI('/api/hospitality/booking/'+bookingID, input, options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);
            return Promise.resolve(response);
        });
    }

    getUserBookings():Promise<any[]> {
        var input = {
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }
        
        return api.postAPI('/api/hospitality/booking/user-list', input, options).then((response:any) => {
            return Promise.resolve(response.bookings);
        });
    }

}
