import { Module } from '@fwk-node-modules/vuex';
import { Route } from '@fwk-node-modules/vue-router';
import Vue from '@fwk-node-modules/vue';

import { utils as RouterUtils } from '@fwk-client/router/utils';
import { metaTypes as types } from '@fwk-client/store/types';
import { RouteMeta } from '@fwk-client/types/meta';
import { getPublicDynamicPath } from '@fwk-utils/public';
import { getComputedMediaURL } from '@fwk-client/utils/media';

export function createMetaStore () {
  const store:Module<any, any> = {
    namespaced: true,
    state: {
      /**
       * localizedMetas contains for each language the common meta + all route meta loaded
       * ex:
       *  localizedMetas: {
       *    fr: {
       *      bundle : {
       *        key : value
       *      }
       *    }
       *  }
       */
      localizedMetas: {},
      applicationMetas: {}, // The computed application metas
      routeMetas: {}, // The computed Route metas
      style: {}, // The computed styles stored under each key of the object.
      faviconMetas: {}, // The computed link and meta for favicon.
      stylesMetas: {}, // The list of style links stored under each key of the object.
      bodyClassesFromRoute: [], // The body classes associated to a route
      bodyClasses: [], // The stored body classes
      metasComputer: 'meta/' + types.actions.DEFAULT_METAS_COMPUTER,
      styleComputers: [],
      pageMetaInfo : {}
    },
    getters: {
      /**
       * GET_APPLICATION_METAS
       * This getter get the computed application metas stored in the store.
       * @param state - current meta state
       */
      [types.getters.GET_APPLICATION_METAS] (state):any {
        return state.applicationMetas;
      },
      /**
       * GET_ROUTE_METAS
       * This getter get the computed metas stored in the store.
       * @param state - current meta state
       */
       [types.getters.GET_ROUTE_METAS] (state):any {
        return state.routeMetas;
      },
      /**
       * GET_FAVICON_METAS
       * This getter get the computed favicon metas stored in the store.
       * @param state - current meta state
       */
      [types.getters.GET_FAVICON_METAS] (state):any {
        var faviconMetas = state.faviconMetas;
        if(!faviconMetas.meta) { faviconMetas.meta = []; }  
        if(!faviconMetas.links) { faviconMetas.links = []; }  
        return faviconMetas;
      },
      /**
       * GET_STYLES_METAS
       * This getter get the list of styles metas stored in the store.
       * @param state - current meta state
       */
       [types.getters.GET_STYLES_METAS] (state):any {
        var computedStyles = [];
        for(var key in state.stylesMetas) {
          computedStyles.push(...state.stylesMetas[key]);
         }
         return computedStyles;
      },
      /**
       * GET_BODY_CLASSES
       * This getter get the body classes from current route and current store.
       * @param state - current meta state
       */
      [types.getters.GET_BODY_CLASSES] (state):any {
        return [...state.bodyClasses, ...state.bodyClassesFromRoute];
      },
      /**
       * GET_STYLE
       * This getter get the style to be added for the route.
       * @param state - current meta state
       */
       [types.getters.GET_STYLE] (state):any {
         var computedStyle = "";
         for(var key in state.style) {
          computedStyle += state.style[key];
         }
         return computedStyle;
      },
      /**
       * GET_PAGE_META_INFO
       * This getter get the page meta info computed in useGenericPage
       * @param state - current meta state
       */
      [types.getters.GET_PAGE_META_INFO] (state):any {
        return state.pageMetaInfo;
     }

    },
    mutations: {
        [types.mutations.ADD_BODY_CLASS] (state, bodyClass) {
            // We update the store
            if(state.bodyClasses.indexOf(bodyClass) < 0) {
                state.bodyClasses.push(bodyClass);
            }
        },
        [types.mutations.REMOVE_BODY_CLASS] (state, bodyClass) {
            if(state.bodyClasses.indexOf(bodyClass) > -1) {
                state.bodyClasses.splice( state.bodyClasses.indexOf(bodyClass), 1 );
            }
        },
        [types.mutations.TOGGLE_BODY_CLASS] (state, bodyClass) {
            if(state.bodyClasses.indexOf(bodyClass) > -1) {
                state.bodyClasses.splice( state.bodyClasses.indexOf(bodyClass), 1 );
            }
            else {
                state.bodyClasses.push(bodyClass);
            }
        },
        [types.mutations.SET_BODY_CLASSES] (state, payload) {
          // We set the bodyClasses in the store
          state.bodyClasses = payload.bodyClasses;
        },
        [types.mutations.SET_BODY_CLASSES_FROM_ROUTE] (state, payload) {
          // We set the bodyClassesFromRoute in the store
          state.bodyClassesFromRoute = payload.bodyClasses;
        },
        [types.mutations.SET_METAS_COMPUTER] (state, payload) {
          // We set the metas computer in the store
          state.metasComputer = payload.action;
        },
        [types.mutations.SET_ROUTE_METAS] (state, payload) {
          // We set the metas in the store
          state.routeMetas = payload.routeMetas;
        },
        [types.mutations.SET_APPLICATION_METAS] (state, payload) {
          // We set the metas in the store
          state.applicationMetas = payload.applicationMetas;
        },
        [types.mutations.SET_FAVICON_METAS] (state, payload) {
          // We set the metas in the store
          state.faviconMetas = payload.faviconMetas;
        },
        [types.mutations.ADD_STYLE_COMPUTER] (state, payload) {
          if(state.styleComputers.indexOf(payload.action) < 0) {
            state.styleComputers.push(payload.action);
          }
        },
        [types.mutations.SET_STYLE] (state, payload) {
          // We set the style in the store
          state.style[payload.key] = payload.style;
        },
        [types.mutations.SET_STYLES_META] (state, payload) {
          // We set the style in the store
          state.stylesMetas[payload.key] = payload.styles;
        },
        [types.mutations.UPDATE_METAS_FROM_TRANSLATIONS] (state, payload) {

            var {languageCode, meta} = payload;

            if(process.env.CONSOLE == "LOG") {
              console.log("STORE - META - MUTATION - UPDATE_METAS_FROM_TRANSLATIONS - " + languageCode);
            }

            if(meta !== undefined) {
    
                // merge the meta
                if(state.localizedMetas[languageCode]) {
                    state.localizedMetas[languageCode] = {
                        ...state.localizedMetas[languageCode],
                        ...meta
                    }
                }
                else {
                    Vue.set(state.localizedMetas, languageCode, meta);
                }
            }
        },
        [types.mutations.SET_PAGE_META_INFO] (state, payload) {
          // We set the metas in the store
          state.pageMetaInfo = payload.metaInfo;
        },
    },
    actions: {
      /**
       * DEFAULT_METAS_COMPUTER
       * This action is triggered to compute the meta based on current route and language.
       */
      [types.actions.DEFAULT_METAS_COMPUTER] ({state, commit, rootState}, {app}) {
        // The computer is based on the current route
        var route:Route = rootState.route;
        var routeShortName = RouterUtils.getRouteShortNameFromRoute(route);
        // This will be updated each time the language is updated as well.
        const languageCode = rootState.languages.currentLanguageCode;

        // We compute the meta from the store
        var routeMetas = getRouteMetasFromShortName(rootState, routeShortName, languageCode);
        // We populate the social medias meta
        populateRouteSocialMediasMeta(routeMetas, rootState);
        // We update the computed metas
        commit(types.mutations.SET_ROUTE_METAS, {routeMetas})

        // We compute the favicon metas
        var faviconMetas = computeDefaultFaviconMetas();
        // We update the computed favicon mets
        commit(types.mutations.SET_FAVICON_METAS, {faviconMetas});
      },
      /**
       * COMPUTE_METAS
       * This action compute the metas based on the metas computer in store.
       * @param state - current meta state
       * @param getters - current meta getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
      [types.actions.COMPUTE_METAS] ({state, rootState, dispatch}, {app}) {
        // The computer is based on current route and language
        var route:Route = rootState.route;
        const languageCode = rootState.languages.currentLanguageCode;

        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - META - COMPUTE_METAS - " + route.path + " - " + languageCode)
        }

        // We compute the meta from the associated action
        dispatch(state.metasComputer, {app}, { root: true })
      },
      /**
       * COMPUTE_STYLE
       * This action compute the style based on the style computers in store.
       * @param state - current meta state
       * @param getters - current meta getters
       * @param rootState - root state
       * @param rootGetters - root getters
       */
       [types.actions.COMPUTE_STYLE] ({state, rootState, dispatch}, {app}) {
        // The computer is based on current route and language
        var route:Route = rootState.route;

        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - META - COMPUTE_STYLE - " + route.path)
        }

        // We compute the meta from the associated action
        for(var computer of state.styleComputers) {
          dispatch(computer, {app}, { root: true })
        }
      },
    /**
     * UPDATE_ROUTE_BODY_CLASSES
     * This action compute the body class based on route.
     * @param state - current meta state
     * @param getters - current meta getters
     * @param rootState - root state
     * @param rootGetters - root getters
     */
    [types.actions.UPDATE_ROUTE_BODY_CLASSES] ({state, rootState, commit}, {route}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - META - UPDATE_ROUTE_BODY_CLASSES - " + route.path);
        }

        if(route.meta && route.meta.bodyClasses) {
          // We update the route body classes
          commit(types.mutations.SET_BODY_CLASSES_FROM_ROUTE, {
            bodyClasses : [...route.meta.bodyClasses]
          });
        }
        else {
          // We update the route body classes
          commit(types.mutations.SET_BODY_CLASSES_FROM_ROUTE, {
            bodyClasses : []
          });
        }
      },
    }
  };

  return store;
};


/**
 * computeRouteMeta
 * This method computes route meta from list of metas including common ones.
 * @param metas 
 * @param routeShortName 
 * @returns 
 */
export function computeRouteMeta(metas:any, routeShortName:string) {
  // We get the common meta to be used by default if no page specific meta
  var metaCommon:any = {};
  if(metas["common"]) {
    metaCommon = JSON.parse(JSON.stringify(metas["common"]));
  }

  var meta:RouteMeta = metaCommon;
  if(routeShortName) {
    // We check if we have meta for current route
    var metaRoute:RouteMeta = metas[routeShortName];
    if(metaRoute) {
      // We merge with the route specific localized metas
      meta = {...meta, ...metaRoute}
    }
  }

  return meta;
}

/**
 * getRouteMetasFromShortName
 * This method retrieves all the metas associated to a routeShortName from the store (localized resoruces).
 * @param state 
 * @param routeShortName 
 * @param languageCode 
 */
export function getRouteMetasFromShortName(rootState:any, routeShortName:string, languageCode:string):RouteMeta {
  if(process.env.CONSOLE == "LOG") {
    console.log("STORE - META - GET_METAS_FROM_SHORT_NAME - " + routeShortName + " - " + languageCode)
  }
  
  // We get the localized metas coming from the static resources
  var localizedMeta:any = {};
  if(rootState.meta.localizedMetas[languageCode]) {
    localizedMeta = JSON.parse(JSON.stringify(rootState.meta.localizedMetas[languageCode]));
  }

  var meta:RouteMeta = computeRouteMeta(localizedMeta, routeShortName);
  
  return meta;
}

/**
 * populateRouteSocialMediasMeta
 * This method populate social medias meta if not already available based on available meta.
  * Populated metas:
 *  - title
 *  - description
 *  - image (baseURL added if related picture URL)
 * @param metas - the computed RouteMeta
 * @param rootState 
 */
export function populateRouteSocialMediasMeta(metas:RouteMeta, rootState:any) {
  // We create the empty structure
  if(!metas.socialMedias) {
    metas.socialMedias = {};
  }
  
  // We add default socialMedias metas if not provided
  if(metas.title || metas.description) {
    if(metas.title && !metas.socialMedias.title) { metas.socialMedias.title = metas.title; }
    if(metas.description && !metas.socialMedias.description) { metas.socialMedias.description = metas.description; }
  }

  // We get the baseURL
  var baseURL = rootState.server && rootState.server.baseURL ? rootState.server.baseURL : undefined;

  if(metas.socialMedias && metas.socialMedias.image) {
    // We first compute the media URL (statics path or public path)
    var staticsDomain = (rootState.server && rootState.server.staticsDomain) ? rootState.server.staticsDomain : undefined;
    metas.socialMedias.image = getComputedMediaURL(metas.socialMedias.image, staticsDomain);
    
    // We check if the image is full URL or public relative path.
    if(!metas.socialMedias.image.startsWith("http") && baseURL) {
      // We append the baseURL from server store
      metas.socialMedias.image = baseURL + metas.socialMedias.image;
    }
  }
  
  if(baseURL) {
    // We add the URL without language code
    metas.socialMedias.url = baseURL + RouterUtils.getRoutePathWithLanguage(rootState.route);
  }

  // We hardcode the og:type (should be article in case of blog post)
  metas.socialMedias.type = "website";
}

/**
 * computeDefaultFaviconMetas
 * This method computes the default favicon metas which are expted to be stored in public path / favicon folder.
 * Favicon elements provided by https://www.favicon-generator.org/ 
 */
export function computeDefaultFaviconMetas(faviconPath?:string) {
  // We get the default path from public folder of the application
  var pdpPrefix = getPublicDynamicPath();
  var path = pdpPrefix + "/favicon";

  // In case a favicon path is provided by the site
  if(faviconPath) {
    path = faviconPath;
  }

  var links:any[] = [];
  var meta:any[] = [];

  if(path.endsWith(".ico")) {
    links = [
      { rel: 'shortcut icon', type: 'image/x-icon', href: path }
    ]
  }
  else {
    links = [
      { rel: 'apple-touch-icon', sizes: '57x57', href: path + "/apple-icon-57x57.png" },
      { rel: 'apple-touch-icon', sizes: '60x60', href: path + "/apple-icon-60x60.png" },
      { rel: 'apple-touch-icon', sizes: '72x72', href: path + "/apple-icon-72x72.png" },
      { rel: 'apple-touch-icon', sizes: '76x76', href: path + "/apple-icon-76x76.png" },
      { rel: 'apple-touch-icon', sizes: '114x114', href: path + "/apple-icon-114x114.png" },
      { rel: 'apple-touch-icon', sizes: '120x120', href: path + "/apple-icon-120x120.png" },
      { rel: 'apple-touch-icon', sizes: '144x144', href: path + "/apple-icon-144x144.png" },
      { rel: 'apple-touch-icon', sizes: '152x152', href: path + "/apple-icon-152x152.png" },
      { rel: 'apple-touch-icon', sizes: '180x180', href: path + "/apple-icon-180x180.png" },
  
      { rel: 'icon', type: "image/png",  sizes: '192x192', href: path + "/android-icon-192x192.png" },
      { rel: 'icon', type: "image/png",  sizes: '32x32', href: path + "/favicon-32x32.png" },
      { rel: 'icon', type: "image/png",  sizes: '96x96', href: path + "/favicon-96x96.png" },
      { rel: 'icon', type: "image/png",  sizes: '16x16', href: path + "/favicon-16x16.png" },
  
      { rel: 'manifest', href: path + "/manifest.json" }
    ];
  
    meta = [
      { name:"msapplication-TileColor", content:"#ffffff" },
      { name:"msapplication-TileImage", content:path + "/ms-icon-144x144.png" },
      { name:"theme-color", content:"#ffffff" }
    ]
  }
    

  var faviconMetas:any = {
    links: links,
    meta: meta
  }
  return faviconMetas;
}