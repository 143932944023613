import __Vue from '@fwk-node-modules/vue';
import { Store } from 'vuex';
import { Blog } from './helpers/blog';
import { Feed } from './helpers/feed';
import { Post } from './helpers/post';
import { createStore, getters as blogGetters, types as blogTypes } from './stores';
import { authenticationTypes, languagesTypes } from '@fwk-client/store/types';


let Vue:typeof __Vue // bind on install
let installed:boolean = false;

function init(this: __Vue) {

  const options = this.$options

  // We inject the $shop features
  if (options.parent && options.parent.$blog) {
    this.$blog = options.parent.$blog;
  }
  else if(!this.$blog) {
    if(process.env.CONSOLE == "LOG") {
      console.log("BLOG PLUGIN - WE REGISTER $BLOG FEATURES TO APP INSTANCE");
    }
    // We create the $blog instance for the parent component
    this.$blog = {
      blog: new Blog(this),
      feed: new Feed(this),
      post: new Post(this)
    }
  }
}

/**
 * registerStore
 * This method has to be called to register the CMS PLUGIN store
 * @param store 
 */
 function registerStore(store:Store<any>) {
  if(process.env.CONSOLE == "LOG") {
    console.log("BLOG PLUGIN - WE CREATE THE BLOG STORE");
  }
  
  // We create the blog store
  var blogStore = createStore();
  // We register the blog module in the store
  store.registerModule("blog", blogStore, { preserveState: store.state.blog !== undefined });

  // We add watcher if authentication store is available to clear list of posts after login
  if(store.hasModule("authentication")) {
    store.watch(
      (state, getters) => {
        return getters['authentication/' + authenticationTypes.getters.IS_LOGGED_IN];
      },
      (val:boolean, oldVal:boolean) => {
          if(val === true) {
            store.commit('blog/' + blogTypes.mutations.CLEAR_FEEDS);
          }
      }      
    )
    
  }

}

/**
 * install
 * This method install the plugin.
 * The code is based on vue-router (https://github.com/vuejs/vue-router/blob/dev/src/install.js)
 * @param Vue 
 * @param options 
 */
function install(_Vue: typeof __Vue): void {

  if (Vue && _Vue === Vue || installed) {
    if(process.env.CONSOLE == "LOG") {
      console.log('BLOG PLUGIN - ALREADY INSTALLED - Vue.use(BlogPlugin) should be called only once.')
    }
    return
  }
  
  Vue = _Vue
  installed = true;
  
  if(process.env.CONSOLE == "LOG") {
    console.log("BLOG PLUGIN - WE INSTALL THE PLUGIN");
  }

  Vue.$blog = {
    router : {
      beforeEach : Blog.routerBeforeEach
    }
  }

  Vue.mixin({ 
    beforeCreate: init,

    methods : {
      
    },

    // We add some computed properties
    computed : {
      ...blogGetters
    }
  });

}

export default {
  install,
  registerStore
}