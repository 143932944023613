<template>
  <div v-if="isModalInDOM" class="modal fade" tabindex="-1" ref="modal">
    <div :class="modalCssClass">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
// @ts-ignore
import { Modal as BSModal } from 'bootstrap';

@Component({
  components: {}
})
export default class Modal extends Vue {

  @Prop({
    type: String,
    required: false,
    default: ''
  }) readonly title!: string

  @Prop({
    type: String,
    required: false,
    default: ''
  }) readonly size!: "sm"|"lg"|"xl"|""

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly value!: boolean

  isModalInDOM:boolean = false;

  get modalCssClass() {
    var cssClass = 'modal-dialog';
    if(this.size != '') {
      cssClass += ' modal-'+this.size
    }
    return cssClass;
  }

  show() {
    // We add the modal in the DOM
    this.isModalInDOM = true;
    // We wait for DOM to be rendered
    Vue.nextTick(() => {
      // We create the instance of the modal
      var modalInstance = BSModal.getOrCreateInstance(this.$refs.modal as HTMLElement);
      // We attach the hidden event
      (this.$refs.modal as HTMLElement).addEventListener('hidden.bs.modal', (event:Event) => {
        // We destroy the modal instance
        modalInstance.dispose();
        // We remove the modal from the DOM
        this.isModalInDOM = false;
        // We update the v-model
        this.$emit('input', false);
      });

      // We show the modal
      modalInstance.show();
      // We update the v-model
      this.$emit('input', true);
    })
  }

  hide() {
    // We get the instance of the modal
    var modalInstance = BSModal.getOrCreateInstance(this.$refs.modal as HTMLElement);
    modalInstance.hide();
  }

  @Watch('value', { deep: true })
  onOpenedChange(value: any, oldVal: any) {
    if(value) {
      this.show();
    }
    else {
      this.hide();
    }
  }
  
}
</script>