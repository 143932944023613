export {};

/**********************************************************************
 * NEEDED POLYFILLS
 **********************************************************************/

declare global {
    interface HTMLElement {
        hasClass(className: string):boolean;
        removeClass(className: string):void;
        addClass(className: string):void;
        height(val?:number|string|Function):number;
		outerHeight():number;
        width(val?:number|string|Function):number;
		outerWidth():number;
        find(selector:string):HTMLElement[];
        attr(attribute:string, value?:any):string|null;
        parents(selector:string):HTMLElement[];
        $children(selector:string):HTMLElement[];
        html(content:any):void;
        next():HTMLElement|null;
        css(style:any):any;
        offset():any;
    }
    interface Array<T> {
        each(callback:Function):void;
    }
    interface Window {
        scrollTop():number;
    }
}


if(process.env.VUE_ENV == 'client') {

    /*-------------------------------------------------------------------------------*/
    /*	UPDATE PROCESS: Compare functions.js file between versions                   */
    /*  this method for requestAnimationFrame is on top of the file	                 */
    /*-------------------------------------------------------------------------------*/

    (function() {
        var lastTime = 0;
        var vendors = ['ms', 'moz', 'webkit', 'o'];
        for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
            // @ts-ignore
            window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
            // @ts-ignore
            window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame'] || window[vendors[x]+'CancelRequestAnimationFrame'];
        }
    
        if (!window.requestAnimationFrame)
            window.requestAnimationFrame = function(callback:FrameRequestCallback) {
                var currTime = new Date().getTime();
                var timeToCall = Math.max(0, 16 - (currTime - lastTime));
                var id = window.setTimeout(function() { callback(currTime + timeToCall); },
                  timeToCall);
                lastTime = currTime + timeToCall;
                return id;
            };
    
        if (!window.cancelAnimationFrame)
            window.cancelAnimationFrame = function(id) {
                clearTimeout(id);
            };
    }());

    /*-------------------------------------------------------------------------------*/
    /*	JQUERY VANILLA JS EQUIVALENT TO FACILITATE REWRITTING OF METHODS             */
    /*-------------------------------------------------------------------------------*/

    if (!Array.prototype.each) {
        Array.prototype.each = function(callback:Function) {
            this.forEach(function(element) {
                callback.call(element);
            })
        }
    }
    
    if (!HTMLElement.prototype.html) {
        HTMLElement.prototype.html = function(content:any):void {
            var el:HTMLElement = this;
            el.innerHTML = content;
        };
    }

    if (!HTMLElement.prototype.find) {
        HTMLElement.prototype.find = function(selector:string):HTMLElement[] {
            var el:HTMLElement = this;
            return Array.from(el.querySelectorAll(selector)) as HTMLElement[];
        };
    }

    if (!HTMLElement.prototype.parents) {
        HTMLElement.prototype.parents = function(selector:string):HTMLElement[] {
            var el:HTMLElement = this;
            var parents = [];
            for (var p = el && el.parentElement; p; p = p.parentElement) {
                parents.push(p);
            }
            return parents.filter(element => {
                return element.matches(selector);
            })
        };
    }

    if (!HTMLElement.prototype.$children) {
        HTMLElement.prototype.$children = function(selector:string):HTMLElement[] {
            var el:HTMLElement = this;
            if(selector && selector != "") {
                return  Array.from(el.querySelectorAll(':scope > '+selector)) as HTMLElement[];
            }
            else {
                return  Array.from(el.children) as HTMLElement[];
            }
        };
    }

    if(!HTMLElement.prototype.next) {
        HTMLElement.prototype.next = function():HTMLElement|null {
            var el:HTMLElement = this;
            return el.nextElementSibling as (HTMLElement | null)
        }
    }

    if(!HTMLElement.prototype.matches) {
        HTMLElement.prototype.matches = function(selector:string):boolean {
            var el:HTMLElement = this;
            // @ts-ignore
            return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector);
        }
    }

    if (!HTMLElement.prototype.attr) {
        HTMLElement.prototype.attr = function(attribute:string,value?:any):string|null {
            var el:HTMLElement = this;
            if(value) {
                el.setAttribute(attribute, value);
            }
            return el.getAttribute(attribute);
        };
    }

    if (!HTMLElement.prototype.hasClass) {
        HTMLElement.prototype.hasClass = function(className:string) {
            var el:HTMLElement = this;
            return el.className.indexOf(className) > -1;
        };
    }

    if (!HTMLElement.prototype.removeClass) {
        HTMLElement.prototype.removeClass = function(className?:string) {
            var el:HTMLElement = this;
			if(className) {
				var classesToRemove = className.split(/ +/);
				el.classList.remove(...classesToRemove);
			}
			else {
				// We remove all classes
				el.setAttribute('class','');
			}
            
        };
    }

    if (!HTMLElement.prototype.addClass) {
        HTMLElement.prototype.addClass = function(className:string) {
            var el:HTMLElement = this;
			if(className) {
				var classesToAdd = className.split(/ +/);
            	el.classList.add(...classesToAdd);
			}
        };
    }

    if(!HTMLElement.prototype.height) {
        HTMLElement.prototype.height = function(val?:number|string|Function) {
            var el:HTMLElement = this;
            if(val) {
                if (typeof val === "function") val = val();
                if (typeof val === "string") el.style.height = val;
                else el.style.height = val + "px";
            }
            return parseFloat(getComputedStyle(el, null).height.replace("px", ""));
        };
    }

	if(!HTMLElement.prototype.outerHeight) {
        HTMLElement.prototype.outerHeight = function() {
            var el:HTMLElement = this;
            return el.offsetHeight;
        };
    }

    if(!HTMLElement.prototype.width) {
        HTMLElement.prototype.width = function(val?:number|string|Function) {
            var el:HTMLElement = this;
            if(val) {
                if (typeof val === "function") val = val();
                if (typeof val === "string") el.style.height = val;
                else el.style.width = val + "px";
            }
            return parseFloat(getComputedStyle(el, null).width.replace("px", ""));
        };
    }

	if(!HTMLElement.prototype.outerWidth) {
        HTMLElement.prototype.outerWidth = function() {
            var el:HTMLElement = this;
            return el.offsetWidth;
        };
    }

	

    if(!HTMLElement.prototype.offset) {
        HTMLElement.prototype.offset = function() {
            var el:HTMLElement = this;
            var rect = el.getBoundingClientRect();
            var offset = { 
                top: rect.top + window.scrollY, 
                left: rect.left + window.scrollX, 
            };
            return offset;
        };
    }

    if (!HTMLElement.prototype.css) {
        HTMLElement.prototype.css = function(style:any):any {
            var el:HTMLElement = this;
            if(typeof style === "object") {
                Object.keys(style).map((key:string) => {
                    // @ts-ignore
                    el.style[key] = style[key]
                })
            }
            if(typeof style === "string") {
                // @ts-ignore
                return el.style[style];
            }
        };
    }

	if (!HTMLElement.prototype.after) {
        HTMLElement.prototype.after = function(html:string):any {
            var el:HTMLElement = this;
            el.insertAdjacentHTML('afterend', html);
        };
    }

	
    
    if(!Window.prototype.scrollTop) {
        Window.prototype.scrollTop = function() {
            return window.scrollY;
        }
    }   
}