import Vue from '@fwk-node-modules/vue';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';

import toastr from 'toastr';

/**
 * toastrMessages
 * This method display all messages in messages store within toastr notification and clear the messages
 * from the store.
 * This needs to be added in App.vue
 *   import { toastrMessages } from '@fwk-client/utils/messages';
 *   import { messagesTypes, Message } from '@fwk-client/store/types';
 * 
 *   get messages():Message[] {
 *     return this.$store.getters['messages/' + messagesTypes.getters.GET_MESSAGES];
 *   }
 * 
 *   mounted() {
 *     if(this.messages.length > 0) {
 *       toastrMessages(this);
 *     }
 *   }
 * 
 *   @Watch('messages')
 *   onMessagesChange(messages:Message[], previous:Message[]) {
 *     toastrMessages(this);
 *   }
 * 
 * This needs to be added in client/hooks.ts
 *   // We import the toastr style
 *   import 'toastr/toastr.scss';
 *
 * @param app - the vue application
 */
export function toastrMessages(app:Vue) {
    var messages = app.$store.getters['messages/' + messagesTypes.getters.GET_MESSAGES];
    if(messages.length > 0) {
        toastr.options = {
            progressBar: true
        };
        for(var message of messages) {
            if(message.type == MessageTypes.ERROR) {
                toastr.error(message.formattedMessage);
            }
            else if(message.type == MessageTypes.SUCCESS) {
                toastr.success(message.formattedMessage);
            }
            else if(message.type == MessageTypes.WARNING) {
                toastr.warning(message.formattedMessage)
            }
        }
        // We clear the messages as consummed
        app.$store.commit('messages/'+messagesTypes.mutations.CLEAR_MESSAGES);
    }
}