import Vue from '@fwk-node-modules/vue';


export class Delivery {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    getOrderDeliveryType(order:any) {
        var methodsOfDelivery = order.methodsOfDelivery;
        if(methodsOfDelivery && methodsOfDelivery.length > 0) {
            return methodsOfDelivery[0].type;
        }
        return null;
    }


    getDeliveryTitle(methodOfDelivery:any) {
        var title = "";
        if(methodOfDelivery.title) {
            title = this.$vm.getLocalizedText(methodOfDelivery.title);
        }
        else {
            switch(methodOfDelivery.type) {
                case "PICKUP":{
                    title = this.$vm.$t("shop.deliveries.pickup.title") as string;
                    break;
                }
                case "SHIPPING":{
                    title = this.$vm.$t("shop.deliveries.shipping.title") as string;
                    break;
                }
            }
        }

        return title;
    }

    /**
     * getDeliveryTypeTitle
     * Get title from a list of methods of delivery of the same type
     * @param methodsOfDelivery - the list of methods of delivery of the same type
     * @returns 
     */
    getDeliveryTypeTitle(methodsOfDelivery:any[]) {
        var title = "";
        if(methodsOfDelivery.length == 1) {
            title = this.getDeliveryTitle(methodsOfDelivery[0]);
        }
        else if(methodsOfDelivery.length > 1) {
            var type = methodsOfDelivery[0].type;
            switch(type) {
                case "PICKUP":{
                    title = this.$vm.$t("shop.deliveries.pickup.title") as string;
                    break;
                }
                case "SHIPPING":{
                    title = this.$vm.$t("shop.deliveries.shipping.title") as string;
                    break;
                }
            }
        }

        return title;
    }

}
