import { elements } from './canvas.elements';

export function getAttribute(element:HTMLElement|null, attribute:string, defaultValue?:string|number):any {
	var value = (element && element.hasAttribute(attribute)) ? element.getAttribute(attribute)! : defaultValue;
	if(value && defaultValue && typeof defaultValue == "number" && typeof value == "string") {
		value = parseInt(value);
	}
	return value;
}

export function anchorLink(path:string, headerOffset:number = 0) {

	var {$body} = elements;

	if( !($body.hasClass('device-xl') || $body.hasClass('device-lg') )) {
		headerOffset = 0;
	}

	if(path && path == "#") {
		// We are on top of the page link
		window.scrollTo({top: 0, behavior: 'smooth'});
	}
	else if(path && path.startsWith("#")) {
		// We are on anchor link

		const yOffset = -headerOffset; 
		const targetElement = document.querySelector(path);
		if(!targetElement) {
			console.error("The anchor does not exist on the page: "+path);
			return;
		}

		const boundingClientRectTop = targetElement.getBoundingClientRect().top
		const windowPageYOffset = window.pageYOffset;
		const y = boundingClientRectTop + windowPageYOffset + yOffset;

		window.scrollTo({top: y, behavior: 'smooth'});
	}
}