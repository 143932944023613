import { Module } from '@fwk-node-modules/vuex';
import { containerUtils } from '@fwk-client/modules/shop/stores';
import { BookingGetters } from '../vue';


export const types = {
  getters : {
    // CONTAINER COMMON GETTERS
    GET_BOOKING : 'GET_BOOKING',
    GET_BOOKING_PRODUCTS : 'GET_BOOKING_PRODUCTS',
    GET_BOOKING_PRODUCTS_AMOUNT : 'GET_BOOKING_PRODUCTS_AMOUNT',
    // BOOKING SPECIFIC GETTERS
    GET_BOOKING_TOTAL_AMOUNT : 'GET_BOOKING_TOTAL_AMOUNT'
  },
  mutations : {
    // CONTAINER COMMON SETTERS
    SET_BOOKING : 'SET_BOOKING',
  },
  actions : {}
}

export const statePath:string = 'hospitality/booking/';

export function createBookingStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      booking : null, // We store the booking information
    },
    getters: {
      /**
       *  CONTAINER COMMON GETTERS
       */ 
      [types.getters.GET_BOOKING](state) { return state.booking; },
      [types.getters.GET_BOOKING_PRODUCTS](state) {
        if(state.booking && state.booking.products) {
          return state.booking.products;
        }
        return [];
      },
      [types.getters.GET_BOOKING_PRODUCTS_AMOUNT](state) {
        return containerUtils.getProductsAmount(state.booking);
      },
      [types.getters.GET_BOOKING_TOTAL_AMOUNT](state) {
        return utils.getBookingTotalAmount(state.booking);
      },
    },
    mutations: {
      [types.mutations.SET_BOOKING] (state, booking) { state.booking = booking; },
    },
    actions: {}
  }
  return store;
};

export const utils = {
  getBookingTotalAmount(booking:any){
    if(booking && booking.totalAmount) {
      return booking.totalAmount;
    }
    return null;
  }
}

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;

export const getters = {
  $hospitality_booking : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_BOOKING]];
    }
  },
  $hospitality_bookingProducts : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_BOOKING_PRODUCTS]];
    },
  },
  $hospitality_bookingProductsAmount : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_BOOKING_PRODUCTS_AMOUNT]];
    }
  },
  $hospitality_bookingTotalAmount : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_BOOKING_TOTAL_AMOUNT]];
    }
  },
}