import { getPath } from "@igotweb-node-api-utils/misc";
import { languagesTypes } from '@fwk-client/store/types';

const languages:any = {
    "en" : "en_US",
    "fr" : "fr_FR"
}

export interface MessengerOptions {
    themeColor?:string;
}

export function init(app:Vue, options?:MessengerOptions) {

    var messengerID:any = getPath(app.$store.state, "server.configuration.fwk.messenger.id");
    if(messengerID && (typeof document !== 'undefined')) {

        var currentLanguageCode = app.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
        var themeColor = (options && options.themeColor) ? options.themeColor : undefined;

        createFacebookNodes(messengerID, themeColor);

        // messengerID = 103380325099099

        initFBSdk(currentLanguageCode);

        app.$store.watch(
            (state, getters) => {
                return getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
            },
            (val:string, oldVal:string) => {
                createFacebookNodes(messengerID, themeColor);
                initFBSdk(val);
            }
        )
    }
}

const createFacebookNodes = (messengerID:string, themeColor?:string) => {
    var fbRoot = document.getElementById("fb-root");
    var fbCustomerChat = document.getElementById("fb-customer-chat");
    if(fbRoot && fbRoot.parentNode) {
        // Delete fb-root to let Facebook SDK create it again
        fbRoot.parentNode.removeChild(fbRoot)
    }
    
    fbRoot = document.createElement('div');
    fbRoot.id = "fb-root";
    document.body.prepend(fbRoot);

    fbCustomerChat = document.createElement('div');
    fbCustomerChat.id = "fb-customer-chat";
    fbCustomerChat.setAttribute("page_id", messengerID);
    fbCustomerChat.setAttribute("attribution", "biz_inbox");
    if(themeColor) {
        fbCustomerChat.setAttribute("theme_color", themeColor) 
    }
    fbCustomerChat.className = "fb-customerchat"
    
    document.body.prepend(fbCustomerChat);
}

const initFBSdk = (languageCode:string) => {

    var messengerLanguageCode:string = (languages[languageCode]) ? languages[languageCode] : languages["fr"];

    delete (window as any).FB;

    (window as any).fbAsyncInit = () => {
        (window as any).FB.init({
            xfbml            : true,
            version          : 'v16.0'
        });
    };

    (function(d, s, id) {
        var js = d.getElementById(id) as HTMLScriptElement;
        var head = d.getElementsByTagName('head')[0];
        if (js && js.parentNode) { js.parentNode.removeChild(js) };
        js = d.createElement(s) as HTMLScriptElement; js.id = id;
        js.src = 'https://connect.facebook.net/'+messengerLanguageCode+'/sdk/xfbml.customerchat.js';
        head.appendChild(js);
      }(document, 'script', 'facebook-jssdk'));

}