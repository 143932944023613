import { getPublicDynamicPath } from '@fwk-utils/public';

/**
 * getComputedMediaURL
 * @param mediaURL 
 * @param staticsDomain - should be provided from server store : this.$vm.$store.state.server.staticsDomain
 * @returns 
 */
export function getComputedMediaURL(mediaURL:string, staticsDomain:string) {
    // We check if the URL requested is public relative path.
    var publicPrefix = /^@public\//i;
    if (publicPrefix.test(mediaURL)) {
        var publicPrefixPath = getPublicDynamicPath();
        // We add the public dynamic path to the relative URL if available and if not already in the relative path
        mediaURL = mediaURL.replace(publicPrefix, publicPrefixPath + "/");
    }

    // We check if the URL requested is static relative path.
    var staticPrefix = /^@static\//i;
    if (staticPrefix.test(mediaURL)) {
        var staticPrefixPath = (staticsDomain && staticsDomain != "") ? staticsDomain + '/' : '';
        // We add the static domain to the relative URL (it includes the https protocol)
        mediaURL = mediaURL.replace(staticPrefix, staticPrefixPath);
    }
    return mediaURL;
}