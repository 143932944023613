import Vue from '@fwk-node-modules/vue';
import * as api from '@fwk-client/utils/api';
import { types, updateStoreFromBackend } from '@fwk-client/modules/shop/stores';
import { types as cartTypes } from '@fwk-client/modules/shop/stores/cart';
import { ContactInterface } from '@fwk-client/modules/shop/helpers/contact';
import { Price } from '@fwk-client/modules/shop/vue';
import * as gtag from '../utils/gtag';

export class Cart {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }


    /**
     * toggleSideCart
     * Toggle display of the side cart
     */
    public toggleSideCart() {
        this.$vm.$store.commit('shop/' + types.mutations.TOGGLE_TOP_CART_DISPLAY);
    }

    /**
     * setSideCartDisplayed
     * Set display of the side cart
     */
    public setSideCartDisplayed(isDisplayed:boolean) {
        this.$vm.$store.commit('shop/' + types.mutations.SET_TOP_CART_DISPLAYED, isDisplayed);
    }

    /**
     * refreshCart
     * This method refresh the cart from backend.
     */
    refreshCart():Promise<void> {
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('shop/' + types.mutations.START_CART_LOADING);

        return api.getAPI('/api/shop/cart', options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('shop/' + types.mutations.STOP_CART_LOADING);
        })
    }

    /**
     * addProduct
     * This method add a product and associated quantity to cart
     */
    addProduct(product:any, quantity:number):Promise<any> {
        var input = {
            "quantity" : quantity,
            "productID" : product._id
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('shop/' + types.mutations.START_CART_PRODUCTS_LOADING);

        let path = '/api/shop/cart/product/add';
        
        return api.postAPI(path, input, options).then((response:any) => {

            // We send analytics
            gtag.addProductInCart(product, quantity, this.$vm);

            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('shop/' + types.mutations.STOP_CART_PRODUCTS_LOADING);
            return Promise.resolve(response);
        });
    }

    /**
     * removeProduct
     * This method removes a product from the cart
     */
    removeProduct(product:any) {
        var input = {
            "productID" : product._id
        }

        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('shop/' + types.mutations.START_CART_PRODUCTS_LOADING);
        
        return api.postAPI('/api/shop/cart/product/remove', input, options).then((response:any) => {

            // We send analytics
            gtag.removeProductInCart(product, this.$vm);

            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('shop/' + types.mutations.STOP_CART_PRODUCTS_LOADING);
            return Promise.resolve(response);
        });
    }

    /**
     * updateProduct
     * This method update the quantity of a product in the cart.
     */
    updateProduct(product:any, quantity:number) {
        var input = {
            "quantity" : quantity,
            "productID" : product._id
        }

        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('shop/' + types.mutations.START_CART_PRODUCTS_LOADING);
        
        return api.postAPI('/api/shop/cart/product/update', input, options).then((response:any) => {

            // We send analytics
            gtag.updateProductInCart(product, quantity, this.$vm);

            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('shop/' + types.mutations.STOP_CART_PRODUCTS_LOADING);
            return Promise.resolve(response);
        });
    }

    /**
     * addActivity
     * This method add an activity and associated information to cart
     */
    addActivity(product:any, numberOfPersons:number, startDate:Date):Promise<any> {
        var input = {
            "numberOfPersons" : numberOfPersons,
            "startDate" : startDate,
            "activityID" : product._id
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('shop/' + types.mutations.START_CART_PRODUCTS_LOADING);

        let path = '/api/shop/cart/activity/add';
        
        return api.postAPI(path, input, options).then((response:any) => {

            // We send analytics
            gtag.addActivityInCart(product, numberOfPersons, startDate, this.$vm);

            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('shop/' + types.mutations.STOP_CART_PRODUCTS_LOADING);
            return Promise.resolve(response);
        });
    }

    /**
     * removeActivity
     * This method removes an activity from the cart
     */
    removeActivity(product:any) {
        var input = {
            "activityID" : product._id
        }

        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('shop/' + types.mutations.START_CART_PRODUCTS_LOADING);
        
        return api.postAPI('/api/shop/cart/activity/remove', input, options).then((response:any) => {

            // We send analytics
            gtag.removeActivityInCart(product, this.$vm);

            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('shop/' + types.mutations.STOP_CART_PRODUCTS_LOADING);
            return Promise.resolve(response);
        });
    }

    /**
     * updateProduct
     * This method update the quantity of a product in the cart.
     */
    updateActivity(product:any, numberOfPersons:number, startDate:Date) {
        var input = {
            "numberOfPersons" : numberOfPersons,
            "startDate" : startDate,
            "productID" : product._id
        }

        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('shop/' + types.mutations.START_CART_PRODUCTS_LOADING);
        
        return api.postAPI('/api/shop/cart/activity/update', input, options).then((response:any) => {

            // We send analytics
            gtag.updateActivityInCart(product, numberOfPersons, startDate, this.$vm);

            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('shop/' + types.mutations.STOP_CART_PRODUCTS_LOADING);
            return Promise.resolve(response);
        });
    }

    /**
     * updateContact
     * This method updates contact information in the cart.
     */
    updateContact(contact:ContactInterface) {

        var cart = this.$vm.$store.getters['shop/cart/' + cartTypes.getters.GET_CART];
        var input = {
            "shopID" : cart.shop._id,
            "contact" : contact
        }

        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        this.$vm.$store.commit('shop/' + types.mutations.START_CART_CONTACT_LOADING);
        
        return api.postAPI('/api/shop/cart/contact/update', input, options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);
            this.$vm.$store.commit('shop/' + types.mutations.STOP_CART_CONTACT_LOADING);
            return Promise.resolve(response);
        });
    }

    addPromoCode(promoCode:string) {
        var promoCode = promoCode.toUpperCase();

        var cart = this.$vm.$store.getters['shop/cart/' + cartTypes.getters.GET_CART];
        var input = {
            "shopID" : cart.shop._id,
            "promoCode" : promoCode
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }
        
        return api.postAPI('/api/shop/cart/promo-code/add', input, options).then((response:any) => {

            // We send analytics
            gtag.addPromoCodeInCart(promoCode, this.$vm);

            updateStoreFromBackend(response, this.$vm.$store);
            return Promise.resolve(response);
        });
    }

    public startCheckout() {
        // We send analytics
        gtag.startCheckout(this.$vm);
    }

    public selectTypeOfDelivery(type:string|null) {
        // We send analytics
        gtag.selectTypeOfDelivery(type, this.$vm);
        // We update the store
        this.$vm.$store.commit('shop/' + types.mutations.SET_SELECTED_METHOD_OF_DELIVERY_TYPE, type);
    }

    public getDeliveryPrice():Price|null {
        var deliveryType = this.$vm.$store.getters['shop/' + types.getters.GET_SELECTED_METHOD_OF_DELIVERY_TYPE];
        if(deliveryType != null) {
            var deliveryPrice = this.$vm.$shop_cartPriceForDeliveryType(deliveryType);
            if(deliveryPrice && deliveryPrice.price) {
                return deliveryPrice.price;
            }
        }
        return null;
    }

    public selectMethodOfPayment(index:number|null) {

        // We send analytics
        gtag.selectMethodOfPayment(index, this.$vm);
        
        this.$vm.$store.commit('shop/' + types.mutations.SET_SELECTED_METHOD_OF_PAYMENT_INDEX, index);
    }

    public getTotalPrice():Price|null {
        var totalPrice = {
            ...this.$vm.$shop_cartProductsAmount
        }
        // In case we have promo codes applied, we need to use the reduced products amount to calculate the total amount
        if(this.$vm.$shop_cartPromoCodes && this.$vm.$shop_cartPromoCodes.length > 0) {
            totalPrice = {
                ...this.$vm.$shop_cart.promoCodesReduction.reducedProductsAmount
            }
        }

        // We check if we have products in cart
        var products = this.$vm.$shop_cartProducts.filter((product:any) => product.type == 'PRODUCT');
        var activities = this.$vm.$shop_cartProducts.filter((product:any) => product.type == 'ACTIVITY');
        var deliveryType = this.$vm.$store.getters['shop/' + types.getters.GET_SELECTED_METHOD_OF_DELIVERY_TYPE];
        if(deliveryType != null) {
            var deliveryPrice = this.$vm.$shop_cartPriceForDeliveryType(deliveryType);
            if(deliveryPrice && deliveryPrice.price) {
                totalPrice.amount += deliveryPrice.price.amount;
                totalPrice.nonDiscountedAmount +=  deliveryPrice.price.nonDiscountedAmount;
            }
            return totalPrice;
        }
        else if(products.length == 0 && activities.length > 0) {
            return totalPrice;
        }
        return null;
    }

    /**
     * checkout
     * This method checkout the cart.
     */
    checkout() {
        var cart = this.$vm.$store.getters['shop/cart/' + cartTypes.getters.GET_CART];
        var input = {
            "shopID" : cart.shop._id,
            "contact" : this.$vm.$store.getters['shop/' + types.getters.GET_CONTACT],
            "methodOfDeliveryType" : this.$vm.$store.getters['shop/' + types.getters.GET_SELECTED_METHOD_OF_DELIVERY_TYPE],
            "methodOfPaymentIndex" : this.$vm.$store.getters['shop/' + types.getters.GET_SELECTED_METHOD_OF_PAYMENT_INDEX],
        }

        var options:api.ApiVueOptions =  {
            app: this.$vm
        }
        
        return api.postAPI('/api/shop/cart/checkout', input, options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);

            var order = response.order;
            if(order && order.transaction && 
                    order.transaction.method && 
                    order.transaction.method.type == 'CARD' && 
                    order.transaction.method.provider == 'STRIPE' && 
                    response.stripeClientSecret) {
              
                // We need to handle stripe payment
                return this.$vm.$shop.payment.confirmStripeCardPayment('checkout_card', response.stripeClientSecret).then(() => {
                    // We send analytics
                    gtag.confirmTransaction(order, this.$vm);
                    // The payment is succedded
                    return Promise.resolve();
                });
            }
            else if(order) {
                // We send analytics
                gtag.confirmTransaction(order, this.$vm);
                // The payment is succedded
                return Promise.resolve();
            }
            return Promise.reject();
        })
    }

}
