import Vue from '@fwk-node-modules/vue';
import { Payment as ShopPayment } from '../../shop/helpers/payment';
import { types as cartTypes } from '../stores/cart';

export class Payment extends ShopPayment {

    constructor(vm:Vue) {
        super(vm);
        this.type = "hospitality";
    }

    updateStoreAfterConfirmation() {
        // We check that we have a cart with order id and an order associated in the store.
        var cart = this.$vm.$hospitality_cart;
        var booking = this.$vm.$hospitality_booking;

        if(cart && booking && cart.booking_id && cart.booking_id == booking._id) {
            // We remove clean the cart
            this.$vm.$store.commit('hospitality/cart/' + cartTypes.mutations.SET_CART,null);
        }
    }

}
