import { getComputedMediaURL } from '@fwk-client/utils/media';
import Vue from '@fwk-node-modules/vue';
import { Route } from '@fwk-node-modules/vue-router';
import { utils as RouterUtils } from '@fwk-client/router/utils';

import { types as pagesContentTypes, gettersPath as pagesContentGettersPath } from '../stores/pagesContent';
import { LocalizedText } from '@igotweb-node-api/api/models/interface/localization';
import { LocalizedTextOptions } from '@igotweb-node-api-utils/formatter';

export class Utils {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    /**
     * getHeaderLogoURL
     * This method looks for header logo URL option provided in header component in site content.
     * It returns header logo URL for the current route.
     */
    getHeaderLogoURL() {
        // We check on current route definition within header
        var pageComponents = this.$vm.$cms.components.getComponentsFromRoute(this.$vm.$route);
        if(pageComponents.header) {
            var headerProps = this.$vm.$cms.components.getComponentProps(pageComponents.header);
            if(headerProps && headerProps.options && headerProps.options.logoURL) {
                return headerProps.options.logoURL;
            }
        }
        return undefined;
        // TODO - If missing but default header has logo URL, then we should add this backup logic
    }

    getComputedPictureURL(pictureURL:string) {
        var staticsDomain = (this.$vm.$store.state.server && this.$vm.$store.state.server.staticsDomain) ? this.$vm.$store.state.server.staticsDomain : undefined;
        return getComputedMediaURL(pictureURL, staticsDomain);
    }

    getComputedLabel(label:string|LocalizedText, options?:LocalizedTextOptions) {
        if(label) {
            if(typeof label != "string") {
                return this.$vm.getLocalizedText(label, options);
            }
            return label;
        }
        return undefined;
    }

    /**
     * getRouteMetaData
     * This method retrieves the meta data from page content by default or from route meta definition if not found.
     * @param route 
     * @param metaKey 
     * @returns 
     */
    getRouteMetaData(route:Route, metaKey:string) {
        var metaData = null;
        // We check if the route has page content
        if(route && route.meta && route.meta.hasPageContent) {
            var routeShortName = RouterUtils.getRouteShortNameFromRoute(route);
            var pageContent = this.$vm.$store.getters[pagesContentGettersPath[pagesContentTypes.getters.GET_PAGE_CONTENT]](routeShortName);
            if(pageContent && pageContent[metaKey]) {
                metaData = {...pageContent[metaKey]};
            }
        }
        // We get the components from the route definition
        if(!metaData && route && route.meta && route.meta[metaKey]) {
            metaData = {...route.meta[metaKey]}
        }
        return metaData;
    }

}
