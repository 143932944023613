import Vue from '@fwk-node-modules/vue';
import { templateReplace } from '@fwk-client/languages';
import { computeRouteMeta, populateRouteSocialMediasMeta as populateGenericRouteSocialMediasMeta } from '@fwk-client/store/meta';
import { RouteMeta } from '@fwk-client/types/meta';
import { utils as RouterUtils } from '@fwk-client/router/utils';
import { isBlogPostRoute, isShopRoute, isShopRouteName } from './menu';
import { Route } from '@fwk-node-modules/vue-router';
import { metaTypes } from '@fwk-client/store/types';
import { Store } from '@fwk-node-modules/vuex';
import { gettersPath as siteGettersPath, types as siteTypes } from '@fwk-client/modules/cms/stores/site';

/**
 * getThemeVariablesFromRoute
 * Returns the theme variables associated to the shopContent or site currently loaded
 * @param route 
 * @param app 
 * @returns 
 */
export function getThemeVariablesFromRoute(route:Route, app:Vue) {
  // If we are on a shop route of a vigneron-online site
  var themeVariables:any = {};
  if(isShopRoute(route) && app.$shopContent_theme && app.$shopContent_theme.variables) {
    themeVariables = app.$shopContent_theme.variables;
  }
  else if(app.$cms_site && app.$cms_site.theme && app.$cms_site.theme.variables) {
    themeVariables = app.$cms_site.theme.variables;
  }
  return themeVariables;
}

/**
 * updatePageMetaForShopProduct
 * This method update the page meta when we are on shop product page.
 * Computed metas:
 *  - title
 *  - description
 * @param metas 
 * @param route 
 * @param $vm 
 */
export function updatePageMetaForShopProduct(metas:RouteMeta, route:any, productMode:"ACTIVITY"|"PRODUCT", $vm:Vue) {
    // We are on the product page
    var product:any = null;
    if(route.params && route.params.productCode) {
      // We need to get the associated product from code
      var product = productMode == 'ACTIVITY' ? $vm.$shop_activityFromCode(route.params.productCode) : $vm.$shop_productFromCode(route.params.productCode);
    }

    if(!product) {
      // We do not compute the meta as we do not found product on product page.
      console.error("Product missing ("+route.params.productCode+")");
      return;
    }

    // We compute the title
    var prefix = $vm.$shop.product.getProductTitle(product, $vm.$shop_shop.name);

    // We compute the title
    var title = prefix;
    if(metas.title) {
      title = templateReplace(metas.title!, [prefix], $vm);
    }

    // We update the title
    metas.title = title;

    // We compute the description with product specific description
    if(product.description) {
        // We update the description
        metas.description = $vm.getTextFromLocalizedContent(product.description);
    }
}

/**
 * updatePageMetaForBlogPost
 * This method update the page meta when we are on blog post page.
 * Computed metas:
 *  - title
 *  - description
 * @param metas 
 * @param route 
 * @param $vm 
 */
 export function updatePageMetaForBlogPost(metas:RouteMeta, route:any, $vm:Vue) {
  // We are on the post page
  var post:any = null;
  var postPath:string|undefined;
  if(route.meta && route.meta.blog && route.meta.blog.postPathParam) {
    // We check if we want to retrieve the content of a post
    postPath = route.params[route.meta.blog.postPathParam];
    if(postPath) {
        post = $vm.$blog_post(postPath);
    }
  }

  if(!post) {
    // We do not compute the meta as we do not found product on product page.
    console.error("Post missing ("+postPath+")");
    return;
  }

  // We compute the title
  var prefix = $vm.getLocalizedText(post.title);

  // We compute the title
  var title = prefix;
  if(metas.title) {
    title = templateReplace(metas.title!, [prefix], $vm);
  }

  // We update the title
  metas.title = title;

  // We compute the description with post short description
  if(post.shortDescription) {
      // We update the description
      metas.description = $vm.getLocalizedText(post.shortDescription);
  }
}

/**
 * updateRouteMetasFromPagesMeta
 * This method update the page meta when we are on a shop page on Vigneron-Online
 * Computed metas:
 *  - title
 *  - description
 * @param metas 
 * @param route - the current route
 * @param $vm 
 */
export function updateRouteMetasFromPagesMeta(metas:RouteMeta, route:any, $vm:Vue) {
  // We check if we have pages meta defined for common and this page  
  var routeShortName = RouterUtils.getRouteShortNameFromRoute(route);
  var computedPagesMeta:RouteMeta = {};
  // We check if we are on vigneron-online with shopContent on shop pages
  if(isShopRoute(route) && $vm.$shopContent_pagesMeta) {
    computedPagesMeta = computeRouteMeta($vm.$shopContent_pagesMeta, routeShortName);
  }
  // We check if we are on cms site
  else if($vm.$cms_site && $vm.$cms_site.content && $vm.$cms_site.content.pagesMeta) {
    // We compute page meta
    computedPagesMeta = computeRouteMeta($vm.$cms_site.content.pagesMeta, routeShortName);
    // We remove default common social media metas (Vigneron Online site name and logo)
    delete metas.socialMedias;
  }

  // We merge computed pages meta within metas
  if(computedPagesMeta.title) {
    var localizedTitle = $vm.getLocalizedText(computedPagesMeta.title, {backup:false});
    if(localizedTitle && localizedTitle != "") {
      metas.title = localizedTitle;
    }
  }

  // We merge computed pages meta within metas
  if(computedPagesMeta.description) {
    var localizedDescription = $vm.getLocalizedText(computedPagesMeta.description, {backup:false});
    if(localizedDescription && localizedDescription != "") {
      metas.description = localizedDescription;
    }
  }

  // We merge computed pages meta within metas
  if(computedPagesMeta.socialMedias) {
    metas.socialMedias = computedPagesMeta.socialMedias;
  }
    
  // We review specific behavior for shop routes
  if(isShopRouteName(routeShortName)) {
      if(routeShortName == "shop-product" || routeShortName == "shop-activity") {
        let productMode:"PRODUCT"|"ACTIVITY" = routeShortName == "shop-product" ? "PRODUCT" : "ACTIVITY";
          // We compute the metas based on the shop product
          updatePageMetaForShopProduct(metas,route,productMode, $vm);  
      }
      else {
          if(!$vm.$shop_shop) {
            console.error("METAS - UPDATE METAS FROM PAGES META - NO SHOP ON SHOP ROUTE");
            console.error("Route short name: "+ routeShortName);
            if(route.params && route.params.shopPath) {
              console.error("Shop path:" + route.params.shopPath);
            }
            if($vm.$cms_site) {
              console.error("Site name:" + $vm.$cms_site.name);
            }
          }
          else if(metas.title) {
            // We compute the title with default resource if we are on a shop page
            metas.title = templateReplace(metas.title!, [$vm.$shop_shop.name], $vm);
          }
      }
  }
  else if(isBlogPostRoute(route)) {
    updatePageMetaForBlogPost(metas, route, $vm);
  } 
}

export function populateRouteSocialMediasMeta(metas:RouteMeta, route:any, $vm:Vue) {

    var routeShortName = RouterUtils.getRouteShortNameFromRoute(route);

    // We first populate generic social medias
    populateGenericRouteSocialMediasMeta(metas, $vm.$store.state);

    // We check if we are on vigneron-online with shopContent on shop pages
    if(isShopRoute(route) && $vm.$shopContent_shop) {
        if(!metas.socialMedias) { metas.socialMedias = {}; }
        if($vm.$shopContent_logo) { 
            metas.socialMedias.image = $vm.getStaticURLFromPath($vm.$shopContent_logo.original.path);
        }
        metas.socialMedias.siteName = $vm.$shopContent_shop.name;
        metas.socialMedias.imageAlt = $vm.$shopContent_shop.name;
    }
    // We check if we are on cms site
    else if($vm.$cms_site) {
        if(!metas.socialMedias) { metas.socialMedias = {}; }
        // If no image provided in metas definition, 
        // We check if we have logo available in header definition
        if(!metas.socialMedias.image) {
          var headerLogoURL = $vm.$cms.utils.getHeaderLogoURL();
          if(headerLogoURL) {
            metas.socialMedias.image = $vm.$cms.utils.getComputedPictureURL(headerLogoURL);
          }
        }
        metas.socialMedias.siteName = $vm.$cms_site.name;
        metas.socialMedias.imageAlt = $vm.$cms_site.name;
    }

    // We check if we are on a shop product page to update metas
    if(routeShortName == "shop-product" || routeShortName == "shop-activity") {
      let productMode:"PRODUCT"|"ACTIVITY" = routeShortName == "shop-product" ? "PRODUCT" : "ACTIVITY";
      updateRouteSocialMediasMetaForShopProduct(metas, route, productMode,$vm);
    }
    // We check if we are on a blog post page
    else if(isBlogPostRoute(route)) {
      updateRouteSocialMediasMetaForBlogPost(metas, route, $vm);
    }

}

/**
 * updateRouteSocialMediasMetaForShopProduct
 * This method update the page social medias meta when we are on shop product page.
 * Computed metas:
 *  - image
 *  - imageAlt
 * @param metas 
 * @param route 
 * @param $vm 
 */
 export function updateRouteSocialMediasMetaForShopProduct(metas:RouteMeta, route:any,productMode:"ACTIVITY"|"PRODUCT", $vm:Vue) {
  // We are on the product page
  var product:any = null;
  if(route.params && route.params.productCode) {
    // We need to get the associated product from code
    var product = productMode == 'ACTIVITY' ? $vm.$shop_activityFromCode(route.params.productCode) : $vm.$shop_productFromCode(route.params.productCode);
  }

  if(!product) {
    // We do not compute the meta as we do not found product on product page.
    console.error("Product missing ("+route.params.productCode+")");
    return;
  }

  if(!metas.socialMedias) { metas.socialMedias = {}; }

  // We compute the title
  var prefix = $vm.$shop.product.getProductTitle(product, $vm.$shop_shop.name);

  // We update the imageAlt
  metas.socialMedias.imageAlt = prefix;
  // We update the image
  let defaultShape = product.type == 'ACTIVITY' ? require('@public/img/vigneron-online/activity-shape.jpeg') : require('@public/img/vigneron-online/shape.png');
  var defaultImage = metas.socialMedias.image ? metas.socialMedias.image : defaultShape; 
  metas.socialMedias.image = $vm.$shop.product.getPicturesURLs(product, defaultImage)[0];
}

/**
 * updateRouteSocialMediasMetaForBlogPost
 * This method update the page social medias meta when we are on bloog postpage.
 * Computed metas:
 *  - image
 *  - imageAlt
 * @param metas 
 * @param route 
 * @param $vm 
 */
 export function updateRouteSocialMediasMetaForBlogPost(metas:RouteMeta, route:any, $vm:Vue) {
  // We are on the post page
  var post:any = null;
  var postPath:string|undefined;
  if(route.meta && route.meta.blog && route.meta.blog.postPathParam) {
    // We check if we want to retrieve the content of a post
    postPath = route.params[route.meta.blog.postPathParam];
    if(postPath) {
        post = $vm.$blog_post(postPath);
    }
  }

  if(!post) {
    // We do not compute the meta as we do not found product on product page.
    console.error("Post missing ("+postPath+")");
    return;
  }

  if(!metas.socialMedias) { metas.socialMedias = {}; }

  // We compute the title
  var prefix = $vm.getLocalizedText(post.title);

  // We update the imageAlt
  metas.socialMedias.imageAlt = prefix;
  // We update the image
  if(post.picture) {
    metas.socialMedias.image = $vm.getStaticURLFromPath(post.picture.original.path);
  }
}

export function populateApplicationMetas(store: Store<any>) {
  var data:any = {
    applicationMetas : {
      meta : []
    }
  }

  var site = store.getters[siteGettersPath[siteTypes.getters.GET_SITE]];

  // If in site configuration we have googleSiteVerification we add it to meta.
  if(site && site.configuration && site.configuration.googleSiteVerification) {
    data.applicationMetas.meta.push(
      { name: 'google-site-verification', content: site.configuration.googleSiteVerification }
    )
  }

  // If in site configuration we have facebookDomainVerification we add it to meta.
  if(site && site.configuration && site.configuration.facebookDomainVerification) {
    data.applicationMetas.meta.push(
      { name: 'facebook-domain-verification', content: site.configuration.facebookDomainVerification }
    )
  }
  
  store.commit('meta/' + metaTypes.mutations.SET_APPLICATION_METAS, data);
}