<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { layoutCreated, layoutDestroyed, layoutMounted, layoutOnRouteChange } from '../../theme/canvas-664/canvas';

@Component({})
export default class CanvasLayout extends Vue {

  created() {
    layoutCreated(this.$store);
  }

  mounted() {
    layoutMounted();

    this.$cms.components.onCmsRouteMounted(this.onCanvasLayoutRouteMounted)
  }

  destroyed() {
    layoutDestroyed()

    this.$cms.components.offCmsRouteMounted(this.onCanvasLayoutRouteMounted)
  }

  onCanvasLayoutRouteMounted() {
    layoutOnRouteChange();
  }
  
}
</script>