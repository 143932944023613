import { Module, Store } from '@fwk-node-modules/vuex';
import { createCartStore, types as cartTypes } from '@fwk-client/modules/shop/stores/cart';
import { createOrderStore, types as orderTypes } from '@fwk-client/modules/shop/stores/order';
import { createShopStore, types as shopTypes } from '@fwk-client/modules/shop/stores/shop';

export const types = {
  getters : {
    IS_TOP_CART_DISPLAYED : 'IS_TOP_CART_DISPLAYED',
    IS_CART_LOADING : 'IS_CART_LOADING',
    IS_CART_PRODUCTS_LOADING : 'IS_CART_PRODUCTS_LOADING',
    IS_CART_CONTACT_LOADING : 'IS_CART_CONTACT_LOADING',
    IS_SHOP_PRODUCTS_LOADING : 'IS_SHOP_PRODUCTS_LOADING',
    GET_SELECTED_METHOD_OF_DELIVERY_TYPE : 'GET_SELECTED_METHOD_OF_DELIVERY_TYPE',
    GET_SELECTED_METHOD_OF_PAYMENT_INDEX : 'GET_SELECTED_METHOD_OF_PAYMENT_INDEX',
    GET_SELECTED_CATEGORY : 'GET_SELECTED_CATEGORY',
    GET_SELECTED_ACTIVITY_CATEGORY : 'GET_SELECTED_ACTIVITY_CATEGORY',
    IS_CATEGORY_SELECTED : 'IS_CATEGORY_SELECTED',
    GET_CONTACT : 'GET_CONTACT'
  },
  mutations : {
    START_CART_LOADING : 'START_CART_LOADING',
    STOP_CART_LOADING : 'STOP_CART_LOADING',
    START_CART_PRODUCTS_LOADING : 'START_CART_PRODUCTS_LOADING',
    STOP_CART_PRODUCTS_LOADING : 'STOP_CART_PRODUCTS_LOADING',
    START_CART_CONTACT_LOADING : 'START_CART_CONTACT_LOADING',
    STOP_CART_CONTACT_LOADING : 'STOP_CART_CONTACT_LOADING',
    TOGGLE_TOP_CART_DISPLAY :  'TOGGLE_TOP_CART_DISPLAY',
    SET_TOP_CART_DISPLAYED : 'SET_TOP_CART_DISPLAYED',
    SET_SELECTED_CATEGORY : 'SET_SELECTED_CATEGORY',
    SET_SELECTED_ACTIVITY_CATEGORY : 'SET_SELECTED_ACTIVITY_CATEGORY',
    SET_SELECTED_METHOD_OF_DELIVERY_TYPE : 'SET_SELECTED_METHOD_OF_DELIVERY_TYPE',
    SET_SELECTED_METHOD_OF_PAYMENT_INDEX : 'SET_SELECTED_METHOD_OF_PAYMENT_INDEX',
    START_SHOP_PRODUCTS_LOADING : 'START_SHOP_PRODUCTS_LOADING',
    STOP_SHOP_PRODUCTS_LOADING : 'STOP_SHOP_PRODUCTS_LOADING',
    SET_CONTACT : 'SET_CONTACT'
  },
  actions : {
  }
}

export function createStore () {

  const cart = createCartStore();
  const order = createOrderStore();
  const shop = createShopStore();

  const store:Module<any, any> = {
    namespaced: true,
    modules: {
      cart,
      order,
      shop
    },
    state: {
      isTopCartDisplayed : false,
      isCartLoading : false, // Set to true when backend action to load the cart
      isCartProductsLoading : false, // Set to true when backend action updating the cart products
      isCartContactLoading : false, // Set to true when backend action updating the cart contact
      isShopProductsLoading : false, // Set to true when backend action loading the products associated to current shop.s
      contact : null, // The contact information filled by the user.
      isContactAdressValid : false, // True when contact address is valid.
      selectedCategory : null, // The current selected category
      selectedActivityCategory : null, // The current selected activity category
      selectedMethodOfDeliveryType : null, // type or null, it is the type of method of delivery selected (shop.methodOfDeliveries or cart.pricesForDeliveryPerType)
      selectedMethodOfPaymentIndex : null, // number or null, it is the index of method of payment selected (shop.methodOfPayments)
    },
    getters: {
      [types.getters.IS_TOP_CART_DISPLAYED](state) { return state.isTopCartDisplayed; },
      [types.getters.IS_CART_LOADING](state) { return state.isCartLoading; },
      [types.getters.IS_CART_PRODUCTS_LOADING](state) { return state.isCartProductsLoading; },
      [types.getters.IS_CART_CONTACT_LOADING](state) { return state.isCartContactLoading; },
      [types.getters.GET_SELECTED_METHOD_OF_DELIVERY_TYPE](state) { return state.selectedMethodOfDeliveryType; },
      [types.getters.GET_SELECTED_METHOD_OF_PAYMENT_INDEX](state) { return state.selectedMethodOfPaymentIndex; },
      
      [types.getters.GET_SELECTED_CATEGORY](state) { return state.selectedCategory; },
      [types.getters.GET_SELECTED_ACTIVITY_CATEGORY](state) { return state.selectedActivityCategory; },
      
      [types.getters.IS_CATEGORY_SELECTED](state) {
        return function(category:any) {
          if(category && state.selectedCategory && state.selectedCategory == category._id) {
            return true;
          }
          else if(category == null && state.selectedCategory == null) {
            return true;
          }
          return false; 
        }
      },
      
      [types.getters.GET_CONTACT](state) { return state.contact; },
    },
    mutations: {
      [types.mutations.SET_TOP_CART_DISPLAYED] (state, isTopCartDisplayed) { state.isTopCartDisplayed = isTopCartDisplayed; },
      [types.mutations.TOGGLE_TOP_CART_DISPLAY] (state) { state.isTopCartDisplayed = !state.isTopCartDisplayed; },
      [types.mutations.START_CART_LOADING] (state) { state.isCartLoading = true; },
      [types.mutations.STOP_CART_LOADING] (state) { state.isCartLoading = false; },
      [types.mutations.START_CART_PRODUCTS_LOADING] (state) { state.isCartProductsLoading = true; },
      [types.mutations.STOP_CART_PRODUCTS_LOADING] (state) { state.isCartProductsLoading = false; },
      [types.mutations.START_CART_CONTACT_LOADING] (state) { state.isCartContactLoading = true; },
      [types.mutations.STOP_CART_CONTACT_LOADING] (state) { state.isCartContactLoading = false; },
      [types.mutations.START_SHOP_PRODUCTS_LOADING] (state) { state.isShopProductsLoading = true; },
      [types.mutations.STOP_SHOP_PRODUCTS_LOADING] (state) { state.isShopProductsLoading = false; },
      [types.mutations.SET_SELECTED_CATEGORY] (state, category) { state.selectedCategory = category ? category._id : null; },
      [types.mutations.SET_SELECTED_ACTIVITY_CATEGORY] (state, category) { state.selectedActivityCategory = category ? category._id : null; },
      [types.mutations.SET_SELECTED_METHOD_OF_DELIVERY_TYPE] (state, type) { state.selectedMethodOfDeliveryType = type; },
      [types.mutations.SET_SELECTED_METHOD_OF_PAYMENT_INDEX] (state, index) { state.selectedMethodOfPaymentIndex = index; },
      [types.mutations.SET_CONTACT] (state, contact) { state.contact = contact; }
    },
    actions: {
      
    }
  }
  return store;
};

export const containerUtils = {
  getProductsAmount(container:any){
    if(container && container.productsAmount) {
      return container.productsAmount;
    }
    return null;
  },
  getPromoCodesReduction(container:any) {
    if(container && container.promoCodesReduction) {
      return container.promoCodesReduction.reduction;
    }
    return null;
  },
  getPromoCodes(container:any) {
    if(container && container.promoCodes) {
      return container.promoCodes;
    }
    return [];
  }
}

/**
 * updateStoreFromBackend
 * This method update the store based on data returned in backend responses.
 * @param response - the backend response
 * @param store - the store instance
 */
export function updateStoreFromBackend(response:any, store:Store<any>) {
  if(response.cart !== undefined) {
    // We update the cart in store
    store.commit('shop/cart/' + cartTypes.mutations.SET_CART,response.cart);
    store.commit('shop/cart/' + cartTypes.mutations.SET_CART_RETRIEVED, true);
  }
  if(response.order) {
    // We update the order in store
    store.commit('shop/order/' + orderTypes.mutations.SET_ORDER,response.order);
  }
  if(response.shop) {  
    // We update the cart in store
    store.commit('shop/shop/' + shopTypes.mutations.SET_SHOP,response.shop);
  }
}