import { types } from '@fwk-client/modules/hospitality/stores';
import { types as agencyTypes, gettersPath as agencyGettersPath } from '@fwk-client/modules/hospitality/stores/agency';
import { formatDate, formatDay, getLocalizedText } from '@igotweb-node-api-utils/formatter';

export enum CHECKOUT_STEPS {
    REVIEW = 1,
    METHOD_OF_PAYMENT = 2
}

export function showApartmentContent(apartment:any, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {

        var eventName = 'Click';
        var eventContent = {
            "event_category": 'Apartment link',
            "event_label" : $vm.getLocalizedText(apartment.title)
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("HOSPITALITY - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function addRentalInCart(apartment:any, startDate:Date, endDate:Date, price:number, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var agencyID = apartment.agency_id;
        var agency = $vm.$store.getters[agencyGettersPath[agencyTypes.getters.GET_AGENCY]]();
        var agencyCode = agency.code;

        var eventName = 'add_to_cart';
        var eventContent = {
            "items": [
                getItemFromRental(apartment,agencyCode, startDate, endDate, price)
            ],
            "affiliation" : agencyCode
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("HOSPITALITY - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function removeRentalInCart(rental:any, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var apartment = rental.apartment;
        var startDate = rental.startDate;
        var endDate = rental.endDate;
        var price = rental.amount.amount;
        
        var agencyID = apartment.agency_id;
        var agency = $vm.$store.getters[agencyGettersPath[agencyTypes.getters.GET_AGENCY]]();
        var agencyCode = agency.code;

        var eventName = 'remove_from_cart';
        var eventContent = {
            "items": [
                getItemFromRental(apartment,agencyCode, startDate, endDate, price)
            ],
            "affiliation" : agencyCode
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("HOSPITALITY - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function startCheckout($vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var agency = $vm.$store.getters[agencyGettersPath[agencyTypes.getters.GET_AGENCY]]();
        var agencyCode = agency.code;

        var cartProducts = $vm.$hospitality_cartProducts;

        if(!cartProducts || cartProducts.length == 0) {
            return;
        }

        var cartProductsAmount = $vm.$hospitality_cartProductsAmount;

        var eventName = 'begin_checkout'
        var eventContent = {
            "currency": cartProductsAmount.currencyCode,
            "value": cartProductsAmount.amount,
            "items": [...cartProducts].map((cartProduct:any) => {
                return getItemFromRental(cartProduct, agencyCode, cartProduct.startDate, cartProduct.endDate, cartProduct.price)
            })
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("HOSPITALITY - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function selectMethodOfPayment(index:number|null, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var agency = $vm.$store.getters[agencyGettersPath[agencyTypes.getters.GET_AGENCY]]();
        var agencyCode = agency.code;

        var paymentTitle = "No method";
        if(index != null) {
            var methodOfPayment = $vm.$hospitality_methodOfPayments[index]
            paymentTitle = $vm.$hospitality.payment.getPaymentTitle(methodOfPayment);
        }

        var cartProductsAmount = $vm.$hospitality_cartProductsAmount;

        var eventName = 'checkout_progress'
        var eventContent = {
            "checkout_step": CHECKOUT_STEPS.METHOD_OF_PAYMENT,
            "checkout_option": paymentTitle,
            "value": cartProductsAmount.amount,
            "currency" : cartProductsAmount.currencyCode
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("HOSPITALITY - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}

export function getItemFromRental(apartment:any, agencyCode:string, startDate:Date, endDate:Date, price:number) {

    var item:Gtag.Item = {
        "item_id": apartment._id,
        "item_name": getLocalizedText(apartment.title, "fr"),
        //"list_name": "Search Results",
        "item_brand": agencyCode,
        //"category": "Apparel/T-Shirts",
        "item_variant": formatDay(startDate, "fr") + " -> " + formatDay(endDate, "fr"),
        // "list_position": 1,
        "quantity": 1,
        "price": price
      }

    return item;
}

export function confirmBooking(booking:any, $vm:Vue) {
    if(process.env.CONSOLE == "LOG" || $vm.$gtag) {
        var agency = $vm.$store.getters[agencyGettersPath[agencyTypes.getters.GET_AGENCY]]();
        var agencyCode = agency.code;

        var eventName = 'purchase'
        var eventContent = {
            "affiliation" : agencyCode,
            "value": booking.totalAmount.amount,
            "currency" : booking.totalAmount.currencyCode,
            "items": [...booking.products].map((cartProduct:any) => {
                return getItemFromRental(cartProduct, agencyCode, cartProduct.startDate, cartProduct.endDate, cartProduct.price)
            })
        }
        if(process.env.CONSOLE == "LOG") {
            console.log("HOSPITALITY - GTAG - " + eventName);
            console.log(eventContent);
        }
        if($vm.$gtag) {
            $vm.$gtag.event(eventName, eventContent);
        }
    }
}