import { languagesGettersPath, languagesTypes } from '@fwk-client/store/types';
import Vue from '@fwk-node-modules/vue';
import VueRouter , { Route } from '@fwk-node-modules/vue-router';
import { gettersPath, types } from '../stores';
import { Feed } from './feed';
import { Post } from './post';


export enum BlogData {
    POSTS = 'POSTS',
    POST = 'POST'
}

export class Blog {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    /**
     * routerBeforeEach
     * This helpers needs to be added in router instance as before each.
     * It checks that the blog requested exists.
     * @param to - The destination route
     * @param from - The origin route
     * @param next - the callback
     */
    static routerBeforeEach(this:VueRouter, to:Route, from:Route, next:any) {
        var languageCode = this.app.$store.getters[languagesGettersPath[languagesTypes.getters.GET_REQUESTED_LANGUAGE]];

        // We check if we are currently redirecting to 404 page
        var redirectPath = '';
        if(languageCode) { redirectPath += '/'+languageCode; }
        redirectPath += '/404';
        var message = "BLOG PLUGIN - SHOP - ROUTER BEFORE EACH (To: " + to.fullPath + ") - 404 REDIRECTION ("+redirectPath+")";

        if(to.fullPath == redirectPath) {
            return next();
        }
            
        // We load the data if needed
        var promises:Promise<void>[] = [];

            
        // We get the components from page content or route meta
        var blog = this.app.$cms.utils.getRouteMetaData(to, "blog");
        if(blog && blog.feeds) {
            for(var feedName of blog.feeds) {
                // We check if the feed is already available
                var existingFeed = this.app.$store.getters[gettersPath[types.getters.GET_FEED]](feedName)
                // We retrieve the feed information
                if(!existingFeed) {
                    var feedHelper = new Feed(this.app);
                    promises.push(feedHelper.getFeed(feedName));
                }
            }
        }
        if(blog && blog.postPathParam) {
            // We check if we want to retrieve the content of a post
            var postPath = to.params[blog.postPathParam];
            if(postPath) {
                var postHelper = new Post(this.app);
                promises.push(postHelper.getPost(postPath));
            }
        }
        return Promise.all(promises).then(() => {
            next();
        })
        .catch((error) => {
            if(process.env.CONSOLE == "LOG") { 
                console.log(message); 
                console.log(error);
            }
            return next(redirectPath);
        })
    }

}
