import { Module } from '@fwk-node-modules/vuex';

export const types = {
  getters : {
    GET_SITE : 'GET_SITE',
    GET_SITE_LAYOUT_NAME : 'GET_SITE_LAYOUT_NAME',
    GET_SITE_MENU : 'GET_SITE_MENU',
    GET_SITE_ROUTES : 'GET_SITE_ROUTES',
    GET_SITE_DEFAULT_HEADER : 'GET_SITE_DEFAULT_HEADER',
    GET_SITE_DEFAULT_FOOTER : 'GET_SITE_DEFAULT_FOOTER',
    GET_SITE_SHARED_COMPONENTS : 'GET_SITE_SHARED_COMPONENTS',
    GET_SITE_HAS_PROFILE : 'GET_SITE_HAS_PROFILE'
  },
  mutations : {
    SET_SITE : 'SET_SITE',
  },
  actions : {
    
  }
}

const privateTypes = {
  mutations : {
    
  },
  actions : {
    
  }
}

export const statePath:string = 'cms/site/';

export function createStore () {

  const store:Module<any, any> = {
    namespaced: true,
    modules: {
    },
    state: {
      site : null, // We store the site instance
    },
    getters: {      
      [types.getters.GET_SITE](state, getters, rootState, rootGetters) { 
        return state.site;
      },
      [types.getters.GET_SITE_LAYOUT_NAME](state, getters) { 
        var site = getters[types.getters.GET_SITE];
        if(site && site.content && site.content.layoutName) {
          return site.content.layoutName;
        }
        return null;
      },
      [types.getters.GET_SITE_MENU](state, getters) { 
        var site = getters[types.getters.GET_SITE];
        if(site && site.content && site.content.menu) {
          return site.content.menu;
        }
        return null;
      },
      [types.getters.GET_SITE_ROUTES](state, getters) {
        var site = getters[types.getters.GET_SITE];
        if(site && site.content && site.content.routes) {
          return site.content.routes;
        }
        return [];
      },
      [types.getters.GET_SITE_DEFAULT_HEADER](state, getters) {
        var site = getters[types.getters.GET_SITE];
        if(site && site.content && site.content.header) {
          return site.content.header;
        }
        return null;
      },
      [types.getters.GET_SITE_DEFAULT_FOOTER](state, getters) {
        var site = getters[types.getters.GET_SITE];
        if(site && site.content && site.content.footer) {
          return site.content.footer;
        }
        return null;
      },
      [types.getters.GET_SITE_SHARED_COMPONENTS](state, getters) {
        var site = getters[types.getters.GET_SITE];
        if(site && site.content && site.content.components) {
          return site.content.components;
        }
        return [];
      },
      [types.getters.GET_SITE_HAS_PROFILE](state, getters) {
        var site = getters[types.getters.GET_SITE];
        if(site && site.hasProfile != undefined) {
          return site.hasProfile;
        }
        return false;
      }
    },
    mutations: {
      [types.mutations.SET_SITE] (state, site) { 
        state.site = site;
      }
    },
    actions: {
      
    }
  }
  return store;
};

export const getters = {
  $cms_site : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SITE]];
    }
  },
}

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;