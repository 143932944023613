
import {stickyMenuClass, logo} from './canvas.header';
import { elements, areElementsInitialized } from './canvas.elements';

/*-------------------------------------------------------------------------------*/
/*	UPDATE PROCESS: Compare functions.js file between versions to adapt methods	 */
/*-------------------------------------------------------------------------------*/

export function sliderDimensions(){

	var {$sliderParallaxEl, $slider, $body, $wrapper} = elements;

	if($sliderParallaxEl) {
		let parallaxElHeight	= $sliderParallaxEl.outerHeight(),
			parallaxElWidth		= $sliderParallaxEl.outerWidth(),
			slInner				= $sliderParallaxEl.querySelector('.slider-inner'),
			slFlexHeight		= $slider.hasClass('h-auto') || $slider.hasClass('min-vh-0');

		if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
			setTimeout( function(){
				slInner.height( parallaxElHeight );
				if( slFlexHeight ) {
					parallaxElHeight = $sliderParallaxEl.find('.slider-inner').$children()[0].outerHeight();
					$sliderParallaxEl.height( parallaxElHeight );
					slInner.height( parallaxElHeight );
				}
			}, 500 );

			if( $body.hasClass('side-header') ) {
				slInner.width( parallaxElWidth );
			}

			if( !$body.hasClass('stretched') ) {
				parallaxElWidth = $wrapper.outerWidth();
				slInner.width( parallaxElWidth );
			}
		}
		else {
			$sliderParallaxEl.css({ 'height': '' });
			slInner.css({ 'width': '', 'height': '' });
		}
	}

	if($slider) {
		let slSwiperW			= $slider.querySelector('.swiper-wrapper'),
			slSwiperS			= $slider.querySelector('.swiper-slide'),
			slFlexHeight		= $slider.hasClass('h-auto') || $slider.hasClass('min-vh-0');

		if( $body.hasClass('device-xl') || $body.hasClass('device-lg') ) {
			if( slFlexHeight ) {
				let slSwiperFC = slSwiperS.$children()[0];
				if( slSwiperFC.hasClass('container') || slSwiperFC.hasClass('container-fluid') ) {
					slSwiperFC = slSwiperFC.$children()[0];
				}
				if( slSwiperFC.outerHeight() > slSwiperW.outerHeight() ) {
					slSwiperW.css({ 'height': 'auto' });
				}
			}
		} else {
			slSwiperW.css({ 'height': '' });
		}
	}
}

export function swiperSliderMenu( onWinLoad:boolean = false ){

	var {$body, $header, $slider} = elements;

	onWinLoad = typeof onWinLoad !== 'undefined' ? onWinLoad : false;
	if( $body.hasClass('device-xl') || $body.hasClass('device-lg') || ( $header.hasClass('transparent-header-responsive') && !$body.hasClass('primary-menu-open') ) ) {
		let activeSlide = $slider ? $slider.find('.swiper-slide.swiper-slide-active') : [];
		if(activeSlide.length > 0) {
			headerSchemeChanger(activeSlide[0], onWinLoad);
		}
	}
}

export function revolutionSliderMenu( onWinLoad:boolean = false ) {

	var {$body, $header, $slider} = elements;

	onWinLoad = typeof onWinLoad !== 'undefined' ? onWinLoad : false;
	if( $body.hasClass('device-xl') || $body.hasClass('device-lg') || ( $header.hasClass('transparent-header-responsive') && !$body.hasClass('primary-menu-open') ) ) {
		let activeSlide = $slider ? $slider.find('.active-revslide') : [];
		if(activeSlide.length > 0) {
			headerSchemeChanger(activeSlide[0], onWinLoad);
		}
	}
}

function headerSchemeChanger(activeSlide?:HTMLElement, onWinLoad:boolean = false ){

	var {$body ,$header, oldHeaderClasses, $headerWrap} = elements;

	if( activeSlide ) {
		let darkExists = false,
			oldClassesArray, noOfOldClasses;
		if( activeSlide.hasClass('dark') ){
			if( oldHeaderClasses ) {
				oldClassesArray = oldHeaderClasses.split(/ +/);
			} else {
				oldClassesArray = '';
			}

			noOfOldClasses = oldClassesArray.length;

			if( noOfOldClasses > 0 ) {
				let i = 0;
				for( i=0; i<noOfOldClasses; i++ ) {
					if( oldClassesArray[i] == 'dark' && onWinLoad == true ) {
						darkExists = true;
						break;
					}
				}
			}
			Array.from(document.querySelectorAll('#header.transparent-header:not(.sticky-header,.semi-transparent,.floating-header)')).forEach((element:Element) => { (element as HTMLElement).addClass('dark') });
			if( !darkExists ) {
				Array.from(document.querySelectorAll('#header.transparent-header.sticky-header,#header.transparent-header.semi-transparent.sticky-header,#header.transparent-header.floating-header.sticky-header')).forEach((element:Element) => { (element as HTMLElement).removeClass('dark') });
			}
			$headerWrap.removeClass('not-dark');
		} else {
			if( $body.hasClass('dark') ) {
				activeSlide.addClass('not-dark');
				Array.from(document.querySelectorAll('#header.transparent-header:not(.semi-transparent,.floating-header)')).forEach((element:Element) => { (element as HTMLElement).removeClass('dark') });
				Array.from(document.querySelectorAll('#header.transparent-header:not(.sticky-header,.semi-transparent,.floating-header)')).forEach((element:Element) => { ((element as HTMLElement).querySelector('#header-wrap')! as HTMLElement).addClass('not-dark') });
			} else {
				Array.from(document.querySelectorAll('#header.transparent-header:not(.semi-transparent,.floating-header)')).forEach((element:Element) => { (element as HTMLElement).removeClass('dark') });
				$headerWrap.removeClass('not-dark');
			}
		}
		if( $header.hasClass('sticky-header') ) {
			stickyMenuClass();
		}
		logo();
	}
}

export function getSwiperOptionsOn(swiperWrapper:HTMLElement) {
	return {
		init: function(swiper:any){
			/*
			SEMICOLON.slider.sliderParallaxDimensions();
			swiperWrapper.find('.yt-bg-player').attr('data-autoplay', 'false').removeClass('customjs');
			SEMICOLON.widget.youtubeBgVideo();
			$('.swiper-slide-active [data-animate]').each(function(){
				var $toAnimateElement = $(this),
					toAnimateDelay = $toAnimateElement.attr('data-delay'),
					toAnimateDelayTime = 0;
				if( toAnimateDelay ) { toAnimateDelayTime = Number( toAnimateDelay ) + 750; } else { toAnimateDelayTime = 750; }
				if( !$toAnimateElement.hasClass('animated') ) {
					$toAnimateElement.addClass('not-animated');
					var elementAnimation = $toAnimateElement.attr('data-animate');
					setTimeout(function() {
						$toAnimateElement.removeClass('not-animated').addClass( elementAnimation + ' animated');
					}, toAnimateDelayTime);
				}
			});
			swiperWrapper.find('[data-animate]').each(function(){
				var $toAnimateElement = $(this),
					elementAnimation = $toAnimateElement.attr('data-animate');
				if( $toAnimateElement.parents('.swiper-slide').hasClass('swiper-slide-active') ) { return true; }
				$toAnimateElement.removeClass('animated').removeClass(elementAnimation).addClass('not-animated');
			});
			*/
			//swiperSliderMenu();
		},
		transitionStart: function(swiper:any){
			/*
			if( slideNumberCurrent.length > 0 ){
				if( elementLoop == true ) {
					slideNumberCurrent.html( Number( element.find('.swiper-slide.swiper-slide-active').attr('data-swiper-slide-index') ) + 1 );
				} else {
					slideNumberCurrent.html( swiperSlider.activeIndex + 1 );
				}
			}
			swiperWrapper.find('[data-animate]').each(function(){
				var $toAnimateElement = $(this),
					elementAnimation = $toAnimateElement.attr('data-animate');
				if( $toAnimateElement.parents('.swiper-slide').hasClass('swiper-slide-active') ) { return true; }
				$toAnimateElement.removeClass('animated').removeClass(elementAnimation).addClass('not-animated');
			});
			*/
			if(areElementsInitialized()) {
				swiperSliderMenu();
			}
		},
		transitionEnd: function(swiper:any) {
			/*
			swiperWrapper.find('.swiper-slide').each(function(){
				var slideEl = $(this);
				if( slideEl.find('video').length > 0 && sliderVideoAutoPlay == true ) {
					slideEl.find('video').get(0).pause();
				}
				if( slideEl.find('.yt-bg-player.mb_YTPlayer:not(.customjs)').length > 0 ) {
					slideEl.find('.yt-bg-player.mb_YTPlayer:not(.customjs)').YTPPause();
				}
			});
			swiperWrapper.find('.swiper-slide:not(".swiper-slide-active")').each(function(){
				var slideEl = $(this);
				if( slideEl.find('video').length > 0 ) {
					if( slideEl.find('video').get(0).currentTime != 0 ) {
						slideEl.find('video').get(0).currentTime = 0;
					}
				}
				if( slideEl.find('.yt-bg-player.mb_YTPlayer:not(.customjs)').length > 0 ) {
					slideEl.find('.yt-bg-player.mb_YTPlayer:not(.customjs)').YTPSeekTo( slideEl.find('.yt-bg-player.mb_YTPlayer:not(.customjs)').attr('data-start') );
				}
			});
			if( swiperWrapper.find('.swiper-slide.swiper-slide-active').find('video').length > 0 && sliderVideoAutoPlay == true ) {
				swiperWrapper.find('.swiper-slide.swiper-slide-active').find('video').get(0).play();
			}
			if( swiperWrapper.find('.swiper-slide.swiper-slide-active').find('.yt-bg-player.mb_YTPlayer:not(.customjs)').length > 0 && sliderVideoAutoPlay == true ) {
				swiperWrapper.find('.swiper-slide.swiper-slide-active').find('.yt-bg-player.mb_YTPlayer:not(.customjs)').YTPPlay();
			}

			swiperWrapper.find('.swiper-slide.swiper-slide-active [data-animate]').each(function(){
				var $toAnimateElement = $(this),
					toAnimateDelay = $toAnimateElement.attr('data-delay'),
					toAnimateDelayTime = 0;
				if( toAnimateDelay ) { toAnimateDelayTime = Number( toAnimateDelay ) + 300; } else { toAnimateDelayTime = 300; }
				if( !$toAnimateElement.hasClass('animated') ) {
					$toAnimateElement.addClass('not-animated');
					var elementAnimation = $toAnimateElement.attr('data-animate');
					setTimeout(function() {
						$toAnimateElement.removeClass('not-animated').addClass( elementAnimation + ' animated');
					}, toAnimateDelayTime);
				}
			});
			*/
		}
	}
}

export function init() {

	sliderDimensions();
	//SEMICOLON.slider.sliderRun();
	//SEMICOLON.slider.sliderParallax();
	//SEMICOLON.slider.sliderElementsFade();

}
