import Vue from '@fwk-node-modules/vue';
import * as api from '@fwk-client/utils/api';
import { updateStoreFromBackend } from '@fwk-client/modules/shop/stores';

export class Order {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }


    /**
     * retrieveOrder
     * This method retrieves an order
     */
    retrieveOrder(orderID:string, email?:string):Promise<any> {
        var input = {
            "email" : email
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }
        
        return api.postAPI('/api/shop/order/'+orderID, input, options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);
            return Promise.resolve(response);
        });
    }

    getUserOrders():Promise<any[]> {
        var input = {
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }
        
        return api.postAPI('/api/shop/order/user-list', input, options).then((response:any) => {
            return Promise.resolve(response.orders);
        });
    }

    /**
     * getShippingStatus
     * This method retrieves shipping status from an order
     */
    getShippingStatus(order:any) {
        var input = {
            "email" : order.contact.email.email
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }
        
        return api.postAPI('/api/shop/order/'+order._id+'/delivery/shipping-status', input, options).then((response:any) => {
            return Promise.resolve(response);
        });
    }

}
