import Vue from '@fwk-node-modules/vue';
import VueRouter , { Route } from '@fwk-node-modules/vue-router';
import { types as componentsTypes, gettersPath as componentsGettersPath} from '../stores/components';
import { types as pagesContentTypes, gettersPath as pagesContentGettersPath } from '../stores/pagesContent';
import { types as siteTypes, gettersPath as siteGettersPath} from '../stores/site';
import { utils as RouterUtils } from '@fwk-client/router/utils';
import { getIsLanguageUpdated } from '@fwk-client/router';
import { mergeObjects } from '@igotweb-node-api-utils/misc';

export const CMS_ROUTE_MOUNTED = "CMS_ROUTE_MOUNTED";

export interface StructuredComponent {
    setup:Function;
    path:string;
    component?:Function; // The component if not loaded in store
    props:any;
    components:ComponentSlots
}

export type Component = string|StructuredComponent|Function

interface ComponentSlots {
    [slot:string] : Component
}
export class Components {

    private $vm:Vue;

    private routeComponentPathsToBeMounted:string[];
    private routeMountedTimeout:NodeJS.Timeout|undefined;

    constructor(vm:Vue) {
        this.$vm = vm;
        this.routeComponentPathsToBeMounted = [];
    }

    /**
     * routerBeforeEach
     * This helpers needs to be added in router instance as before each.
     * It checks that the shop requested exists.
     * @param to - The destination route
     * @param from - The origin route
     * @param next - the callback
     */
    static async routerBeforeEach(this:VueRouter, to:Route, from:Route, next:any) {

        var promises = [];

        /**
         * Load route content
         * before each route, we check that the route content is loaded from static file
         * route content includes:
         *  - components
         */
        try {
            if(to && to.meta && to.meta.hasPageContent) {
                await this.app.$store.dispatch('cms/pagesContent/' + pagesContentTypes.actions.CHECK_PAGE_CONTENT, to);
            }
        }
        catch(error:any) {
            if(process.env.CONSOLE == "LOG") {
                console.log("CMS PLUGIN - COMPONENTS - ROUTER BEFORE EACH (To: " + to.fullPath + ") - ERROR: "+error);
            }
            next(error);
        }
        
        /**
         * Components check
         * before each route, we check that the components needed on the page are loaded.
         */
        var listComponents = this.app.$cms.components.getListComponentsFromRoute(to);
        var setComponentPaths = new Set(listComponents.map((component) => this.app.$cms.components.getComponentPath(component)));
        for(var componentPath of setComponentPaths) {
            if(componentPath) {
                promises.push(this.app.$store.dispatch('cms/components/' + componentsTypes.actions.CHECK_COMPONENT, componentPath));
            }
        }

        Promise.all(promises)
            .then(() => {
                if(process.env.CONSOLE == "LOG") {
                    console.log("CMS PLUGIN - COMPONENTS - ROUTER BEFORE EACH (To: " + to.fullPath + ") - NEXT");
                }
                next();
            })
            .catch((error) => {
                if(process.env.CONSOLE == "LOG") {
                console.log("CMS PLUGIN - COMPONENTS - ROUTER BEFORE EACH (To: " + to.fullPath + ") - ERROR: "+error);
                }
                next(error);
            });
    }

    async loadComponent(component:Component) {
       var componentPath = this.getComponentPath(component);
        return this.$vm.$store.dispatch('cms/components/' + componentsTypes.actions.CHECK_COMPONENT, componentPath)
    }

    checkSharedComponent(component:Component) {
        if(typeof component == "object" && component.path && component.path.startsWith("id:")) {
            // We have a shared component
            var sharedComponentId = component.path.substring(3);
            var sharedComponent = this.getSharedComponentDefinition(sharedComponentId);
            if(sharedComponent) {
                return sharedComponent;
            }
            else {
                // The shared component references is not defined.
                console.error("CMS PLUGIN - CHECK SHARED COMPONENT - SHARED COMPONENT IS NOT DEFINED: "+sharedComponentId);
                return null;
            }
        }
        return component;
    }

    getComponentPath(component:Component):string|null {
        component = this.checkSharedComponent(component);

        if(typeof component == 'function') { return null; }
        else if(typeof component == 'string') { return component; }
        return component.path;
    }

    

    getComponentSubComponents(component:Component) {
        component = this.checkSharedComponent(component);

        if(typeof component == 'function') { return null; }
        else if(typeof component == 'string') { return null; }
        return component.components;
    }

    getComponent(component:Component) {
        component = this.checkSharedComponent(component);

        if(typeof component == "function") { return component; }
        if(typeof component == "object" && component.component) {
            // We force a component to be used
            return component.component;
        }
        var path = this.getComponentPath(component);
        var vueComponent = this.$vm.$store.getters[componentsGettersPath[componentsTypes.getters.GET_COMPONENT]](path);
        // We have component with Option API
        if(typeof vueComponent == 'function') { return vueComponent; }
        // We have component with Composition API, we send a copy as plugins are installed to avoid mutation in vuex store
        return { ...vueComponent };
	}

    getSharedComponentDefinition(id:string) {
        var sharedComponents = this.$vm.$store.getters[siteGettersPath[siteTypes.getters.GET_SITE_SHARED_COMPONENTS]];
        return sharedComponents[id];
    }

    getComponentProps(component:Component) {
        component = this.checkSharedComponent(component);

        var path = this.getComponentPath(component);
        const storedComponentProps = this.$vm.$store.getters[componentsGettersPath[componentsTypes.getters.GET_COMPONENT_PROPS]](path);       
        var props = {
            ...(typeof component != "string" && typeof component != "function" && component.props ? component.props : {}), // We get the props provided by routes configuration of site
            ...storedComponentProps // We get the props setup directly in store
        }   
        return props;
    }

    /**
   * mergeComponentProps
   * This method merges several component config and returned the merged config.
   * It deep merge the props
   * @param components - components configs 
   */
  mergeComponentProps(...components: Component[]) {
    return mergeObjects<Component>(...components);
  }

    getComponentsFromRoute(route:Route):ComponentSlots {

        // We get the components from page content or route meta
        var components = this.$vm.$cms.utils.getRouteMetaData(route, "components");
        if(!components) {
            // We check if there is a Vue Component associated to the Route Config
            var routes = this.$vm.$router.getRoutes();
            for(var routeConfig of routes) {
                if(route.name && routeConfig.name && routeConfig.name == route.name && routeConfig.components.default) {
                    components = {
                        content: [routeConfig.components.default]
                    }
                }
            }
            if(!components) {
                components = {};
            }
        }

        // We check for default header and footer
        if(!components.header || !this.getComponentPath(components.header)) {
            // We check if we have a default header defined
            var defaultHeader = this.$vm.$store.getters[siteGettersPath[siteTypes.getters.GET_SITE_DEFAULT_HEADER]];
            if(defaultHeader) {
                if(!components.header) {
                    components.header = defaultHeader;
                }
                else {
                    // We have component properties to apply to the default component
                    components.header = this.mergeComponentProps(
                        defaultHeader,
                        components.header
                    );
                }   
            }
        }
        if(!components.footer || !this.getComponentPath(components.footer)) {
            // We check if we have a default header defined
            var defaultFooter = this.$vm.$store.getters[siteGettersPath[siteTypes.getters.GET_SITE_DEFAULT_FOOTER]];
            if(defaultFooter) {
                if(!components.footer) {
                    components.footer = defaultFooter;
                }
                else {
                    // We have component properties to apply to the default component
                    components.footer = this.mergeComponentProps(
                        defaultFooter,
                        components.footer
                    );  
                } 
            }
        }

        return components ? components : {};
    }

    getAllSubComponents(component:Component) {
        var subComponents = null;
        var componentSubComponents = this.getComponentSubComponents(component);
        if(componentSubComponents) {
            // We add the sub components
            var listSubComponents = this.getListComponentsFromSlots(componentSubComponents);
            subComponents = [...listSubComponents];
            // for each sub components we need to check if we have other sub components
            for(var subComponent of listSubComponents) {
                // We call the recusrive method
                var subSubComponents = this.getAllSubComponents(subComponent);
                if(subSubComponents) {
                    subComponents.push(...subSubComponents);
                }
            }

        }
        return subComponents;
    }

    getListComponentsFromRoute(route:Route):Component[] {
        var routeComponents = this.getComponentsFromRoute(route);
        var listRouteComponents = this.getListComponentsFromSlots(routeComponents);
        var components = [...listRouteComponents];
        for(var routeComponent of listRouteComponents) {
            var listSubComponents = this.getAllSubComponents(routeComponent);
            if(listSubComponents) {
                components.push(...listSubComponents)
            }
        }
        return components;
    }

    getListComponentsFromSlots(componentSlots:ComponentSlots) {
        var listComponents:Component[] = [];
        // Each slot can contain a list of components or is a component.
        for(var slot of Object.values(componentSlots)) {
            if(Array.isArray(slot)) {
                listComponents.push(...slot);
            }
            else {
                listComponents.push(slot);
            }
        }
        return listComponents;
    }

    /**
     * routerAfterEach
     * This helpers needs to be added in router instance as after each.
     * @param to - The destination route
     * @param from - The origin route
     */
    static routerAfterEach(this:VueRouter, to:Route, from:Route) {

        // We need to review when all components are mounted on client side
        if(process.env.VUE_ENV == 'client') {

            // We get the list of already mounted components
            /*
            var listMountedComponentPaths:string[] = [];
            if(from) {
                var listComponents = this.app.$cms.components.getListComponentsFromRoute(from);
                listMountedComponentPaths = listComponents.map((component) => {
                    // We get the associated path
                    return this.app.$cms.components.getComponentPath(component)!;
                })
            }
            */

            // We update the list of component paths to be mounted in the target route.
            var listComponents = this.app.$cms.components.getListComponentsFromRoute(to);
            var routeComponentPathsToBeMounted = listComponents.map((component) => {
                // We get the associated path
                return this.app.$cms.components.getComponentPath(component)!;
            }).filter((componentPath) => {
                // We keep only components that have valid path
                return componentPath != null && typeof componentPath != null;
                // We keep only components that have path and are not already mounted
                //return typeof componentPath != null && listMountedComponentPaths.indexOf(componentPath) == -1;
            })

            if(routeComponentPathsToBeMounted.length > 0) {
                var isLanguageUpdated = getIsLanguageUpdated(to, from, this.app.$store)
                if(isLanguageUpdated) {
                    // We update the language so components are automatically updated (because of the key in CmsComponent)
                    // We need to trigger the route mounted as we are on a new route anyway
                    this.app.$emit(CMS_ROUTE_MOUNTED);
                    return;
                }

                this.app.$cms.components.routeComponentPathsToBeMounted = routeComponentPathsToBeMounted;

                if(process.env.CONSOLE == "LOG") {
                    console.log("CMS - COMPONENTS - COMPONENTS TO BE MOUNTED")
                    console.log(this.app.$cms.components.routeComponentPathsToBeMounted);
                }

                // We add timeout if we have at least one component to check
                // We set timeout to check that we are able to 
                this.app.$cms.components.routeMountedTimeout = setTimeout(() => {
                    // Timeout of components mounted
                    console.error("CMS - COMPONENTS - ROUTE MOUNTED TIMEOUT");
                    console.error(this.app.$cms.components.routeComponentPathsToBeMounted.join(","));
                    // We reset the routeComponentPathsToBeMounted
                    this.app.$cms.components.routeComponentPathsToBeMounted = [];
                }, 2000);
            }

        }
    }

    /**
     * addComponentToBeMounted
     * This methods is to be used when there are CmsComponent directly added in template
     * @param components - list of compopnents to be mounted
     */
    addComponentToBeMounted(components:Component[]) {

        this.routeComponentPathsToBeMounted.push(...components.map((component) => {

            var componentPath = this.getComponentPath(component)!;
            if(process.env.CONSOLE == "LOG") {
                console.log("CMS - COMPONENTS - ADD COMPONENT TO BE MOUNTED: "+componentPath)
            }
            // We get the associated path
            return componentPath;
        }))
    }

    onComponentMounted(component:Component) {
        var path = this.getComponentPath(component);
        if(path != null) {
            // The component is mounted
            if(process.env.CONSOLE == "LOG") {
                console.log("CMS - COMPONENTS - COMPONENT MOUNTED: "+path);
            }
            // No additional component was expected to be mounted so we do not trigger the route mounted event
            if(this.routeComponentPathsToBeMounted.length == 0) {
                return;
            }

            // We remove the component path
            this.routeComponentPathsToBeMounted.splice(this.routeComponentPathsToBeMounted.indexOf(path), 1);

            if(this.routeComponentPathsToBeMounted.length == 0) {
                if(process.env.CONSOLE == "LOG") {
                    console.log("CMS - COMPONENTS - COMPONENT MOUNTED - ALL COMPONENTS MOUNTED!!");
                }
                clearTimeout(this.routeMountedTimeout!);
                Vue.nextTick(() => {
                    this.$vm.$emit(CMS_ROUTE_MOUNTED);
                })
            }
        }
    }

    onCmsRouteMounted(callback:Function) {
        this.$vm.$on(CMS_ROUTE_MOUNTED, callback);
    }

    offCmsRouteMounted(callback:Function) {
        this.$vm.$off(CMS_ROUTE_MOUNTED, callback);
    }

}
