import { createApp } from '@fwk-client/client'
import { removeFacebookAppendedHash, getBrowserPreferredLanguages} from '@fwk-client/utils/browser';
import { enableKeepAlive } from '@fwk-client/utils/session';
import { hooks } from './hooks';

/*--------------------------------------------------------------*/
/*                       ENTRY-CLIENT                           */
/*  This code is the entry point of the application in the      */
/*  browser.                                                    */
/*--------------------------------------------------------------*/

// Remove the ugly Facebook appended hash after login redirection
// <https://github.com/jaredhanson/passport-facebook/issues/12>
(removeFacebookAppendedHash());

// initial state is coming from SSR rendering.
const initialState = (window as any).__INITIAL_STATE__;

// We only check for browser languages in case of no SSR
var acceptedLanguages = [];
if(!initialState) {
    acceptedLanguages = getBrowserPreferredLanguages();
}
// We hardcode supported languages in case of no SSR
var availableLanguages = ['en','fr'];

// We create the app with initial state from SSR.
const { app, router, store } = createApp(acceptedLanguages, availableLanguages, initialState);

if(initialState) {
    // We load the application coming with SSR so meaining that we have a backend.
    // We enable the keep alive calls to keep a session.
    (enableKeepAlive(app, initialState));
}

// We store the vue application as a global object.
(window as any).__VUE_APP__ = app;

router.onReady(() => {
      // We call the hook with all dependencies
    if(hooks.beforeAppMount) {
        hooks.beforeAppMount(app);
    }
    // We wait the language bundle to be loaded before loading the application
    app.$mount('#app');
});