
import { RouteConfig } from '@fwk-node-modules/vue-router';
import { PAGE_NOT_FOUND } from '@fwk-client/router/utils';

/*
 * We use Lazy Loading to have each route loaded asynchronously from the main bundle in the page.
 * Note that to support this syntax with Babel, we need to use syntax-dynamic-import plugin for babel.
 */
const Home = () => import(/* webpackChunkName: "route-home" */ '../components/pages/Home.vue').then(m => m.default);
const PageNotFound = () => import(/* webpackChunkName: "route-pageNotFound" */ '../components/pages/PageNotFound.vue').then(m => m.default);
const PreRenderedPage = () => import(/* webpackChunkName: "route-preRenderedPage" */ '../components/pages/PreRenderedPage.vue').then(m => m.default);

export const pageNotFoundRoutes:Array<RouteConfig> = [
  {
    // The 404 path.
    path: '/404', 
    name: 'page-not-found-404',
    component: PageNotFound,
    meta : {
      [PAGE_NOT_FOUND]: true
    }
  },
  {
    // The default route is used to catch all urls redirecting to non existing routes.
    path: '*', 
    name: 'page-not-found-all',
    component: PageNotFound,
    meta : {
      [PAGE_NOT_FOUND]: true
    }
  } 
]

export const routes:Array<RouteConfig> = [
  { path: '/', redirect: '/home' }, // When user target root path, we redirect it to the home path.
  { 
    path: '/home', 
    component: Home,
    meta: {
      languageBundle: ['home'],
      bodyClasses: ['stretched']
    }
  },
  {
    path: '/prerendered',
    component: PreRenderedPage
  },
  ...pageNotFoundRoutes
];

  