



<template>
    <Modal ref="contactModal" v-model="showContactForm" :title="$t('contact.title')" size="lg">
        <slot v-if="$slots.default" v-on:contact-form-sent="onContactFormSent" />
        <Contact v-else  v-on:contact-form-sent="onContactFormSent" :contactIndex="contactIndex">
            <template v-slot:description v-if="description">
                <div v-html="description"></div>
            </template>
        </Contact> 
    </Modal>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import Modal from '@fwk-client/components/panels/bootstrap/Modal.vue';
import Contact from '../../panels/Contact.vue';
import { types as layoutTypes } from '../../../store/layout';

@Component({
  components: { 
    Modal,
    Contact
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class ContactModal extends Vue {

    @Prop({
        type: String,
        required: false,
    }) readonly description?: string;

    @Prop({
        type: Number,
        required: false,
    }) readonly contactIndex?: number;

    created() {
        
    }

    get showContactForm() {
        return this.$store.getters['layout/'+layoutTypes.getters.GET_SHOW_CONTACT_FORM];
    }
    set showContactForm(show:boolean) {
        this.$store.commit('layout/'+layoutTypes.mutations.SET_SHOW_CONTACT_FORM, show);
    }
    
    mounted() {
       
    }

    beforeDestroy() {
        
    }

    onContactFormSent() {
        this.showContactForm = false;
    }

    @Watch('showContactForm')
    showContactModal(val:boolean) {
        if(val == true && this.$gtag) {
            this.$gtag.event('Show', {
                'event_category': 'Contact Form'
            })
        }
    }
    
}
</script>