import Vue from '@fwk-node-modules/vue';
import Vuex, {Module, Store} from '@fwk-node-modules/vuex';

import { createLanguagesStore } from '@fwk-client/store/languages';
import { createRouterStore } from '@fwk-client/store/router';
import { createMessagesStore } from '@fwk-client/store/messages';
import { createMetaStore } from '@fwk-client/store/meta';

import { languagesTypes, routerTypes } from '@fwk-client/store/types';

import { hooks } from '../hooks';


Vue.use(Vuex);

declare var process: any;

/**
 * createStore
 * This method creates all the stores needed by the application.
 * The values are empty for the moment.
 */
export function createStore ():Store<any> {

  // We create all empty stores
  const languages = createLanguagesStore();
  const router = createRouterStore();
  const messages = createMessagesStore();
  const meta = createMetaStore();

  const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
      languages,
      router,
      messages,
      meta
    },
    state: {},
    mutations: {},
    actions: {}
  });

  if(hooks.onStoreCreated) {
    hooks.onStoreCreated(store);
  }

  return store;
}

/**
 * populateStore
 * This method populate the store once created.
 * It is using the initialState if coming from SSR or add data.
 * @param store - the store instance.
 * @param acceptedLanguages - the list of accepted languages (detected on browser or backend side)
 * @param availableLanguages - the list of available languages (hardcoded on browser or in fwk configuration on backend side)
 * @param initialState - the initial state provided on web application from SSR.
 */
export function populateStore(store: Store<any>, acceptedLanguages:string[], availableLanguages:string[], initialState:any) {
  if(initialState) {
    // We override the state if initial state is given (from SSR)
    store.replaceState(initialState);
  }
  else {
    // We populate the stores with some initial values that we already have.
    store.commit('languages/' + languagesTypes.mutations.SET_AVAILABLE_LANGUAGE, { availableLanguages: availableLanguages });
    
    // We set the default language based on accepted languages
    store.dispatch('languages/' + languagesTypes.actions.SET_DEFAULT_LANGUAGE, { acceptedLanguages: acceptedLanguages });
    
    // We add beforeLanguageUpdate handler to retrieve localized async data if needed.
    store.commit('languages/' + languagesTypes.mutations.ADD_BEFORE_SET_LANGUAGE_ACTION, {action: 'router/' + routerTypes.actions.CHECK_ASYNC_DATA_ALL_FOR_LANGUAGE});

    if(process.env.VUE_ENV == "server") {
      // In case of SSR rendering, we need to register the server store
      var entrySSR = require('@fwk-utils/ssr');
      var serverState = entrySSR.getServerState();
      var serverStore:Module<any, any> = {
        namespaced: true,
        state: serverState
      }

      store.registerModule('server', serverStore);
    }

    if(hooks.onStorePopulated) {
      hooks.onStorePopulated(store);
    }
  }
}