import { toRefs, Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { redirectToShopPage } from '@root/src/client/utils/vigneron-online';
import { languagesGettersPath, languagesTypes, routerTypes } from '@fwk-client/store/types';
import { gettersPath as shopGettersPath, types as shopTypes } from '@fwk-client/modules/shop/stores/shop';
import { gettersPath as agencyGettersPath, types as agencyTypes } from '@fwk-client/modules/hospitality/stores/agency';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber, CmsBoolean } from '@fwk-client/modules/cms/types';
import { utils } from '@fwk-client/router/utils';

interface CartInput {
  type:CmsEnum<"shop"|"hospitality">,
}

export function useCart(props:CartInput, {emit}:any) { 
  const app = getApp();
  const $store = useStore();

  const { type } = props;

  const cartProducts = computed(() => {
    if(type == 'hospitality') {
        return app.$hospitality_cartProducts;
    }
    return app.$shop_cartProducts ? app.$shop_cartProducts.filter((product:any) => product.type == 'PRODUCT') : [];
  })

  const cartActivities = computed(() => {
      return app.$shop_cartProducts ? app.$shop_cartProducts.filter((product:any) => product.type == 'ACTIVITY') : [];
  })

  const subTotal = computed(() => {
      return type == "shop" ? app.$shop_cartProductsAmount : app.$hospitality_cartProductsAmount;
  })
  const deliveryPrice = computed(() => {
    return type == "shop" ? app.$shop.cart.getDeliveryPrice() : undefined;
  })
  const promoCodes = computed(() => {
    return type == "shop" ? app.$shop_cartPromoCodes : [];
  })
  const promoCodesReduction = computed(() => {
    return type == "shop" ? app.$shop_cartPromoCodesReduction : undefined;
  })
  const totalPrice = computed(() => {
    return type == "shop" ? app.$shop.cart.getTotalPrice() : app.$hospitality.cart.getTotalPrice()
  })

  const methodsOfPayment = computed(() => {
    return type == "shop" ? $store.getters[shopGettersPath[shopTypes.getters.GET_SHOP_METHOD_OF_PAYMENTS]] : $store.getters[agencyGettersPath[agencyTypes.getters.GET_AGENCY_METHOD_OF_PAYMENTS]];
  })

  const isSideCartDisplayed = computed({
    get() {
        return app.$shop_isTopCartDisplayed;
    },
    set(newValue) {
        app.$shop_isTopCartDisplayed = newValue;
    }
  })

  const showBackToListLink = computed(() => {
    // We only show back to list for shop and not for hospitality as we can only book one apartment at a time.
    if(app.$shop_cart && app.$shop_cart.shop) {
        // We show the link when we are not on the shop page
        var routeShortName = utils.getRouteShortNameFromRoute($store.state.route);
        if(routeShortName != "shop") {
            return true;
        }
    }
    return false;
  })

  const onBackToListClick = (evt:Event) => {
    evt.preventDefault();
    // We hide the side cart
    app.$shop.cart.setSideCartDisplayed(false);
    
    if(type == "shop") {
      // We redirect to the products page
      redirectToShopPage('shop-products',app, app.$shop_cart.shop)
    }
    else if(type == "hospitality") {
      var languageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      app.$router.push({
        name : 'hospitality-apartments',
        params : {
          lang : languageCode,
        }
      }).catch((err:any) => {});
    }
  }

  const onCheckoutClick = (evt:Event) => {
    evt.preventDefault();
    // We hide the side cart
    app.$shop.cart.setSideCartDisplayed(false);

    if(type == "shop") {
      // We redirect to the checkout page
      redirectToShopPage('shop-checkout',app, app.$shop_cart.shop)
    }
    else if(type == "hospitality") {
      var languageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      app.$router.push({
        name : 'hospitality-checkout',
        params : {
          lang : languageCode,
        }
      }).catch((err:any) => {});
    }
  }

  const selectMethodOfPayment = (paymentIndex:number|null) => {
    if(type == "shop") {
      app.$shop.cart.selectMethodOfPayment(paymentIndex);
    }
    else if(type == "hospitality") {
      app.$hospitality.cart.selectMethodOfPayment(paymentIndex);
    }
  }

  const onPaymentClick = (evt:Event):Promise<void> => {
    evt.preventDefault();

    if(type == "shop") {
      return app.$shop.cart.checkout()
      .then(() => {
        // We redirect to the confirmation page
        return redirectToShopPage('shop-confirmation', app, app.$shop_order.shop);
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
    }
    else if(type == "hospitality") {
      return app.$hospitality.cart.checkout()
      .then(() => {
        // We redirect to the confirmation page
        var languageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
        return app.$router.push({
          name : 'hospitality-confirmation',
          params : {
            lang : languageCode,
          }
        }).catch((err:any) => {});
      })
      .catch((error:any) => {
        return Promise.reject(error);
      });
    }
    return Promise.reject("Unknown type")
  }

  return {
    onBackToListClick,
    onCheckoutClick,
    cartProducts,
    cartActivities,
    subTotal,
    deliveryPrice,
    promoCodes,
    promoCodesReduction,
    totalPrice,
    isSideCartDisplayed,
    showBackToListLink,
    methodsOfPayment,
    selectMethodOfPayment,
    onPaymentClick
  }
  
}