import { Module } from '@fwk-node-modules/vuex';

export const types = {
  getters : {
    GET_SHOW_CONTACT_FORM : "GET_SHOW_CONTACT_FORM",
    GET_SHOW_RESET_PASSWORD_MODAL : "GET_SHOW_RESET_PASSWORD_MODAL"
  },
  mutations : {
    SHOW_CONTACT_FORM_SWITCH : "SHOW_CONTACT_FORM_SWITCH",
    SET_SHOW_CONTACT_FORM : "SET_SHOW_CONTACT_FORM",
    SHOW_RESET_PASSWORD_MODAL_SWITCH : "SHOW_RESET_PASSWORD_MODAL_SWITCH",
    SET_SHOW_RESET_PASSWORD_MODAL : "SET_SHOW_RESET_PASSWORD_MODAL"
  }
};

export function createLayoutStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      showContactForm : false,
      showResetPasswordModal : false,
    },
    getters: {
      [types.getters.GET_SHOW_CONTACT_FORM] (state) {
        return state.showContactForm;
      },
      [types.getters.GET_SHOW_RESET_PASSWORD_MODAL] (state) {
        return state.showResetPasswordModal;
      }
    },
    mutations: {
      [types.mutations.SET_SHOW_CONTACT_FORM] (state, show) {
        // We update the store
        state.showContactForm = show;
      },
      [types.mutations.SHOW_CONTACT_FORM_SWITCH] (state) {
        // We update the store
        state.showContactForm = !state.showContactForm;
      },
      [types.mutations.SET_SHOW_RESET_PASSWORD_MODAL] (state, show) {
        // We update the store
        state.showResetPasswordModal = show;
      },
      [types.mutations.SHOW_RESET_PASSWORD_MODAL_SWITCH] (state) {
        // We update the store
        state.showResetPasswordModal = !state.showResetPasswordModal;
      }
    },
    actions: {
    }
  }
  return store;
};