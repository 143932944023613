<template>
    <footer id="footer">

        <!-- Copyrights
        ============================================= -->
        <div id="copyrights">

            <div class="container">

                <div class="row col-mb-30">

                    <div class="col-md-6 copyright-links">
                        {{ $t("footer.copyright", [year]) }}
                        <i class="icon-envelope2"></i> <a href="mailto:contact@vigneron-online.fr">contact@vigneron-online.fr</a> -
                        <a class="contactUs" @click="showContactModal()" href="javascript:void(0);">{{ $t("footer.contactUs.link") }}</a>
                        <div>
                            <router-link  :class="'conditions-generales nav-item'" :to="{ name : 'conditions-generales', params : { lang : currentLanguageCode }}">{{ $t("menu.conditions-generales") }}</router-link>
                            /
                            <router-link  :class="'politique-de-confidentialite nav-item'" :to="{ name : 'politique-de-confidentialite', params : { lang : currentLanguageCode }}">{{ $t("menu.politique-de-confidentialite") }}</router-link>
                            /
                            <router-link  :class="'cookies nav-item'" :to="{ name : 'cookies', params : { lang : currentLanguageCode }}">{{ $t("menu.cookies") }}</router-link>
                            /
                            <router-link  :class="'mentions-legales nav-item'" :to="{ name : 'mentions-legales', params : { lang : currentLanguageCode }}">{{ $t("menu.mentions-legales") }}</router-link>
                        </div>
                    </div>

                    <div class="col-md-6 text-md-end copyrights-menu">
                        <template v-for="(menuItem, index) in menuItems">
                            {{ index !== 0 ? '/' : '' }}
                            <router-link  :class="menuItem.shortName+' nav-item'" :to="'/'+currentLanguageCode+menuItem.path" :key="index + '-' + currentLanguageCode">{{ getLocalizedText(menuItem.label) }}</router-link>
                        </template>
                        
                        <!--
                        <br/>
                        <router-link :class="'portal nav-item'" :to="'/'+currentLanguageCode+'/portal'" >{{ $t("menu.portal") }}</router-link>
                        / <router-link v-if="!isLoggedIn" :class="'login nav-item'" :to="'/'+currentLanguageCode+'/login'" >{{ $t("menu.login") }}</router-link>
                        <span v-if="isLoggedIn">
                            {{ username }} - 
                            <a href="javascript:void(0);"  @click="onLogoutClick()" :class="'login nav-item'" >{{ $t("footer.facebook.disconnect.link") }}</a>
                        </span>
                        -->
                    
                    </div>

                </div>

            </div>

        </div><!-- #copyrights end -->

    </footer>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { authenticationTypes } from '@fwk-client/store/types';
import { languagesTypes } from '@fwk-client/store/types';
import { types as layoutTypes } from '../../../../store/layout';
import { types as applicationTypes } from '../../../../store/application';


@Component({
  components: { 
  },
  // We add name property from store as a name computed
  computed: {
      ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        }),
      ...mapState({
          isLoggedIn: (state:any) => state.authentication.isLoggedIn
        })
  }
})
export default class Footer extends Vue {
    year = (new Date()).getFullYear();

    get username() {
        if(this.$store.state.authentication.isLoggedIn) {
            return this.$store.state.authentication.user.profile.displayName;
        }
        return null;
    }

    get menuItems() {
        return this.$store.getters['application/' + applicationTypes.getters.GET_ROUTES_IN_MENU](this, "footer");
    }

    onLogoutClick() {
        this.$store.dispatch('authentication/' + authenticationTypes.actions.AUTH_LOGOUT, {app:this});
    }

    showContactModal() {
        this.$store.commit('layout/'+ layoutTypes.mutations.SHOW_CONTACT_FORM_SWITCH);
    }
}
</script>