import { Module, Store } from '@fwk-node-modules/vuex';
import Vue from '@fwk-node-modules/vue';

export const types = {
  getters : {
    GET_FEED : 'GET_FEED',
    GET_POST : 'GET_POST'
  },
  mutations : {
    SET_FEED : 'SET_FEED',
    SET_POST : 'SET_POST',
    CLEAR_FEEDS : 'CLEAR_FEEDS'
  },
  actions : {
    
  }
}

const privateTypes = {
  mutations : {
    
  },
  actions : {
    
  }
}

export const statePath:string = 'blog/';

export function createStore () {

  const store:Module<any, any> = {
    namespaced: true,
    modules: {
    },
    state: {
      feeds : {},
      posts : {}
    },
    getters: {      
      [types.getters.GET_FEED](state) {
        return function(feedName?:string) {
          if(!feedName) {
            var keys = Object.keys(state.feeds);
            if(keys && keys.length == 1) {
              return state.feeds[keys[0]];
            }
          }
          else if(state.feeds[feedName]) {
            return state.feeds[feedName];
          }
          return null; 
        }
      },
      [types.getters.GET_POST](state) {
        return function(postPath:string) {
          if(state.posts[postPath]) {
            return state.posts[postPath];
          }
          return null; 
        }
      },
    },
    mutations: {
      [types.mutations.SET_FEED] (state, feed:any) { 
        Vue.set(state.feeds, feed.name, feed);
      },
      [types.mutations.SET_POST] (state, post:any) { 
        Vue.set(state.posts, post.path, post);
      },
      [types.mutations.CLEAR_FEEDS] (state) { 
        Vue.set(state, "feeds", {});
        Vue.set(state, "posts", {});
      }
    },
    actions: {
      
    }
  }
  return store;
};

export const getters = {
  $blog_posts : {
    get: function (this:Vue) {
      return (feedName?:string) => {
        var feed = this.$store.getters[gettersPath[types.getters.GET_FEED]](feedName);
        if(feed) { return feed.posts; }
        return [];
      }
    }
  },
  $blog_post : {
    get: function (this:Vue) {
      return (postPath:string) => {
        var post = this.$store.getters[gettersPath[types.getters.GET_POST]](postPath);
        if(post) { return post; }
        return undefined;
      }
    }
  }
}

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;

/**
 * updateStoreFromBackend
 * This method update the store based on data returned in backend responses.
 * @param response - the backend response
 * @param store - the store instance
 */
export function updateStoreFromBackend(response:any, store:Store<any>) {
  if(response.feed !== undefined && response.feed !== null) {
    // We update list of feed
    store.commit('blog/' + types.mutations.SET_FEED,response.feed);
  }
  if(response.post !== undefined && response.post !== null) {
    // We update list of post
    store.commit('blog/' + types.mutations.SET_POST,response.post);
  }
}