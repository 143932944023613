import { RouteConfig } from '@fwk-node-modules/vue-router';
import { utils } from '@fwk-client/router/utils';

export const routes:any[] = [
  {
    name: 'root',
    redirect: { name : 'shop-products' }
  },
  { name: 'coming-soon' },
  { name: 'maintenance' },
  { name: 'cookies' },
  { name: 'conditions-generales' },
  { name: 'mentions-legales' },
  { name: 'politique-de-confidentialite' },
  { 
    name: 'shop-products',
    path: '/products'
  },
  {
    name: 'shop-product',
    path: '/products/:productCode'
  },
  { 
    name: 'shop-activities',
    path: '/activities'
  },
  {
    name: 'shop-activity',
    path: '/activities/:productCode'
  },
  { 
    name: 'shop-information',
    path: '/information'
  },
  { 
    name: 'shop-checkout',
    path: '/checkout'
  },
  { 
    name: 'shop-confirmation',
    path: '/confirmation'
  },
  { 
    name: 'shop-order',
    path: '/order/:orderId?'
  }
]

export const routesFromShortName:{[routeShortName:string]:RouteConfig} = utils.computeRoutesFromShortName(routes);