import { getCurrentInstance } from 'vue'

export function useRoute() {
  const { proxy }:any = getCurrentInstance()
  const route = proxy.$route
  return route
}
export function useRouter() {
  const { proxy }:any = getCurrentInstance()
  const router = proxy.$router
  return router
}

export function useStore() {
    const { proxy }:any = getCurrentInstance()
    const router = proxy.$store
    return router
}

export function useCmsModule() {
    const { proxy }:any = getCurrentInstance()
    const router = proxy.$cms
    return router
}

export function getApp() {
  const { proxy }:any = getCurrentInstance()
  return proxy
}