import Vue from '@fwk-node-modules/vue';
import VueRouter , { Route } from '@fwk-node-modules/vue-router';
import * as api from '@fwk-client/utils/api';
import { types } from '@fwk-client/modules/shop/stores';
import { types as cartTypes} from '@fwk-client/modules/shop/stores/cart';
import { Cart } from './cart';
import { gettersPath as shopGettersPath, types as shopTypes } from '@fwk-client/modules/shop/stores/shop';
import { languagesGettersPath, languagesTypes } from '@fwk-client/store/types';

export enum ShopData {
    CART = 'CART',
    PRODUCTS = 'PRODUCTS',
    ACTIVITIES = 'ACTIVITIES'
}

export class Shop {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    /**
     * routerBeforeEach
     * This helpers needs to be added in router instance as before each.
     * It checks that the shop requested exists.
     * @param to - The destination route
     * @param from - The origin route
     * @param next - the callback
     */
    static routerBeforeEach(this:VueRouter, to:Route, from:Route, next:any) {
        var languageCode = this.app.$store.getters[languagesGettersPath[languagesTypes.getters.GET_REQUESTED_LANGUAGE]];

        var redirectPath = '';
        if(languageCode) { redirectPath += '/'+languageCode; }
        redirectPath += '/404';
        var message = "SHOP PLUGIN - SHOP - ROUTER BEFORE EACH (To: " + to.fullPath + ") - 404 REDIRECTION ("+redirectPath+")";

        if(to.fullPath == redirectPath) {
            return next();
        }

        var shopPromise = new Promise((resolve, reject) => {
            var shop = this.app.$store.getters[shopGettersPath[shopTypes.getters.GET_SHOP]];
            if(shop) {
                // We have a shop populated in store
                return resolve(shop);
            }
            else {
                return resolve(null);
            }
        });
      
        shopPromise.then((shop) => {
            // We load the cart and products if needed
            var promises:Promise<void>[] = [];

            // We check if we have the order available while trying to reach confirmation page
            if(to.meta && to.meta.shop && to.meta.shop.isConfirmation) {
                var order = this.app.$shop_order;
                if(!order) {
                    if(process.env.CONSOLE == "LOG") { 
                        console.log("SHOP PLUGIN - SHOP - ROUTER BEFORE EACH - IS CONFIRMATION - THERE IS NO ORDER IN THE STORE");
                    }
                    return next(redirectPath);
                }
            }
            
            if(to.meta && to.meta.shop && to.meta.shop.data) {
                for(var data of to.meta.shop.data) {
                    var cartRetrieved = this.app.$store.getters['shop/cart/' + cartTypes.getters.GET_CART_RETRIEVED];
                    if(data == ShopData.CART && !cartRetrieved) {
                        var cartHelper = new Cart(this.app);
                        promises.push(cartHelper.refreshCart());
                    }
                    if(shop && data == ShopData.PRODUCTS) {
                        var shopHelper = new Shop(this.app);
                        promises.push(shopHelper.updateProducts());
                    }
                    else if(shop && data == ShopData.ACTIVITIES) {
                        var shopHelper = new Shop(this.app);
                        promises.push(shopHelper.updateActivities());
                    }
                }
            }
            return Promise.all(promises).then(() => {
                // We check if we have a requested product code
                if(to.name == "shop-product" && to.params && to.params.productCode) {
                    let product = this.app.$store.getters[shopGettersPath[shopTypes.getters.GET_SHOP_PRODUCT_FROM_CODE]](to.params.productCode);
                    if(!product) {
                        throw "NO PRODUCT";
                    }
                }
                else if(to.name == "shop-activity" && to.params && to.params.productCode) {
                    let activity = this.app.$store.getters[shopGettersPath[shopTypes.getters.GET_SHOP_ACTIVITY_FROM_CODE]](to.params.productCode);
                    if(!activity) {
                        throw "NO ACTIVITY";
                    }
                }
                next();
            })
        })
        .catch((error) => {
            if(process.env.CONSOLE == "LOG") { 
                console.log(message); 
                console.log(error);
            }
            return next(redirectPath);
        })
    }

    public search(text:string):Promise<any> {
        var input = {
            "text" : text
        }
    
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }
        
        return api.postAPI('/api/vigneron-online/shop/search', input, options).then((response:any) => {
            return Promise.resolve(response);
        });
    }

    public updateProducts():Promise<void> {
        return this.$vm.$store.dispatch('shop/shop/' + shopTypes.actions.UPDATE_PRODUCTS, {
            app:this.$vm
        });
    }

    public updateActivities():Promise<void> {
        return this.$vm.$store.dispatch('shop/shop/' + shopTypes.actions.UPDATE_ACTIVITIES, {
            app:this.$vm
        });
    }

    public selectCategory(category:any) {
        this.$vm.$store.commit('shop/' + types.mutations.SET_SELECTED_CATEGORY, category);
    }

}
