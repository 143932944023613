<template>
  <div class="app" id="app">
    <component v-if="layout" :is="layout"></component>
    <GenericError v-else />
  </div>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import Page from './components/layouts/Page.vue';
import { toastrMessages } from '@fwk-client/utils/messages';
import { messagesTypes, Message, routerTypes} from '@fwk-client/store/types';

@Component({
  components: { 
    Page,
    GenericError : () => import(/* webpackChunkName: "layouts-generic-error" */ './components/layouts/GenericError.vue'),
  }
})
export default class App extends Vue {

  layoutName:string = "Page"; 
  // canvas?:Canvas;

  get layout() {
    if(this.layoutName == "Page") {
      return Page;
    }
    else {
      return this.$store.getters['router/' + routerTypes.getters.GET_LAYOUT](this.layoutName);
    }
  }

  get messages():Message[] {
    return this.$store.getters['messages/' + messagesTypes.getters.GET_MESSAGES];
  }

  /**
   * Lifecycle events
   */
  created() {
    if(process.env.CONSOLE == "LOG") { console.log("COMPONENT - APP - CREATED"); }
    
    if(typeof window !== 'undefined') {
      // We add the dom event listeners
      window.addEventListener('resize', this.onResize);
      window.addEventListener('load', this.onLoad);
      window.addEventListener('scroll', this.onScroll);
    }

    this.updateLayout();
  }
  
  destroyed() {
    if(typeof window !== 'undefined') {
      // We destroy the Canvas instance
      // if(this.canvas) { this.canvas.onAppDestroyed(); }
      // We remove the dom event listeners
      window.removeEventListener('resize', this.onResize);
      window.removeEventListener('load', this.onLoad);
      window.removeEventListener('scroll', this.onScroll);
    }
  }
    
  mounted() {
    if(process.env.CONSOLE == "LOG") { console.log("COMPONENT - APP - MOUNTED"); }
    if(this.messages.length > 0) {
      toastrMessages(this);
    }

    // We create the Canvas instance
    //this.canvas = Canvas.getInstance();
  }

  /**
   * Watch
   */
  @Watch('$route')
  onRouteChange(to:any, from:any) {
      this.updateLayout();
  }

  @Watch('messages')
  onMessagesChange(messages:Message[], previous:Message[]) {
    toastrMessages(this);
  }

  /**
   * Methods
   */
  updateLayout() {
    // We check the layout configured for the current route.
    var layoutName = this.$store.getters['router/' + routerTypes.getters.GET_LAYOUT_NAME_FROM_ROUTE](this.$route);
    this.layoutName = layoutName;
  }

  onLoad(event:Event) {
    
  }

  onResize(event:Event) {
    
  }

  onScroll(event:Event) {
    
  }
}
</script>