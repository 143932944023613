import { Module } from '@fwk-node-modules/vuex';
import { createStore as createSiteStore } from '@fwk-client/modules/cms/stores/site';
import { createStore as createComponentsStore } from '@fwk-client/modules/cms/stores/components';
import { createStore as createPagesContentStore } from '@fwk-client/modules/cms/stores/pagesContent';

export const types = {
  getters : {
    
  },
  mutations : {
    
  },
  actions : {
    
  }
}

export function createStore () {

  const site = createSiteStore();
  const components = createComponentsStore();
  const pagesContent = createPagesContentStore();

  const store:Module<any, any> = {
    namespaced: true,
    modules: {
      site,
      components,
      pagesContent
    }
  }
  return store;
};