import { getAttribute } from './canvas.utils';

export const elements:any = {};

/*-------------------------------------------------------------------------------*/
/*	UPDATE PROCESS: Compare functions.js file between versions to adapt 		 */
/*	elements needed. All elements are initialized at the end of the file		 */
/*-------------------------------------------------------------------------------*/

/**
 * This method is to be called when header elements are updated in DOM.
 * @param elements 
 */
 export function updateHeaderElements() {
	
	var $header = document.querySelector('#header') as HTMLElement;
	var $headerWrap = document.querySelector('#header-wrap');
	var $headerWrapClone = null;
	var logo = document.querySelector('#logo');
	var defaultLogo = logo ? logo.querySelector('.standard-logo') : null;
	var retinaLogo = logo ? logo.querySelector('.retina-logo') : null;
	
	// We store default logo as attribute as well as img src is updated by the script
	var defaultLogoImg = defaultLogo ? defaultLogo.getAttribute('data-default-logo') : null;
	var retinaLogoImg = retinaLogo ? retinaLogo.getAttribute('data-default-logo') : null;
	
	var defaultDarkLogo = defaultLogo ? defaultLogo.getAttribute('data-dark-logo') : null;
	var retinaDarkLogo = retinaLogo ? retinaLogo.getAttribute('data-dark-logo') : null;
	var defaultStickyLogo = defaultLogo ? defaultLogo.getAttribute('data-sticky-logo') : null;
	var retinaStickyLogo = retinaLogo ? retinaLogo.getAttribute('data-sticky-logo') : null;
	var defaultMobileLogo = defaultLogo ? defaultLogo.getAttribute('data-mobile-logo') : null;
	var retinaMobileLogo = retinaLogo ? retinaLogo.getAttribute('data-mobile-logo') : null;
	var stickyMenuClasses = $header ? $header.getAttribute('data-sticky-class') : null;

	var responsiveMenuClasses = getAttribute($header, 'data-responsive-class');
	var stickyShrink= getAttribute($header, 'data-sticky-shrink','true');
	var stickyShrinkOffset = getAttribute($header, 'data-sticky-shrink-offset',300);
	var stickyMenuP = getAttribute($header,'data-sticky-menu-padding',19);
	var defMenuP = getAttribute($header,'data-menu-padding',39);

	var headerSizeCustom = !$header!.hasClass('header-size-lg') && !$header.hasClass('header-size-md') && !$header.hasClass('header-size-sm') && !$header.hasClass('header-size-custom');
	var defLogoH = getAttribute($header, 'data-logo-height',100);
	var stickyLogoH = getAttribute($header, 'data-sticky-logo-height',60);
	var mobileLogoH = getAttribute($header, 'data-mobile-logo-height',defLogoH);
	var mobileSticky = getAttribute($header, 'data-mobile-sticky','false');
	var oldHeaderClasses = $header.getAttribute('class');
	var oldHeaderWrapClasses = $headerWrap ? $headerWrap.getAttribute('class') : null;

	var headerOffset = 0;
	var headerWrapOffset = 0;
	var initHeaderHeight;

	Object.assign(elements, {$header, $headerWrap, $headerWrapClone, logo, defaultLogo, retinaLogo, defaultLogoImg, retinaLogoImg, defaultDarkLogo,
		retinaDarkLogo, defaultStickyLogo, retinaStickyLogo, defaultMobileLogo, retinaMobileLogo, stickyMenuClasses, responsiveMenuClasses, stickyShrink, stickyShrinkOffset, stickyMenuP, defMenuP,
		headerSizeCustom, defLogoH, stickyLogoH, mobileLogoH, mobileSticky, headerOffset, headerWrapOffset, oldHeaderClasses, oldHeaderWrapClasses, initHeaderHeight});
}

export function areElementsInitialized() {
	return elements && elements.$body;
}

/**
 * This method is to be called when primary menu elements are updated in DOM.
 * primary menu is a component included within header
 * @param elements 
 */
export function updatePrimayMenuElements() {

	var primaryMenu = document.querySelector('.primary-menu') as HTMLElement;
	var primaryMenuMainItems = [];
	if(primaryMenu) {
		var containers = (primaryMenu as HTMLElement).find('.menu-container:not(mobile-primary-menu):not(.custom-spacing)');
		var menuItems = [];
		for(var container of containers) {
			menuItems.push(...Array.from(container.children).filter((child:Element) => { return child.matches('.menu-item') }))
		}
		for(var menuItem of menuItems) {
			primaryMenuMainItems.push(...Array.from(menuItem.children).filter((child:Element) =>  { return child.matches('.menu-link') }))
		}
	}

	Object.assign(elements, {primaryMenu, primaryMenuMainItems});
}

export function updateGlobalElements() {
	
	var $window = window;
	var windowWidth = window.document.documentElement.clientWidth;
	var $body = document.querySelector('body');
	var $wrapper = document.querySelector('#wrapper'); // body child element containing header, content and footer

	Object.assign(elements, {$window, windowWidth, $body, $wrapper})
}

export function updateContentElements() {

	var $headerInc = document.querySelector('.include-header'); // This is class added to the element following header
	
	Object.assign(elements, {$headerInc});
}

// Page menu is a specific element after header with submenu
export function updatePageMenuElements() {
	
	var $pagemenu = document.querySelector('#page-menu');
	var $pageMenuClone = null;
	var $pageMenuWrap = $pagemenu ? $pagemenu.querySelector('#page-menu-wrap') : null;

	Object.assign(elements, {$pagemenu, $pageMenuClone, $pageMenuWrap});
}

export function updateSliderELements() {
	
	var $slider = document.querySelector('#slider') as HTMLElement;
	var $sliderParallaxEl = document.querySelector('.slider-parallax') as HTMLElement;
	var $sliderElement = document.querySelector('.slider-element') as HTMLElement;

	Object.assign(elements, {$slider, $sliderParallaxEl, $sliderElement});
}


/**
 * This method is called on init to get all DOM elements
 */
export function updateAllElements() {
	updateGlobalElements();
	updateHeaderElements();
	updatePrimayMenuElements();
	updateContentElements();
	updatePageMenuElements();
	updateSliderELements();
}