import Vue, {VueConstructor} from "@fwk-node-modules/vue";
import { Store } from '@fwk-node-modules/vuex';
import VueI18n from "@fwk-node-modules/vue-i18n";
import VueRouter , { Route } from "@fwk-node-modules/vue-router";
import { createApplicationStore } from "./store/application";
import { createAuthenticationStore } from "@fwk-client/store/authentication";
import { routerTypes, metaTypes } from "@fwk-client/store/types";
import { types as applicationTypes } from "./store/application";
import { createLayoutStore } from "./store/layout";
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Parallax from './components/panels/Parallax.vue';
import { routerBeforeEach as vigneronOnlineBeforeEach} from './utils/vigneron-online';
import { getThemeVariablesFromRoute } from './utils/metas';
import { getters as vigneronOnlineGetters } from './store/vigneron-online';

// We import @popperjs ad dependency of bootstrap 5
import '@popperjs/core';

import vSelect from 'vue-select';

// We import smooth scroll to support page scrolling with anchors when not supported (ex: Safari)
import smoothscroll from 'smoothscroll-polyfill';

// @ts-ignore
import VueAutosuggest from "vue-autosuggest";

// Import Swiper Vue.js components
import { directive as swiperDirective } from 'vue-awesome-swiper';
// import style (<= Swiper 5.x)
import 'swiper/css/swiper.css'
  
// We import the bootstrap 4 style
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css';

// We import pretty checkbox style
import './theme/pretty-checkbox.scss';

// We import the ladda style
import 'ladda/dist/ladda-themeless.min.css';

// We import the vue-select style
import 'vue-select/dist/vue-select.css';

// We import the intl-tel-input stype
import '@fwk-node-modules/intl-tel-input/build/css/intlTelInput.css';

// We import the toastr style
import 'toastr/toastr.scss';

// We import the client side specific scripts
if(process.env.VUE_ENV == 'client') {
  // We import the theme associated javascript
  // require('./theme/functions.js');

  // TODO - Export methods to be called in App mounted
  // TODO - review why fading is not working on home page
}

// We import Vee Validate components
import {ValidationProvider,ValidationObserver, extend, configure, localize} from "@fwk-node-modules/vee-validate";
import { required, email } from '@fwk-node-modules/vee-validate/dist/rules';

import { hooks } from '@fwk-client/interfaces/hooks';
import CmsPlugin from "@fwk-client/modules/cms";
import ShopPlugin from "@fwk-client/modules/shop";
import BlogPlugin from "@fwk-client/modules/blog";
import HospitalityPlugin from "@fwk-client/modules/hospitality";
import { init as hotjarInit } from "@fwk-client/utils/hotjar";
import { init as messengerInit } from "@fwk-client/utils/messenger";
import { createVigneronOnlineStore } from "./store/vigneron-online";
import { populateApplicationMetas } from "./utils/metas";

/**
 * onCreateAppEnter
 * This method is called first when we enter the createApp method of the client.ts
 * @param Vue 
 * @param initialState 
 */
hooks.onCreateAppEnter = function (Vue:VueConstructor<Vue>, initialState:any) {

  // @ts-ignore
  Vue.component('v-select', vSelect);

  // @ts-ignore
  Vue.use(VueReCaptcha, { 
    siteKey: '6LelBe8UAAAAAMyjqtGyPBlEsAKcLbs0R6FPacqm',
    loaderOptions : {
      autoHideBadge: true
    }
  });

  Vue.use(VueAutosuggest);

  // @ts-ignore
  Vue.directive('swiper', swiperDirective);

  // We add the common validaton and component for Vee Validate
  extend('required',required);
  extend('email',email);
  Vue.component('validation-provider', ValidationProvider);
  Vue.component('validation-observer', ValidationObserver);

  Vue.component('parallax',Parallax);

  // We add the cms plugin
  Vue.use(CmsPlugin);
  // We add the shop plugin
  Vue.use(ShopPlugin);
  // We add the blog plugin
  Vue.use(BlogPlugin);
  // We add the hospitality plugin
  Vue.use(HospitalityPlugin);

  Vue.mixin({ 

    // We add some computed properties
    computed : {
      ...vigneronOnlineGetters,
    }
  })
}

/**
   * onStoreCreated
   * This method is called when the store is created within store/index.ts
   * @param store - the store instance just created.
   */
hooks.onStoreCreated = function(store: Store<any>) {

  // We register a module in the store
  var applicationStore = createApplicationStore();
  store.registerModule("application", applicationStore);

  // We register the layout store
  var layoutStore = createLayoutStore();
  store.registerModule("layout", layoutStore);

  // We register the authentication module in the store
  var authenticationStore = createAuthenticationStore();
  store.registerModule("authentication", authenticationStore);

  // We register the vigneron-online module in the store
  var vigneronOnlineStore = createVigneronOnlineStore();
  store.registerModule("vigneron-online", vigneronOnlineStore);

  // We register store for the plugins
  CmsPlugin.registerStore(store);
  ShopPlugin.registerStore(store);
  BlogPlugin.registerStore(store);
  HospitalityPlugin.registerStore(store);

}

/**
 * onStorePopulated
 * This method is called when the stores are populated within store/index.ts
 * It is called when there is no initial state from SSR.
 * @param store - the store instance to be populated.
 */
hooks.onStorePopulated = function(store: Store<any>) {
  CmsPlugin.populateStore(store);
  ShopPlugin.populateStore(store);
  HospitalityPlugin.populateStore(store);

}

/**
   * onVueDependenciesCreated
   * This method is called when all dependencies are created (store, router, i18n), before creating the Vue instance.
   * This method is called in client.ts.
   * @param store 
   * @param router 
   * @param i18n 
   * @param initialState 
   */
hooks.onVueDependenciesCreated = function (store: Store<any>, router: VueRouter, i18n: VueI18n, initialState: any) {

  // We put the custom layout computer
  store.commit('router/' + routerTypes.mutations.SET_LAYOUT_GETTER, {action: 'application/' + applicationTypes.getters.LAYOUT_GETTER});

  // We put the custom metas computer
  store.commit('meta/' + metaTypes.mutations.SET_METAS_COMPUTER, {action: 'application/' + applicationTypes.actions.METAS_COMPUTER});

  // We put the theme style computer
  store.commit('meta/' + metaTypes.mutations.ADD_STYLE_COMPUTER, {action: 'application/' + applicationTypes.actions.THEME_STYLE_COMPUTER});  

  // We call the localize method initially
  // This is done as when called the first time, defaultMessage method is configured as well.
  // https://github.com/logaretm/vee-validate/issues/2426
  localize('en');

  configure({
    classes: {
      valid: 'is-valid',
      invalid: 'is-invalid'
    },
    defaultMessage: function(_, values) {
      return i18n.t(`error.fields.${values!._rule_}`, values) as string
    }
  });

  if(store.state.languages.currentLanguageCode != null) {
    // We localize vee validate. The language code is available here on client side only.
    var languageCode = store.state.languages.currentLanguageCode;
    localize(languageCode);
  }

  // We populate the application mets
  populateApplicationMetas(store)
}

/**
 * onRouterAfterRedirectionCheck
 * @param router - The VueRouter instance
 */
hooks.onRouterAfterRedirectionCheck = function(router:VueRouter) {
  
  // We attach the vigneron-online router before each method
  router.beforeEach(function(to, from, next) {
    return vigneronOnlineBeforeEach.call(router, to, from, next);
  });
  // We attach the cms router before each method
  // We put it first to ensure we have page content file loaded for routes
  router.beforeEach(function(to, from, next) {
    return Vue.$cms.router.beforeEach.call(router, to, from, next);
  })
  // We attach the shop router before each method
  router.beforeEach(function(to, from, next) {
    return Vue.$shop.router.beforeEach.call(router, to, from, next);
  });
  // We attach the blog router before each method
  router.beforeEach(function(to, from, next) {
    return Vue.$blog.router.beforeEach.call(router, to, from, next);
  })
  // We attach the hospitality router before each method
  router.beforeEach(function(to, from, next) {
    return Vue.$hospitality.router.beforeEach.call(router, to, from, next);
  })
}

hooks.onRouterAfterEachAdded = function(router:VueRouter) {
  router.afterEach(function(to, from) {
    return Vue.$cms.router.afterEach.call(router, to, from);
  })
  
  // We store the theme primary color to init messenger widget 
  // only when the theme has been changed
  var themeColor:any = null;
  router.afterEach(function(to, from) {
    var newThemeColor = undefined;
    // We get the primary color of the theme if current site or shopContent
    var themeVariables = getThemeVariablesFromRoute(to, router.app);
    if(themeVariables && themeVariables.primaryColor) {
      newThemeColor = themeVariables.primaryColor;
    }
    else {
      newThemeColor = undefined;
    }

    // We init the messenger widget with new color.
    if(themeColor === null ||
        (themeColor === undefined && typeof newThemeColor == "string") ||
        (typeof themeColor == "string" && newThemeColor === undefined) || 
        (newThemeColor !== themeColor)) {
      messengerInit(router.app, {
        themeColor: newThemeColor
      });
      themeColor = newThemeColor;
    }
    
  })
}

hooks.beforeAppMount = function(app:Vue) {
  // We init hotjar
  hotjarInit(app);
  // kick off the polyfill!
  smoothscroll.polyfill();
}


// We export the updated hooks
export { hooks };
