import { metaTypes } from '@fwk-client/store/types';
import { Store } from '@fwk-node-modules/vuex';

/*-------------------------------------------------------------------------------*/
/*	UPDATE PROCESS: Check functions.js file for: "let jRes = jRespond"      	 */
/*-------------------------------------------------------------------------------*/


interface MediaCheckOptions {
	media: string,
	entry?: Function,
	exit?: Function,
	both?: Function
}

function mediaCheck(options:MediaCheckOptions) {
	if (typeof window.matchMedia === 'undefined' || !window.matchMedia('!').addEventListener) {
	  throw new Error('No matchMedia support');
	}
	function mqChange(mq:MediaQueryList, options:MediaCheckOptions) {
	  if (mq.matches) {
		if (typeof options.entry === 'function') { options.entry(mq); }
	  } else {
		if (typeof options.exit === 'function') { options.exit(mq); }
	  }
	  if (typeof options.both === 'function') { options.both(mq); }
	}
  
	function createListener() {
	  let mq = window.matchMedia(options.media);
	  mq.addEventListener('change', function () { mqChange(mq, options); });
  
	  window.addEventListener('orientationchange', function () {
		mq = window.matchMedia(options.media);
		mqChange(mq, options);
	  }, false);
  
	  mqChange(mq, options);
	}
  
	createListener();
}

/**
 * checkViewport
 * This method checks the viewport size and add the associated class to the body
 * @param store - the store instance
 */
export function checkViewport(store:Store<any>) {

	var smallest = mediaCheck({
		media: '(max-width: 575px)',
		entry: function() { store.commit('meta/' + metaTypes.mutations.ADD_BODY_CLASS, 'device-xs'); },
		exit: function() { store.commit('meta/' + metaTypes.mutations.REMOVE_BODY_CLASS, 'device-xs'); }
	});
	var handheld = mediaCheck({
		media: '(min-width:576px) and (max-width: 767px)',
		entry: function() { store.commit('meta/' + metaTypes.mutations.ADD_BODY_CLASS, 'device-sm'); },
		exit: function() { store.commit('meta/' + metaTypes.mutations.REMOVE_BODY_CLASS, 'device-sm'); }
	});
	var tablet = mediaCheck({
		media: '(min-width:768px) and (max-width: 991px)',
		entry: function() { store.commit('meta/' + metaTypes.mutations.ADD_BODY_CLASS, 'device-md'); },
		exit: function() { store.commit('meta/' + metaTypes.mutations.REMOVE_BODY_CLASS, 'device-md'); }
	});
	var laptop = mediaCheck({
		media: '(min-width:992px) and (max-width: 1199px)',
		entry: function() { store.commit('meta/' + metaTypes.mutations.ADD_BODY_CLASS, 'device-lg'); },
		exit: function() { store.commit('meta/' + metaTypes.mutations.REMOVE_BODY_CLASS, 'device-lg'); }
	});
	var desktop = mediaCheck({
		media: '(min-width:1200px)',
		entry: function() { store.commit('meta/' + metaTypes.mutations.ADD_BODY_CLASS, 'device-xl'); },
		exit: function() { store.commit('meta/' + metaTypes.mutations.REMOVE_BODY_CLASS, 'device-xl'); }
	});
};