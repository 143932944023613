import Vue from '@fwk-node-modules/vue';
import { types } from '@fwk-client/modules/shop/stores';
import { types as cartTypes } from '@fwk-client/modules/shop/stores/cart';

export interface ContactInterface {
    email: {
        type:"PERSONAL",
        email:string,
    }
    firstname: string,
    lastname: string,
    companyName? : string,
    address:Address,
    phone:{
        type:"BUSINESS",
        number:string
    }
  }

export interface Address {
    addressLines:[], 
    postalCode:string, 
    city:string, 
    countryCode:string
}

export class Contact {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    public update(contact:ContactInterface) {
        this.$vm.$store.commit('shop/' + types.mutations.SET_CONTACT, contact);
    }

    public getDefaultFromCart() {
        var defaultForm:ContactInterface = {
            email: {
                type:"PERSONAL",
                email:""
            },
            firstname: "",
            lastname: "",
            companyName: "",
            address:{
                addressLines:[], 
                postalCode:"", 
                city:"", 
                countryCode:""
            },
            phone:{
                type:"BUSINESS",
                number:""
            }
        }
        var cartContact = this.$vm.$store.getters['shop/cart/'+cartTypes.getters.GET_CART_CONTACT];
        if(cartContact) {
            defaultForm = JSON.parse(JSON.stringify(cartContact));
        }
        return defaultForm;
    }

}
