import { Module } from '@fwk-node-modules/vuex';
import Vue from '@fwk-node-modules/vue';
import { Component } from '../helpers/components';

export const types = {
  getters : {
    GET_COMPONENT : 'GET_COMPONENT',
    GET_COMPONENT_PROPS : 'GET_COMPONENT_PROPS'
  },
  mutations : {
    ADD_COMPONENT : 'ADD_COMPONENT',
    SET_COMPONENT_PROP : 'SET_COMPONENT_PROP'
  },
  actions : {
    CHECK_COMPONENT : 'CHECK_COMPONENT'
  }
}

// We store the components outside of a reactive store as we do not need any reactivity on components.
export const availableComponents:{[key:string]:Component} = {};

export const statePath:string = 'cms/components/';

export function createStore () {

  const cmsStore:Module<any, any> = {
    namespaced: true,
    state: {
      componentsProps:{}, // contains properties to be assigned to components (path / object with properties)
    },
    getters : {
      [types.getters.GET_COMPONENT]: (state) => (componentPath:string) => {
        return availableComponents[componentPath];
      },
      [types.getters.GET_COMPONENT_PROPS]: (state) => (componentPath:string) => {
        if(!state.componentsProps[componentPath]) { return {}; }
        return state.componentsProps[componentPath];
      }
    },
    mutations: {
      /**
       *  ADD_COMPONENT
       *  This mutation update store to add component.
       */
       [types.mutations.ADD_COMPONENT] (state, {componentPath, component}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - CMS - ADD_COMPONENT MUTATION - " + componentPath)
        }
        // We update the store
        availableComponents[componentPath] = component;
        // We check if object for component props already exists
        // This is to avoid props mutation when we use SET_COMPONENT_PROP
        if(!state.componentsProps[componentPath]) {
          // We update the store
          Vue.set(state.componentsProps, componentPath, {});
        }
      },
      /**
       *  SET_COMPONENT_PROP
       *  This mutation update store to set a property for a component.
       */
       [types.mutations.SET_COMPONENT_PROP] (state, {componentPath, propName, propValue}) {
        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - CMS - SET_COMPONENT_PROP MUTATION - " + componentPath + ", propName: "+propName)
        }

        if(!state.componentsProps[componentPath]) {
          if(process.env.CONSOLE == "LOG") {
            // We raise this error here as this would mean that all components are going to update their props.
            console.error("STORE - CMS - SET_COMPONENT_PROP MUTATION - We add prop on component which is not yet in store:" + componentPath)
          }
          // We update the store
          Vue.set(state.componentsProps, componentPath, {
            [propName] : propValue
          });
        }
        else {
          // We update the store
          Vue.set(state.componentsProps[componentPath], propName, propValue);
        }
      },
    },
    actions: {
      /**
       *  CHECK_COMPONENT
       *  This method check if component in parameter is available.
       */
      [types.actions.CHECK_COMPONENT](payload: any, componentPath) {
        // We get all properties from payload parameter.
        var { commit, state, rootState, getters, dispatch } = payload;

        if(process.env.CONSOLE == "LOG") {
          console.log("STORE - CMS - CHECK COMPONENT: " + componentPath);
        }
        
        if(componentPath && !isComponentAvailableInStore(componentPath, state)) {
          if(process.env.CONSOLE == "LOG") {
            console.log("STORE - CMS - CHECK COMPONENT - We need to load component: " + componentPath);
          }
          return import(/* webpackChunkName: "component-cms-[request]" */ `../../../components/cms/${componentPath}.vue`)
          .then(
            (m) => {
              var component:any = m.default;
              // We check if the component register sub components
              commit(types.mutations.ADD_COMPONENT, {
                componentPath,
                component
              });
              return Promise.resolve();
            }
          )
          .catch((err:string) => {
            return Promise.reject(err);
          })
        }
        else {
          return Promise.resolve();
        }
      }
    }
    
  }
  return cmsStore;
};

function isComponentAvailableInStore(componentPath:string, state:any) {
  if(!availableComponents[componentPath]) { return false; }
  // We add a particular check as Composition API components stores object on SSR side.
  // The object stored does not contain the setup function
  if(typeof availableComponents[componentPath] == 'object' && !(availableComponents[componentPath] as any).setup) { 
    return false; 
  }
  return true;
}

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;