
import { RouteConfig } from '@fwk-node-modules/vue-router';
import { utils } from '@fwk-client/router/utils';
import { References } from '@fwk-client/store/languages';
import { ShopData } from '@fwk-client/modules/shop/helpers/shop';
import { ShopData as VigneronOnlineShopData } from '../utils/vigneron-online'

/*
 * We use Lazy Loading to have each route loaded asynchronously from the main bundle in the page.
 * Note that to support this syntax with Babel, we need to use syntax-dynamic-import plugin for babel.
 */
const Home = () => import(/* webpackChunkName: "route-home" */ '../components/pages/Home.vue').then(m => m.default);
const ShopProducts = () => import(/* webpackChunkName: "route-shop-products" */ '../components/pages/shop/Products.vue').then(m => m.default);
const ShopProduct = () => import(/* webpackChunkName: "route-shop-product" */ '../components/pages/shop/Product.vue').then(m => m.default);
const ShopInformation = () => import(/* webpackChunkName: "route-shop-information" */ '../components/pages/shop/Information.vue').then(m => m.default);
const Checkout = () => import(/* webpackChunkName: "route-checkout" */ '../components/pages/shop/Checkout.vue').then(m => m.default);
const Confirmation = () => import(/* webpackChunkName: "route-confirmation" */ '../components/pages/shop/Confirmation.vue').then(m => m.default);
const Order = () => import(/* webpackChunkName: "route-order" */ '../components/pages/shop/Order.vue').then(m => m.default);
const Login = () => import(/* webpackChunkName: "route-login" */ '../components/pages/Login.vue').then(m => m.default);
const ConditionsGenerales = () => import(/* webpackChunkName: "route-conditions-generales" */ '../components/pages/legal/ConditionsGenerales.vue').then(m => m.default);
const Cookies = () => import(/* webpackChunkName: "route-cookies" */ '../components/pages/legal/Cookies.vue').then(m => m.default);
const MentionsLegales = () => import(/* webpackChunkName: "route-mentions-legales" */ '../components/pages/legal/MentionsLegales.vue').then(m => m.default);
const PolitiqueDeConfidentialite = () => import(/* webpackChunkName: "route-politique-de-confidentialite" */ '../components/pages/legal/PolitiqueDeConfidentialite.vue').then(m => m.default);
const ComingSoon = () => import(/* webpackChunkName: "route-coming-soon" */ '../components/pages/ComingSoon.vue').then(m => m.default);
const Maintenance = () => import(/* webpackChunkName: "route-maintenance" */ '../components/pages/Maintenance.vue').then(m => m.default);
const Actualites = () => import(/* webpackChunkName: "route-actualites" */ '../components/pages/Actualites.vue').then(m => m.default);
const Actualite = () => import(/* webpackChunkName: "route-actualite" */ '../components/pages/Actualite.vue').then(m => m.default);
const Vignerons = () => import(/* webpackChunkName: "route-vignerons" */ '../components/pages/Vignerons.vue').then(m => m.default);


export const routes:RouteConfig[] = [
  {
    path: '/', 
    name: 'root',
    redirect: { name : 'home' },
  },
  { 
    path: '/home', 
    name: 'home',
    component: Home,
    meta: {
      languageBundle: ['home', 'shop', 'contact','actualites'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      shop: {
        data: [ShopData.CART]
      },
      // We add the components to be loaded before
      // We put 2 instance of button as we are mounting 2 instances
      components : [ 
        "canvas/actions/Button",
        "canvas/actions/Button"
      ]
    }
  },
  { 
    path: '/nos-actualites',
    name: 'nos-actualites',
    component: Actualites, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','actualites','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      blog : {
        feeds: ["vigneron-online"]
      }
    },
  },
  { 
    path: '/nos-actualites/:postPath',
    name: 'actualite',
    component: Actualite, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','actualites','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      blog : {
        postPathParam: "postPath"
      }
    },
  },
  { 
    path: '/nos-vignerons',
    name: 'nos-vignerons',
    component: Vignerons, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact', 'vignerons'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      shop : {
        data: [VigneronOnlineShopData.SHOPS]
      }
    },
  },
  { 
    path: '/coming-soon',
    name: 'coming-soon',
    component: ComingSoon, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['coming-soon', 'contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      layout: "NoHeader"
    },
  },
  { 
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      layout: "NoHeader"
    },
  },
  { 
    path: '/order/:orderId?',
    name: 'order',
    component: Order, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      languageReferences: [References.COUNTRIES]
    },
  },
  /*
  {
    path: '/login',
    component: Login,
    meta: {
      layout: "NoHeader"
    }
  },
  */
  { 
    path: '/cookies',
    name: 'cookies',
    component: Cookies, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      fragments: ['cookies']
    },
  },
  { 
    path: '/conditions-generales',
    name: 'conditions-generales',
    component: ConditionsGenerales, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      fragments: ['conditions-generales']
    },
  },
  { 
    path: '/mentions-legales',
    name: 'mentions-legales',
    component: MentionsLegales, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      fragments: ['mentions-legales']
    },
  },
  { 
    path: '/politique-de-confidentialite',
    name: 'politique-de-confidentialite',
    component: PolitiqueDeConfidentialite, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      fragments: ['politique-de-confidentialite']
    },
  },
  { 
    path: '/:shopPath', // (^404)
    name: 'shop-products',
    component: ShopProducts, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      keepScrollFrom: ['shop-product', 'shop-checkout'],
      layout: "Shop",
      shop: {
        data: [ShopData.CART, ShopData.PRODUCTS]
      }
    },
  },
  { 
    path: '/:shopPath/products/:productCode',
    name: 'shop-product',
    component: ShopProduct, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact','shop-product'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        data: [ShopData.CART, ShopData.PRODUCTS]
      }
    } 
  },
  { 
    path: '/:shopPath/activities', // (^404)
    name: 'shop-activities',
    component: ShopProducts, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      keepScrollFrom: ['shop-activity', 'shop-checkout'],
      layout: "Shop",
      shop: {
        productMode: 'ACTIVITY',
        data: [ShopData.CART, ShopData.ACTIVITIES]
      }
    },
  },
  { 
    path: '/:shopPath/activities/:productCode',
    name: 'shop-activity',
    component: ShopProduct, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact','shop-product'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        productMode: 'ACTIVITY',
        data: [ShopData.CART, ShopData.ACTIVITIES]
      }
    } 
  },
  { 
    path: '/:shopPath/information',
    name: 'shop-information',
    component: ShopInformation, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact','shop-information'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        data: [ShopData.CART]
      }
    } 
  },
  { 
    path: '/:shopPath/checkout',
    name: 'shop-checkout',
    component: Checkout, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        data: [ShopData.CART]
      }
    } 
  },
  { 
    path: '/:shopPath/confirmation',
    name: 'shop-confirmation',
    component: Confirmation, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        isConfirmation : true
      }
    } 
  },
  { 
    path: '/:shopPath/order/:orderId?',
    name: 'shop-order',
    component: Order, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop','contact'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        data: [ShopData.CART]
      }
    },
  },
  { 
    path: '/:shopPath/cookies',
    name: 'shop-cookies',
    component: Cookies, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      fragments: ['cookies'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        data: [ShopData.CART]
      }
    },
  },
  { 
    path: '/:shopPath/conditions-generales',
    name: 'shop-conditions-generales',
    component: ConditionsGenerales, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      fragments: ['conditions-generales'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        data: [ShopData.CART]
      }
    },
  },
  { 
    path: '/:shopPath/mentions-legales',
    name: 'shop-mentions-legales',
    component: MentionsLegales, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      fragments: ['mentions-legales'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        data: [ShopData.CART]
      }
    },
  },
  { 
    path: '/:shopPath/politique-de-confidentialite',
    name: 'shop-politique-de-confidentialite',
    component: PolitiqueDeConfidentialite, 
    meta: { // meta is used to add any information in regards to the route which is then accessible via $route object (https://router.vuejs.org/en/advanced/meta.html).
      languageBundle: ['shop'],
      scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
      bodyClasses: ['stretched'],
      fragments: ['politique-de-confidentialite'],
      layout: "Shop",
      languageReferences: [References.COUNTRIES],
      shop: {
        data: [ShopData.CART]
      }
    },
  },
];

export const routesFromShortName:{[routeShortName:string]:RouteConfig} = utils.computeRoutesFromShortName(routes);