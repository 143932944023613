import Vue from '@fwk-node-modules/vue';
import * as api from '@fwk-client/utils/api';
import { types, updateStoreFromBackend } from '../stores';
import { gettersPath as agencyGettersPath, types as agencyTypes } from '../stores/agency';

export class Agency {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    }

    /**
     * getAgency
     * This method get the agency from backend
     */
    getAgency(agencyCode:string):Promise<void> {
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        return api.getAPI('/api/hospitality/agency/'+agencyCode, options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);
        })
    }

    /**
     * updateApartments
     * This method get the agency apartments from backend
     */
    updateApartments(agencyCode:string):Promise<void> {
        return this.$vm.$store.dispatch('hospitality/agency/' + agencyTypes.actions.UPDATE_APARTMENTS, {
            app:this.$vm,
            agencyCode
        });
    }

}
