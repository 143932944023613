import { RouteConfig } from '@fwk-node-modules/vue-router';
import { utils } from '@fwk-client/router/utils';

export const defaultCmsRoute:any = {
  meta: {
    languageBundle: [],
    scrollToTop: true, // The scrollToTop meta is accessed by router.scrollBehavior method.
    bodyClasses: ['stretched']
  }
}

export const routes:RouteConfig[] = [
  {
    path: '/', 
    name: 'root',
    redirect: { name : 'home' },
  },
  { 
    path: '/home', 
    name: 'home',
    ...defaultCmsRoute
  },
]

export const routesFromShortName:{[routeShortName:string]:RouteConfig} = utils.computeRoutesFromShortName(routes);