import Vue from '@fwk-node-modules/vue';
import * as api from '@fwk-client/utils/api';
import * as gtag from '../utils/gtag';
import { updateStoreFromBackend } from '../stores';

export class Post {

    private $vm:Vue;

    constructor(vm:Vue) {
        this.$vm = vm;
    } 

    public onPostContentClick(post:any) {
        // We send analytics
        gtag.showPostContent(post, this.$vm);
    }

    /**
     * getPost
     * This method get the post from backend
     */
     getPost(postPath:string):Promise<void> {
        var options:api.ApiVueOptions =  {
            app: this.$vm
        }

        return api.getAPI('/api/blog/post/'+postPath, options).then((response:any) => {
            updateStoreFromBackend(response, this.$vm.$store);
        })
    }

}
