import __Vue from '@fwk-node-modules/vue';
import { Store } from 'vuex';
import { Hospitality } from './helpers/hospitality';
import { Agency } from './helpers/agency';
import { Apartment } from './helpers/apartment';
import { Cart } from './helpers/cart';
import { Payment } from './helpers/payment';
import { createStore, types as hospitalityTypes } from './stores';
import { getters as cartGetters } from './stores/cart';
import { getters as bookingGetters } from './stores/booking';
import { getters as agencyGetters, types as agencyTypes } from './stores/agency';
import { authenticationTypes, languagesTypes } from '@fwk-client/store/types';
import { Booking } from './helpers/booking';



let Vue:typeof __Vue // bind on install
let installed:boolean = false;

function init(this: __Vue) {

  const options = this.$options

  // We inject the $shop features
  if (options.parent && options.parent.$hospitality) {
    this.$hospitality = options.parent.$hospitality;
  }
  else if(!this.$hospitality) {
    if(process.env.CONSOLE == "LOG") {
      console.log("HOSPITALITY PLUGIN - WE REGISTER $HOSPITALITY FEATURES TO APP INSTANCE");
    }
    // We create the $hospitality instance for the parent component
    this.$hospitality = {
      hospitality: new Hospitality(this),
      agency: new Agency(this),
      apartment: new Apartment(this),
      cart: new Cart(this),
      payment: new Payment(this),
      booking: new Booking(this)
    }
  }
}

/**
 * registerStore
 * This method has to be called to register the CMS PLUGIN store
 * @param store 
 */
 function registerStore(store:Store<any>) {
  if(process.env.CONSOLE == "LOG") {
    console.log("HOSPITALITY PLUGIN - WE CREATE THE HOSPITALITY STORE");
  }
  
  // We create the hospitality store
  var hospitalityStore = createStore();
  // We register the hospitality module in the store
  store.registerModule("hospitality", hospitalityStore, { preserveState: store.state.hospitality !== undefined });

  // We add watcher if authentication store is available to clear list of apartments after login
  if(store.hasModule("authentication")) {
    store.watch(
      (state, getters) => {
        return getters['authentication/' + authenticationTypes.getters.IS_LOGGED_IN];
      },
      (val:boolean, oldVal:boolean) => {
          if(val === true) {
            store.commit('hospitality/agency/' + agencyTypes.mutations.CLEAR_APARTMENTS);
          }
      }      
    )
  }
}

/**
 * populateStore
 * This method has to be called to populate store based on server state
 * @param store 
 */
function populateStore(store:Store<any>) {
  // We check if we have shop provided by server to populate the store
  if(store.state.server && store.state.server.agencies) {
    if(process.env.CONSOLE == "LOG") {
      console.log("HOSPITALITY PLUGIN - WE COPY THE AGENCIES FROM SERVER");
    } 
    for(var agency of store.state.server.agencies) {
      store.commit('hospitality/agency/'+agencyTypes.mutations.SET_AGENCY, agency)
    }
  }
}

/**
 * install
 * This method install the plugin.
 * The code is based on vue-router (https://github.com/vuejs/vue-router/blob/dev/src/install.js)
 * @param Vue 
 * @param options 
 */
function install(_Vue: typeof __Vue): void {

  if (Vue && _Vue === Vue || installed) {
    if(process.env.CONSOLE == "LOG") {
      console.log('HOSPITALITY PLUGIN - ALREADY INSTALLED - Vue.use(BlogPlugin) should be called only once.')
    }
    return
  }
  
  Vue = _Vue
  installed = true;
  
  if(process.env.CONSOLE == "LOG") {
    console.log("HOSPITALITY PLUGIN - WE INSTALL THE PLUGIN");
  }

  Vue.$hospitality = {
    router : {
      beforeEach : Hospitality.routerBeforeEach
    }
  }

  Vue.mixin({ 
    beforeCreate: init,

    methods : {
      
    },

    // We add some computed properties
    computed : {
      ...agencyGetters,
      ...cartGetters,
      ...bookingGetters
    }
  });

}

export default {
  install,
  registerStore,
  populateStore
}