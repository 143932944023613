import Vue from '@fwk-node-modules/vue';
import { Module } from '@fwk-node-modules/vuex';
import { types as moduleTypes } from '@fwk-client/modules/shop/stores';
import * as api from '@fwk-client/utils/api';


export const types = {
  getters : {
    GET_SHOP : 'GET_SHOP',
    GET_SHOP_PRODUCTS : 'GET_SHOP_PRODUCTS',
    GET_SHOP_PRODUCT_FROM_CODE : 'GET_SHOP_PRODUCT_FROM_CODE',
    GET_SHOP_PRODUCT_CODE_FROM_PRODUCT : 'GET_SHOP_PRODUCT_CODE_FROM_PRODUCT',
    GET_SHOP_CATEGORIES : 'GET_SHOP_CATEGORIES',
    GET_SHOP_ACTIVITIES : 'GET_SHOP_ACTIVITIES',
    GET_SHOP_ACTIVITY_FROM_CODE : 'GET_SHOP_ACTIVITY_FROM_CODE',
    GET_SHOP_METHOD_OF_DELIVERIES : 'GET_SHOP_METHOD_OF_DELIVERIES',
    GET_SHOP_METHOD_OF_DELIVERIES_LOCAL : 'GET_SHOP_METHOD_OF_DELIVERIES_LOCAL',
    GET_SHOP_METHOD_OF_DELIVERIES_PICKUP : 'GET_SHOP_METHOD_OF_DELIVERIES_PICKUP',
    GET_SHOP_METHOD_OF_DELIVERIES_SHIPPING : 'GET_SHOP_METHOD_OF_DELIVERIES_SHIPPING',
    GET_SHOP_METHOD_OF_PAYMENTS : 'GET_SHOP_METHOD_OF_PAYMENTS',
    GET_SHOP_METHOD_OF_PAYMENTS_CARD : 'GET_SHOP_METHOD_OF_PAYMENTS_CARD',
    GET_SHOP_IS_PROMOCODES_ALLOWED : 'GET_SHOP_IS_PROMOCODES_ALLOWED'
  },
  mutations : {
    SET_SHOP : 'SET_SHOP'
  },
  actions : {
    UPDATE_PRODUCTS : 'UPDATE_PRODUCTS',
    UPDATE_ACTIVITIES : 'UPDATE_ACTIVITIES'
  }
}

const privateTypes = {
  mutations : {
    SET_SHOP_PRODUCTS : 'SET_SHOP_PRODUCTS',
    SET_SHOP_CATEGORIES : 'SET_SHOP_CATEGORIES',
    SET_SHOP_ACTIVITIES : 'SET_SHOP_ACTIVITIES',
    SET_SHOP_ACTIVITY_CATEGORIES : 'SET_SHOP_ACTIVITY_CATEGORIES'
  },
  actions : {
    
  }
}

export const statePath:string = 'shop/shop/';

export function createShopStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      shop : null, // We store the current shop,
      products : {
        list: [], // the list of products available for current shop,
        datetime: null // The datetime when products have been retrieved
      },
      categories : [], // the list of categories available for current shop,
      activities : {
        list: [], // the list of activities available for current shop,
        datetime: null // The datetime when products have been retrieved
      },
      activityCategories : [], // the list of categories available for current shop,
    },
    getters: {
      [types.getters.GET_SHOP](state, getters, rootState, rootGetters) { 
        return state.shop; 
      },
      [types.getters.GET_SHOP_PRODUCTS](state) {
        if(state.products && state.products.list) {
          return state.products.list;
        }
        return [];
      },
      [types.getters.GET_SHOP_PRODUCT_CODE_FROM_PRODUCT](state) {
        return function(product:any) {
          var type = product.type;
          var list = (type == 'ACTIVITY' && state.activities) ? state.activities.list : (state.products) ? state.products.list : undefined;
          if(list) {
            var index = list.map((product:any) => {
              return product._id;
            }).indexOf(product._id);
            if(index > -1) {
              var identified = list[index];
              // We first return the code if any
              if(identified.code) { 
                return identified.code;
              }
              else {
                // We fallback on the id if no code is available here.
                return identified._id;
              }
            }
          }
          return null; 
        }
      },
      [types.getters.GET_SHOP_PRODUCT_FROM_CODE](state) {
        return function(code:string) {
          if(state.products && state.products.list) {
            // We loop on the list of products 
            for(var product of state.products.list) {
              // We first check if the code is found
              if((product.code && product.code == code) ||
                  product._id == code) {
                return product;
              }
            }
          }
          return null; 
        }
      },
      [types.getters.GET_SHOP_CATEGORIES](state) {
        if(state.categories) {
          return state.categories;
        }
        return [];
      },
      [types.getters.GET_SHOP_ACTIVITIES](state) {
        if(state.activities && state.activities.list) {
          return state.activities.list;
        }
        return [];
      },
      [types.getters.GET_SHOP_ACTIVITY_FROM_CODE](state) {
        return function(code:string) {
          if(state.activities && state.activities.list) {
            // We loop on the list of products 
            for(var product of state.activities.list) {
              // We first check if the code is found
              if((product.code && product.code == code) ||
                  product._id == code) {
                return product;
              }
            }
          }
          return null; 
        }
      },
      [types.getters.GET_SHOP_METHOD_OF_DELIVERIES](state, getters) { 
        var shop = getters[types.getters.GET_SHOP];
        if(shop && shop.methodOfDeliveries) {
          return shop.methodOfDeliveries;
        }
        return []; 
      },
      [types.getters.GET_SHOP_METHOD_OF_DELIVERIES_LOCAL](state, getters) {
        var shop = getters[types.getters.GET_SHOP];
        return getMethodOfDeliveriesFromType(shop, 'LOCAL');
      },
      [types.getters.GET_SHOP_METHOD_OF_DELIVERIES_PICKUP](state, getters) {
        var shop = getters[types.getters.GET_SHOP]; 
        return getMethodOfDeliveriesFromType(shop, 'PICKUP');
      },
      [types.getters.GET_SHOP_METHOD_OF_DELIVERIES_SHIPPING](state, getters) {
        var shop = getters[types.getters.GET_SHOP]; 
        return getMethodOfDeliveriesFromType(shop, 'SHIPPING');
      },
      [types.getters.GET_SHOP_METHOD_OF_PAYMENTS](state, getters) { 
        var shop = getters[types.getters.GET_SHOP];
        if(shop && shop.methodOfPayments) {
          return shop.methodOfPayments;
        }
        return []; 
      },
      [types.getters.GET_SHOP_METHOD_OF_PAYMENTS_CARD](state, getters) { 
        var shop = getters[types.getters.GET_SHOP];
        return getMethodOfPaymentFromType(shop, 'CARD');
      },
      [types.getters.GET_SHOP_IS_PROMOCODES_ALLOWED](state, getters) { 
        var shop = getters[types.getters.GET_SHOP];
        if(shop && shop.isPromoCodesAllowed) {
          return shop.isPromoCodesAllowed;
        }
        return false; 
      }
    },
    mutations: {
      [types.mutations.SET_SHOP] (state, shop) { 
        // We check if current shop is the same one or not as current one.
        if(state.shop && state.shop._id != shop._id) {
          // The shop is different from previous one, we need to clear products and categories
          Vue.set(state.products, "datetime", null);
          Vue.set(state.products, "list", []);
        }
        // We update the shop
        state.shop = shop; 
      },
      [privateTypes.mutations.SET_SHOP_PRODUCTS] (state, products) { 
        Vue.set(state.products, "datetime", (new Date()).getTime());
        Vue.set(state.products, "list", products);
      },
      [privateTypes.mutations.SET_SHOP_CATEGORIES] (state, categories) { 
        state.categories = categories; 
      },
      [privateTypes.mutations.SET_SHOP_ACTIVITIES] (state, products) { 
        Vue.set(state.activities, "datetime", (new Date()).getTime());
        Vue.set(state.activities, "list", products);
      },
      [privateTypes.mutations.SET_SHOP_ACTIVITY_CATEGORIES] (state, categories) { 
        state.activityCategories = categories; 
      }
    },
    actions: {
      /**
       * UPDATE_PRODUCTS
       * @param app - The Vue instance
       */
      [types.actions.UPDATE_PRODUCTS] ({state, commit, getters, rootGetters}, {app}):Promise<void> {
        var shop = getters[types.getters.GET_SHOP];

        // We check if we already have products for the current shop for more than 5min
        const EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes expiration
        if(state.products && state.products.list && state.products.list.length > 0 && state.products.datetime && (new Date()).getTime() - state.products.datetime < EXPIRATION_TIME) {
          return Promise.resolve();
        }

        commit('shop/' + moduleTypes.mutations.START_SHOP_PRODUCTS_LOADING,{}, {root: true});

        var options:api.ApiVueOptions =  {
          app: app
        }

        return api.postAPI('/api/shop/shop/'+shop._id+'/products', {}, options).then((response:any) => {
          commit('shop/' + moduleTypes.mutations.STOP_SHOP_PRODUCTS_LOADING,{},{root:true});
          if(response.products) {  
              // We update the list of products and categories
              commit(privateTypes.mutations.SET_SHOP_PRODUCTS, response.products);
              commit(privateTypes.mutations.SET_SHOP_CATEGORIES, response.categories);

              // We need to update the selected category if any
              var selectedCategory = rootGetters['shop/'+moduleTypes.getters.GET_SELECTED_CATEGORY];
              // We check if selected category is available
              if(selectedCategory && response.categories.map((category:any) => { return category._id}).indexOf(selectedCategory) < 0) {
                  commit('shop/' + moduleTypes.mutations.SET_SELECTED_CATEGORY, response.categories[0], {root:true});
              }
          }
          else {
              commit(privateTypes.mutations.SET_SHOP_PRODUCTS, []);
              commit(privateTypes.mutations.SET_SHOP_CATEGORIES, []);
              commit('shop/' + moduleTypes.mutations.SET_SELECTED_CATEGORY, null, {root:true});
          }
          return Promise.resolve();
        });
      },
      /**
       * UPDATE_ACTIVITIES
       * @param app - The Vue instance
       */
      [types.actions.UPDATE_ACTIVITIES] ({state, commit, getters, rootGetters}, {app}):Promise<void> {
        var shop = getters[types.getters.GET_SHOP];

        // We check if we already have products for the current shop for more than 5min
        const EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes expiration
        if(state.activities && state.activities.list && state.activities.list.length > 0 && state.activities.datetime && (new Date()).getTime() - state.activities.datetime < EXPIRATION_TIME) {
          return Promise.resolve();
        }

        commit('shop/' + moduleTypes.mutations.START_SHOP_PRODUCTS_LOADING,{}, {root: true});

        var options:api.ApiVueOptions =  {
          app: app
        }

        var input = {
          mode : 'ACTIVITY'
        }

        return api.postAPI('/api/shop/shop/'+shop._id+'/products', input,options).then((response:any) => {
          commit('shop/' + moduleTypes.mutations.STOP_SHOP_PRODUCTS_LOADING,{},{root:true});
          if(response.products) {  
              // We update the list of products and categories
              commit(privateTypes.mutations.SET_SHOP_ACTIVITIES, response.products);
              commit(privateTypes.mutations.SET_SHOP_ACTIVITY_CATEGORIES, response.categories);

              // We need to update the selected category if any
              var selectedCategory = rootGetters['shop/'+moduleTypes.getters.GET_SELECTED_ACTIVITY_CATEGORY];
              // We check if selected category is available
              if(selectedCategory && response.categories.map((category:any) => { return category._id}).indexOf(selectedCategory) < 0) {
                  commit('shop/' + moduleTypes.mutations.SET_SELECTED_ACTIVITY_CATEGORY, response.categories[0], {root:true});
              }
          }
          else {
              commit(privateTypes.mutations.SET_SHOP_ACTIVITIES, []);
              commit(privateTypes.mutations.SET_SHOP_ACTIVITY_CATEGORIES, []);
              commit('shop/' + moduleTypes.mutations.SET_SELECTED_ACTIVITY_CATEGORY, null, {root:true});
          }
          return Promise.resolve();
        });
      }
    }
  }
  return store;
};

function getMethodOfDeliveriesFromType(shop:any, type:string) {
  if(shop && shop.methodOfDeliveries) {
    return shop.methodOfDeliveries.filter((mod:any) => {
      return mod.type == type
    });
  }
  return []; 
}

export function getMethodOfPaymentFromType(structure:{methodOfPayments:any}, type:string) {
  if(structure && structure.methodOfPayments) {
    return structure.methodOfPayments.filter((mop:any) => {
      return mop.type == type
    });
  }
  return []; 
}

export const getters = {
  $shop_methodOfDeliveries : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_METHOD_OF_DELIVERIES]];
    }
  },
  $shop_methodOfDeliveriesFromType : {
    get: function (this:Vue) {
      return (type:string) => {
        // We get the methods of delivery
        var shop = this.$store.getters[gettersPath[types.getters.GET_SHOP]];
        return getMethodOfDeliveriesFromType(shop, type);
      }
    },
  },
  $shop_methodOfDeliveries_shipping : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_METHOD_OF_DELIVERIES_SHIPPING]];
    }
  },
  $shop_methodOfPayments : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_METHOD_OF_PAYMENTS]];
    }
  },
  $shop_methodOfPayments_card : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_METHOD_OF_PAYMENTS_CARD]];
    }
  },
  $shop_isPromoCodesAllowed : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_IS_PROMOCODES_ALLOWED]];
    }
  },
  $shop_shop : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP]];
    }
  },
  $shop_categories : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_SHOP_CATEGORIES]];
    }
  },
  $shop_productFromCode : {
    get: function (this:Vue) {
      return (code:string) => {
        return this.$store.getters[gettersPath[types.getters.GET_SHOP_PRODUCT_FROM_CODE]](code);
      }
    },
  },
  $shop_productCodeFromProduct : {
    get: function (this:Vue) {
      return (product:any) => {
        return this.$store.getters[gettersPath[types.getters.GET_SHOP_PRODUCT_CODE_FROM_PRODUCT]](product);
      }
    },
  },  
  $shop_activityFromCode : {
    get: function (this:Vue) {
      return (code:string) => {
        return this.$store.getters[gettersPath[types.getters.GET_SHOP_ACTIVITY_FROM_CODE]](code);
      }
    },
  },
}

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;
