import Vue, {VueConstructor} from "@fwk-node-modules/vue";
import { Store } from '@fwk-node-modules/vuex';
import VueI18n from "@fwk-node-modules/vue-i18n";
import VueRouter, { Route } from "@fwk-node-modules/vue-router";

/**
 * hooks.ts
 * This script is used to apply custom code based on hooks available within the generic code of the framework.
 * This file is to be added to each site with the customized extended hooks handler.
 */
export namespace hooks {
  /**
   * onCreateAppEnter
   * This method is called first when we enter the createApp method of the client.ts
   * @param Vue 
   * @param initialState 
   */
  export function onCreateAppEnter (Vue:VueConstructor<Vue>, initialState:any) {}

  /**
   * onVueDependenciesCreated
   * This method is called when all dependencies are created (store, router, i18n), before creating the Vue instance.
   * This method is called in client.ts.
   * @param store 
   * @param router 
   * @param i18n 
   * @param initialState 
   */
  export function onVueDependenciesCreated (store: Store<any>, router: VueRouter, i18n: VueI18n, initialState: any) {}

  /**
   * onStoreCreated
   * This method is called when the stores are created within store/index.ts
   * This hooks can be used to create additional stores.
   * @param store - the store instance just created (with empty data).
   */
  export function onStoreCreated (store: Store<any>) {}

  /**
   * onStorePopulated
   * This method is called when the stores are populated within store/index.ts
   * It is called when there is no initial state from SSR.
   * @param store - the store instance to be populated.
   */
  export function onStorePopulated (store: Store<any>) {}

  /**
   * onRouterCreated
   * This method is called when router instance is created.
   * It is used to add router guards.
   * @param router - The VueRouter instance
   */
  export function onRouterCreated (router:VueRouter) {}

  

  /**
   * onRouterAfterRedirectionCheck
   * This method is called when router redirection check before each is added.
   * It is used to add router guards.
   * @param router - The VueRouter instance
   */
   export function onRouterAfterRedirectionCheck (router:VueRouter) {}

  /**
   * onRouterAfterTargetRouteDataChecks
   * This method is called when router target route data checks before each is added.
   * It is used to add router guards.
   * @param router - The VueRouter instance
   */
  export function onRouterAfterTargetRouteDataChecks (router:VueRouter) {}

  /**
   * onRouterAfterEachAdded
   * This method is called after default router after each is added.
   * It is used to add router guards.
   * @param router - The VueRouter instance
   */
   export function onRouterAfterEachAdded (router:VueRouter) {}

  /**
   * beforeAppMount
   * This method is called before app is mounted on frontend side. 
   * window exist and specific frontend code can be executed.
   */
  export function beforeAppMount(app:Vue) {}

  
 
}