import { Module } from '@fwk-node-modules/vuex';
import { containerUtils } from './index';


export const types = {
  getters : {
    // CONTAINER COMMON GETTERS
    GET_CART : 'GET_CART',
    GET_CART_RETRIEVED : 'GET_CART_RETRIEVED',
    GET_CART_CONTACT : 'GET_CART_CONTACT',
    GET_CART_PRODUCTS : 'GET_CART_PRODUCTS',
    GET_CART_PRODUCT_DETAILS : 'GET_CART_PRODUCT_DETAILS',
    GET_CART_PRODUCTS_AMOUNT : 'GET_CART_PRODUCTS_AMOUNT',
    GET_CART_PROMOCODES : 'GET_CART_PROMOCODES',
    GET_CART_PROMOCODES_REDUCTION : 'GET_CART_PROMOCODES_REDUCTION',
    // CART SPECIFIC GETTERS
    GET_CART_PRICES_FOR_DELIVERY_PER_TYPE : 'GET_CART_PRICES_FOR_DELIVERY_PER_TYPE'
  },
  mutations : {
    // CONTAINER COMMON SETTERS
    SET_CART : 'SET_CART',
    SET_CART_RETRIEVED : 'SET_CART_RETRIEVED',
  },
  actions : {}
}

export function createCartStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      cart : null, // We store the shopping cart
      cartRetrieved : false, // We update it to true when the cart has been retrieved from backend
    },
    getters: {
      [types.getters.GET_CART](state) { return state.cart; },
      [types.getters.GET_CART_RETRIEVED](state) { return state.cartRetrieved; },
      [types.getters.GET_CART_CONTACT](state) {
        return state.cart.contact;
      },
      [types.getters.GET_CART_PRODUCTS](state) {
        if(state.cart && state.cart.products) {
          return state.cart.products;
        }
        return [];
      },
      [types.getters.GET_CART_PRODUCTS_AMOUNT](state) {
        return containerUtils.getProductsAmount(state.cart);
      },
      // The pricesForDelivery are ordered following same order as the methodOfDeliveries within the shop
      [types.getters.GET_CART_PRICES_FOR_DELIVERY_PER_TYPE](state) {
        if(state.cart && state.cart.pricesForDeliveryPerType) {
          return state.cart.pricesForDeliveryPerType;
        }
        return [];
      },
      [types.getters.GET_CART_PRODUCT_DETAILS](state) {
        return function(product:any) {
          if(state.cart && state.cart.products && product && product._id) {
            var index = state.cart.products.map((containerProduct:any) => {
              if(containerProduct.type == 'ACTIVITY') {
                return containerProduct.activity._id;
              }
              return containerProduct.product._id;
            }).indexOf(product._id);
            if(index > -1) {
              return state.cart.products[index];
            }
          }
          return undefined; 
        }
      },
      [types.getters.GET_CART_PROMOCODES](state) {
        return containerUtils.getPromoCodes(state.cart);
      },
      [types.getters.GET_CART_PROMOCODES_REDUCTION](state) {
        return containerUtils.getPromoCodesReduction(state.cart);
      }
    },
    mutations: {
      [types.mutations.SET_CART] (state, cart) { state.cart = cart; },
      [types.mutations.SET_CART_RETRIEVED] (state, cartRetrieved) { state.cartRetrieved = cartRetrieved; },
    },
    actions: {}
  }
  return store;
};

export const getters = {
  $shop_cart : {
    get: function (this:Vue) {
      return this.$store.getters['shop/cart/'+types.getters.GET_CART];
    }
  },
  $shop_cartContact : {
    get: function (this:Vue) {
      return this.$store.getters['shop/cart/'+types.getters.GET_CART_CONTACT];
    }
  },
  $shop_cartProductDetails : {
    get: function (this:Vue) {
      return (product:any) => {
        return this.$store.getters['shop/cart/'+types.getters.GET_CART_PRODUCT_DETAILS](product);
      }
    },
  },
  $shop_cartProducts : {
    get: function (this:Vue) {
      return this.$store.getters['shop/cart/'+types.getters.GET_CART_PRODUCTS];
    },
  },
  $shop_cartProductsAmount : {
    get: function (this:Vue) {
      return this.$store.getters['shop/cart/'+types.getters.GET_CART_PRODUCTS_AMOUNT];
    }
  },
  $shop_cartPricesForDeliveryPerType : {
    get: function (this:Vue) {
      return this.$store.getters['shop/cart/'+types.getters.GET_CART_PRICES_FOR_DELIVERY_PER_TYPE];
    }
  },
  $shop_cartPromoCodes : {
    get: function (this:Vue) {
      return this.$store.getters['shop/cart/'+types.getters.GET_CART_PROMOCODES];
    },
  },
  $shop_cartPromoCodesReduction : {
    get: function (this:Vue) {
      return this.$store.getters['shop/cart/'+types.getters.GET_CART_PROMOCODES_REDUCTION];
    }
  },
  $shop_cartPriceForDeliveryType : {
    get: function (this:Vue) {
      return (type:string) => {
        // We get all prices per type
        var pricesPerType = this.$shop_cartPricesForDeliveryPerType[type];
        // We keep the total for the type
        if(pricesPerType && pricesPerType.length > 0 && pricesPerType[pricesPerType.length-1].code == "TOTAL_"+type) {
          return pricesPerType[pricesPerType.length-1];
        }
        return null;
      }
    },
  },
  $shop_cartNonEligibilityReasonForDeliveryType : {
    get: function (this:Vue) {
      return (type:string) => {
        // We return null when delivery type is available
        if(this.$shop_cartIsDeliveryTypeAvailable(type)) { return null; }
        // We get all prices per type
        var pricesPerType = this.$shop_cartPricesForDeliveryPerType[type];
        if(pricesPerType.length > 2) {
          // We have at least two methods of delivery
          // We check that they all have a non eligibility reason which is the same.
          var isCommon = true;
          var nonEligibilityReason = pricesPerType[0].non_eligibility_reason;
          if(!nonEligibilityReason) { return null; }
          for(var index = 1 ; index < pricesPerType.length - 1 ; index++) {
            if(!pricesPerType[index].non_eligibility_reason || pricesPerType[index].non_eligibility_reason != nonEligibilityReason) {
              isCommon = false;
              break;
            }
          }
          if(isCommon) {
            // They all have non eligibility reason which is the same
            return nonEligibilityReason;
          }
        }
        else {
            // We have only one delivery type so we return the non eligibility reasons of the onlye one
            return pricesPerType[0].non_eligibility_reason;
        }
        return null;
      }
    },
  },
  $shop_cartPriceForDeliveryCode : {
    get: function (this:Vue) {
      return (code:string) => {
        // We get all prices for delivery
        var pricesForDelivery:any[] = [].concat.apply([], Object.values(this.$shop_cartPricesForDeliveryPerType) as any[]);
        // We the one with the right code
        return pricesForDelivery.find((priceForDelivery:any) => priceForDelivery.code == code);
      }
    },
  },
  $shop_cartIsDeliveryTypeAvailable : {
    get: function (this:Vue) {
      return (type:string) => {
        var priceForDeliveryType = this.$shop_cartPriceForDeliveryType(type);
        return priceForDeliveryType && !priceForDeliveryType.non_eligibility_reason
      }
    },
  },
  $shop_cartIsOneDeliveryTypeAvailable : {
    get: function (this:Vue) {
      var pricesForDeliveryPerType = this.$shop_cartPricesForDeliveryPerType;
      for(var type of Object.keys(pricesForDeliveryPerType)) {
        if(this.$shop_cartIsDeliveryTypeAvailable(type)) {
          return true;
        }
      }
      return false;
    },
  },
}