import VueRouter, { RouteConfig, Route } from '@fwk-node-modules/vue-router';
import { PositionResult, Position } from '@fwk-node-modules/vue-router/types/router';
import { References } from '@fwk-client/store/languages';
import { routerTypes } from '@fwk-client/store/types';
import { mergeObjects } from '@igotweb-node-api-utils/misc';

export const PAGE_NOT_FOUND:string = "pageNotFound";
export const IS_LOCALIZED_PATH:string = "isLocalizedPath";
export const LOCALIZED_PARAM = "lang";
export const LOCALIZED_PATH_PREFIX:string = "/:"+LOCALIZED_PARAM;
export const LOCALIZED_PATH_REGEX:RegExp = /\/\:lang\(.+\)/gi;
const PARAM_PATH_REGEX:RegExp = /\/\:([^\/]+)/gi;

export namespace utils {

  /**
   * getLocalizedPathPrefix
   * This method provide the prefix to be used for localized routes based on supported languages.
   * @param supportedLanguages - the list of supported languages code.
   */
  export function getLocalizedPathPrefix(supportedLanguages: string[], isOptional:boolean = false) {
    var prefix = LOCALIZED_PATH_PREFIX + '(' + supportedLanguages.join('|') + ')';
    if(isOptional) {
      // We add empty value
      prefix += "?"
    }
    return prefix;
  }

  /**
   * getRouteShortNameFromRouteConfig
   * This method return the route short name.
   * It is based on a RouteConfig with path being the dyanmic path.
   * @param route 
   */
  export function getRouteShortNameFromRouteConfig(route:RouteConfig) {
    // We first check if a name is defined for the route
    if(route.name) {
      return route.name;
    }

    // We get the name from the path
    var name = route.path;
    // We remove the language parameter from path if any (RouteConfig)
    name = name.replace(LOCALIZED_PATH_REGEX,'');
    // We remove any parameter
    name = name.replace(PARAM_PATH_REGEX,'');
    // We remove the last /
    if(name.charAt(name.length-1) == "/") {
      name = name.slice(0, -1);
    }
    // We remove the first /
    if(name.charAt(0) == "/") {
      name = name.substring(1);
    }
    return name;
  }

  /**
   * getRouteShortNameFromRoute
   * This method return the route short name.
   * It is based on a Route with path being the URL in browser.
   * @param route 
   */
  export function getRouteShortNameFromRoute(route:Route) {
    // We first check if a name is defined for the route
    if(route.name) {
      return route.name;
    }

    // We get the name from the path
    var name = route.path;
    // We remove the parameters from path if any (Route)
    for(var param in route.params) {
      var paramRegExp = new RegExp('\/'+route.params[param]+'(\/|$)');
      name = name.replace(paramRegExp,'/');
    }

    // We remove the last /
    if(name.charAt(name.length-1) == "/") {
      name = name.slice(0, -1);
    }
    // We remove the first /
    if(name.charAt(0) == "/") {
      name = name.substring(1);
    }
    return name;
  }

  /**
   * getRoutePathWithLanguage
   * This method returns the path to route in parameter with the new language.
   * If no language is provided, route path without language is provided.
   * It is based on a Route with path being the URL in browser.
   * @param route 
   */
  export function getRoutePathWithLanguage(route:Route, languageCode?:string) {
    var routePath = route.path;
    // We remove the language parameter from path if any (Route)
    var paramRegExp = new RegExp('\/'+route.params[LOCALIZED_PARAM]+'(\/|$)');
    routePath = routePath.replace(paramRegExp,'/');
    // We remove the last /
    routePath = routePath.substring(1);
    // In case no language code is provided
    if(!languageCode) {
      return '/' + routePath;
    }
    return '/' + languageCode + '/' + routePath;
  }

  /**
   * computeRoutesFromShortName
   * This method return a map of routes where key is the route short name
   * @param routes - list of route configurations
   * @returns 
   */
  export function computeRoutesFromShortName(routes:RouteConfig[]):{[routeShortName:string]:RouteConfig} {
    return Object.fromEntries(
      routes.map((route:RouteConfig) => {
        return [utils.getRouteShortNameFromRouteConfig(route), route];
      })
    );
  }

  /**
   * getRouteConfigFromShortName
   * @param routeShortName - the route short name to look for
   * @param routes - the list of route config
   * @returns 
   */
  export function getRouteConfigFromShortName(routeShortName:string, routes:RouteConfig[]) {
    for(var route of routes) {
      var shortName = getRouteShortNameFromRouteConfig(route);
      if(shortName == routeShortName) {
        return route;
      }
    }
    return null;
  }

  /**
   * getLanguageBundlesFromRoute
   * This method return the route associated language bundles if any or null.
   * @param route 
   */
  export function getLanguageBundlesFromRoute(route:Route):string[]|null {
    if(!route.meta || !route.meta.languageBundle) {
      return null;
    }
    var bundles = route.meta.languageBundle;
    if(!Array.isArray(bundles)) {
      bundles = [bundles];
    }
    return bundles;
  }

  /**
   * getLanguageReferencesFromRoute
   * This method return the route associated language references if any or null.
   * @param route 
   */
  export function getLanguageReferencesFromRoute(route:Route):References[]|null {
    if(!route.meta || !route.meta.languageReferences) {
      return null;
    }
    var references = route.meta.languageReferences;
    if(!Array.isArray(references)) {
      references = [references];
    }
    return references;
  }

  /**
   * getFragmentsFromRoute
   * This method return the route associated fragments if any or null.
   * @param route 
   */
  export function getFragmentsFromRoute(route:Route):string[]|null {
    if(!route.meta || !route.meta.fragments) {
      return null;
    }
    var fragments = route.meta.fragments;
    if(!Array.isArray(fragments)) {
      fragments = [fragments];
    }
    return fragments;
  }

  /**
   * mergeRouteConfigs
   * This method merges several route config and returned the merged config.
   * It deep merge the meta information.
   * @param routeConfigs - route configs 
   */
  export function mergeRouteConfigs(...routeConfigs: RouteConfig[]) {
    return mergeObjects<RouteConfig>(...routeConfigs);
  }

  /**
   * addLocalizedRoutes
   * This method return the routes with additional routes supporting language prefix.
   * @param routes - the routes for which we want to add language prefix support
   */
  export function addLocalizedRoutes(routes: RouteConfig[], supportedLanguages: string[]) {
    var keptRoutes:RouteConfig[] = [];
    
    // We build the pattern with listed supported languages
    var routePatternPrefix = getLocalizedPathPrefix(supportedLanguages);

    const localizedRoutes:RouteConfig[] = routes.filter(function(route:RouteConfig, index, array) {
      // We exclude the default route from the localized list.
      if(route.path == '*') {
        // We add the default route to the list.
        keptRoutes.push({...route});
        return false;
      }
      return true;
    }).map(function(route:RouteConfig, index, array) {
      var localizedRoute = {...route};
      
      // We update the path to add the language prefix
      localizedRoute.path = routePatternPrefix + localizedRoute.path;

      // We check if the route is a redirect
      if(localizedRoute.redirect) {
        // We update the redirect to add the language prefix
        if(typeof localizedRoute.redirect == 'string') {
          localizedRoute.redirect = routePatternPrefix + localizedRoute.redirect;
        }
      }
      
      // We add specific meta to be able to identify them.
      if(!localizedRoute.meta) {
        localizedRoute.meta = {};
      }
      else {
        localizedRoute.meta = {...localizedRoute.meta};
      }
      localizedRoute.meta[IS_LOCALIZED_PATH] = true;
      return localizedRoute;
    });

    const mergedRoutes:RouteConfig[] = [
      ...localizedRoutes,
      // We do not keep the routes at root level as we have specific redirection
      // ...routes
      ...keptRoutes
    ];

    return mergedRoutes;
  }

  /**
   * @name scrollBehavior
   * @description This method handle the scroll behavior when switching between routes.
   * @param to the destination route.
   * @param from the previous route.
   * @param savedPosition the saved position if destination route was already in history.
   * @param router the VueRouter instance.
   */
  export function scrollBehavior (to:Route, from:Route, savedPosition: void | Position, router:VueRouter) : PositionResult | Promise<PositionResult> {
    if (savedPosition) {
      // savedPosition is only available for popstate navigations.
      return savedPosition
    } else {
  
      // scroll to anchor by returning the selector
      if (to.hash) {
        let position:PositionResult = {
          selector: to.hash,
          offset: undefined
        }
  
        // specify offset of the element
        if (to.hash === '#anchor2') {
          position.offset = { x:0, y: 100 }
        }
  
        if (document.querySelector(to.hash)) {
          return position
        }
  
        // if the returned position is falsy or an empty object,
        // will retain current scroll position.
        return;
      }

      return new Promise(resolve => {
        let position = {} as Position;
        // check if any matched route config has meta that requires scrolling to top
        if (to.matched.some(m => m.meta.scrollToTop)) {
          // coords will be used if no selector is provided,
          // or if the selector didn't match any element.
          position.x = 0;
          position.y = 0;
        }
        
        // We check if the target route expects position to be kept coming from current route
        var currentRouteShortName = utils.getRouteShortNameFromRoute(from); 
        var targetRouteShortName = utils.getRouteShortNameFromRoute(to);
        if(to.meta && to.meta.keepScrollFrom && to.meta.keepScrollFrom.indexOf(currentRouteShortName) > -1) {
          var routePosition = router.app.$store.getters['router/' + routerTypes.getters.GET_PREVIOUS_ROUTE_SCROLL](1);
          // We check if the previous route position is matching the one we target again.
          if(routePosition && routePosition.routeShortName == targetRouteShortName) {
            // We are coming back to previous page. We restore the scrolling.
            position = routePosition.position;
          }
        }
  
        // wait for the out transition to complete (if necessary)
        // This event is emitted once the transition is done on App.vue afterLeave.
        // We ensure the handler is added only once time.
        router.app.$root.$off('triggerScroll', triggerScrollListener);
        
        // If the trigger scroll event has already been fired on the new page
        // (catched by the generic listener)
        // We return the position directly
        if(listenerPageShortName == targetRouteShortName) {
          if(process.env.CONSOLE == "LOG") {
            console.log("ROUTER - UTILS - TRIGGER SCROLL RESOLVED")
          }
          return resolve(position);
        }
        else {
          // The trigger scroll event has not yet been fired on the new page so we add listener
          triggerScrollListener = () => {
            if(process.env.CONSOLE == "LOG") {
              console.log("ROUTER - UTILS - TRIGGER SCROLL EVENT CATCHED")
            }
            
            // if the resolved position is falsy or an empty object,
            // will retain current scroll position.
            return resolve(position);
          }
          if(process.env.CONSOLE == "LOG") {
            console.log("ROUTER - UTILS - TRIGGER SCROLL EVENT REGISTERED")
          }
          router.app.$root.$once('triggerScroll', triggerScrollListener)
        }
      })
    }
  }

  // We keep link to the trigger scroll listener function to be able to remove event listener.
  var triggerScrollListener:Function|undefined;
  // We keep link to the trigger scroll generic listener function to be able to remove event listener.
  var triggerScrollGenericListener:Function|undefined;
  // We store the page short name catched by the generic listener.
  var listenerPageShortName:string;

  /**
   * Generic listener for trigger scroll event
   * @param app 
   */
  export function registerTriggerScrollEvent(app:Vue) {
    if(triggerScrollGenericListener) {
      app.$root.$off('triggerScroll', triggerScrollGenericListener);
    }
    else {
      triggerScrollGenericListener = () => {
        if(process.env.CONSOLE == "LOG") {
          console.log("ROUTER - UTILS - TRIGGER SCROLL GENERIC CATCH")
        }
        var targetRouteShortName = utils.getRouteShortNameFromRoute(app.$router.currentRoute);
        listenerPageShortName = targetRouteShortName;
      };
    }
    app.$root.$on('triggerScroll', triggerScrollGenericListener);
  }
}