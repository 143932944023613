<template>
    <div>Generic Input</div>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';

@Component({})
export default class GenericInput<T> extends Vue {
  
  @Prop({
    type: [Number,String,Date,Boolean,Object,Array],
    required: false
  }) readonly value!: T | undefined

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly required!: boolean

  @Prop({
    type: String,
    required: false
  }) readonly id!: string | undefined

  @Prop({
    type: String,
    required: false
  }) readonly label!: string

  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) readonly labelAsColumn!: boolean

  @Prop({
    type: Object,
    required: false
  }) readonly rowClass!: any

  @Prop({
    type: Object,
    required: false
  }) readonly labelClass!: any

  @Prop({
    type: Object,
    required: false
  }) readonly fieldClass!: any

  @Prop({
    type: Object,
    required: false
  }) readonly inputClass!: any

  @Prop({
    type: Object,
    required: false
  }) readonly controlClass!: any

   @Prop({
    type: String,
    required: false
  }) readonly placeholder!: string

  @Prop({
    type: String,
    required: false,
    default: "eager",
  }) readonly validationMode!: string

  @Prop({
    type: Object,
    required: false,
    default: () => { return {} }
  }) readonly validationRules!: any

  input:any = this.value !== undefined ? this.value : "";

  labelCSSClass:any = this.labelClass ? this.labelClass : this.labelAsColumn ? {"col-lg-3":true, "col-form-label":true} : null
  fieldCSSClass:any = this.fieldClass ? this.fieldClass : this.labelAsColumn ? {"col-lg-9":true} : null;
  inputCSSClass:any = this.inputClass ? this.inputClass : {"form-control":true};
  controlCSSClass:any = this.controlClass ? this.controlClass : {"invalid-feedback":true};

  get rowCSSClass() {
    if(this.rowClass) { return this.rowClass; }
    return this.labelAsColumn ? {"form-group":true, "row":true} : {"form-group":true};
  } 

  get computedValidationRules() {
    var validationRules:any = {};
    if(this.validationRules) {
      validationRules = {
        ...this.validationRules
      }
    }
    if(this.required) {
      validationRules["required"] = true;
    }
    return validationRules;
  }

  @Watch('input', { deep: true })
  onInputChange(val: any, oldVal: any) {
    this.$emit('update:value', val);
  }

  @Watch('value', { deep: true })
  onValueChange(val: any, oldVal: any) {
    this.input = val;
  }
  
}
</script>