<template>
    <div>Generic Page</div>
</template>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { languagesTypes, metaTypes} from '@fwk-client/store/types';
import { MetaInfo } from '@fwk-client/utils/class-component-decorators';
import { RouteMeta } from '@fwk-client/types/meta';
import { utils } from '@fwk-client/router/utils';
import { getPath } from '@igotweb-node-api-utils/misc';

@Component({})
export default class GenericPage extends Vue {
  metas:any;
  bodyClasses:string[] = [];

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  get routeMeta():RouteMeta {
    return this.$store.getters['meta/' + metaTypes.getters.GET_ROUTE_METAS];
  }

  get currentBodyClasses() {
    return this.$store.getters['meta/' + metaTypes.getters.GET_BODY_CLASSES];
  }

  get currentStyle() {
    return this.$store.getters['meta/' + metaTypes.getters.GET_STYLE];
  }

  get hreflangLinks() {
    const baseURL = (this.$store.state.server && this.$store.state.server.baseURL) ? this.$store.state.server.baseURL : null;
    const route = this.$store.state.route;
    const supportedLanguages:string[] = this.$store.getters['languages/' + languagesTypes.getters.GET_AVAILABLE_LANGUAGES]
    if(baseURL) {
      var links = [];
      // We generate the link for each language we have.
      for(var languageCode of supportedLanguages) {
        var link = {
          vmid: 'hreflang-'+languageCode,
          rel: 'alternate',
          hreflang: languageCode,
          href: baseURL + utils.getRoutePathWithLanguage(route, languageCode)
        }
        links.push(link);
      }

      // We generate the default link
      var link = {
        vmid: 'hreflang-x-default',
        rel: 'alternate',
        hreflang: 'x-default',
        href: baseURL + utils.getRoutePathWithLanguage(route)
      }
      links.push(link);
      
      return links;
    }
    return [];
  }

  get faviconMeta() {
    return this.$store.getters['meta/' + metaTypes.getters.GET_FAVICON_METAS];
  }

  get stylesMeta() {
    return this.$store.getters['meta/' + metaTypes.getters.GET_STYLES_METAS];
  }

  get appMeta() {
    return this.$store.getters['meta/' + metaTypes.getters.GET_APPLICATION_METAS];
  }

  get configMeta() {
    // We get the meta exposed by the fwk config
    var metas = getPath(this.$store.state, "server.configuration.fwk.metas");
    var configMeta:any =  {
      meta:[],
      links:[],
      htmlAttrs:{}
    }
    if(metas && metas.meta) { configMeta.meta.push(...metas.meta); }
    if(metas && metas.links) { configMeta.links.push(...metas.links); }
    if(metas && metas.htmlAttrs) { configMeta.htmlAttrs = {
        ...configMeta.htmlAttrs,
        ...metas.htmlAttrs
      }; 
    }
    return configMeta;
  }

  @MetaInfo
  metaInfo() {
    const routeMeta:RouteMeta = this.routeMeta;
    const bodyClasses = this.currentBodyClasses;
    const hreflangLinks = this.hreflangLinks;
    const faviconLinks = this.faviconMeta.links;
    const faviconMeta = this.faviconMeta.meta;
    const currentStyle = this.currentStyle;
    const appMeta = this.appMeta;
    const configMeta = this.configMeta;
    const stylesMeta = this.stylesMeta;

    var metaInfo = {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: routeMeta.title || "",
      // all titles will be injected into this template
      titleTemplate: undefined,
      htmlAttrs: { 
        // attributes within HTML tag
        lang : this.currentLanguageCode,
        prefix : "og: https://ogp.me/ns#",
        ...configMeta.htmlAttrs
      },
      headAttrs: {
        // attributes within HEAD tag
      },
      bodyAttrs: {
        class : bodyClasses.join(" ")
      },
      meta : [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' },
        ...faviconMeta,
        ...configMeta.meta
      ],
      link : [
        ...hreflangLinks,
        ...faviconLinks,
        ...configMeta.links
      ],
      style: []
    }
    if(stylesMeta && stylesMeta.length > 0) {
      (metaInfo.link as Array<any>).push(...stylesMeta.map((style:string) => {
        return { rel: 'stylesheet', type : "text/css" , href : style}
      }))
    }
    if(appMeta && appMeta.meta) {
      (metaInfo.meta as Array<any>).push(...appMeta.meta);
    }
    if(appMeta && appMeta.link) {
      (metaInfo.link as Array<any>).push(...appMeta.link);
    }
    if(currentStyle && currentStyle != "") {
      (metaInfo.style as Array<any>).push({ cssText: currentStyle, type: 'text/css' });
    }
    if(routeMeta.description) {
      (metaInfo.meta as Array<any>).push({ vmid: 'description', name : "description" , content : routeMeta.description});
    }
    if(routeMeta.socialMedias) {
      /*

      <!--  Essential META Tags -->
      <meta property="og:title" content="European Travel Destinations">
      <meta property="og:description" content="Offering tour packages for individuals or groups.">
      <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg">
      <meta property="og:url" content="http://euro-travel-example.com/index.htm">
      <meta property="og:image:secure_url" content="https://euro-travel-example.com/thumbnail.jpg">
      <meta name="twitter:card" content="summary_large_image">

      <!--  Non-Essential, But Recommended -->
      <meta property="og:site_name" content="European Travel, Inc.">
      <meta name="twitter:image:alt" content="Alt text for image">
      <meta name="og:image:alt" content="Alt text for image">
      <meta property="og:type" content="website or article">

      */

      if(routeMeta.socialMedias.title) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:title', property : "og:title" , content : routeMeta.socialMedias.title});
      }
      if(routeMeta.socialMedias.description) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:description', property : "og:description" , content : routeMeta.socialMedias.description});
      }
      if(routeMeta.socialMedias.image) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:image', property : "og:image" , content : routeMeta.socialMedias.image.replace("https","http")});
        (metaInfo.meta as Array<any>).push({ vmid: 'og:image:secure_url', property : "og:image:secure_url" , content : routeMeta.socialMedias.image});
        //og:image:width
        //og:image:height
        (metaInfo.meta as Array<any>).push({ vmid: 'twitter:card', name : "twitter:card" , content : "summary_large_image"});
      }
      if(routeMeta.socialMedias.imageAlt) {
        (metaInfo.meta as Array<any>).push({ vmid: 'twitter:image:alt', name : "twitter:image:alt" , content : routeMeta.socialMedias.imageAlt});
        (metaInfo.meta as Array<any>).push({ vmid: 'og:image:alt', name : "og:image:alt" , content : routeMeta.socialMedias.imageAlt});
      }
      if(routeMeta.socialMedias.url) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:url', property : "og:url" , content : routeMeta.socialMedias.url});
      }
      if(routeMeta.socialMedias.siteName) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:site_name', property : "og:site_name" , content : routeMeta.socialMedias.siteName});
      }
      if(routeMeta.socialMedias.type) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:type', property : "og:type" , content : routeMeta.socialMedias.type});
      }
    }
    return metaInfo;
  }
  
}
</script>