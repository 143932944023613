import { Module, Store } from '@fwk-node-modules/vuex';
import Vue from '@fwk-node-modules/vue';
import { types as moduleTypes } from '@fwk-client/modules/hospitality/stores';
import * as api from '@fwk-client/utils/api';
import { getMethodOfPaymentFromType } from '@fwk-client/modules/shop/stores/shop';

export const types = {
  getters : {
    GET_AGENCY : 'GET_AGENCY',
    GET_AGENCY_APARTMENTS : 'GET_AGENCY_APARTMENTS',
    GET_AGENCY_APARTMENT_FROM_CODE : 'GET_AGENCY_APARTMENT_FROM_CODE',
    GET_AGENCY_APARTMENT_CODE_FROM_APARTMENT : 'GET_AGENCY_APARTMENT_CODE_FROM_APARTMENT',
    GET_AGENCY_METHOD_OF_PAYMENTS : 'GET_AGENCY_METHOD_OF_PAYMENTS',
    GET_AGENCY_METHOD_OF_PAYMENTS_CARD : 'GET_AGENCY_METHOD_OF_PAYMENTS_CARD',
  },
  mutations : {
    SET_AGENCY : 'SET_AGENCY',
    CLEAR_APARTMENTS : 'CLEAR_APARTMENTS'
  },
  actions : {
    UPDATE_APARTMENT : 'UPDATE_APARTMENT',
    UPDATE_APARTMENTS : 'UPDATE_APARTMENTS'
  }
}

const privateTypes = {
  mutations : {
    SET_AGENCY_APARTMENTS: 'SET_AGENCY_APARTMENTS',
    SET_APARTMENT : 'SET_APARTMENT'
  },
  actions : {
    
  }
}

export const statePath:string = 'hospitality/agency/';

export function createAgencyStore () {

  const store:Module<any, any> = {
    namespaced: true,
    state: {
      agencies : {},
      apartments : {
        agencyCode: "", // The agency code associated to products
        list: [], // the list of apartments available for current agency,
        datetime: null // The datetime when products have been retrieved
      },
    },
    getters: {  
      [types.getters.GET_AGENCY](state) {
        return function(agencyCode?:string) {
          if(!agencyCode) {
            var keys = Object.keys(state.agencies);
            if(keys && keys.length == 1) {
              return state.agencies[keys[0]];
            }
          }
          else if(state.agencies[agencyCode]) {
            return state.agencies[agencyCode];
          }
          else {
            // We check as well on the ID
            var keys = Object.keys(state.agencies);
            for(var key of keys) {
              var agency = state.agencies[key];
              if(agency._id == agencyCode) {
                return agency;
              }
            }
          }
          return null; 
        }
      },
      [types.getters.GET_AGENCY_APARTMENTS](state) {
        if(state.apartments && state.apartments.list) {
          return state.apartments.list;
        }
        return [];
      },
      [types.getters.GET_AGENCY_APARTMENT_CODE_FROM_APARTMENT](state) {
        return function(apartment:any) {
          if(state.apartments && state.apartments.list) {
            var index = state.apartments.list.map((apartment:any) => {
              return apartment._id;
            }).indexOf(apartment._id);
            if(index > -1) {
              var identified = state.apartments.list[index];
              // We first return the code if any
              if(identified.code) { 
                return identified.code;
              }
              else {
                // We fallback on the id if no code is available here.
                return identified._id;
              }
            }
          }
          return null; 
        }
      },
      [types.getters.GET_AGENCY_APARTMENT_FROM_CODE](state) {
        return function(code:string) {
          if(state.apartments && state.apartments.list) {
            // We loop on the list of apartments 
            for(var apartment of state.apartments.list) {
              // We first check if the code is found
              if((apartment.code && apartment.code == code) ||
                apartment._id == code) {
                return apartment;
              }
            }
          }
          return null; 
        }
      },
      [types.getters.GET_AGENCY_METHOD_OF_PAYMENTS](state, getters) { 
        var agency = getters[types.getters.GET_AGENCY]();
        if(agency && agency.methodOfPayments) {
          return agency.methodOfPayments;
        }
        return []; 
      },
      [types.getters.GET_AGENCY_METHOD_OF_PAYMENTS_CARD](state, getters) { 
        var agency = getters[types.getters.GET_AGENCY]();
        return getMethodOfPaymentFromType(agency, 'CARD');
      },
    },
    mutations: {
      [types.mutations.SET_AGENCY] (state, agency:any) { 
        Vue.set(state.agencies, agency.code, agency);
      },
      [privateTypes.mutations.SET_APARTMENT] (state, data:{apartment:any, index?:number}) { 
        if(data.index != undefined && data.index > -1) {
          Vue.set(state.apartments.list, data.index, data.apartment)
        }
        else {
          state.apartments.list.push(data.apartment);
        }
      },
      [privateTypes.mutations.SET_AGENCY_APARTMENTS] (state:any, {list, agencyCode}) { 
        Vue.set(state.apartments, "datetime", (new Date()).getTime());
        Vue.set(state.apartments, "list", list);
        Vue.set(state.apartments, "agencyCode", agencyCode);
      },
      [types.mutations.CLEAR_APARTMENTS] (state) { 
        Vue.set(state.apartments, "datetime", null);
        Vue.set(state.apartments, "list", []);
        Vue.set(state.apartments, "agencyCode", "");
      }
    },
    actions: {
      /**
       * UPDATE_APARTMENT
       * This action update the apartment for one agency.
       * @param app - The Vue instance
       */
      [types.actions.UPDATE_APARTMENT] ({state, commit, getters, rootGetters}, {app, apartment}):void {
        var agency = getters[types.getters.GET_AGENCY](apartment.agency_id);
        // We check if the list available is for the same agency
        if(state.apartments.agencyCode == agency.code) {
          // We update the apartment
          var indexApartment = state.apartments.list.map((apartment:any) => {
            return apartment._id
          }).indexOf(apartment._id);

          // We update the apartment in the list
          commit(privateTypes.mutations.SET_APARTMENT, {
            apartment,
            index: indexApartment
          });

          
        }
        else {
          // We have list from an other agency or we have no list
          commit(privateTypes.mutations.SET_AGENCY_APARTMENTS, {
            list:[apartment],
            agencyCode: agency.code
          });
        }
      },
      /**
       * UPDATE_APARTMENTS
       * This action update the apartments for current agency.
       * @param app - The Vue instance
       */
      [types.actions.UPDATE_APARTMENTS] ({state, commit, getters, rootGetters}, {app, agencyCode}):Promise<void> {
        var agency = getters[types.getters.GET_AGENCY](agencyCode);

        // We check if we already have apartments for the current agency for more than 5min
        const EXPIRATION_TIME = 5 * 60 * 1000; // 5 minutes expiration
        if(state.apartments && state.apartments.list && state.apartments.list.length > 0 && state.apartments.datetime && (new Date()).getTime() - state.apartments.datetime < EXPIRATION_TIME) {
          return Promise.resolve();
        }

        commit('hospitality/' + moduleTypes.mutations.START_AGENCY_APARTMENTS_LOADING,{}, {root: true});

        var options:api.ApiVueOptions =  {
          app: app
        }

        return api.getAPI('/api/hospitality/agency/'+agency.code+'/apartments', options).then((response:any) => {
          commit('hospitality/' + moduleTypes.mutations.STOP_AGENCY_APARTMENTS_LOADING,{},{root:true});
          if(response.apartments) {  
              // We update the list of apartments
              commit(privateTypes.mutations.SET_AGENCY_APARTMENTS, {
                list: response.apartments,
                agencyCode: agencyCode
              });
          }
          else {
              commit(privateTypes.mutations.SET_AGENCY_APARTMENTS, {
                list:[],
                agencyCode: ""
              });
          }
          return Promise.resolve();
        });
      }
    }
  }
  return store;
};

var paths: {[x:string]:string} = {};
for(var key of Object.keys(types.getters)) {
  paths[key] = statePath + key;
}
export const gettersPath = paths;

export const getters = {
  $hospitality_apartments : {
    get: function (this:Vue) {
      var apartments = this.$store.getters[gettersPath[types.getters.GET_AGENCY_APARTMENTS]];
      return apartments;
    }
  },
  $hospitality_apartment : {
    get: function (this:Vue) {
      return (apartmentCode:string) => {
        var apartment = this.$store.getters[gettersPath[types.getters.GET_AGENCY_APARTMENT_FROM_CODE]](apartmentCode);
        if(apartment) { return apartment; }
        return undefined;
      }
    }
  },
  $hospitality_methodOfPayments : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_AGENCY_METHOD_OF_PAYMENTS]];
    }
  },
  $hospitality_methodOfPayments_card : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_AGENCY_METHOD_OF_PAYMENTS_CARD]];
    }
  },
  $hospitality_agency : {
    get: function (this:Vue) {
      return this.$store.getters[gettersPath[types.getters.GET_AGENCY]]();
    }
  },
}