<template>
  <component :is="currentComponent" v-bind="componentProps" :key="componentKey" :components="component.components" @hook:mounted="$cms.components.onComponentMounted(component)" v-on="$listeners"></component>
</template>

<script lang="ts">
import { Component } from '../helpers/components';
import { languagesTypes } from '@fwk-client/store/types';
import { defineComponent, computed, PropType, onMounted } from '@fwk-node-modules/vue';
import { useStore, useCmsModule } from '@fwk-client/utils/vue-3-migration';

export default defineComponent({
    props: {
        component: {
            type: [Object,String,Function] as PropType<Component>
        }
    },
    setup(props, context) {

        // We need the router and refs
        const store = useStore(); 
        const $cms = useCmsModule();

        const currentComponent = computed(() => {
            var component = $cms.components.getComponent(props.component);
            return component;
        })

        const componentProps = computed(() => {
            var componentProps = $cms.components.getComponentProps(props.component);
            // We forward all properties added to the CmsComponent tag
            var currentAttrs = context.attrs;
            if(Object.keys(currentAttrs).length > 0) {
                componentProps = {
                    ...componentProps,
                    ...currentAttrs
                }
            }
            return componentProps;
        })

        const currentLanguageCode = computed(() => {
            var languageCode = store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE] as string
            return languageCode;
        })
        

        const componentKey = computed(() => {
            var key = currentLanguageCode.value;
            return key
        })

        return {
            currentComponent,
            componentKey,
            componentProps
        }

    }
})
</script>